import { Component } from '@angular/core';

@Component({
  selector: 'app-fourohfour',
  templateUrl: './fourohfour.component.html',
  styleUrls: ['./fourohfour.component.scss'],
})
export class FourohfourComponent {
  constructor() {}
}
