export type FirewallAddRequest = {
  type: string;
  name: string;
  comment: string;
  accountId: string;
  sourceAddress: string;
  destinationAddress: string;
  service: string;
  securityProfile: string;
  sourceNat: string;
  destinationNat: string;
  urls: string;
  cc_emails: string;
};

export type FirewallModifyRequest = {
  type: string;
  name: string;
  comment: string;
  accountId: string;
  policyid: string;
};

export type FirewallDeleteRequest = {
  type: string;
  name: string;
  comment: string;
  accountId: string;
  policyid: string;
  confirmed: boolean;
};

export type FirewallRuleRequest =
  | FirewallAddRequest
  | FirewallModifyRequest
  | FirewallDeleteRequest;

export type FirewallChangeRequestError = {
  name: string[];
  source: string[];
  destination: string[];
  service: string[];
  securityProfile: string[];
  urls: string[];
};

export enum FirewallRuleChangeType {
  ADD = 'add',
  MODIFY = 'modify',
  DELETE = 'delete',
}
