export enum FieldType {
  PASSWORD = 'password',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  CODE = 'code',
}

export enum FieldStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}
