import { HttpErrorResponse } from '@angular/common/http';

export interface ErrorData {
  data: {
    message: string;
  };
  datetime: string;
  meta: {};
  updated_at: number;
}

export class ApiErrorResponse extends HttpErrorResponse {
  error!: ErrorData;
}

export interface ValidationError {
  [key: string]: string[];
}

export const UserValidationError: ValidationError = {
  givenName: [],
  familyName: [],
  accountId: [],
  email: [],
  countryCode: [],
  phoneNumber: [],
  jobTitle: [],
  groups: [],
  partnerId: [],
};
