import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConnectService } from '@connect/connect.service';
import { Observable, Observer, Subscription, take } from 'rxjs';
import { AccountService } from '@shared/services/account.service';
import { ErrorService } from '@shared/services/error.service';

/* Models */
import {
  ButtonDisplay,
  ButtonRole,
  ButtonStatus,
  ButtonType,
} from '@shared/models/button.model';
import { Account } from '@shared/models/account';

/* Store */
import * as StatusActions from '@store/status/status.actions';
import { Store } from '@ngrx/store';
import { FileOptions, FilePreviewModel } from '@shared/models/file-options';
import { MediaService } from '@shared/services/media.service';
import { SSLVPN } from '@connect/models/ssl-vpn.model';

@Component({
  selector: 'app-bulk-upload-users',
  templateUrl: './bulk-upload-users.component.html',
  styleUrls: ['./bulk-upload-users.component.scss'],
})
export class BulkUploadUsersComponent implements OnInit, OnDestroy {
  @Output() modalClosed: EventEmitter<boolean> = new EventEmitter();

  public ButtonType = ButtonType;
  public ButtonRole = ButtonRole;
  public ButtonDisplay = ButtonDisplay;
  public ButtonStatus = ButtonStatus;

  private accountSubscription!: Subscription;
  private accountId!: string;
  private sslVpnLicenceCount!: number;

  public fileOptions: FileOptions = {
    directory: 'remote-users',
    fileTypes: ['csv'],
    fileSize: 300,
    fileCount: 1,
    endpoint: '/settings/csv-transform?type=bulk-create-user',
  };
  public uploadedFileName: string = '';
  public uploadError: string = '';
  public csvRowLength: number = 0;
  public submitButtonStatus: ButtonStatus = ButtonStatus.DISABLED;
  private vpnCount: number = 0;

  constructor(
    private toastr: ToastrService,
    private connectService: ConnectService,
    private accountService: AccountService,
    private store: Store,
    private errorService: ErrorService,
    private mediaService: MediaService
  ) {}

  ngOnInit(): void {
    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(take(1))
      .subscribe((account?: Account) => {
        if (account && account.id) {
          this.accountId = account.id;
          this.fileOptions.endpoint += `&accountId=${account.id}`;
          this.sslVpnLicenceCount = account.sslVpnLicenceCount || Infinity;
          this.checkButtonStatus().subscribe({
            next: (status) => {
              this.submitButtonStatus = status;
            },
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.accountSubscription?.unsubscribe();
  }

  private checkButtonStatus(): Observable<ButtonStatus> {
    // Get number of existing VPNs
    return new Observable((observer: Observer<ButtonStatus>) => {
      this.connectService
        .getSSLVPNs({ accountId: this.accountId, cacheBreak: false })
        .subscribe({
          next: (vpns?: SSLVPN[]) => {
            // Enable 'create users' button if limit is not reached
            this.vpnCount = vpns?.length ?? 0;
            if (
              this.sslVpnLicenceCount &&
              this.csvRowLength &&
              this.vpnCount + this.csvRowLength <= this.sslVpnLicenceCount
            ) {
              observer.next(ButtonStatus.ACTIVE);
            } else {
              observer.next(ButtonStatus.DISABLED);
            }
          },
        });
    });
  }

  public onUploadSuccess(file: FilePreviewModel) {
    // Get filename from API response
    if (file.uploadResponse.body?.data) {
      this.uploadedFileName = `${file.uploadResponse.body.data.directory}/${file.uploadResponse.body.data.fileName}`;
      this.csvRowLength = file.uploadResponse.body.data.rowLength;
      if (this.csvRowLength > 100) {
        this.csvRowLength = 0; // Reset text in button
        this.toastr.warning(
          `The maximum number of rows in your CSV file is 100. Please split your file into multiple parts and try again.`,
          'Warning',
          {
            progressBar: true,
          }
        );
        return;
      }
      this.checkButtonStatus().subscribe({
        next: (status) => {
          this.submitButtonStatus = status;
          if (status === ButtonStatus.DISABLED) {
            const numberToRemove =
              this.vpnCount + this.csvRowLength - this.sslVpnLicenceCount;
            const removeUserString = numberToRemove === 1 ? 'user' : 'users';
            const userString = this.csvRowLength === 1 ? 'user' : 'users';
            const remainingLicences = this.sslVpnLicenceCount - this.vpnCount;
            const remainingLicenceString =
              remainingLicences === 1 ? 'licence' : 'licences';
            this.toastr.warning(
              `Not enough licences. You are trying to add ${this.csvRowLength} more ${userString}, but you only have ${remainingLicences} ${remainingLicenceString} remaining. Please remove ${numberToRemove} ${removeUserString} and try again.`,
              'Warning',
              {
                closeButton: true,
                disableTimeOut: true,
              }
            );
            this.csvRowLength = 0; // Reset text in button
          }
        },
      });
    }
  }

  public onRemoveSuccess(file: FilePreviewModel) {
    this.csvRowLength = 0; // Reset text in button
    this.submitButtonStatus = ButtonStatus.DISABLED;
    this.uploadedFileName = '';
  }

  public openSampleFile() {
    this.mediaService.open('add-users-example.csv');
  }

  public createUsers() {
    // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(StatusActions.setWaiting({ waiting: true }));
    this.connectService
      .bulkUserCreate(
        {
          fileName: this.uploadedFileName,
        },
        this.accountId
      )
      .subscribe({
        next: () => {
          // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
          this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
          this.toastr.success(
            'Users will be created in the background and will appear in the list shortly.',
            'Success',
            {
              progressBar: true,
            }
          );
          this.modalClosed.emit();
        },
        error: (err) => {
          this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
          this.errorService.handleSaveErrors(err);
        },
      });
  }

  public cancel() {
    this.modalClosed.emit();
  }
}
