import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Account } from '@shared/models/account';
import { FirewallModifyRequest } from '@shared/models/firewall-change-request.model';
import { AccountService } from '@shared/services/account.service';
import { Subscription, catchError, take } from 'rxjs';
import { FieldType } from '@forms/models/form-field.model';
import { IconSize, IconType } from '@shared/models/icon.model';

import { ValidationError } from '@shared/models/error';
import { PillSize, PillStatus } from '@shared/models/pill.model';
import { SupportValidationService } from '@support/support-validation.service';
import { Status } from '@shared/models/status.model';
import {
  ExtendedFirewallRule,
  FirewallRule,
  FirewallType,
} from '@shared/models/firewall.model';
import { FirewallStatus } from '@shared/models/firewall.datatypes';
import { ProtectService } from '@protect/protect.service';
import { SubnetToCIDRPipe } from '@protect/pipes/subnet-to-cidr.pipe';

@Component({
  selector: 'app-firewall-rule-modify',
  templateUrl: './firewall-rule-modify.component.html',
  styleUrls: ['./firewall-rule-modify.component.scss'],
  providers: [SubnetToCIDRPipe],
})
export class FirewallRuleModifyComponent implements OnInit, OnDestroy {
  @Input() type = FirewallType.REGULAR;
  @Input() formDataInput!: FirewallModifyRequest;
  @Output() FormUpdated = new EventEmitter<FirewallModifyRequest>();
  @Output() FormValid = new EventEmitter<boolean>();

  public accountSubscription?: Subscription;
  public accountId!: string;

  // Table properties
  public tableData: FirewallRule[] = [];
  public tableColumns: string[] = ['selected', 'name', 'source', 'destination'];
  public tableStatus: Status = Status.LOADING;
  public filteredData: FirewallRule[] = [];
  public proxyIps?: string;
  public proxyPort?: string;
  public searchText = '';

  // Styling and data constants
  public FirewallStatus = FirewallStatus;
  public IconSize = IconSize;
  public IconType = IconType;
  public FirewallType = FirewallType;
  public PillStatus = PillStatus;
  public PillSize = PillSize;
  public FieldType = FieldType;

  public formData: FirewallModifyRequest = {
    type: 'Modify an existing rule',
    name: '',
    comment: '',
    accountId: '',
    policyid: '',
  };

  public errors: ValidationError = {
    comment: [],
  };

  public selectedRule: FirewallRule | null = null;

  constructor(
    private accountService: AccountService,
    private protectService: ProtectService,
    private supportValidationService: SupportValidationService,
    private subnetToCIDRPipe: SubnetToCIDRPipe
  ) {}

  ngOnInit(): void {
    this.formData = this.formDataInput;

    this.tableColumns =
      this.type === FirewallType.REGULAR
        ? ['selected', 'name', 'source', 'destination']
        : ['selected', 'name', 'proxy', 'to', 'source', 'destination'];

    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(take(1))
      .subscribe((account?: Account) => {
        if (account?.id) {
          this.accountId = account.id;
          this.formData.accountId = account.id;
          this.getData();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
  }

  /**
   * Retrieves data from protect service and applies to component data
   */
  public getData(): void {
    this.protectService
      .getFirewallRules({ accountId: this.accountId, firewallType: this.type })
      .pipe(
        catchError((error) => {
          this.tableStatus = Status.ERROR;
          throw error;
        })
      )
      .subscribe((rules: FirewallRule[] | undefined) => {
        if (rules) {
          this.tableData = rules;
          this.filteredData = rules;
          this.tableStatus = Status.COMPLETE;
          this.proxyIps = this.getProxyIps();
          this.proxyPort = rules[0].proxyPort;
          if (this.formData?.policyid) {
            this.setSelectedRule();
          }
        }
      });
  }

  /**
   * Formats proxyIps into a single string
   */
  public getProxyIps(): string | undefined {
    return this.filteredData?.[0].proxyIps
      ?.map((ip) => this.subnetToCIDRPipe.transform(ip, ip))
      .join(', ');
  }

  /**
   * Filter the table data
   */
  public filter(): void {
    this.filteredData = this.tableData;

    if (this.searchText) {
      this.filteredData = this.filterByText();
    }
  }

  /**
   * Text search filter - case-insensitive
   * @returns { FirewallRule[] } list of rules matching the text filter
   */
  public filterByText(): FirewallRule[] {
    const filteredFirewallRules = this.tableData.filter(
      (
        firewallRule: FirewallRule | ExtendedFirewallRule
      ): boolean | undefined => {
        return (
          firewallRule.name
            ?.toLowerCase()
            .indexOf(this.searchText.toLowerCase()) > -1 ||
          firewallRule.policyid?.toString().startsWith(this.searchText)
        );
      }
    );
    return filteredFirewallRules;
  }

  /**
   * Preselects row in table if provided
   */
  public setSelectedRule(): void {
    this.searchText = this.formData.name;
    this.filter();

    const ruleFound = this.tableData.find(
      (rule) => rule.policyid === parseInt(this.formData.policyid)
    );

    if (ruleFound) {
      this.selectedRule = ruleFound;
    }
  }

  /**
   * Handle rule selection
   * @param data Rule selected from table
   */
  public onRowClick(data: unknown) {
    const rule = data as FirewallRule | ExtendedFirewallRule;

    if (rule.policyid === this.selectedRule?.policyid) {
      this.selectedRule = null;
      this.formData.policyid = '';
      this.formData.name = '';
    } else {
      this.selectedRule = rule;
      this.formData.policyid = rule.policyid.toString();
      this.formData.name = rule.name;
    }

    this.handleFormUpdate();
  }

  /**
   * Handle the focus event from the form field
   * @param prop Name of the property to clear
   */
  public handleFocus(prop: string): void {
    this.errors[prop] = [];
  }

  /**
   * Handle the blur event from the form field
   * @param prop Name of the property to validate
   */
  public handleBlur(prop: string) {
    const validation =
      this.supportValidationService.validateFirewallModifyRequest(
        this.formData
      );
    if (validation === true) {
      this.errors[prop] = [];
    } else {
      this.errors[prop] = validation[prop];
    }
  }

  /**
   * Emits relevant events for parent component
   */
  public handleFormUpdate(): void {
    if (this.isFormValid()) {
      this.FormValid.emit(true);
    } else {
      this.FormValid.emit(false);
    }
    this.FormUpdated.emit(this.formData);
  }

  /**
   * Checks validation errors and adds them to the page
   * @return boolean
   */
  public isFormValid(): boolean {
    const validationResponse =
      this.supportValidationService.validateFirewallModifyRequest(
        this.formData
      );

    return validationResponse === true ? true : false;
  }
}
