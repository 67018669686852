<h3 class="firewall-security-filters__title">Security Profiles</h3>

<div *ngFor="let profile of data.securityprofiles">
  <p class="firewall-security-filters__profile">
    <app-pill
      class="firewall-security-filters__pill"
      [type]="PillStatus.NEUTRAL"
      [size]="PillSize.SMALL"
      >{{ profile.pilltext }}</app-pill
    >
    {{ profile.name }}
  </p>
</div>

<p class="firewall-security-filters__text">
  This rule has {{ data.securityprofiles?.length }} applied Security Profile{{
    data.securityprofiles?.length !== 1 ? "s" : ""
  }}.
</p>
