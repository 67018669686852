import { createAction, props } from '@ngrx/store';
import { User } from '@shared/models/user';

/* STORE ACTIONS */
export const SET_USERS = '[Users] Set Users';
export const ADD_USER = '[Users] Add User';
export const UPDATE_USER = '[Users] Update User';
export const REMOVE_USER = '[Users] Remove User';
export const CLEAR_USERS = '[Users] Clear Users';

/* EFFECTS ACTIONS */
export const FETCH_ALL_USERS = '[Users] Fetch All Users';

/* ACTIONS FOR STORE */
export const setUsers = createAction(
  SET_USERS,
  props<{ users: User[] }>()
);
export const clearUsers = createAction(CLEAR_USERS);
export const addUser = createAction(ADD_USER, props<{ user: User }>());
export const updateUser = createAction(UPDATE_USER, props<{ user: User }>());
export const removeUser = createAction(REMOVE_USER, props<{ userId: string }>());

/* ACTIONS FOR EFFECTS */
export const fetchAllUsers = createAction(FETCH_ALL_USERS);
