<app-modal
  [size]="ModalSize.SMALL"
  [status]="modalStatus"
  [warningOnClose]="!isSubmitted"
  [warningOnCloseMessage]="modalCloseMessage"
>
  <div modal-header>FIREWALL / UTM POLICY CHANGE</div>
  <div modal-body>
    <app-info-box
      *ngIf="isSubmitted !== true"
      class="request-firewall-rule__infobox"
      id="infobox"
      >You are requesting the addition, removal, or modification of
      <abbr title="Unified Threat Management">UTM</abbr> policies including
      <abbr title="Uniform Resource Locator">URL</abbr>
      whitelisting. This is for inbound/outbound access requests and
      <span class="request-firewall-rule__infobox-bold"
        >you may submit multiple rules using this form</span
      >. In order to submit multiple rules, you can click the ‘Add Another
      Change’ button at the bottom of the form.
    </app-info-box>
    <div
      *ngIf="isSubmitted !== true"
      class="request-firewall-rule__rule-select-container"
    >
      <div
        *ngFor="let change of changes; index as i"
        class="request-firewall-rule__rule-select"
      >
        <app-button
          iconName="{{ ruleButtonHasIcon(i) ? 'close' : '' }}"
          [iconPosition]="ButtonIconPosition.END"
          [iconType]="IconType.REGULAR"
          [style]="ButtonStyle.NORMAL"
          [size]="ButtonSize.LARGE"
          [role]="ButtonRole.DEFAULT"
          [type]="ButtonType.BUTTON"
          [status]="getRuleButtonStatus(i)"
          (buttonClick)="handleRuleSelect(i)"
          (iconClick)="deleteChange(i)"
          >Rule {{ i + 1 }}</app-button
        >
      </div>
    </div>
    <h2 class="request-firewall-rule__title" *ngIf="isSubmitted !== true">
      Rule Type
    </h2>

    <!-- Contextual Container -->
    <div *ngIf="!isSubmitted">
      <div>
        <app-form-toggle
          [toggleOptions]="toggleOptions"
          [selectedOption]="changes[selectedRule].type"
          (selectedOptionChange)="handleChangeTypeToggle($event)"
          [type]="ToggleType.RADIO"
          [fontStyle]="FontStyle.BODY"
          name="firewall-rule-request-togglescr"
        ></app-form-toggle>
      </div>

      <ng-container [ngTemplateOutlet]="form" #formOutlet>
        <!-- Display form template -->
      </ng-container>

      <ng-template #form>
        <div [ngSwitch]="changes[selectedRule].type" #form>
          <div *ngSwitchCase="FirewallRuleChangeType.ADD">
            <!-- This is not ideal -->
            <app-firewall-rule-add
              [formDataInput]="getTypedAddFormData()"
              (FormUpdated)="handleFormUpdated($event)"
              (FormValid)="handleFormValid($event)"
            >
            </app-firewall-rule-add>
          </div>

          <div *ngSwitchCase="FirewallRuleChangeType.MODIFY">
            <!-- This is not ideal -->
            <app-firewall-rule-modify
              [formDataInput]="getTypedModifyFormData()"
              (FormUpdated)="handleFormUpdated($event)"
              (FormValid)="handleFormValid($event)"
            >
            </app-firewall-rule-modify>
          </div>

          <div *ngSwitchCase="FirewallRuleChangeType.DELETE">
            <!-- This is not ideal -->
            <app-firewall-rule-delete
              [formDataInput]="getTypedDeleteFormData()"
              (FormUpdated)="handleFormUpdated($event)"
              (FormValid)="handleFormValid($event)"
            >
            </app-firewall-rule-delete>
          </div>
        </div>
      </ng-template>
    </div>

    <div
      *ngIf="isSubmitted === false"
      modal-footer
      class="request-firewall-rule__buttons"
    >
      <app-button-group
        [flexType]="ButtonFlexType.SPACEBETWEEN"
        class="request-firewall-rule__button-group"
      >
        <app-button
          [size]="ButtonSize.MEDIUM"
          [role]="ButtonRole.DEFAULT"
          [type]="ButtonType.RESET"
          (buttonClick)="cancelRequest()"
          >Discard</app-button
        >
        <app-button-group
          [flexType]="ButtonFlexType.FLEXSTART"
          class="request-firewall-rule__button-subgroup"
        >
          <app-button
            [size]="ButtonSize.MEDIUM"
            [role]="ButtonRole.DEFAULT"
            [type]="ButtonType.BUTTON"
            [status]="
              isMaxRulesReached() || !buttonsEnabled
                ? ButtonStatus.DISABLED
                : ButtonStatus.ACTIVE
            "
            (buttonClick)="addChange()"
            >Add Another Change</app-button
          >
          <app-button
            [size]="ButtonSize.MEDIUM"
            [role]="ButtonRole.PRIMARY"
            [type]="ButtonType.SUBMIT"
            [status]="submitButtonStatus"
            (buttonClick)="submitChangesRequest()"
            >SUBMIT REQUEST</app-button
          >
        </app-button-group>
      </app-button-group>
    </div>

    <!-- Modal Success -->
    <div
      *ngIf="isSubmitted === true"
      modal-confirmation
      class="modal-confirmation"
    >
      <div class="modal-confirmation__icon-container">
        <app-icon
          title="Success"
          class="modal-confirmation__icon"
          [type]="IconType.OUTLINED"
        >
          check_circle
        </app-icon>
      </div>

      <div class="modal-confirmation__success-message">
        <span>
          Your firewall rule requests have been raised successfully.
        </span>
      </div>

      <div class="modal-confirmation__subtitle">WHAT NEXT?</div>

      <div class="modal-confirmation__information">
        <div class="modal-confirmation__review-icon">
          <app-icon
            [size]="IconSize.LARGE"
            [type]="IconType.OUTLINED"
            [status]="IconStatus.NEUTRAL"
            title="Review"
            >preview</app-icon
          >
        </div>
        <div class="modal-confirmation__review-text">
          <span
            >Our service team will review your request and pass it on to our
            NetOps team to make any update or changes.</span
          >
        </div>
        <div class="modal-confirmation__contact-icon">
          <app-icon
            [size]="IconSize.LARGE"
            [type]="IconType.OUTLINED"
            [status]="IconStatus.NEUTRAL"
            title="Contact"
            ><span class="material-symbols-outlined">
              speaker_notes
            </span></app-icon
          >
        </div>
        <div class="modal-confirmation__contact-text">
          <span
            >We’ll contact you if there is an issue with your request, such as
            missing or unclear information.</span
          >
        </div>
        <div class="modal-confirmation__confirmation-icon">
          <app-icon
            [size]="IconSize.LARGE"
            [type]="IconType.OUTLINED"
            [status]="IconStatus.NEUTRAL"
            title="Confirmation"
            >thumb_up</app-icon
          >
        </div>
        <div class="modal-confirmation__confirmation-text">
          <span
            >Once implemented, we’ll contact you again to let you know that the
            change is live and ready to be tested.</span
          >
        </div>
      </div>

      <app-button
        class="modal-confirmation--view-ticket-button"
        [role]="ButtonRole.PRIMARY"
        (buttonClick)="viewTicketDetails()"
      >
        VIEW YOUR REQUEST
      </app-button>
    </div>
  </div>
</app-modal>
