import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Account } from '@shared/models/account';
import {
  ButtonIconPosition,
  ButtonRole,
  ButtonStatus,
  ButtonType,
} from '@shared/models/button.model';
import { ConnectionStatus } from '@shared/models/connection.model';
import { IconSize, IconType, IconStatus } from '@shared/models/icon.model';
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import { Status } from '@shared/models/status.model';
import { AccountService } from '@shared/services/account.service';
import { ModalService } from '@shared/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConnectService } from '../../connect.service';
import { IPSECVPN, PhaseTwo } from '../../models/ipsec-vpn.model';
import * as StatusActions from '@store/status/status.actions';

import { WebsocketService } from '@shared/services/websocket.service';
import { PillStatus } from '@shared/models/pill.model';
import { Store } from '@ngrx/store';
import * as fromStatus from '@store/status/status.reducer';

@Component({
  selector: 'app-vpn-detail',
  templateUrl: './vpn-detail.component.html',
  styleUrls: ['./vpn-detail.component.scss'],
})
export class VpnDetailComponent implements OnInit, OnDestroy {
  public ModalSize = ModalSize;
  public IconSize = IconSize;
  public IconType = IconType;
  public IconStatus = IconStatus;
  public ButtonIconPosition = ButtonIconPosition;
  public ButtonType = ButtonType;
  public ButtonRole = ButtonRole;
  public ButtonStatus = ButtonStatus;
  public ConnectionStatus = ConnectionStatus;
  public ModalStatus = ModalStatus;
  public Status = Status;
  public PillStatus = PillStatus;

  public modalStatus: ModalStatus = ModalStatus.OPEN;
  public tableStatus: Status = Status.LOADING;
  public buttonStatus: Status = Status.COMPLETE;
  public resetStatus?: string;
  public ipsec!: IPSECVPN;

  private routeSubscription!: Subscription;
  private accountSubscription!: Subscription;
  private accountData!: Account;

  constructor(
    private accountService: AccountService,
    private modalService: ModalService,
    private connectService: ConnectService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private websocketService: WebsocketService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(take(1))
      .subscribe((account?: Account) => {
        if (account?.id) {
          this.accountData = account;
          const ipsecContext = this.modalService.getContext();
          if (!ipsecContext) {
            this.getThisIPSEC();
          } else {
            this.ipsec = ipsecContext;
            this.tableStatus = Status.COMPLETE;
            this.handleSocketResponse();
          }
        }
      });
  }

  private getThisIPSEC(): void {
    this.connectService
      .getIPSECVPNs({ accountId: this.accountData.id })
      .subscribe({
        next: (ipsecAccounts: IPSECVPN[]) => {
          this.tableStatus = Status.COMPLETE;
          this.routeSubscription = this.route.params.subscribe((params) => {
            if (params.phaseOne) {
              const thisIPSEC = ipsecAccounts.find((ipsec) => {
                return ipsec.phaseOne === params.phaseOne;
              });
              if (thisIPSEC) {
                this.ipsec = thisIPSEC;
                this.handleSocketResponse();
              } else {
                this.modalStatus = ModalStatus.CLOSED;
              }
            } else {
              this.modalStatus = ModalStatus.CLOSED;
            }
          });
        },
        error: () => {
          this.tableStatus = Status.ERROR;
        },
      });
  }

  public countActiveConnections(phaseTwo: PhaseTwo[]): number {
    return (
      phaseTwo.reduce((acc: number, p2: PhaseTwo) => {
        if (p2.status === 'up') {
          return acc + 1;
        }
        return acc;
      }, 0) || 0
    );
  }

  public countInactiveConnections(phaseTwo: PhaseTwo[]): number {
    return (
      phaseTwo.reduce((acc: number, p2: PhaseTwo) => {
        if (p2.status === 'down') {
          return acc + 1;
        }
        return acc;
      }, 0) || 0
    );
  }

  public resetTunnel() {
    console.log('Resetting tunnel', this.ipsec);
    this.buttonStatus = Status.LOADING;
    this.resetStatus = 'starting';
    this.connectService
      .resetVPNTunnel(this.ipsec, this.accountData.id)
      .subscribe({
        next: (apiResponse) => {
          this.toastr.success(
            'The IPSEC tunnel reset has been started',
            'Success'
          );
          const taskId = apiResponse.data.taskId;
          const intervalSub = interval(10000).subscribe(() => {
            this.websocketService.sendMessage(taskId);
          });
          this.websocketService.connect(
            'remote',
            (message: string) =>
              StatusActions.addResetTunnelStatus({
                site: this.ipsec.phaseOne,
                message,
              }),
            (message: string) =>
              StatusActions.updateResetTunnelStatus({
                site: this.ipsec.phaseOne,
                message,
                running: true,
              }),
            (message: string) =>
              StatusActions.updateResetTunnelStatus({
                site: this.ipsec.phaseOne,
                message,
                running: false,
              }),
            intervalSub,
            this.ipsec.phaseOne
          );
          this.resetStatus = 'started';
        },
        error: () => {
          this.toastr.warning('The IPSEC tunnel Could not be reset', 'Warning');
          this.buttonStatus = Status.ERROR;
        },
      });
  }

  ngOnDestroy() {
    this.accountSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
  }

  handleSocketResponse() {
    this.store
      .select(fromStatus.selectResetTunnelStatus(this.ipsec.phaseOne))
      .subscribe({
        next: (socketResponse) => {
          if (socketResponse?.running) {
            this.buttonStatus = Status.LOADING;
            this.resetStatus = 'started';
          }
          if (!socketResponse?.message) {
            return;
          }
          const parsedJson = JSON.parse(socketResponse.message);
          this.resetStatus = parsedJson.message;
          if (
            this.resetStatus === 'failed' ||
            this.resetStatus === 'succeeded'
          ) {
            this.buttonStatus = Status.COMPLETE;
            this.websocketService.closeConnection(this.ipsec.phaseOne);
          }
        },
      });
  }
}
