import { Component } from '@angular/core';
import { EmailPattern } from '@auth/models/patterns';
import { FieldType } from '@forms/models/form-field.model';
import {
  ButtonRole,
  ButtonType,
  ButtonDisplay,
  ButtonStatus,
} from '@shared/models/button.model';

import { CognitoResponse } from '../../models/cognito-response';
import { CognitoErrorService } from '../../services/cognito-error.service';
import { CognitoResetPasswordService } from '../../services/cognito-reset-password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  public email: string = '';
  public errorMsg?: string;
  public formSubmitted: boolean = false;
  public buttonStatus = ButtonStatus.ACTIVE;

  public FieldType = FieldType;
  public ButtonRole = ButtonRole;
  public ButtonType = ButtonType;
  public ButtonDisplay = ButtonDisplay;
  public ButtonStatus = ButtonStatus;
  public EmailPattern = EmailPattern;

  /**
   * Forgot password component.
   * @param { CognitoResetPasswordService } cognitoResetPasswordService - Soak's cognito services provider.
   * @param { CognitoErrorService } cognitoErrorService - Cognito error service
   */
  constructor(
    private cognitoResetPasswordService: CognitoResetPasswordService,
    private cognitoErrorService: CognitoErrorService
  ) {}

  public isValidEmail(): boolean {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      this.email
    );
  }

  /**
   * Reset user's password.
   */
  public forgotPassword(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.buttonStatus = ButtonStatus.LOADING;
    this.cognitoResetPasswordService
      .forgotPassword(this.email.toLowerCase())
      .subscribe((res: CognitoResponse) => {
        this.buttonStatus = ButtonStatus.ACTIVE;
        if (!res.success && res.reason !== 'Incorrect username or password') {
          this.cognitoErrorService.throwError(res.reason);
        }
        this.formSubmitted = true;
      });
  }
}
