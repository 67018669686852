<app-widget class="inspect-summary">
  <ng-container widget-content>
    <app-icon-title class="inspect-summary__header">
      <app-custom-icon
        icon
        [type]="CustomIcons.INSPECT"
        [ariaLabel]="'Inspect'"
      ></app-custom-icon>
      Inspect
    </app-icon-title>
    <div *appPermitted="'INSPECT__SESSIONS'" class="inspect-summary__content">
      <h3 class="inspect-summary__title">Sessions (last 7 days)</h3>
      <app-donut-chart-legend
        [data]="sessionsData"
        [status]="sessionsStatus"
        [size]="ChartSize.SMALL"
        [legendSize]="LegendSize.SMALL"
        [type]="DonutType.COUNT"
        [canvasAriaLabel]="'Inspect analytics data donut chart'"
      ></app-donut-chart-legend>
    </div>
  </ng-container>
  <app-button
    widget-footer
    *appPermitted="'INSPECT__DASHBOARD'"
    [display]="ButtonDisplay.BLOCK"
    [type]="ButtonType.BUTTON"
    iconName="chevron_right"
    [iconPosition]="ButtonIconPosition.END"
    appRouterLink="inspect"
    data-testid="view-inspect"
    >View Inspect</app-button
  >
</app-widget>
