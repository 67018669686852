/**
 * Ticket Comment
 * @property {string} comment           Comment text
 * @property {Object} author            Author object
 * @property {string} author.email      Author's email address
 * @property {string} author.name       Author's name (i.e. ${firstName} ${lastName})
 * @property {string} author.supportId  Author's support ID
 * @property {string} date              Date the comment was added
 * @property {string[]} file            Array of files to be attached to comment
 * @property {string} ticketId          ID of ticket that comment is attached to.
 */
export interface TicketComment {
  comment: string | null;
  author: {
    email: string | null;
    name: string | null;
    supportId: string | null;
  };
  date: string | null;
  file: string[];
  ticketId?: string | null;
}

export enum CommentType {
  INITIAL = 'initial',
  RECENT = 'recent',
  DEFAULT = 'default',
}
