<div class="account-summary">
  <section class="account-summary__account">
    <header class="account-summary__header">
      <h3 class="account-summary__heading">
        {{ account.companyName
        }}<app-pill
          class="account-summary__partner-name"
          *ngIf="account.partnerName"
          >{{ account.partnerName }}</app-pill
        >
      </h3>
      <app-button
        iconName="chevron_right"
        [iconPosition]="ButtonIconPosition.END"
        (buttonClick)="viewDetails()"
        data-testid="view-dashboard"
        >View dashboard</app-button
      >
    </header>
    <main
      class="account-summary__account__bandwidth"
      *appPermitted="['INSPECT__BANDWIDTH']"
    >
      <p>
        Bandwidth Usage (last 30 days)
        <app-bandwidth-tooltip></app-bandwidth-tooltip>
      </p>
      <app-bandwidth-chart
        [size]="ChartSize.MEDIUM"
        [data]="bandwidthData"
        [status]="bandwidthStatus"
      ></app-bandwidth-chart>
    </main>
  </section>
  <section
    class="account-summary__support"
    *appPermitted="['SUPPORT__INCIDENTS__READ']"
  >
    <h3 class="account-summary__ticket-heading">Incidents</h3>
    <app-ticket-data
      class="account-summary__ticket-data"
      [layout]="TicketDataLayout.VERTICAL"
      [open]="openTickets"
      [closed]="closedTickets"
      [status]="supportStatus"
      (raiseTicket)="raiseIncident()"
      (viewTickets)="viewTickets()"
    ></app-ticket-data>
  </section>
</div>
