import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Auth } from 'aws-amplify';
import { Observable, Observer, map } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { LocalStorageService } from '@shared/services/local-storage.service';
import { UserService } from '@shared/services/user.service';

@Injectable()
export class AuthGuard {
  // route to go to when auth fails
  public onFailureRoute: string = '/login';

  /**
   * Authentication Guard for protected routes.
   * @param { LocalStorageService } localStorageService - provider for local storage services.
   * @param { Router } router - Provider for Angular routing services.
   * @param { ToastrService } toastr - provider for toasts.
   */
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService
  ) {}

  /**
   * Check user is authorised to access route.
   * @return { Observable<boolean>} observer to subscribe for result.
   */
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (route.data?.isAuthRoute) {
      return this.userService.getCurrentUser().pipe(
        map((user) => {
          if (user) {
            return this.router.createUrlTree(['/']);
          } else {
            return true;
          }
        })
      );
    }
    return new Observable((observer: Observer<boolean>) => {
      Auth.currentAuthenticatedUser()
        .then((fulfilled: CognitoUser | any) => {
          if (!fulfilled) {
            observer.next(false);
            this.router.navigate([this.onFailureRoute]);
          } else {
            // Check localStorage for 'CognitoExpiry' timestamp
            const cognitoExpiry =
              this.localStorageService.getLocalStorage('CognitoExpiry');
            const now = new Date().getTime();
            if (cognitoExpiry && +cognitoExpiry < now) {
              Auth.signOut();
              this.localStorageService.removeLocalStorage('CognitoExpiry');
              this.toastr.warning(
                'You have been logged out due to inactivity',
                `Signed out`,
                {
                  disableTimeOut: true,
                  closeButton: true,
                }
              );
              observer.next(false);
              // navigate to logout to make sure everything is
              // tidied up properly
              this.router.navigateByUrl('/logout');
            } else {
              observer.next(true);
            }
          }
          observer.complete();
        })
        .catch(() => {
          this.router.navigate([this.onFailureRoute]);
          observer.next(false);
          observer.complete();
        });
    });
  }

  /**
   * Check user is authorised to access child routes.
   * @return { Observable<boolean>} observer to subscribe for result.
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
