export enum IconType {
  REGULAR = 'regular',
  OUTLINED = 'outlined',
}

export enum IconSize {
  XXSMALL = 'xxs',
  XSMALL = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  XLARGE = 'xl',
}

export enum IconStatus {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  NEUTRAL = 'neutral',
  DEFAULT = 'default',
}

export enum CustomIcons {
  ANDROID = 'android',
  APPLE = 'apple',
  AWS = 'aws',
  CHROME = 'chrome',
  CONNECT = 'connect',
  EXPLORER = 'explorer',
  GOOGLECLOUD = 'google-cloud',
  IBMCLOUD = 'ibm-cloud',
  INSPECT = 'inspect',
  LINUX = 'linux',
  LOGO = 'logo',
  MICROSOFT = 'microsoft',
  MOZILLA = 'mozilla',
  NETSCAPE = 'netscape',
  NOTIFICATIONS_BELL = 'notifications-bell',
  OPERA = 'opera',
  ORACLE = 'oracle',
  PROTECT = 'protect',
  RACKSPACE = 'rackspace',
  SAFARI = 'safari',
  WINDOWS = 'windows',
}

export enum IconWeight {
  REGULAR = 'regular',
  BOLD = 'bold',
}
