import { TicketComment } from './comment';
import { TicketPerson } from './ticket-person';

/**
 * Ticket
 * @property {string} opened_at - created at date.
 * @property {string} resolved_at - resolved at date.
 * @property {string} closed_at - closed at date.
 * @property {string} description - description.
 * @property {string} id - id.
 * @property {string} accountId - account ID.
 * @property {string} priority - priority. 1 = Critical, 2 = High, 3 = Moderate, 4 = Low
 * @property {string} number - the id of the ticket as a number.
 * @property {string} title - title.
 * @property {TicketComment[]} comments - additional comments.
 * @property {string[]} file - array of filenames of attached files.
 * @property {number} status - status of ticket (Open=2, Pending=3, Resolved=4, Closed=5, see https://api.freshservice.com/v2/#ticket_attributes).
 */
export interface Ticket {
  opened_at?: string;
  resolved_at?: string | null;
  closed_at?: string | null;
  description: string;
  cc_emails?: string[];
  id?: string;
  accountId?: string;
  priority?: number | null;
  number?: number;
  title: string;
  comments?: TicketComment[];
  file: string[];
  status?: number;
  requester?: TicketPerson;
  responder?: TicketPerson;
}

export interface NewTicket {
  title: string;
  cc_emails?: string[];
  priority?: number | null;
  description: string;
  accountId?: string;
  file: string[];
}

export interface NewIncident extends NewTicket {
  impact: string;
  affected_users: string | null;
  available_to_join: boolean;
  srcip: string | null;
  dstip: string | null;
  related_to_connectivity: string;
}

export enum TicketType {
  INCIDENTS = 'incidents',
  SR = 'sr',
}
