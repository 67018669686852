import { Observable, map, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as UsersActions from '@store/users/users.actions';
import * as fromUsers from '@store/users/users.selectors';
import * as fromStatus from '@store/status/status.reducer';
import * as StatusActions from '@store/status/status.actions';

/* Services */
import { ApiService } from './api.service';
import { CognitoService } from '@auth/services/cognito.service';
import { CognitoUpdateService } from '@auth/services/cognito-update.service';
import { AlertService } from './alert.service';

/* Models */
import { RequesterLevel, User } from '../models/user';
import { UserGroupData, UserGroups } from '../models/user-group';
import {
  ApiErrorResponse,
  UserValidationError,
  ValidationError,
} from '../models/error';
import { CognitoUser } from '@auth/models/cognito-user';
import { SelectOptions } from '@forms/models/form-select.model';
import { PermissionsService } from './permissions.service';
import { PermissionsOperator } from '@shared/models/permission.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUser: User | null = null;
  private cognitoUser?: User;

  constructor(
    private apiService: ApiService,
    private cognito: CognitoService,
    private cognitoUpdateService: CognitoUpdateService,
    private alertService: AlertService,
    private permissionsService: PermissionsService,
    private store: Store,
    private actions$: Actions,
  ) {
    this.cognito
      .getUser()
      .pipe(
        switchMap((user) => {
          // If no signInUserSession set, that means no JWT is available
          if (!user?.signInUserSession) {
            return of();
          }
          // only update the user if the user changes
          if (
            !this.currentUser ||
            user.username !== this.currentUser.username
          ) {
            // Convert the cognito user to our user model
            const thisUser = this.convertCognitoUser(user);
            this.currentUser = thisUser;
            this.store.dispatch(
              StatusActions.setCurrentUser({
                user: thisUser as User,
              }),
            );
          }

          return of();
        }),
      )
      .subscribe();
  }

  /**
   * Convert a cognito user to our user model
   * Add new user attribute conversions in here
   */
  convertCognitoUser(user: CognitoUser | null): User | null {
    if (!user?.attributes || !user?.attributes['custom:permissions']) {
      return null;
    }

    const userPermissions = JSON.parse(user.attributes['custom:permissions']);
    const thisUser: User = {
      username: user.attributes.sub ?? user.username,
      sub: user.attributes.sub,
      email: user.attributes.email,
      givenName: user.attributes.given_name || '',
      familyName: user.attributes.family_name || '',
      groups: user.attributes['custom:groups'] || 'user',
      jobTitle: user.attributes['custom:job_title'] || '',
      // default is for existing users with no countryCode (all UK users)
      countryCode: user.attributes['custom:country_code'] || '+44',
      phoneNumber: user.attributes.phone_number || '',
      preferredMFA: user.preferredMFA,
      supportId: user.attributes['custom:support_id'] || '',
      status: user.status,
      partnerId: user.attributes['custom:partner_id'] || '',
      accountId: this.getAccountId(user),
      termsVersion: user.attributes['custom:terms-version'] || '',
      permissions: userPermissions,
      requesterLevel: user.attributes['custom:requester-level'] || '',
      securityContact: user.attributes['custom:security-contact'] || false,
    };

    return thisUser;
  }
  private getAccountId(user: CognitoUser): string[] {
    return user.attributes['custom:account_ids']
      ? JSON.parse(user.attributes['custom:account_ids'])
      : [];
  }

  /**
   * Update a user
   * @param { User } user - user to update.
   * @returns { Observable<User> } observer to subscribe to for result.
   */
  public update(user: User): Observable<User> {
    return new Observable((observer) => {
      let fixedPhoneNumber = '';
      if (user.phoneNumber)
        fixedPhoneNumber = this.fixPhoneNumber(user.phoneNumber);
      const validation = this.validate(user);
      if (validation !== true) {
        observer.error(validation);
      } else {
        // User object to pass to API
        const userObject = {
          givenName: user.givenName,
          familyName: user.familyName,
          jobTitle: user.jobTitle,
          groups: user.groups,
          supportId: user.supportId,
          accountId: user.accountId,
          partnerId: user.partnerId,
          countryCode: user.countryCode,
          phoneNumber: user.countryCode + fixedPhoneNumber,
          termsVersion: user.termsVersion,
          permissions: user.permissions,
          requesterLevel: user.requesterLevel,
          securityContact: user.securityContact,
        };
        this.apiService.put(`/users/${user.username}`, userObject).subscribe({
          next: (response) => {
            if (user.username === this.currentUser?.username) {
              this.cognitoUpdateService.refresh();
              this.store.dispatch(
                StatusActions.setCurrentUser({
                  user: response,
                }),
              );
            }
            this.store.dispatch(
              UsersActions.updateUser({
                user: response,
              }),
            );
            observer.next(response);
            observer.complete();
          },
          error: (err) => {
            observer.error(err);
          },
        });
      }
    });
  }

  /**
   * Get a user from their ID
   * @param { string } id  The user ID
   * @returns { Observable<User> } observer to subscribe to for result.
   */
  public getUser(id: string): Observable<User> {
    return this.apiService.get(`/users/${id}`);
  }

  /**
   * Validate an user before creating/updating
   * @param  {User} user The user to validate
   * @returns {any}       True if valid, an object of arrays of errors if not
   */
  public validate(user: User): true | ValidationError {
    let validation = true;
    const errors: ValidationError = JSON.parse(
      JSON.stringify(UserValidationError),
    );

    // Given name error existence
    if (!user.givenName || user.givenName === '') {
      errors.givenName.push('Please enter a given name');
      validation = false;
    }
    // Family name error existence
    if (!user.familyName || user.familyName === '') {
      errors.familyName.push('Please enter a family name');
      validation = false;
    }
    if (!user.email || user.email === '') {
      // Email error existence
      errors.email.push('Please enter an email');
      validation = false;
    } else if (/.*@.*\..*/.test(user.email) === false) {
      // tests email for *@*.*
      errors.email.push('Your email must be in the right format');
      validation = false;
    }
    if (!user.phoneNumber || user.phoneNumber === '') {
      // Phone number existence
      errors.phoneNumber.push('Please enter a phone number');
      validation = false;
    } else if (user.phoneNumber.length < 8 || user.phoneNumber.length > 14) {
      // Phone number length checking
      errors.phoneNumber.push(
        'Your phone number should be between 8 and 14 characters',
      );
      validation = false;
    } else if (/\d*/.test(user.phoneNumber) === false) {
      // Phone number must be digits
      errors.phoneNumber.push('Your phone number can only be numbers');
      validation = false;
    }
    return validation !== true ? errors : true;
  }

  /**
   * Fix a phone number to ensure it has a leading '+' and no spaces
   * @param { string } number The phone number to fix
   * @returns { string } The fixed number
   */
  fixPhoneNumber(number: string): string {
    // Make sure it's a string
    number = number + '';

    // Remove spaces
    number = number.replace(/\s/g, '');

    // Quick check: number can't start with '+' or '0'
    const nonStarters = ['+', '0'];
    const firstLetter = number.substring(0, 1);
    if (nonStarters.includes(firstLetter)) {
      number = number.substring(1);
    }
    return number;
  }

  public loadAllUsers(): Observable<User[]> {
    return this.permissionsService.checkPermissions().pipe(
      switchMap(() => {
        return this.store.select(fromUsers.selectAllUsers).pipe(
          switchMap((users: User[]) => {
            if (!users || users.length === 0) {
              if (
                this.permissionsService.isUserPermitted(
                  this.currentUser,
                  ['ADMIN__USER__READ', 'USER__MANAGEMENT__READ'],
                  PermissionsOperator.OR,
                )
              ) {
                this.store.dispatch(UsersActions.fetchAllUsers());
              }

              return this.actions$.pipe(
                ofType(UsersActions.SET_USERS),
                map((action: { type: string; users: User[] }) => {
                  return action.users;
                }),
              );
            } else {
              return of(users);
            }
          }),
        );
      }),
    );
  }

  /**
   * For admins show all user groups;
   * For others, allow them to create/edit standard and network users.
   * @param { User } user The logged in user
   * @returns { UserGroup } groups
   */
  public getEditableGroups(user: User): SelectOptions[] {
    let userGroups: SelectOptions[] = [];

    if (
      this.permissionsService.isUserPermitted(user, ['ADMIN__USER__UPDATE'])
    ) {
      for (const userGroup in UserGroupData) {
        userGroups.push({
          label: UserGroupData[userGroup].displayName,
          value: userGroup,
        });
      }
    } else if (
      this.permissionsService.isUserPermitted(user, [
        'USER__MANAGEMENT__UPDATE',
      ])
    ) {
      userGroups.push({
        label: UserGroupData[UserGroups.STANDARD_USER].displayName,
        value: UserGroups.STANDARD_USER,
      });
      userGroups.push({
        label: UserGroupData[UserGroups.NETWORK_USER].displayName,
        value: UserGroups.NETWORK_USER,
      });
    }

    return userGroups;
  }

  public getRequesterLevels(user: User) {
    const requesterLevels: SelectOptions[] = [
      {
        value: RequesterLevel.NONE,
        label: 'None',
      },
      {
        value: RequesterLevel.STANDARD,
        label: 'Standard',
      },
      {
        value: RequesterLevel.GENERAL,
        label: 'General',
      },
      {
        value: RequesterLevel.ENHANCED,
        label: 'Enhanced',
      },
    ];

    if (user.requesterLevel === RequesterLevel.COMPANY_HEAD) {
      requesterLevels.push({
        value: RequesterLevel.COMPANY_HEAD,
        label: 'Company head',
      });
    }

    return requesterLevels;
  }

  public getUserName(fullUser: User | null): string {
    // prefer user's name, but default to email if name not available
    let userName = 'Your Account';

    if (fullUser) {
      if (fullUser.givenName) {
        userName = fullUser.givenName;
        if (fullUser.familyName) {
          userName += ' ' + fullUser.familyName;
        }
      } else if (fullUser.email) {
        userName = fullUser.email;
      }
    }

    return userName;
  }

  /**
   * Create a user
   * @param { any } user - user.
   * @returns { Observable<User> } observer to subscribe to for result.
   */
  public create(user: User, accountIds?: string | string[]): Observable<User> {
    return new Observable((observer) => {
      let fixedPhoneNumber = '';
      if (user.phoneNumber) {
        fixedPhoneNumber = this.fixPhoneNumber(user.phoneNumber);
      }
      const validation = this.validate(user);
      if (validation !== true) {
        observer.error(validation);
      } else {
        // User object to pass to API
        const userObject = {
          email: user.email.toLowerCase(),
          accountId: accountIds,
          givenName: user.givenName,
          familyName: user.familyName,
          jobTitle: user.jobTitle,
          groups: user.groups,
          supportId: user.supportId,
          countryCode: user.countryCode,
          phoneNumber: user.countryCode + fixedPhoneNumber,
          permissions: user.permissions,
          requesterLevel: user.requesterLevel,
          securityContact: user.securityContact,
        };
        this.apiService.post('/users', userObject).subscribe({
          next: (response) => {
            this.store.dispatch(
              UsersActions.addUser({
                user: response,
              }),
            );
            observer.next(response);
            observer.complete();
          },
          error: (err) => {
            observer.error(err);
          },
        });
      }
    });
  }

  /**
   * Remove a user
   * @param { User } user - user.
   * @returns { Observable<User> } observer to subcribe to for result.
   */
  public remove(user: User): Observable<User> {
    return new Observable((observer) => {
      if (user.username === this.currentUser?.username) {
        const errors = new ApiErrorResponse({
          status: 401,
          error: {
            data: {
              message: 'You cannot delete yourself!',
            },
          },
        });
        observer.error(errors);
      } else {
        this.alertService.throwConfirmation(
          `Are you sure you want to remove this user?`,
          'Yes, remove',
          'Remove user',
          () => {
            this.apiService.destroy(`/users/${user.username}`).subscribe({
              next: (response) => {
                this.store.dispatch(
                  UsersActions.removeUser({
                    userId: user.sub as string,
                  }),
                );
                observer.next(response);
                observer.complete();
              },
              error: (err: ApiErrorResponse) => {
                observer.error(err);
              },
            });
          },
        );
      }
    });
  }

  public async getPreferredMFA(): Promise<string> {
    this.cognitoUser = await Auth.currentAuthenticatedUser();
    return Auth.getPreferredMFA(this.cognitoUser, {
      bypassCache: true,
    });
  }

  public getCurrentUser(): Observable<User | null> {
    return this.store.select(fromStatus.selectCurrentUser);
  }
}
