<div class="tabs">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <a
      [ngClass]="{
        'tab--active': activeTabId === tab.id || (i === 0 && !activeTabId)
      }"
      class="tab"
      (click)="switchTab(tab.id)"
    >
      {{ tab.label }}
    </a>
  </ng-container>
</div>
