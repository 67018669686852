import { Component, Input } from '@angular/core';
import { AddressType } from '@shared/models/firewall.datatypes';
import { NameValueComparePipe } from '../../pipes/name-value-compare.pipe';
import { SubnetToCIDRPipe } from '../../pipes/subnet-to-cidr.pipe';
import { FontWeight } from '@shared/models/font';

@Component({
  selector: 'app-firewall-address-list',
  templateUrl: './firewall-address-list.component.html',
  styleUrls: ['./firewall-address-list.component.scss'],
  providers: [SubnetToCIDRPipe, NameValueComparePipe],
})
export class FirewallAddressListComponent {
  @Input() addressList!: any[];
  @Input() fontWeight: FontWeight = FontWeight.SEMIBOLD;

  AddressType = AddressType;

  constructor() {}
}
