import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';

import { Account } from '@shared/models/account';
import { User } from '@shared/models/user';
import { map, switchMap, take, tap } from 'rxjs/operators';
import {
  Feature,
  FeatureAccountPermissionMap,
  FeatureUserPermissionMap,
} from '@shared/models/features';

import * as fromPermissions from '@store/permissions/permissions.reducer';
import {
  Permission,
  PermissionsOperator,
} from '@shared/models/permission.model';
import * as PermissionsActions from '@store/permissions/permissions.actions';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private store: Store, private actions$: Actions) {}

  public isFeatureAllowedForAccount(
    feature: Feature,
    account: Account | undefined
  ): boolean {
    if (!FeatureAccountPermissionMap[feature]) {
      return true;
    } else if (!account) {
      return false;
    }
    return this.isAccountPermitted(
      account,
      FeatureAccountPermissionMap[feature],
      PermissionsOperator.OR
    );
  }

  public isFeatureAllowedForUser(feature: Feature, user: User | null): boolean {
    // Remove SUMMARY for single-account users
    if (
      user &&
      !this.userHasAccessToMultipleAccounts(user) &&
      feature === Feature.SUMMARY
    ) {
      return false;
    }
    if (!FeatureUserPermissionMap[feature]) {
      return true;
    }
    return this.isUserPermitted(
      user,
      FeatureUserPermissionMap[feature],
      PermissionsOperator.OR
    );
  }

  public isFeatureAllowed(
    feature: Feature,
    account: Account | undefined,
    user: User | null
  ): boolean {
    if (feature) {
      return (
        this.isFeatureAllowedForAccount(feature, account) &&
        this.isFeatureAllowedForUser(feature, user)
      );
    } else {
      return false;
    }
  }

  /**
   * PERMISSIONS - new model
   */
  private permissionsSubject = new BehaviorSubject<Permission[]>([]);
  public permissions: Observable<Permission[] | null> =
    this.permissionsSubject.asObservable();

  public checkPermissions(): Observable<Permission[]> {
    return this.store.select(fromPermissions.selectAllPermissions).pipe(
      take(1),
      switchMap((permissions: Permission[]) => {
        if (permissions && permissions.length > 0) {
          return of(permissions);
        } else {
          this.store.dispatch(PermissionsActions.fetchPermissions());
          return this.actions$.pipe(
            ofType(PermissionsActions.SET_PERMISSIONS),
            take(1),
            map((response: { type: string; permissions: Permission[] }) => {
              // Action response is read only, so make a copy
              return <Permission[]>(
                JSON.parse(JSON.stringify(response.permissions))
              );
            }),
            tap((perms: Permission[]) => {
              this.permissionsSubject.next(perms);
            })
          );
        }
      })
    );
  }

  public isUserPermitted(
    user: User | null,
    permissions: (string | number)[],
    operator: PermissionsOperator = PermissionsOperator.AND
  ): boolean {
    if (!user) {
      return false;
    }
    // Convert permission strings to numbers
    const permissionNumbers = permissions.flatMap((permission) => {
      if (typeof permission === 'string') {
        return (
          this.permissionsSubject.getValue().find((p) => p.name === permission)
            ?.id ?? []
        );
      }
      return [permission];
    });

    if (permissionNumbers.length) {
      if (operator === PermissionsOperator.AND) {
        // User must have all these permissions
        return permissionNumbers.every((permission) =>
          user.permissions?.includes(permission)
        );
      } else if (operator === PermissionsOperator.OR) {
        // User must have at last one of these permissions
        return permissionNumbers.some((permission) =>
          user.permissions?.includes(permission)
        );
      }
    }
    return false;
  }

  public isAccountPermitted(
    account: Account | null,
    permissions: string[],
    operator: PermissionsOperator = PermissionsOperator.AND
  ): boolean {
    if (!account) {
      return false;
    }

    if (permissions.length) {
      if (operator === PermissionsOperator.AND) {
        // User must have all these permissions
        return permissions.every((permission) =>
          account.featureId?.includes(permission)
        );
      } else if (operator === PermissionsOperator.OR) {
        // User must have at last one of these permissions
        return permissions.some((permission) =>
          account.featureId?.includes(permission)
        );
      }
    }
    return false;
  }

  public userHasAccessToMultipleAccounts(user: User): boolean {
    if (
      (Array.isArray(user.accountId) && user.accountId.length > 1) ||
      this.isUserPermitted(user, ['ADMIN__ACCOUNT__READ']) ||
      user.partnerId
    ) {
      return true;
    }

    return false;
  }
}
