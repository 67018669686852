<div class="password-strength">
  <app-password-strength-item
    class="password-strength__item"
    [param]="passwordsMatch"
    *ngIf="requireMatch"
    >Passwords match</app-password-strength-item
  >
  <app-password-strength-item
    class="password-strength__item"
    [param]="containAtLeastMinChars"
    >Is at least {{ minChars }} characters long</app-password-strength-item
  >
  <app-password-strength-item
    class="password-strength__item"
    [param]="containAtLeastOneLowerCaseLetter"
    *ngIf="requireLowercase"
    >Contains at least 1 lowercase character</app-password-strength-item
  >
  <app-password-strength-item
    class="password-strength__item"
    [param]="containAtLeastOneUpperCaseLetter"
    *ngIf="requireUppercase"
    >Contains at least 1 uppercase character</app-password-strength-item
  >
  <app-password-strength-item
    class="password-strength__item"
    [param]="containAtLeastOneDigit"
    *ngIf="requireNumber"
    >Contains at least 1 number</app-password-strength-item
  >
  <app-password-strength-item
    class="password-strength__item"
    [param]="containAtLeastOneSpecialChar"
    *ngIf="requireSpecial"
    >Contains at least 1 special character</app-password-strength-item
  >
  <app-password-strength-item
    class="password-strength__item"
    [param]="containNoSpecialChar"
    *ngIf="requireNoSpecial"
    >Contains <strong>no</strong> special characters</app-password-strength-item
  >
  <app-password-strength-item
    class="password-strength__item"
    [param]="containNoSimilarity"
    *ngIf="requireNoSimilarity"
    >Contains no more than 2 consecutive letters of your
    username</app-password-strength-item
  >
</div>
