import { Color } from 'chart.js';

export type LegendItem = {
  swatchColor: Color;
  label: string;
  value?: string;
  sortvalue?: number;
};

export enum LegendPosition {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export enum LegendType {
  LIST = 'list',
  TABLE = 'table',
}

export enum LegendSize {
  SMALL = 'sm',
  MEDIUM = 'md',
}
