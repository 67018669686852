<app-modal
  [size]="ModalSize.MEDIUM"
  [status]="modalStatus"
  [warningOnClose]="true"
  [warningOnCloseMessage]="modalCloseMessage"
  class="add-edit-user"
>
  <div modal-header>
    <ng-container *ngIf="editMode">
      Edit User: <strong>{{ user.givenName }} {{ user.familyName }}</strong>
    </ng-container>
    <ng-container *ngIf="!editMode"> Add New User </ng-container>
  </div>
  <div modal-body class="add-edit-user__body">
    <ng-container>
      <h2 class="add-edit-user__header">User Details</h2>
      <div class="add-edit-user__content" *ngIf="user">
        <div class="add-edit-user__column">
          <app-form-field
            [type]="FieldType.TEXT"
            [name]="'first-name'"
            placeholder="First Name"
            [(inputValue)]="user.givenName"
            [errorMsg]="errors.givenName[0]"
            [showErrorMsg]="true"
            (inputBlur)="handleBlur('givenName')"
            (inputFocus)="handleFocus('givenName')"
            >First Name
          </app-form-field>
          <app-form-field
            [type]="FieldType.TEXT"
            [name]="'last-name'"
            placeholder="Last Name"
            [(inputValue)]="user.familyName"
            [errorMsg]="errors.familyName[0]"
            [showErrorMsg]="true"
            (inputBlur)="handleBlur('familyName')"
            (inputFocus)="handleFocus('familyName')"
            >Last Name
          </app-form-field>
          <app-form-field
            [type]="FieldType.TEXT"
            [name]="'email'"
            placeholder="Email"
            [(inputValue)]="user.email"
            [errorMsg]="errors.email[0]"
            [status]="editMode ? FieldStatus.DISABLED : FieldStatus.ACTIVE"
            [showErrorMsg]="true"
            (inputBlur)="handleBlur('email')"
            (inputFocus)="handleFocus('email')"
            >Email
          </app-form-field>
        </div>
        <div class="add-edit-user__column">
          <app-form-select
            *ngIf="accountIsEditable"
            [selectOptions]="availableAccountOptions"
            [status]="
              accountIsDisabled ? FieldStatus.DISABLED : FieldStatus.ACTIVE
            "
            subtext="Hold Ctrl/Cmd to select multiple accounts"
            [type]="SelectType.MULTIPLE"
            [(selectedOption)]="user.accountId"
            data-testid="account-selector"
            >Account(s)</app-form-select
          >
          <app-form-field
            [type]="FieldType.TEXT"
            [name]="'job-title'"
            placeholder="Job Title"
            [(inputValue)]="user.jobTitle"
            [errorMsg]="errors.jobTitle[0]"
            [showErrorMsg]="true"
            (inputBlur)="handleBlur('jobTitle')"
            (inputFocus)="handleFocus('jobTitle')"
            >Job Title
          </app-form-field>
          <div class="add-edit-user__phone-details">
            <app-form-select
              class="add-edit-user__country-code"
              [selectOptions]="availableCountryOptions"
              [(selectedOption)]="user.countryCode"
              [type]="SelectType.SINGLE"
              [name]="'country-code'"
              [errorMsg]="errors.countryCode[0]"
              >Country Code
            </app-form-select>
            <app-form-field
              class="add-edit-user__phone-number"
              [type]="FieldType.TEXT"
              [name]="'phone-number'"
              placeholder="7700 900375"
              [(inputValue)]="phoneNumber"
              subtext="Please enter your mobile number without the leading zero"
              [errorMsg]="errors.phoneNumber[0]"
              [showErrorMsg]="true"
              (inputBlur)="handleBlur('phoneNumber')"
              (inputFocus)="handleFocus('phoneNumber')"
              >Phone Number
            </app-form-field>
          </div>
          <div class="add-edit-user__mfa">
            <div class="add-edit-user__mfa-content">
              <p class="add-edit-user__label">MFA Options</p>
              <p class="add-edit-user__text">
                TOTP Status:
                <span *ngIf="user.preferredMFA === MfaTypes.TOTP">
                  Enabled
                </span>
                <span *ngIf="user.preferredMFA !== MfaTypes.TOTP"
                  >Not Configured</span
                >
              </p>
            </div>
            <app-button
              *ngIf="user.preferredMFA === MfaTypes.TOTP"
              (buttonClick)="turnOffTOTP()"
              data-testid="totp-button"
              >Turn off TOTP</app-button
            >
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="user && !isProfile">
      <div class="add-edit-user__header-container">
        <h2 class="add-edit-user__header">Account Options</h2>
        <app-help-icon>
          <p>
            From here you can update this user's Account Type or Requester
            Level.
            <br />
            <br />
            The Account Type determines which features can be seen and managed
            on the portal, controlling access to different data visibility
            features.
            <br />
            <br />
            The Requester Level field determines what types of requests a user
            can make, Enhanced includes
            <abbr title="Statement of Work">SoW</abbr> and small changes,
            Standard includes only small changes, General includes general
            requests with no monetary value and None prevents requests from
            being fulfilled.
            <br />
            <br />
            The Security Contact field determines whether a user should receive
            information about security incidents.
            <br />
            <br />
            It's important to note that Account Admin's have full access to all
            company data and features which are available in the portal.
          </p>
        </app-help-icon>
      </div>
      <div class="add-edit-user__content">
        <div class="add-edit-user__content" *ngIf="user">
          <div class="add-edit-user__column">
            <ng-container *ngIf="!isProfile">
              <ng-container
                *appPermitted="
                  [
                    'ADMIN__USER__CREATE',
                    'USER__MANAGEMENT__CREATE',
                    'ADMIN__USER__UPDATE',
                    'USER__MANAGEMENT__UPDATE'
                  ];
                  operator: PermissionsOperator.OR
                "
              >
                <app-form-select
                  [selectOptions]="availableUserGroups"
                  [status]="
                    lockAccountType ? FieldStatus.DISABLED : FieldStatus.ACTIVE
                  "
                  [(selectedOption)]="selectedUserGroup"
                  (selectedOptionChange)="onUserGroupChange()"
                  data-testid="usergroup-selector"
                  >Account Type</app-form-select
                >
              </ng-container>
            </ng-container>
          </div>
          <div class="add-edit-user__column">
            <!-- Empty block for two column layout -->
            <ng-container *ngIf="!isProfile">
              <ng-container
                *appPermitted="
                  [
                    'ADMIN__USER__CREATE',
                    'USER__MANAGEMENT__CREATE',
                    'ADMIN__USER__UPDATE',
                    'USER__MANAGEMENT__UPDATE'
                  ];
                  operator: PermissionsOperator.OR
                "
              >
                <app-form-select
                  [selectOptions]="availableRequesterLevels"
                  [status]="
                    lockAccountType ? FieldStatus.DISABLED : FieldStatus.ACTIVE
                  "
                  [(selectedOption)]="selectedRequesterLevel"
                  (selectedOptionChange)="onRequesterLevelChange()"
                  data-testid="requester-level-selector"
                  >Requester Status</app-form-select
                >
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div>
          <p class="add-edit-user__label">Security Contact</p>
          <app-form-toggle
            [toggleOptions]="toggleOptions"
            [selectedOption]="selectedSecurityContact"
            (selectedOptionChange)="handleChangeTypeToggle($event)"
            [type]="ToggleType.RADIO"
            name="security-contact-togglescr"
          ></app-form-toggle>
        </div>
      </div>
    </ng-container>
  </div>
  <div modal-footer class="add-edit-user__footer">
    <app-button-group
      [flexType]="ButtonFlexType.SPACEBETWEEN"
      class="add-edit-user__button-group"
    >
      <ng-container>
        <app-button
          [size]="ButtonSize.MEDIUM"
          [role]="ButtonRole.DEFAULT"
          [type]="ButtonType.RESET"
          (buttonClick)="cancelRequest()"
          >Discard</app-button
        >
      </ng-container>
      <app-button-group
        [flexType]="ButtonFlexType.FLEXSTART"
        class="add-edit-user__button-subgroup"
      >
        <ng-container
          *appPermitted="
            ['ADMIN__USER__DELETE', 'USER__MANAGEMENT__DELETE'];
            operator: PermissionsOperator.OR
          "
        >
          <app-button
            (buttonClick)="remove()"
            [type]="ButtonType.BUTTON"
            [role]="ButtonRole.DELETE"
            [display]="ButtonDisplay.INLINE"
            *ngIf="isRemovable && editMode"
            [status]="buttonStatus"
            data-testid="remove-button"
            >Remove user</app-button
          >
        </ng-container>
        <ng-container *ngIf="!isProfile">
          <ng-container *ngIf="!editMode">
            <app-button
              (buttonClick)="save()"
              *appPermitted="
                ['ADMIN__USER__CREATE', 'USER__MANAGEMENT__CREATE'];
                operator: PermissionsOperator.OR
              "
              [type]="ButtonType.SUBMIT"
              [role]="ButtonRole.PRIMARY"
              [display]="ButtonDisplay.INLINE"
              [status]="buttonStatus"
              data-testid="save-button"
              >Save</app-button
            >
          </ng-container>
          <ng-container *ngIf="editMode">
            <app-button
              (buttonClick)="save()"
              *appPermitted="
                ['ADMIN__USER__UPDATE', 'USER__MANAGEMENT__UPDATE'];
                operator: PermissionsOperator.OR
              "
              [type]="ButtonType.SUBMIT"
              [role]="ButtonRole.PRIMARY"
              [display]="ButtonDisplay.INLINE"
              [status]="buttonStatus"
              data-testid="save-button"
              >Save</app-button
            >
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isProfile">
          <app-button
            (buttonClick)="save()"
            *appPermitted="'PROFILE__INFO__UPDATE'"
            [type]="ButtonType.SUBMIT"
            [role]="ButtonRole.PRIMARY"
            [display]="ButtonDisplay.INLINE"
            [status]="buttonStatus"
            data-testid="save-button"
            >Save</app-button
          >
        </ng-container>
      </app-button-group>
    </app-button-group>
  </div>
</app-modal>
