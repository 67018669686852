<div
  class="button-group"
  [ngClass]="{
    'button-group--block': display === ButtonDisplay.BLOCK,
    'button-group--inline': display === ButtonDisplay.INLINE,
    'button-group--flex-start': flexType === ButtonFlexType.FLEXSTART,
    'button-group--space-between': flexType === ButtonFlexType.SPACEBETWEEN
  }"
>
  <ng-content></ng-content>
</div>
