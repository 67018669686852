import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { PartnerAccount } from '../models/partner-account';
import { PartnerService } from '../services/partner.service';

@Injectable({ providedIn: 'root' })
export class PartnersResolverService implements Resolve<PartnerAccount[]> {
  constructor(private partnerService: PartnerService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<PartnerAccount[]>
    | Promise<PartnerAccount[]>
    | PartnerAccount[] {
    return this.partnerService.checkPartners().pipe(take(1));
  }
}
