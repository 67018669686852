import { Injectable } from '@angular/core';
import { ValidationError } from '@shared/models/error';
import {
  FirewallAddRequest,
  FirewallDeleteRequest,
  FirewallModifyRequest,
} from '@shared/models/firewall-change-request.model';
import { TicketComment } from './models/comment';
import { NewIncident, Ticket } from './models/ticket';

@Injectable({ providedIn: 'root' })
export class SupportValidationService {
  public validateIncident(incident: NewIncident): ValidationError | true {
    let validation = true;
    const errors: ValidationError = {
      description: [],
      title: [],
      cc_emails: [],
      impact: [],
      priority: [],
    };

    if (!incident.description || incident.description === '') {
      errors.description.push('Please enter a description');
      validation = false;
    }

    if (!incident.title || incident.title === '') {
      errors.title.push('Please enter a ticket subject');
      validation = false;
    }

    if (!incident.impact || incident.impact === '') {
      errors.impact.push('Please enter the impact to your service');
      validation = false;
    }

    if (!this.validateCCemails(incident.cc_emails)) {
      validation = false;
      errors.cc_emails.push('Please enter email eddresses correctly');
    }

    if (!incident.priority) {
      errors.priority.push('Please select a priority for this incident');
      validation = false;
    }

    return validation !== true ? errors : true;
  }

  /**
   * Validate a ticket before creating
   * @param  {Ticket} ticket The ticket to validate
   * @returns {any}       True if valid, an array of errors if not
   */
  public validateSR(ticket: Ticket): ValidationError | true {
    let validation = true;
    const errors: { [key: string]: string[] } = {
      title: [],
      description: [],
      priority: [],
      cc_emails: [],
    };

    // Title error existence
    if (!ticket.title || ticket.title === '') {
      errors.title.push('Please enter a title');
      validation = false;
    }
    // Description error existence
    if (!ticket.description || ticket.description === '') {
      errors.description.push('Please enter a description');
      validation = false;
    }

    if (!this.validateCCemails(ticket.cc_emails)) {
      validation = false;
      errors.cc_emails.push('Please enter email eddresses correctly');
    }

    return validation !== true ? errors : true;
  }

  /**
   * Validate a comment before adding
   * @param  {TicketComment} comment The comment to validate
   * @returns {any}       True if valid, an array of errors if not
   */
  public validateComment(comment: TicketComment): ValidationError | true {
    let validation = true;
    const errors: { [key: string]: string[] } = {
      comment: [],
      file: [],
    };

    // Comment existence error
    if (!comment.comment || comment.comment === '') {
      errors.comment.push('Please enter a comment');
      validation = false;
    }

    return validation !== true ? errors : true;
  }

  public validateFirewallAddRequest(
    request: FirewallAddRequest,
  ): ValidationError | true {
    let validation = true;
    const errors: ValidationError = {
      name: [],
      sourceAddress: [],
      destinationAddress: [],
      service: [],
      securityProfile: [],
      sourceNat: [],
      destinationNat: [],
      urls: [],
      comment: [],
      cc_emails: [],
    };

    // name existence error
    if (!request.name || request.name === '') {
      errors.name.push('Please enter a name');
      validation = false;
    }

    // source existence error
    if (!request.sourceAddress || request.sourceAddress === '') {
      errors.sourceAddress.push('Please enter a source IP');
      validation = false;
    }

    // destination existence error
    if (!request.destinationAddress || request.destinationAddress === '') {
      errors.destinationAddress.push('Please enter a destination IP');
      validation = false;
    }

    // service existence error
    if (!request.service || request.service === '') {
      errors.service.push('Please enter a service');
      validation = false;
    }

    return validation !== true ? errors : true;
  }

  public validateFirewallModifyRequest(
    request: FirewallModifyRequest,
  ): ValidationError | true {
    let validation = true;
    const errors: ValidationError = {
      comment: [],
    };

    // comment existence error
    if (!request.comment || request.comment === '') {
      errors.comment.push('Please enter a comment');
      validation = false;
    }

    return validation !== true ? errors : true;
  }

  public validateFirewallDeleteRequest(
    request: FirewallDeleteRequest,
  ): ValidationError | true {
    let validation = true;
    const errors: ValidationError = {
      comment: [],
      confirmed: [],
    };

    // comment existence error
    if (!request.confirmed) {
      errors.confirmed.push('This checkbox is required');
      validation = false;
    }

    return validation !== true ? errors : true;
  }

  // CC emails check - simple regex test *@*.*
  private validateCCemails(requestEmails?: string[]): boolean | undefined {
    if (!requestEmails) {
      return true;
    }

    return requestEmails?.every((email) => /.+@.+\..+/.test(email));
  }
}
