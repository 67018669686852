<app-widget class="connect-summary">
  <ng-container widget-content>
    <app-icon-title class="connect-summary__header">
      <app-custom-icon
        icon
        [type]="CustomIcons.CONNECT"
        [ariaLabel]="'Connect'"
      ></app-custom-icon>
      Connect
    </app-icon-title>
    <div class="connect-summary__content">
      <app-connect-status
        *appPermitted="'CONNECT__NETWORK_OVERVIEW__READ'"
        [status]="connectStatus"
        [details]="connectionDetails"
        [loadingStatus]="connectLoadingStatus"
      ></app-connect-status>
    </div>
  </ng-container>
  <app-button
    widget-footer
    *appPermitted="'CONNECT__DASHBOARD'"
    [display]="ButtonDisplay.BLOCK"
    [type]="ButtonType.BUTTON"
    iconName="chevron_right"
    [iconPosition]="ButtonIconPosition.END"
    appRouterLink="connect"
    data-testid="view-connect"
    >View Connect</app-button
  >
</app-widget>
