import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';

import {
  CookieConsentType,
  COOKIE_NAME,
} from '@shared/models/cookie-consent-type';

import { environment } from '@environments/default/environment';
import * as StatusActions from '@store/status/status.actions';
import { ButtonRole, ButtonType } from '@shared/models/button.model';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent implements OnInit {
  public cookieValue!: CookieConsentType;
  public hasResponded: boolean = false;

  public CookieConsentType = CookieConsentType;
  public domain: string = environment.cookie_domain;

  public ButtonType = ButtonType;
  public ButtonRole = ButtonRole;

  constructor(private cookieService: CookieService, private store: Store) {}

  ngOnInit() {
    this.hasResponded = this.cookieService.check(COOKIE_NAME);

    if (this.hasResponded) {
      this.cookieValue = this.cookieService.get(
        COOKIE_NAME
      ) as CookieConsentType;
      this.store.dispatch(
        StatusActions.setCookieConsent({ cookieConsent: this.cookieValue })
      );
    }
  }

  onRespond(type: CookieConsentType) {
    this.hasResponded = true;
    const expires = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );
    this.cookieService.set(COOKIE_NAME, type, { expires, domain: this.domain });
    this.store.dispatch(
      StatusActions.setCookieConsent({ cookieConsent: type })
    );
  }
}
