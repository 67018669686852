import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Status } from '@shared/models/status.model';
import { CountryService } from '@shared/services/country.service';

@Component({
  selector: 'app-top-country',
  templateUrl: './top-country.component.html',
  styleUrls: ['./top-country.component.scss'],
})
export class TopCountryComponent implements OnChanges {
  @Input() country: string | undefined;
  @Input() value!: string;
  @Input() status: Status = Status.LOADING;

  public countryName: string = '';
  public Status = Status;

  constructor(private countryService: CountryService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.country) {
      this.countryName = this.countryService.getCountryNameFromCode(
        this.country
      );
    } else {
      this.countryName = 'RFC1918';
    }
  }
}
