import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import { NewsStory } from '@shared/models/news-story.model';
import { BandwidthRequest } from '@shared/models/api-response/api-response-analytics.model';
import { BandwidthDatapoint } from '@charts/models/bandwidth.model';

import * as fromNews from '@store/news/news.reducer';
import * as NewsActions from '@store/news/news.actions';
import * as fromAccounts from '@store/accounts/accounts.selectors';
import * as AccountsActions from '@store/accounts/accounts.actions';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private store: Store, private actions$: Actions) {}

  /**
   * Check if News data is loaded, and load it if not
   */

  public checkNews(): Observable<NewsStory[]> {
    return this.store.select(fromNews.selectNews).pipe(
      take(1),
      switchMap((news) => {
        if (!news || news.length === 0) {
          this.store.dispatch(NewsActions.fetchNews());
          return this.actions$.pipe(
            ofType(NewsActions.SET_NEWS),
            map((result: { type: string; news: NewsStory[] }) => {
              return result.news;
            })
          );
        } else {
          return of(news);
        }
      })
    );
  }

  public getBandwidthData(
    request: BandwidthRequest
  ): Observable<BandwidthDatapoint[]> {
    return this.store.select(fromAccounts.selectBandwidthData(request)).pipe(
      switchMap((bandwidthData) => {
        if (!bandwidthData || bandwidthData.length === 0) {
          this.store.dispatch(AccountsActions.fetchBandwidthData(request));
          return this.actions$.pipe(
            filter(
              (action: any) =>
                (action.type === AccountsActions.SET_BANDWIDTH_DATA &&
                  action.bandwidthData.meta.accountId === request.accountId &&
                  action.bandwidthData.meta.interval === request.interval) ||
                action.bandwidthData?.error
            ),
            mergeMap((response) => {
              if (response.bandwidthData.error) {
                throw response.bandwidthData.error;
              } else {
                return of(response.data);
              }
            })
          );
        } else {
          return of(bandwidthData.data);
        }
      })
    );
  }
}
