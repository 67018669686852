<div class="contact-card">
  <img
    class="contact-card__image"
    src="{{ image }}"
    alt="{{ name }} - {{ position }}"
    width="48"
    height="48"
  />
  <div class="contact-card__text">
    <p class="contact-card__name">{{ name }}</p>
    <p class="contact-card__position">{{ position }}</p>
  </div>
  <app-button
    [type]="ButtonType.LINK"
    [role]="ButtonRole.PRIMARY"
    [link]="contact"
    class="contact-card__button is-centred"
    >CONTACT</app-button
  >
</div>
