export interface ToggleOptions {
  value: string;
  label: string;
  iconText?: string;
}

export enum ToggleType {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}
