<button
  *ngIf="type !== ButtonType.LINK"
  [type]="type"
  class="button"
  [ngClass]="buttonClasses"
  [disabled]="isDisabled"
  appDebounceClick
  (debounceClick)="onButtonClick($event)"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>

<a
  *ngIf="type === ButtonType.LINK"
  href="{{ link }}"
  class="button"
  target="_blank"
  [ngClass]="buttonClasses"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #content>
  <app-icon
    *ngIf="iconName"
    className="icon--button"
    [ngClass]="iconClasses"
    [type]="iconType"
    [size]="IconSize.MEDIUM"
    (click)="onIconClick($event)"
    >{{ iconName }}</app-icon
  >
  <ng-content></ng-content>
</ng-template>
