import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpCancelService } from '@shared/services/http-cancel.service';
import { Observable, takeUntil } from 'rxjs';

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
  constructor(private httpCancelService: HttpCancelService) {}

  intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    // /users/all is called by the UserGuard, which gets cancelled as soon as you log in
    // skip this inerceptor for the /users/all call
    if (req.url.slice(-10) === '/users/all') {
      return next.handle(req);
    }
    return next
      .handle(req)
      .pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
  }
}
