import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ButtonDisplay,
  ButtonRole,
  ButtonType,
} from '@shared/models/button.model';
import { WidgetType } from '@shared/models/widget.model';
import { TicketStatus } from '@support/models/ticket-status.constant';
import { Ticket, TicketType } from '../../models/ticket';
import { TicketPriorities } from '../../models/ticket-priorities';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss'],
})
export class TicketDetailsComponent {
  @Input() ticket!: Ticket;
  @Input() showAddComment: boolean = true;
  @Input() type?: TicketType;

  @Output() addCommentClick = new EventEmitter<null>();

  public TicketPriorities = TicketPriorities;
  public TicketStatus = TicketStatus;
  public ButtonRole = ButtonRole;
  public ButtonDisplay = ButtonDisplay;
  public ButtonType = ButtonType;
  public WidgetType = WidgetType;
  public TicketType = TicketType;

  constructor() {}

  onAddComment(): void {
    this.addCommentClick.emit();
  }
}
