import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { Account } from '../models/account';
import { User } from '../models/user';

import { UserService } from '@shared/services/user.service';
import * as AccountsActions from '@store/accounts/accounts.actions';
import * as fromAccounts from '@store/accounts/accounts.selectors';
import * as StatusActions from '@store/status/status.actions';
import * as fromStatus from '@store/status/status.reducer';
import { LocalStorageService } from './local-storage.service';
import { AccountStoreItem } from '@store/account-store-item.model';
import { PermissionsService } from './permissions.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private user: User | null = null;
  public accountsLoaded: boolean = false;
  private multiAccountUser?: boolean;

  constructor(
    private store: Store,
    private actions$: Actions,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private permissionsService: PermissionsService
  ) {
    this.userService.getCurrentUser().subscribe((user: User | null) => {
      if (user) {
        this.user = user;
        this.permissionsService.checkPermissions().subscribe(() => {
          this.multiAccountUser =
            this.permissionsService.userHasAccessToMultipleAccounts(user);
        });
      }
    });
  }

  /**
   * Check that accounts are loaded
   * if not populate the accounts store
   * (usually called from the account resolver to make sure account data exists pre-routing)
   */
  public checkAccounts(): Observable<AccountStoreItem[]> {
    return this.store.select(fromAccounts.selectAllAccounts).pipe(
      take(1),
      switchMap((accounts: AccountStoreItem[]) => {
        if (!accounts || accounts.length === 0) {
          if (this.user) {
            if (
              this.permissionsService.isUserPermitted(this.user, [
                'ADMIN__ACCOUNT__READ',
              ])
            ) {
              this.store.dispatch(AccountsActions.fetchAccounts());
            } else if (this.user.partnerId) {
              this.store.dispatch(AccountsActions.fetchPartnerAccounts());
            } else {
              this.store.dispatch(AccountsActions.fetchMyAccount());
              if (this.user.accountId && !this.multiAccountUser) {
                this.store.dispatch(
                  StatusActions.setCurrentAccountID({
                    id: this.user.accountId[0],
                  })
                );
              }
            }
          }
          return this.actions$.pipe(
            ofType(AccountsActions.SET_ACCOUNTS),
            map(
              (action: {
                type: string;
                accounts: AccountStoreItem[];
              }): any[] => {
                if (Array.isArray(action.accounts)) {
                  return action.accounts;
                } else {
                  return [action.accounts];
                }
              }
            )
          );
        } else {
          return of(accounts);
        }
      })
    );
  }

  public getCurrentAccountID(): Observable<string | null> {
    return this.store.select(fromStatus.selectCurrentAccountID);
  }

  /**
   * Get current account
   * @returns { Observable<Account> } observer to subscribe to for result.
   */
  public fetchCurrentAccount(): Observable<Account | undefined> {
    return this.store.select(fromAccounts.selectCurrentAccountData);
  }

  public updateLoadedAccountSubject(accountId: string) {
    this.localStorageService.setLocalStorage('current-account', accountId);
    this.store.dispatch(StatusActions.setCurrentAccountID({ id: accountId }));
  }

  public checkCurrentAccountID(): Observable<string | null> {
    return this.store.select(fromStatus.selectCurrentAccountID).pipe(
      take(1),
      switchMap((accountId) => {
        if (!accountId) {
          if (!this.multiAccountUser) {
            if (this.user) {
              let thisUserAccountId;
              if (!this.user.accountId) {
                return of(null);
              }
              if (Array.isArray(this.user.accountId)) {
                thisUserAccountId = this.user.accountId[0];
              } else {
                thisUserAccountId = this.user.accountId;
              }
              if (thisUserAccountId) {
                this.store.dispatch(
                  StatusActions.setCurrentAccountID({ id: thisUserAccountId })
                );
                return of(thisUserAccountId);
              }
            }
            return of(null);
          } else {
            const savedAccountId =
              this.localStorageService.getLocalStorage('current-account');
            if (savedAccountId) {
              this.store.dispatch(
                StatusActions.setCurrentAccountID({ id: savedAccountId })
              );
              return of(savedAccountId);
            } else {
              return of(null);
            }
          }
        } else {
          return of(accountId);
        }
      })
    );
  }
}
