import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  /**
   * sets the local storage key and data
   *
   * @param { string } key - key for the local storage
   * @param { string } data - the data related to the key
   */
  public setLocalStorage(key: string, data: string): void {
    localStorage.setItem(key, data);
  }

  /**
   * gets the local storage key and data
   *
   * @param { string } key - key for the local storage
   * @return { string | null } returns the data from the given key
   */
  public getLocalStorage(key: string): string | null {
    return localStorage.getItem(key);
  }

  /**
   * removes the local storage key and data
   *
   * @param { string } key - key for the local storage
   */
  public removeLocalStorage(key: string): void {
    return localStorage.removeItem(key);
  }

  /**
   * Reset all local storage values
   * @param key
   * @returns
   */
  public removeAllItems(): void {
    Object.keys(localStorage).forEach((item) => this.removeLocalStorage(item));
  }
}
