<div class="icon-tile" [ngClass]="classList">
  <app-icon
    *ngIf="!customIcon && !countryFlag"
    [status]="iconStatus"
    [className]="iconClassList"
    [title]="title"
    ><ng-content></ng-content
  ></app-icon>
  <app-custom-icon
    *ngIf="customIcon"
    [type]="customIcon"
    [status]="iconStatus"
    [size]="size"
    [className]="iconClassList"
    [ariaLabel]="customIcon"
    [title]="title"
  ></app-custom-icon>
  <app-country-flag
    *ngIf="countryFlag"
    [country]="countryFlag"
    [size]="size"
  ></app-country-flag>
</div>
