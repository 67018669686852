import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Account } from '@shared/models/account';

import { IconSize, IconType } from '@shared/models/icon.model';
import { AccountService } from '@shared/services/account.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  @Input() userName!: string;

  public accountName?: string;

  public IconType = IconType;
  public IconSize = IconSize;

  private accountSubscription!: Subscription;

  constructor(private accountService: AccountService) {}

  ngOnInit() {
    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .subscribe((account?: Account) => {
        if (account && account.id) {
          this.accountName = account.companyName;
        }
      });
  }

  ngOnDestroy(): void {
    this.accountSubscription?.unsubscribe();
  }
}
