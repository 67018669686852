import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { NavigationService } from '@core/navigation.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

/**
 * The purpose of this directive is to navigate the user to the first available sub-page.
 * For example, the user may have permissions to access Firewall Rules (within "Protect"),
 * but not "Protect Dashboard" (root page of "Protect").
 * In that situation, this directive will navigate the user straight to Firewall Rules
 * instead of showing them a blank "Protect Dashboard" page.
 *
 * How to use this directive:
 *
 * Replace "routerLink" attribute with "appRouterLink", for example:
 * <app-button appRouterLink="protect"></app-button>
 * <h2 appRouterLink="inspect"></h2>
 *
 * This directive will have no effect if the user is not logged in.
 * It is also have no effect if we are navigating the user to a page without any sub-pages.
 * In these scenarios, it is more efficient to use the base routerLink directive.
 */
@Directive({
  selector: '[appRouterLink]',
})
export class RouterLinkDirective extends RouterLink implements OnInit {
  @Input() appRouterLink!: string;

  constructor(
    router: Router,
    route: ActivatedRoute,
    renderer: Renderer2,
    public elementRef: ElementRef,
    private navigationService: NavigationService
  ) {
    super(router, route, undefined, renderer, elementRef);
  }

  ngOnInit() {
    const menuLink = this.navigationService.getMenuLinkByPath(
      this.appRouterLink
    );
    this.routerLink = menuLink;
    this.elementRef.nativeElement.href = menuLink;
  }
}
