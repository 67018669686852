import { Pipe, PipeTransform } from '@angular/core';
import { TicketPriorities } from '@support/models/ticket-priorities';

@Pipe({
  name: 'ticketPriority',
})
export class TicketPriorityPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    if (!value || typeof value !== 'number') {
      return value;
    }

    return TicketPriorities[value];
  }
}
