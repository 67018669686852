import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IconSize } from '@shared/models/icon.model';
import { FieldStatus } from '../../models/form-field.model';
import { SelectOptions, SelectType } from '../../models/form-select.model';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
})
export class FormDropdownComponent implements OnInit, OnChanges {
  @Input() selectOptions: SelectOptions[] = [];
  @Input() selectedOption: unknown = null;
  @Input() name: string = '';
  @Input() status: FieldStatus = FieldStatus.ACTIVE;
  @Input() type: SelectType = SelectType.SINGLE;
  @Input() errorMsg?: string | string[] | null;
  @Input() showErrorMsg: boolean = true;
  @Input() subtext?: string;

  @Output() selectedOptionChange = new EventEmitter();
  @Output() selectBlur = new EventEmitter();
  @Output() selectFocus = new EventEmitter();

  public IconSize = IconSize;
  public FieldStatus = FieldStatus;
  public SelectType = SelectType;

  public classList: string[] = [];

  constructor() {}

  ngOnInit(): void {
    this.classList = this.fieldClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.errorMsg || changes.status) {
      this.classList = this.fieldClasses();
    }
  }

  private fieldClasses(): string[] {
    const classArray = [];
    if (this.errorMsg && this.errorMsg.length !== 0) {
      classArray.push(`form-input--select--error`);
    }

    if (this.status === FieldStatus.DISABLED) {
      classArray.push(`form-input--select--disabled`);
    }

    return classArray;
  }

  onBlur() {
    this.selectBlur.emit();
  }

  onFocus() {
    this.selectFocus.emit();
  }
}
