import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { Directions } from '@shared/models/directions.model';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent {
  @Input() direction: Directions = Directions.RIGHT;
  @Input() offset: number = 5;
  @HostBinding('class.show') visible: boolean = false;

  // Based on cursor hovering over the text box
  public mouseOnPopover = false;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  // Called by the popover directive
  show(rect: DOMRect) {
    const coords = this.getCoords(rect);
    this.renderer.setStyle(
      this.element.nativeElement,
      'inset',
      `${coords.top} ${coords.right} ${coords.bottom} ${coords.left}`
    );
    this.visible = true;
  }

  getCoords(rect: DOMRect) {
    let top: string = 'auto';
    let bottom: string = 'auto';
    let left: string = 'auto';
    let right: string = 'auto';

    switch (this.direction) {
      case Directions.UP:
        top = `${Math.round(rect.top - 10 - this.offset)}px`;
        left = `${Math.round(rect.left + rect.width / 2)}px`;
        break;
      case Directions.DOWN:
        top = `${Math.round(rect.bottom + 10 + this.offset)}px`;
        left = `${Math.round(rect.left + rect.width / 2)}px`;
        break;
      case Directions.LEFT:
        top = `${Math.round(rect.top + rect.height / 2)}px`;
        left = `${Math.round(rect.left - 10 - this.offset)}px`;
        break;
      case Directions.RIGHT:
        top = `${Math.round(rect.top + rect.height / 2)}px`;
        left = `${Math.round(rect.right + 10 + this.offset)}px`;
        break;
    }
    return { top, left, bottom, right };
  }

  // Also called by the popover directive
  hide() {
    this.visible = false;
  }

  // Event handler for mouse events on the text box
  @HostListener('mouseover') onMouseEnter() {
    this.mouseOnPopover = true;
  }

  // Event handler for mouse events on the text box
  @HostListener('mouseleave') onMouseLeave() {
    this.mouseOnPopover = false;
    this.hide();
  }
}
