<app-help-icon>
  <p>
    This is your Bandwidth Overview, it shows you the maximum amount of data
    passing through your network at any one time. Above the zero line shows
    bandwidth in, below the line shows bandwidth out.
  </p>
  <p>
    The 95th percentile measurement for the time period is represented by the
    blue lines. You can query this data to view a specific date range and view
    data as old as 90 days.
  </p>
</app-help-icon>
