<app-modal [size]="ModalSize.XSMALL" [status]="modalStatus" class="add-report">
  <div modal-header>View report</div>

  <div modal-body>
    <ng-container *ngIf="report">
      <h3 class="view-report__heading">
        {{ report.title }}
        <app-pill
          *ngIf="report.reportStatus === ReportStatus.ENABLED"
          [type]="PillStatus.PRIMARY"
          >Enabled</app-pill
        >
        <app-pill
          *ngIf="report.reportStatus === ReportStatus.DISABLED"
          [type]="PillStatus.NEUTRAL"
          >Disabled</app-pill
        >
      </h3>
      <p>Created at: {{ report.createdAt | date : "HH:mm d MMM y" }}</p>
      <p>
        This report runs
        <strong>{{
          report.schedule
            ? ReportScheduleDict[report.schedule] || report.schedule
            : "unscheduled"
        }}</strong>
        with data covering
        <strong>{{
          report.period ? ReportPeriodDict[report.period] : report.period
        }}</strong
        >.
      </p>
      <app-datatable
        [data]="report.files || []"
        [displayedColumns]="['name', 'date', 'download']"
        [limit]="10"
        [sort]="['timestamp', 'desc']"
        [status]="Status.COMPLETE"
        (rowClick)="this.onRowClick($event)"
      >
        <!-- Name column -->
        <ng-container cdkColumnDef="name">
          <th cdk-header-cell *cdkHeaderCellDef sort-header id="name">Name</th>
          <td cdk-cell *cdkCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <!-- Date column -->
        <ng-container cdkColumnDef="date">
          <th cdk-header-cell *cdkHeaderCellDef sort-header id="date">Date</th>
          <td cdk-cell *cdkCellDef="let row">
            {{ row.timestamp | date : "HH:mm d MMM y" }}
          </td>
        </ng-container>

        <!-- Download column -->
        <ng-container cdkColumnDef="download">
          <th cdk-header-cell *cdkHeaderCellDef id="download"></th>
          <td cdk-cell *cdkCellDef="let row">
            <app-icon
              class="download-report"
              [size]="IconSize.MEDIUM"
              title="Download report"
              >download</app-icon
            >
          </td>
        </ng-container>
      </app-datatable>
    </ng-container>
  </div>

  <div
    modal-footer
    class="view-report__footer"
    *appPermitted="['SUPPORT__REPORTS__UPDATE']"
  >
    <app-button
      *ngIf="report?.reportStatus === ReportStatus.DISABLED"
      [role]="ButtonRole.PRIMARY"
      (buttonClick)="scheduleReport(report.id)"
      [status]="scheduleButtonStatus"
      data-testid="reschedule-report-button"
      >Enable</app-button
    >
    <app-button
      *ngIf="report?.reportStatus === ReportStatus.ENABLED"
      [role]="ButtonRole.PRIMARY"
      (buttonClick)="scheduleReport(report.id)"
      [status]="scheduleButtonStatus"
      data-testid="unschedule-report-button"
      >Disable</app-button
    >
  </div>
</app-modal>
