<div
  class="nav-item"
  routerLinkActive="nav-item--active"
  [routerLinkActiveOptions]="{ exact: false }"
  [attr.data-testid]="title"
>
  <a
    class="nav-item__link"
    (click)="onClick()"
    (keyup.enter)="onClick()"
    tabindex="0"
    [ngClass]="{
      'nav-item--isSelected': isSelected
    }"
  >
    <app-icon
      *ngIf="icon && !customIcon"
      [size]="IconSize.SMALL"
      [type]="IconType.OUTLINED"
      class="nav-item__icon"
      [title]="title"
      [ngClass]="{
        'nav-item--isSelected': isSelected
      }"
      >{{ icon }}</app-icon
    ><app-custom-icon
      *ngIf="icon && customIcon"
      [size]="IconSize.SMALL"
      [type]="icon"
      class="nav-item__icon"
      [ngClass]="{
        'nav-item--isSelected': isSelected
      }"
      [ariaLabel]="title"
    ></app-custom-icon>
    <p>{{ title }}</p>
  </a>
</div>
