import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subnetToCIDR',
})
export class SubnetToCIDRPipe implements PipeTransform {
  transform(input: any, subnet: string) {
    const parts = subnet.split(' ');
    const [ip, netmask] = parts;
    const maskNodes = netmask.match(/(\d+)/g);
    let cidr = 0;
    for (const i in maskNodes) {
      if (i) {
        // I'm working with binary data, so i think the bitwise ok.
        // eslint-disable-next-line no-bitwise
        cidr += ((Number(maskNodes[+i]) >>> 0).toString(2).match(/1/g) || [])
          .length;
      }
    }

    if (input === `${ip}/${cidr}`) {
      return;
    } else {
      return `${ip}/${cidr}`;
    }
  }
}
