import { Injectable, SimpleChanges } from '@angular/core';
import { formatNumber } from '@angular/common';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, skipWhile, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { DateTime } from 'luxon';

import {
  ResourceResponse,
  TrafficLog,
} from '@shared/models/api-response/api-response-analytics.model';
import * as AccountsActions from '@store/accounts/accounts.actions';
import * as fromAccounts from '@store/accounts/accounts.selectors';
import { DatePickerRange } from '@forms/models/datepicker.model';
import { ApiService } from '@shared/services/api.service';
import { AsyncLambdaResponse } from '@shared/models/api-response';
import { LogEntry, LogRequest } from '@shared/models/logs.model';

@Injectable({
  providedIn: 'root',
})
export class InspectService {
  constructor(
    private store: Store,
    private actions$: Actions,
    private apiService: ApiService
  ) {}

  /**
   * Get the usage for sources from the API
   * @param { Date } start Start date
   * @param { Date } end End date
   * @param { string } resource Resource to get ('sources', 'sessions' etc.)
   * @param { string } accountId ID of account to get (if absent, use account ID for current logged-in-user)
   * @param { string } interval Interval to filter by
   * @returns { Observable<ApiResponse> } observer to subscribe to for results
   */
  public usageResources({
    start,
    end,
    resource,
    accountId = null,
    interval = null,
  }: any): Observable<ResourceResponse> {
    start = DateTime.fromISO(start);
    end = DateTime.fromISO(end);
    return this.store
      .select(
        fromAccounts.selectResourceData({
          accountId,
          start,
          end,
          resource,
          interval,
        })
      )
      .pipe(
        take(1),
        mergeMap((resourceData) => {
          if (!resourceData) {
            this.store.dispatch(
              AccountsActions.fetchResourceData({
                accountId,
                start,
                end,
                resource,
                interval,
              })
            );
            return this.actions$.pipe(
              filter(
                (action: any) =>
                  action.type === AccountsActions.SET_RESOURCE_DATA &&
                  action.resourceData.meta.type === resource
              ),
              mergeMap((data) => {
                if (data.resourceData.error) {
                  throw data.resourceData.error;
                } else {
                  return this.store.select(
                    fromAccounts.selectResourceData({
                      accountId,
                      start,
                      end,
                      resource,
                      interval,
                    })
                  );
                }
              })
            );
          } else {
            return of(resourceData);
          }
        })
      );
  }

  formatRequest(
    changes: SimpleChanges,
    accountId: string,
    resource: string,
    dates?: DatePickerRange,
    interval?: string
  ) {
    let request = {
      resource: resource,
      accountId: accountId,
    };
    if (changes.dates && dates) {
      request = {
        ...request,
        ...{
          start: dates.from,
          end: dates.to,
        },
      };
    } else {
      request = {
        ...request,
        ...{
          interval: interval || '7days',
        },
      };
    }
    return request;
  }

  formatDataForChart(results: ResourceResponse) {
    const blockedArray: { y: number; x: number }[] = [];
    const allowedArray: { y: number; x: number }[] = [];
    results.data.timeBlocks.map(
      (block: {
        session_pass: number;
        session_block: number;
        time: string;
      }) => {
        blockedArray.push({
          y: block.session_block,
          // x: DateTime.fromSeconds(+block.time).toISO(),
          x: DateTime.fromSeconds(+block.time).toMillis(),
        });
        allowedArray.push({
          y: block.session_pass,
          // x: DateTime.fromSeconds(+block.time).toISO(),
          x: DateTime.fromSeconds(+block.time).toMillis(),
        });
      }
    );
    return {
      name: 'Sessions',
      legendItems: [
        {
          label: 'Blocked',
          value: formatNumber(results.data.blocked, 'en-GB'),
          sortvalue: results.data.blocked,
        },
        {
          label: 'Allowed',
          value: formatNumber(results.data.allowed, 'en-GB'),
          sortvalue: results.data.allowed,
        },
      ],
      lines: [
        { data: blockedArray, label: 'Blocked' },
        { data: allowedArray, label: 'Allowed' },
      ],
    };
  }

  public exportLogs(request: LogRequest): Observable<any> {
    return new Observable((observer) => {
      this.apiService.post('/analytics/logs', request).subscribe({
        next: (response: AsyncLambdaResponse) => {
          if (response.data.asyncLaunched) {
            observer.next();
            observer.complete();
          } else {
            observer.error();
            observer.complete();
          }
        },
        error: (err) => {
          observer.error(err);
          observer.complete();
        },
      });
    });
  }

  /**
   * Get the usage for sources from the API
   * @param { string } accountId ID of account to get (if absent, use account ID for current logged-in-user)
   */
  public trafficLogExports({
    accountId = null,
    cacheBreak = false,
  }: any): Observable<LogEntry[] | undefined> {
    return this.store
      .select(
        fromAccounts.selectTrafficLogsExports({
          accountId,
        })
      )
      .pipe(
        take(1),
        mergeMap((trafficLogExports) => {
          if (!trafficLogExports || cacheBreak === true) {
            this.store.dispatch(
              AccountsActions.fetchTrafficLogsExports({
                accountId,
              })
            );
            return this.actions$.pipe(
              // Ignore null
              skipWhile((val) => val === null),
              // only when action is SET_TRAFFIC_LOGS_EXPORTS and updated logs are in this account
              filter(
                (action: any) =>
                  action.type === AccountsActions.SET_TRAFFIC_LOGS_EXPORTS &&
                  action.trafficLogsExports.meta.accountId === accountId
              ),
              mergeMap((data) => {
                if (data.trafficLogsExports?.error) {
                  throw data.trafficLogsExports.error;
                } else {
                  return this.store.select(
                    fromAccounts.selectTrafficLogsExports({
                      accountId,
                    })
                  );
                }
              })
            );
          } else {
            return of(trafficLogExports);
          }
        })
      );
  }

  public trafficLogs({
    accountId,
    start,
    end,
    srcIp,
    dstIp,
    action,
    type,
    policyid,
  }: any): Observable<{
    data: TrafficLog[];
    meta: { accountId: string; type: string; end: string; start: string };
  }> {
    let url = `/analytics/logs?accountId=${accountId}&start=${start}&end=${end}&type=${type}`;
    if (srcIp) {
      url += `&srcip=${srcIp}`;
    }
    if (dstIp) {
      url += `&dstip=${dstIp}`;
    }
    if (action) {
      url += `&action=${action}`;
    }
    if (policyid) {
      url += `&policyid=${policyid}`;
    }
    return this.apiService.get(url);
  }

  public getTrafficLog(log: LogEntry): Observable<any> {
    return this.apiService.get(
      `/analytics/logs/${log.id}?accountId=${log.accountId}`
    );
  }
}
