<div class="bulk-upload-users__body">
  <app-info-box>
    You can upload users in bulk via CSV file using the process below.
    <strong
      >It is important that you use the
      <a class="pointer" (click)="openSampleFile()">template file</a></strong
    >
    to ensure the format is correct, the template contains 3 fields,
    "firstname", "lastname" and "email",
    <strong>all fields are required</strong>.
  </app-info-box>
  <h4 class="bulk-upload-users__heading">Upload your complete list of users</h4>
  <div class="bulk-upload-users__body__explanation">
    Once you are done adding users to the CSV file, save it and then upload the
    file below.
  </div>
  <app-file-picker
    (uploadSuccess)="onUploadSuccess($event)"
    (removeSuccess)="onRemoveSuccess($event)"
    class="raise-incident__form-field"
    [fileOptions]="fileOptions"
  ></app-file-picker>
</div>
<div class="bulk-upload-users__footer">
  <app-button (buttonClick)="cancel()" data-testid="cancel-button"
    >Cancel</app-button
  >
  <app-button
    [status]="submitButtonStatus"
    (buttonClick)="createUsers()"
    [type]="ButtonType.SUBMIT"
    [role]="ButtonRole.PRIMARY"
    [display]="ButtonDisplay.INLINE"
    data-testid="create-users-button"
    >Create multiple
    {{ csvRowLength ? "(" + csvRowLength + ")" : "" }} users</app-button
  >
</div>
