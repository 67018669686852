export const isNumber = (value: any): value is number => {
  return typeof value === 'number';
};

export const isNumberFinite = (value: any): value is number => {
  return isNumber(value) && isFinite(value);
};

// Not strict positive
export const isPositive = (value: number): boolean => {
  return value >= 0;
};

export const isInteger = (value: number): boolean => {
  // No rest, is an integer
  return value % 1 === 0;
};

export const toDecimal = (value: number, decimal: number): number => {
  return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
};
