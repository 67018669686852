import { Injectable } from '@angular/core';

@Injectable()
export class StylesService {
  private variables: string[] = [
    '--map-country--major',
    '--map-country--high',
    '--map-country--medium',
    '--map-country--low',
    '--chart__text',
    '--chart__gridlines',
    '--tooltip__background',
    '--tooltip__border',
    '--bandwidth-chart__gridlines',
    '--bandwidth-chart__ticks',
    '--bandwidth-chart__labels',
    '--bandwidth-chart__legend',
    '--bandwidth-chart__in-colour',
    '--bandwidth-chart__in-colour--hover',
    '--bandwidth-chart__in95-colour',
    '--bandwidth-chart__out-colour',
    '--bandwidth-chart__out-colour--hover',
    '--bandwidth-chart__out95-colour',
    '--donut-chart__background--0',
    '--donut-chart__background--1',
    '--donut-chart__background--2',
    '--donut-chart__background--3',
    '--donut-chart__background--4',
    '--donut-chart__background--5',
    '--donut-chart__background--6',
    '--donut-chart__background--7',
    '--donut-chart__background--8',
    '--donut-chart__background--9',
    '--donut-chart__background--0-hover',
    '--donut-chart__background--1-hover',
    '--donut-chart__background--2-hover',
    '--donut-chart__background--3-hover',
    '--donut-chart__background--4-hover',
    '--donut-chart__background--5-hover',
    '--donut-chart__background--6-hover',
    '--donut-chart__background--7-hover',
    '--donut-chart__background--8-hover',
    '--donut-chart__background--9-hover',
    '--donut-chart__border',
    '--donut-chart__border--hover',
    '--donut-chart__background--0-0',
    '--donut-chart__background--0-1',
    '--donut-chart__background--1-0',
    '--donut-chart__background--1-1',
    '--donut-chart__background--2-0',
    '--donut-chart__background--2-1',
    '--donut-chart__background--3-0',
    '--donut-chart__background--3-1',
    '--donut-chart__background--0-0-hover',
    '--donut-chart__background--0-1-hover',
    '--donut-chart__background--1-0-hover',
    '--donut-chart__background--1-1-hover',
    '--donut-chart__background--2-0-hover',
    '--donut-chart__background--2-1-hover',
    '--donut-chart__background--3-0-hover',
    '--donut-chart__background--3-1-hover',
    '--donut-chart__track-0',
    '--donut-chart__track-1',
    '--donut-chart__track-2',
    '--donut-chart__track-3',
    '--donut-chart__border',
    '--donut-chart__border--hover',
    '--line-chart--blocked-border',
    '--line-chart--blocked-background',
    '--line-chart--allowed-border',
    '--line-chart--allowed-background',
    '--line-chart--cursor-color',
    '--network-label__background',
    '--network-label__border',
    '--network-blob__colour--up',
    '--network-blob__colour--down',
    '--network-blob__colour--failover',
    '--network-blob__colour--inactive',
    // '--network-blob__hover',
    // '--network-blob__background--up',
    // '--network-blob__background--failover',
    // '--network-blob__background--down',
    // '--network-blob__background-selected--up',
    // '--network-blob__background-selected--failover',
    // '--network-blob__background-selected--down',
    // '--network-blob__line--up',
    // '--network-blob__line--failover',
    // '--network-blob__line--down',
    // '--network-blob__line--inactive',
    // '--network-site__background--up',
    // '--network-site__background--failover',
    // '--network-site__background--down',
    // '--network-site__background--inactive',
    // '--network-site__background-selected--up',
    // '--network-site__background-selected--failover',
    // '--network-site__background-selected--down',
    '--stacked-bar__1',
    '--stacked-bar__2',
    '--stacked-bar__3',
  ];
  public styles: { [key: string]: string } = {};

  constructor() {
    const computedStyle: CSSStyleDeclaration = getComputedStyle(document.body);
    this.variables.forEach((variable) => {
      this.styles[variable] = computedStyle.getPropertyValue(variable);
    });
  }
}
