<app-modal [size]="ModalSize.XSMALL" [status]="modalStatus">
  <div modal-header>Reset {{ label }}</div>
  <div modal-body>
    <form *ngIf="step === 1">
      <ng-container *ngIf="type === 'passwords'">
        <p>
          If a user has reported issues accessing the VPN you can reset their
          password below.
        </p>

        <p>
          Please note, once reset, an automatic email will be sent containing
          instructions on how to generate a new password. Password reset emails
          will expire after 1 hour of being sent.
        </p>

        <p>
          <strong
            >If the link isn't followed the password will not be changed. If you
            are revoking access for a user please use the add/remove user
            function from the 'Manage Users' dropdown instead.</strong
          >
        </p>
      </ng-container>
      <ng-container *ngIf="type === 'mfa-tokens'">
        <p>
          If a user has reported issues accessing the VPN you can reset their
          MFA token below.
        </p>

        <p>
          Please note, once reset, an automatic email will be sent containing
          instructions on how to generate a new MFA token. MFA reset emails will
          expire after 1 hour of being sent.
        </p>

        <p>
          <strong
            >If you are revoking access for a user please use the add/remove
            user function from the 'Manage Users' dropdown instead.</strong
          >
        </p>
      </ng-container>

      <p>
        You are resetting the {{ label }} for
        <strong>{{
          selectedAccounts.length === 1
            ? "one user."
            : selectedAccounts.length + " users."
        }}</strong>
      </p>

      <p>
        <app-datatable
          class="reset-vpn-auth__table"
          [data]="selectedAccounts"
          [displayedColumns]="['email']"
          [limit]="10"
          [sort]="['email', 'desc']"
          [status]="tableStatus"
          [showFooter]="false"
        >
          <!-- email column -->
          <ng-container cdkColumnDef="email">
            <th cdk-header-cell *cdkHeaderCellDef sort-header>E-Mail</th>
            <td cdk-cell *cdkCellDef="let row">{{ row.email }}</td>
          </ng-container>
        </app-datatable>

        <app-button-group>
          <app-button
            [status]="ButtonStatus.ACTIVE"
            [size]="ButtonSize.LARGE"
            [role]="ButtonRole.DEFAULT"
            (buttonClick)="cancel()"
            >Cancel</app-button
          >
          <app-button
            [status]="
              !selectedAccounts.length
                ? ButtonStatus.DISABLED
                : ButtonStatus.ACTIVE
            "
            [size]="ButtonSize.LARGE"
            [role]="ButtonRole.PRIMARY"
            (buttonClick)="nextStep()"
            >Reset {{ selectedAccounts.length }} {{ label }}</app-button
          >
        </app-button-group>
      </p>
    </form>

    <form *ngIf="step === 2">
      <p>You are resetting the {{ label }} for the following users:</p>
      <app-datatable
        class="reset-vpn-auth__table"
        [data]="selectedAccounts"
        [displayedColumns]="['email']"
        [limit]="10"
        [sort]="['email', 'desc']"
        [status]="Status.COMPLETE"
        [showFooter]="false"
      >
        <!-- email column -->
        <ng-container cdkColumnDef="email">
          <th cdk-header-cell *cdkHeaderCellDef sort-header>E-Mail</th>
          <td cdk-cell *cdkCellDef="let row">{{ row.email }}</td>
        </ng-container>
      </app-datatable>
      <p>Type the word "Reset" to continue:</p>
      <app-form-field
        class="reset-vpn-auth__reset-field"
        [type]="FieldType.TEXT"
        [(inputValue)]="resetField"
        name="reset-field"
        placeholder="Reset"
        [errorMsg]="resetError"
      ></app-form-field>
      <app-button-group>
        <app-button
          [status]="ButtonStatus.ACTIVE"
          [size]="ButtonSize.LARGE"
          [role]="ButtonRole.DEFAULT"
          (buttonClick)="cancel()"
          >Cancel</app-button
        >
        <app-button
          [size]="ButtonSize.LARGE"
          [role]="ButtonRole.DEFAULT"
          (buttonClick)="prevStep()"
          >Go back</app-button
        >
        <app-button
          [status]="getButtonStatus()"
          [role]="ButtonRole.PRIMARY"
          [size]="ButtonSize.LARGE"
          (buttonClick)="reset()"
          >Reset {{ selectedAccounts.length }} {{ label }}</app-button
        >
      </app-button-group>
    </form>
  </div>
</app-modal>
