export enum ConnectionStatus {
  UP = 'up',
  WARNING = 'alert',
  DOWN = 'down',
  UNKNOWN = 'unknown',
}

export enum ConnectionsAltText {
  UP = 'Connection UP',
  WARNING = 'Connection Alert',
  DOWN = 'Connection Down',
  UNKNOWN = 'Connection Unknown',
}

export interface ConnectionStatusDetail {
  status: ConnectionStatus;
  label: string;
  value: string;
}
