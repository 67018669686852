import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CdkTable, CdkColumnDef } from '@angular/cdk/table';
import { DateTime } from 'luxon';
import { IconSize } from '@shared/models/icon.model';
import { Status } from '@shared/models/status.model';
import { tableRowAnimation } from '@shared/animations/animations';
import { DatatableType } from '@shared/models/datatable.model';

// Delay before next row appears (in ms)
const delayTime = 50;
// How long it takes for the row to appear (in ms)
const durationTime = 100;
@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  animations: [tableRowAnimation(durationTime + 'ms')],
})
export class DatatableComponent implements AfterContentInit, OnChanges {
  @Input() data: any[];
  @Input() displayedColumns: string[] | undefined;
  @Input() limit: number = 50;
  @Input() sortable: boolean = true;
  @Input() sort: [string | number, 'asc' | 'desc'] | null = null;
  @Input() status: Status = Status.LOADING;
  @Input() noDataMessage = 'There is no data to show';
  @Input() showBorder = true;
  @Input() showFooter = true;
  @Input() type: DatatableType = DatatableType.REGULAR;
  @Input() unknownSize: boolean = false;

  @Output() fetchNextPage = new EventEmitter();
  @Output() rowClick = new EventEmitter<unknown>();

  // Content projected from parent component into this one
  @ViewChild(CdkTable, { static: true }) table: CdkTable<any> | undefined;
  @ContentChildren(CdkColumnDef) columnDefs:
    | QueryList<CdkColumnDef>
    | undefined;

  public clickable: boolean = false;
  public scrollable: boolean = false;
  public pagedData: any[];
  public currentPage: number = 1;
  public lastPage?: number;
  private fromPagination: boolean = false;

  public Status = Status;
  public IconSize = IconSize;
  public DatatableType = DatatableType;

  constructor(private elemRef: ElementRef) {
    this.data = new Array(this.limit);
    this.pagedData = this.data;
  }

  ngOnChanges(changes: SimpleChanges) {
    // Add --clickable class if there is a subscriber to this.rowClick
    this.clickable = this.rowClick.observed;

    // Sort data before rendering
    if (this.sortable) {
      this.sortData();
    }

    // Reset page if data was updated
    if (changes.data) {
      if (!this.fromPagination) {
        this.currentPage = 1;
      }
      this.fromPagination = false;
    }
    // Add pagination if needed
    this.pageData();

    // See if scroll stuff needs doing
    this.setScroll();
  }

  ngAfterContentInit() {
    // Add column definitions to the table after <ng-content> is initialised.
    this.columnDefs?.forEach((column) => {
      this.table?.addColumnDef(column);
      // Sort data with column defs in place
      if (this.data.length) {
        this.sortHeaders();
      }
    });
  }

  /**
   * Adds correct classes to headers according to this.sort
   */
  private sortHeaders(): void {
    if (!this.sort) return;
    let [sortKey, sortOrder] = this.sort;
    // If sortKey is a number, convert first object values into array to get nth value
    if (typeof sortKey === 'number') {
      sortKey = Object.keys(this.data[0])[sortKey];
    }
    this.columnDefs?.forEach((column) => {
      const headerCell = document.querySelector(
        `.cdk-column-${column.cssClassFriendlyName}.cdk-header-cell`,
      );
      // Take off all classes first
      headerCell?.classList.remove('is-sorted--asc', 'is-sorted--desc');
      let columnName: string = column.name;
      if (headerCell?.hasAttribute('sort-value')) {
        columnName = headerCell.getAttribute('sort-value') || '';
      }
      if (columnName === sortKey) {
        // Add class
        headerCell?.classList.add(`is-sorted--${sortOrder}`);
      }
    });
  }

  /**
   * Sorts this.data according to this.sort
   * @returns { Array } Array of sorted data
   */
  private sortData(): void {
    if (this.data.length === 0 || !this.sort) {
      return;
    }
    let [sortKey, sortOrder] = this.sort;
    // If sortKey is a number, convert first object values into array to get nth value
    if (typeof sortKey === 'number') {
      sortKey = Object.keys(this.data[0])[sortKey];
    }

    // Add is-sorted--asc|desc class to necessary column headers
    if (this.columnDefs) {
      this.sortHeaders();
    }

    // if sortOrder is asc, multiply result by -1
    const sortFactor = sortOrder === 'asc' ? -1 : 1;
    this.data = [...this.data].sort((a: any, b: any) => {
      // sortKey can be passed as "monitor.usage", so might need to get sub-properties
      const aValue = ('' + sortKey).split('.').reduce((o, i) => o[i], a);
      const bValue = ('' + sortKey).split('.').reduce((o, i) => o[i], b);

      // That key doesn't exist on either, leave as is
      if (typeof aValue === 'undefined' && typeof bValue === 'undefined') {
        return sortFactor;
      }
      // That key doesn't exist on one of them, the one that has it should be first
      if (typeof aValue === 'undefined' || typeof bValue === 'undefined') {
        return typeof aValue === 'undefined' ? sortFactor : -1 * sortFactor;
      }
      const typeCheckFactor =
        this.checkSortTypes(aValue, bValue) === true ? 1 : -1;
      return sortFactor * typeCheckFactor;
    });
  }

  private checkSortTypes(aValue: any, bValue: any): boolean {
    // Check if aValue and bValue are valid when turned into dates
    const dateA = DateTime.fromISO(aValue);
    const dateB = DateTime.fromISO(bValue);

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      // Numbers default is desc (biggest to smallest)
      return aValue < bValue;
    } else if (dateA.isValid || dateB.isValid) {
      // If so, compare dates
      return dateA < dateB;
    } else if (aValue instanceof Array && bValue instanceof Array) {
      return aValue.length < bValue.length;
    } else {
      // Otherwise, just do string comparison
      // Default is A - Z
      const nameA = aValue?.toUpperCase(); // ignore upper and lowercase
      const nameB = bValue?.toUpperCase(); // ignore upper and lowercase

      return nameA > nameB;
    }
  }

  /**
   * Paginates data according to this.limit.
   * Doing both server-side (unknownSize) and client-side pagination.
   *
   * When the user switches page and server-side pagination is enabled, we check
   * if the data is already loaded. If not - we fire off an API request to fetch it.
   */
  public pageData(page: number = 1, fromPagination: boolean = false): void {
    const pageSwitch = fromPagination ? page : this.currentPage;
    const startItem = (pageSwitch - 1) * this.limit;
    const endItem = pageSwitch * this.limit;

    if (this.unknownSize) {
      if (this.lastPage || this.lastPage === 0) {
        this.lastPage = undefined;
      }
      if (fromPagination && !this.data[endItem - 1]) {
        if (page > this.currentPage) {
          this.fromPagination = true;
          this.fetchNextPage.emit();
        }
      } else {
        this.pagedData = this.data.slice(startItem, endItem);
      }
    } else {
      this.lastPage = Math.ceil(this.data.length / this.limit);
      this.pagedData = this.data.slice(startItem, endItem);
    }
    if (fromPagination) {
      this.scrollToTop();
      this.currentPage = page;
    }
  }

  private scrollToTop() {
    const nativeElem = this.elemRef.nativeElement;
    const nativeElemTop = nativeElem?.getBoundingClientRect().top;
    // Scroll window on <1280, .main-content on >1280
    let elemToScroll;
    let main;
    if (document.body.clientWidth < 1280) {
      elemToScroll = window;
      main = document.body;
    } else {
      elemToScroll = document.querySelector('.main-content');
      main = document.querySelector('.main-content__main');
    }
    const mainTop = main?.getBoundingClientRect().top ?? 0;
    // Distance between top of main container and the datatable
    const top = -1 * ((mainTop ?? 0) - nativeElemTop);
    elemToScroll?.scrollTo({
      behavior: 'smooth',
      top,
    });
  }

  public get classList(): string[] {
    const classes = [];
    if (this.data.length === 0) {
      classes.push('is-empty');
    }
    if (this.status === Status.LOADING) {
      classes.push('is-loading');
    }
    if (this.status === Status.ERROR) {
      classes.push('has-error');
    }
    if (this.clickable === true) {
      classes.push('cdk-table--clickable');
    }
    if (this.scrollable === true) {
      classes.push('cdk-table--scrollable');
    }
    return classes;
  }

  public handleRowClick(row: unknown) {
    this.rowClick.emit(row);
  }

  public handleHeaderRowClick(event: any): void {
    // Only sort if it's a [sort-header]
    if (!event.target.hasAttribute('sort-header')) {
      return;
    }
    let sortKey;
    // Get column name from sort-value or className
    if (event.target.hasAttribute('sort-value')) {
      sortKey = event.target.getAttribute('sort-value');
    } else {
      event.target.classList.forEach((className: string) => {
        if (className.indexOf('cdk-column-') > -1) {
          sortKey = className.substring(11);
        }
      });
    }
    if (!sortKey) {
      return;
    }
    const sortOrder = event.target.classList.contains('is-sorted--desc')
      ? 'asc'
      : 'desc';
    this.sort = [sortKey, sortOrder];
    this.sortData();
    this.pageData();
  }

  /**
   * Make enterDelay and leaveDelay params for animation based on how many items in a row
   * @param row THe row that is being animated in
   * @returns string of params to send to animation
   */
  public makeParams(row: unknown): any {
    let rowIndex = this.data.indexOf(row);
    if (this.limit !== 0) {
      rowIndex = rowIndex % this.limit;
    }
    if (rowIndex === -1) {
      rowIndex = 0;
    }
    return {
      value: '',
      params: {
        enterDelay: rowIndex * delayTime + 'ms', // enter in normal order
      },
    };
  }

  public get isEmpty(): boolean {
    return (
      (this.status === Status.COMPLETE && this.data.length === 0) ||
      this.status === Status.NO_DATA
    );
  }

  private setScroll() {
    const component = this.elemRef.nativeElement;
    const componentDimensions = component.getBoundingClientRect();

    // Sometimes fires before component has any size, so try again
    if (componentDimensions.height === 0) {
      setTimeout(() => {
        this.setScroll();
      }, 20);
      return;
    }

    // If component is going off the edge of the screen, set a max-width on the component
    // And add --scrollable class
    if (
      componentDimensions.left + componentDimensions.width >
      window.innerWidth
    ) {
      this.scrollable = true;
      const maxWidth = window.innerWidth - 2 * componentDimensions.left; // Give it space on the right as well as the left
      component.style.setProperty('--max-width', `${maxWidth}px`);
    }
  }
}
