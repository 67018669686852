<section class="panel">
  <h2 class="panel__title">
    <ng-content select="[panel-title]"></ng-content>
  </h2>
  <div class="panel__content">
    <p class="panel__subtitle">
      <ng-content select="[panel-subtitle]"></ng-content>
    </p>
    <ng-content select="[panel-content]"></ng-content>
  </div>
  <div class="panel__footer">
    <ng-content select="[panel-footer]"></ng-content>
  </div>
</section>
