import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSize } from '@shared/models/icon.model';
import { FieldType } from '../../models/form-field.model';

@Component({
  selector: 'app-filter-text',
  templateUrl: './filter-text.component.html',
  styleUrls: ['./filter-text.component.scss'],
})
export class FilterTextComponent {
  @Input() inputValue: string = '';
  @Input() name: string = '';
  @Input() placeholder: string = '';
  @Output() inputValueChange = new EventEmitter();

  public FieldType = FieldType;
  public IconSize = IconSize;

  constructor() {}
}
