import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PermissionsService } from './services/permissions.service';
import { UserService } from './services/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuard implements CanActivate, CanActivateChild {
  constructor(
    private permissionsService: PermissionsService,
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.permissionsService.checkPermissions().pipe(
      switchMap(() => {
        return this.userService.getCurrentUser();
      }),
      map((user) => {
        const feature = route.data.id;
        const isAllowed = feature
          ? this.permissionsService.isFeatureAllowedForUser(feature, user)
          : true;

        if (isAllowed) {
          return true;
        } else {
          return this.router.createUrlTree(['/']);
        }
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
