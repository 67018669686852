import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Account } from '@shared/models/account';
import {
  ButtonRole,
  ButtonSize,
  ButtonStatus,
} from '@shared/models/button.model';
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import { Status } from '@shared/models/status.model';
import { AccountService } from '@shared/services/account.service';
import { ErrorService } from '@shared/services/error.service';
import { ModalService } from '@shared/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FieldType } from '@forms/models/form-field.model';
import { ConnectService } from '../../connect.service';
import { SSLVPN } from '../../models/ssl-vpn.model';
import { IconSize } from '@shared/models/icon.model';
import { ChangeVPNPasswordRequest } from '@shared/models/api-response/api-response-remote.model';
import { PillSize, PillStatus } from '@shared/models/pill.model';
import { FormFieldComponent } from '@forms/components/form-field/form-field.component';
import { AlertService } from '@shared/services/alert.service';

export interface SSLVPNTableRow extends SSLVPN {
  inputOpened?: boolean;
  newPassword?: string;
  passwordChanged?: boolean;
}

@Component({
  selector: 'app-change-vpn-passwords',
  templateUrl: './change-vpn-passwords.component.html',
  styleUrls: ['./change-vpn-passwords.component.scss'],
})
export class ChangeVPNPasswordsComponent implements OnInit, OnDestroy {
  public ModalSize = ModalSize;
  public TableStatus = Status;
  public ButtonSize = ButtonSize;
  public ButtonRole = ButtonRole;
  public ButtonStatus = ButtonStatus;
  public FieldType = FieldType;
  public IconSize = IconSize;
  public PillStatus = PillStatus;
  public PillSize = PillSize;

  private accountSubscription!: Subscription;
  private accountData!: Account;

  public modalStatus: ModalStatus = ModalStatus.OPEN;
  public selectedAccounts: SSLVPNTableRow[] = [];
  public tableStatus: Status = Status.LOADING;

  public activeUsername?: string;
  public activePassword: string = '';
  public passwordValid: boolean = false;
  public awaitingResponse: boolean = false;
  public modalCloseMessage: string =
    'Closing this modal will discard all changes. Are you sure you want to close the modal?';

  constructor(
    private accountService: AccountService,
    private connectService: ConnectService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    public alertService: AlertService
  ) {}

  @ViewChildren('passwordInput')
  passwordInput!: QueryList<FormFieldComponent>;

  ngOnInit(): void {
    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(take(1))
      .subscribe((account?: Account) => {
        if (account && account.id) {
          this.accountData = account;
          const sslVpnContext = this.modalService.getContext();

          if (!sslVpnContext) {
            this.modalStatus = ModalStatus.CLOSED;
          } else {
            this.selectedAccounts = structuredClone(sslVpnContext);
            this.tableStatus = Status.COMPLETE;
          }
        }
      });
  }

  ngOnDestroy() {
    this.accountSubscription?.unsubscribe();
  }

  public cancel(): void {
    this.alertService.throwConfirmation(
      this.modalCloseMessage,
      'Close',
      'Warning',
      () => (this.modalStatus = ModalStatus.CLOSED)
    );
  }

  public openPasswordField(row: SSLVPNTableRow) {
    if (this.activeUsername) return;
    this.selectedAccounts.forEach((account) => {
      if (account.vpnUsername === row.vpnUsername) {
        account.inputOpened = true;
        this.activeUsername = row.vpnUsername;
        this.activePassword = '';

        // Set focus to the input field
        setTimeout(() => {
          this.passwordInput.first?.select();
        });
      }
    });
  }

  public closePasswordField(row: SSLVPNTableRow) {
    this.selectedAccounts.forEach((account) => {
      if (account.vpnUsername === row.vpnUsername) {
        account.inputOpened = false;
        this.activeUsername = undefined;
        this.activePassword = '';
        this.passwordValid = false;
        account.newPassword = row.newPassword ?? '**************';
      }
    });
  }

  public passwordValidityChanged(valid: boolean): void {
    this.passwordValid = valid;
  }

  public changePassword(row: SSLVPNTableRow) {
    if (!this.passwordValid || !this.activeUsername || !this.activePassword) {
      return;
    }
    this.awaitingResponse = true;
    const adUsername =
      this.selectedAccounts.find(
        (vpnAccount) => vpnAccount.vpnUsername === this.activeUsername
      )?.adUsername ?? '';
    const request: ChangeVPNPasswordRequest = {
      adUsername: adUsername,
      password: this.activePassword,
    };
    this.connectService
      .changeVPNPassword(request, this.accountData.id)
      .subscribe({
        next: () => {
          this.toastr.success(
            "User's password has been changed successfully",
            'Success',
            {
              progressBar: true,
            }
          );
          row.newPassword = this.activePassword;
          this.activeUsername = undefined;
          this.activePassword = '';
          this.passwordValid = false;
          row.passwordChanged = true;
          row.inputOpened = false;
          this.awaitingResponse = false;
        },
        error: (err) => {
          this.errorService.handleSaveErrors(err);
          this.awaitingResponse = false;
        },
      });
  }
}
