import { Pipe, PipeTransform } from '@angular/core';

/**
 * Joins an array with a separator
 * Separator defaults to ','
 * Can optionally pass an attribute, so you can pass an object and join all of a specified attribute
 */
@Pipe({ name: 'join' })
export class JoinPipe implements PipeTransform {
  transform(array: any[], separator = ',', attribute?: string): string {
    return array
      .map((value) => {
        if (attribute) {
          return value[attribute];
        } else {
          return value;
        }
      })
      .join(separator);
  }
}
