<app-panel class="two-factor-auth__form" id="verification">
  <ng-container panel-title>2-factor verification</ng-container>
  <ng-container panel-subtitle *ngIf="mfaType === mfaTypes.TOTP">
    <span data-testid="mfa-instruction"
      >Please enter the PIN number as shown in your Authenticator app.</span
    >
  </ng-container>
  <ng-container panel-subtitle *ngIf="mfaType === mfaTypes.SMS">
    <span data-testid="mfa-instruction"
      >A text message with a 6-digit verification code was just sent to
      {{ codeDestination }}.</span
    >
  </ng-container>
  <div panel-content>
    <app-mfa-code
      (mfaCodeChange)="verify($event)"
      [status]="status"
      class="two-factor-auth__code-entry"
    ></app-mfa-code>
    <div class="two-factor-auth__auth-timer">
      <app-icon title="Timer" class="two-factor-auth__auth-timer-icon" [size]="IconSize.SMALL"
        >timer</app-icon
      >
      <p class="two-factor-auth__auth-timer-text">
        <strong>{{ timeRemaining | secondsToTime }}</strong> Remaining
      </p>
    </div>
  </div>
  <div class="two-factor-auth__verification-footer" panel-footer>
    If you cannot complete this step, for any reason, then you will need to
    speak to an administrator to
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="mailto:service@cloudgateway.co.uk"
      >reset the two-factor authentication</a
    >
    for you.
  </div>
</app-panel>
