import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavData } from '@shared/models/nav-data';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent {
  public title: string = 'Missing title, check routing';

  constructor(public route: ActivatedRoute) {
    const routeData: NavData = route.snapshot.data as NavData;
    this.title = routeData.title;
  }
}
