<div class="user-details">
  <app-icon
    class="user-details__icon"
    [type]="IconType.OUTLINED"
    [size]="IconSize.LARGE"
    title="Account"
    >account_circle</app-icon
  >
  <div class="user-details__name-container">
    <p
      id="user-details__name"
      class="user-details__name-container__name"
      data-testid="user-details__name"
    >
      {{ userName }}
    </p>
    <p
      class="user-details__name-container__account-name"
      data-testid="user-details__account-name"
      title="{{ accountName }}"
    >
      {{ accountName }}
    </p>
  </div>
</div>
