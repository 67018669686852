import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { take, mergeMap, filter } from 'rxjs/operators';
import * as AccountsActions from '@store/accounts/accounts.actions';
import * as fromAccounts from '@store/accounts/accounts.selectors';
import {
  ResourceRequest,
  ResourceResponse,
} from '@shared/models/api-response/api-response-analytics.model';
import {
  FirewallAPIRequest,
  URLFilterAPIRequest,
} from '@shared/models/api-response/api-response-firewall.model';
import { FirewallRule, URLFilter } from '@shared/models/firewall.model';
import { ApiService } from '@shared/services/api.service';
import { ValidationError } from '@shared/models/error';

@Injectable({
  providedIn: 'root',
})
export class ProtectService {
  constructor(
    private store: Store,
    private actions$: Actions,
    private apiService: ApiService
  ) {}

  public getThreatStats({
    accountId,
    start,
    resource,
    end,
    interval,
  }: ResourceRequest): Observable<ResourceResponse> {
    return this.store
      .select(
        fromAccounts.selectResourceData({
          accountId,
          start,
          end,
          resource,
          interval,
        })
      )
      .pipe(
        take(1),
        mergeMap((resourceData) => {
          if (!resourceData) {
            this.store.dispatch(
              AccountsActions.fetchResourceData({
                accountId,
                start,
                end,
                resource,
                interval,
              })
            );
            return this.actions$.pipe(
              filter((action: any) => {
                return (
                  action.type === AccountsActions.SET_RESOURCE_DATA &&
                  action.resourceData.meta.type === resource
                );
                // API does not return interval and start end dates for some reason
                // if (start && end) {
                //   return (
                //     action.type === AccountsActions.SET_RESOURCE_DATA &&
                //     action.resourceData.meta.type === resource &&
                //     action.resourceData.meta.start === start.toISO() &&
                //     action.resourceData.meta.end === end.toISO()
                //   );
                // } else {
                // return (
                //   action.type === AccountsActions.SET_RESOURCE_DATA &&
                //   action.resourceData.meta.type === resource &&
                //   action.resourceData.meta.interval === interval
                // );
              }),
              mergeMap((data) => {
                if (data.resourceData.error) {
                  throw data.resourceData.error;
                } else {
                  return this.store.select(
                    fromAccounts.selectResourceData({
                      accountId,
                      start,
                      end,
                      resource,
                      interval,
                    })
                  );
                }
              })
            );
          } else {
            return of(resourceData);
          }
        })
      );
  }

  public getFirewallRules({
    accountId,
    firewallType,
  }: FirewallAPIRequest): Observable<FirewallRule[] | undefined> {
    return this.store
      .select(fromAccounts.selectFirewallData({ accountId, firewallType }))
      .pipe(
        take(1),
        mergeMap((firewallRules) => {
          if (!firewallRules) {
            this.store.dispatch(
              AccountsActions.fetchFirewallData({
                accountId,
                firewallType,
              })
            );
            return this.actions$.pipe(
              ofType(AccountsActions.SET_FIREWALL_DATA),
              filter(
                (action: any) =>
                  action.type === AccountsActions.SET_FIREWALL_DATA &&
                  action.firewallType === firewallType
              ),
              mergeMap((data: any) => {
                if (data?.firewallData?.error) {
                  throw data.firewallData.error;
                } else {
                  return this.store.select(
                    fromAccounts.selectFirewallData({
                      accountId,
                      firewallType,
                    })
                  );
                }
              })
            );
          } else {
            return of(firewallRules);
          }
        })
      );
  }

  public getURLFilters({
    filterId,
    accountId,
    firewallType,
  }: URLFilterAPIRequest): Observable<URLFilter> {
    return this.store
      .select(
        fromAccounts.selectFirewallURLFilters({
          accountId,
          filterId,
          firewallType,
        })
      )
      .pipe(
        take(1),
        mergeMap((URLFilters) => {
          if (!URLFilters) {
            this.store.dispatch(
              AccountsActions.fetchFirewallURLFilters({
                accountId,
                filterId,
                firewallType,
              })
            );
            return this.actions$.pipe(
              ofType(AccountsActions.SET_FIREWALL_URLFILTERS),
              mergeMap((data: any) => {
                if (data?.urlFilters?.error) {
                  throw data.urlFilters.error;
                } else {
                  return this.store.select(
                    fromAccounts.selectFirewallURLFilters({
                      accountId,
                      filterId,
                      firewallType,
                    })
                  );
                }
              })
            );
          } else {
            return of(URLFilters);
          }
        })
      );
  }

  public updateFirewallRule(
    request: { notes: string },
    accountId: string,
    uuid: string
  ): Observable<any> {
    return new Observable((observer) => {
      const validation = this.validate(request);

      if (validation !== true) {
        observer.error(validation);
        observer.complete();
        return;
      }
      this.apiService
        .put(`/firewall/rules/${uuid}?accountId=${accountId}`, request)
        .subscribe({
          next: (response: { data: { message: string } }) => {
            if (response.data.message) {
              observer.next(response.data.message);
              observer.complete();
            } else {
              observer.error();
              observer.complete();
            }
          },
          error: (err) => {
            // Error response can be found in e.error
            observer.error(err);
            observer.complete();
          },
        });
    });
  }

  public getFirewallNotes(accountId: string, uuid: string): Observable<any> {
    return this.apiService.get(
      `/firewall/rules/${uuid}/notes?accountId=${accountId}`
    );
  }

  private validate(request: { notes: string }): true | ValidationError {
    let validation = true;
    const errors: ValidationError = {
      notes: [],
    };

    if (request.notes.length > 1000) {
      errors.notes.push('Notes cannot be longer than 1000 characters.');
      validation = false;
    }

    return validation === true ? true : errors;
  }
}
