import { Injectable } from '@angular/core';
import { Netmask } from 'netmask';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  private unitSizes: { [key: string]: number } = {
    unit: 1,
    kilo: 1000,
    mega: 1000 * 1000,
    giga: 1000 * 1000 * 1000,
    tera: 1000 * 1000 * 1000 * 1000,
  };

  /**
   * Convert units to kilo, mega, giga or tera, default to kilo
   * @param units { number } - number of units
   * @param unitName { string } - unit name.
   * @return { number } units scaled to unitName.
   */
  public convertUnitsTo(units: number, unitName: string = 'kilo'): number {
    const divisor = this.unitSizes[unitName];
    const value = parseFloat((units / divisor).toFixed(2));
    return value;
  }

  /**
   * Get label for number of units.
   * @param { number } units - number of units.
   * @returns { string } label to use.
   */
  public checkUnitToUse(
    units: number
  ): 'unit' | 'kilo' | 'mega' | 'giga' | 'tera' {
    let smallestKey;
    let smallestValue = 0;

    Object.keys(this.unitSizes).forEach((key: string) => {
      const convertedUnits = this.convertUnitsTo(units, key);
      if (
        convertedUnits >= 1 &&
        (!smallestValue || convertedUnits < smallestValue)
      ) {
        smallestValue = convertedUnits;
        smallestKey = key;
      }
    });

    return smallestKey || 'unit';
  }

  /**
   * Returns a function, that, as long as it continues to be invoked, will not
   * be triggered. The function will be called after it stops being called for
   * N milliseconds. If `immediate` is passed, trigger the function on the
   * leading edge, instead of the trailing.
   * https://davidwalsh.name/javascript-debounce-function
   */
  // public debounce(func: Function, wait: number, immediate: boolean) {
  //   let timeout: number | null;
  //   return function () {
  //     const context = this;
  //     const args = arguments;
  //     const later = () => {
  //       timeout = null;
  //       if (!immediate) {
  //         func.apply(context, args);
  //       }
  //     };
  //     const callNow = immediate && !timeout;
  //     if (timeout) window.clearTimeout(timeout);
  //     timeout = window.setTimeout(later, wait);
  //     if (callNow) {
  //       func.apply(context, args);
  //     }
  //   };
  // }

  /**
   * Search in an entire CIDR range
   * @param { string } address - IP address or CIDR range to search against
   * @param { string } subStr - string to
   * @returns { boolean } true if subStr is within address
   * 1. First we check if the given search string equals the address
   * 2. If not, we check if the search string is a valid IP and is in the right range
   * 3. We skip "0.0.0.0 0.0.0.0" as that means "ANY" and it is not needed to return those rules as a result
   */
  public netmaskSearch(address: string, subStr: string): boolean {
    if (address.includes(subStr)) {
      return true;
    }
    if (address === '0.0.0.0 0.0.0.0') {
      return false;
    }
    if (this.validIp(subStr)) {
      const block = new Netmask(address.replace(' ', '/'));
      return block.contains(subStr);
    }
    return false;
  }

  /**
   * Split the search string to an array of numbers and
   * check if the given string is a valid IP address
   */
  public validIp = (searchString: string): boolean => {
    const arrayOfNumbers = searchString.split('.').map((ip) => {
      return !isNaN(+ip) && parseInt(ip, 10) >= 0 && parseInt(ip, 10) <= 255;
    });
    return !arrayOfNumbers.includes(false) && arrayOfNumbers.length === 4;
  };
}
