import { Component, Input } from '@angular/core';
import { CustomIcons, IconSize, IconStatus } from '../../models/icon.model';

@Component({
  selector: 'app-custom-icon',
  templateUrl: './custom-icon.component.html',
  styleUrls: ['./custom-icon.component.scss'],
})
export class CustomIconComponent {
  @Input() status!: IconStatus;
  @Input() size!: IconSize;
  @Input() type!: CustomIcons | string;
  @Input() className: string[] | string = [];
  @Input() ariaLabel!: string;
  @Input() title!: string;

  public get iconClasses(): string[] {
    const classArray = [];
    // Icon size
    if (this.size) {
      classArray.push(`icon--${this.size}`);
    }
    // Icon status
    if (this.status) {
      classArray.push(`icon--${this.status}`);
    }
    // Classnames passed from parent
    if (Array.isArray(this.className)) {
      // Passed as an array of strings
      classArray.push(...this.className);
    } else if (this.className) {
      // Passed as space separated strings
      classArray.push(...this.className.split(' '));
    }
    return classArray;
  }
}
