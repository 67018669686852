import { Component } from '@angular/core';

@Component({
  selector: 'app-page-title-group',
  templateUrl: './page-title-group.component.html',
  styleUrls: ['./page-title-group.component.scss'],
})
export class PageTitleGroupComponent {
  constructor() {}
}
