<div class="widget">
  <div class="widget__header">
    <h2 class="widget__title">
      <ng-content select="[widget-title]"></ng-content>
    </h2>
    <div class="widget__actions">
      <ng-content select="[widget-actions]"></ng-content>
    </div>
  </div>
  <section class="widget__card" *ngIf="type === WidgetType.CARD">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </section>
  <section class="widget__cardless" *ngIf="type === WidgetType.CARDLESS">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </section>
</div>

<ng-template #content>
  <div class="widget__content">
    <ng-content select="[widget-content]"></ng-content>
  </div>
  <div class="widget__footer">
    <ng-content select="[widget-footer]"></ng-content>
  </div>
</ng-template>
