import { Component } from '@angular/core';
import { IconSize } from '@shared/models/icon.model';

@Component({
  selector: 'app-chart-loading',
  templateUrl: './chart-loading.component.html',
  styleUrls: ['./chart-loading.component.scss'],
})
export class ChartLoadingComponent {
  public IconSize = IconSize;

  constructor() {}
}
