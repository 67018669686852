<ul class="legend legend--{{ size }}" *ngIf="type === LegendType.LIST">
  <li *ngFor="let dataset of items" class="legend__dataset">
    <ul class="legend__list">
      <li *ngFor="let datapoint of dataset" class="legend__item">
        <span
          class="legend__swatch"
          [ngStyle]="{ 'background-color': datapoint.swatchColor }"
        ></span>
        <p class="legend__value">{{ datapoint.value }}</p>
        <p class="legend__label">{{ datapoint.label }}</p>
      </li>
    </ul>
  </li>
</ul>
<div *ngIf="type === LegendType.TABLE" class="legend__datatable">
  <app-datatable
    *ngFor="let dataset of items"
    [data]="dataset"
    [displayedColumns]="displayedColumns"
    [status]="status ? status : Status.COMPLETE"
    [sort]="['sortvalue', 'desc']"
    [showBorder]="false"
    [showFooter]="false"
    [type]="DatatableType.COMPACT"
  >
    <ng-container cdkColumnDef="colour">
      <th cdk-header-cell *cdkHeaderCellDef>
        {{ headings && headings[0] ? headings[0] : "#" }}
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span
          class="legend__swatch legend__swatch--round"
          [ngStyle]="{ 'background-color': row.swatchColor }"
        ></span>
      </td>
    </ng-container>

    <ng-container cdkColumnDef="label">
      <th cdk-header-cell *cdkHeaderCellDef>
        {{ headingOne }}
      </th>
      <td cdk-cell *cdkCellDef="let row">
        {{ row.label }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="value">
      <th cdk-header-cell *cdkHeaderCellDef>
        {{ headingTwo }}
      </th>
      <td cdk-cell *cdkCellDef="let row">
        {{ row.value }}
      </td>
    </ng-container>
  </app-datatable>
</div>
