import { Component } from '@angular/core';
import { IconSize } from '@shared/models/icon.model';

@Component({
  selector: 'app-loading-error',
  templateUrl: './loading-error.component.html',
  styleUrls: ['./loading-error.component.scss'],
})
export class LoadingErrorComponent {
  public IconSize = IconSize;

  constructor() {}
}
