export enum ButtonRole {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DELETE = 'delete',
  REFRESH = 'refresh',
}

export enum ButtonType {
  SUBMIT = 'submit',
  RESET = 'reset',
  BUTTON = 'button',
  LINK = 'link',
}

export enum ButtonSize {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
}

export enum ButtonStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  LOADING = 'loading',
  SELECTED = 'selected',
}

export enum ButtonIconPosition {
  START = 'start',
  END = 'end',
}

export enum ButtonDisplay {
  INLINE = 'inline',
  BLOCK = 'block',
}

export enum ButtonFlexType {
  SPACEBETWEEN = 'space-between',
  FLEXSTART = 'flex-start',
}

export enum ButtonStyle {
  NORMAL = 'normal',
  EMPHASISED = 'emphasised',
}
