import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToggleOptions, ToggleType } from '../../models/form-toggle.model';
import { IconType } from '@shared/models/icon.model';
import { FontStyle } from '@shared/models/font';

@Component({
  selector: 'app-form-toggle',
  templateUrl: './form-toggle.component.html',
  styleUrls: ['./form-toggle.component.scss'],
})
export class FormToggleComponent {
  @Input() toggleOptions: ToggleOptions[] = [];
  @Input() selectedOption: string = '';
  @Input() fontStyle: FontStyle = FontStyle.HEADING;
  @Input() type: ToggleType = ToggleType.RADIO;
  @Input() name: string = `form-toggle-${Math.random()
    .toString(36)
    .slice(2, 7)}`; // Random 5 character string

  @Output() selectedOptionChange = new EventEmitter();

  public IconType = IconType;
  public FontStyle = FontStyle;

  constructor() {}
  updateValue(value: string): void {
    this.selectedOptionChange.emit(value);
  }
}
