import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, repeat, switchMap } from 'rxjs/operators';
import { PartnerAccount } from '@shared/models/partner-account';

import { ApiService } from '@shared/services/api.service';

import * as PartnerActions from './partners.actions';
import * as StatusActions from '../status/status.actions';

@Injectable()
export class PartnerEffects {
  fetchPartner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PartnerActions.FETCH_PARTNER),
      mergeMap((action: { type: string; id: string }) => {
        return this.apiService.get(`/partners/${action.id}`).pipe(
          map((partner: PartnerAccount) =>
            PartnerActions.setPartners({ partners: [partner] })
          ),
          catchError((err) => of(StatusActions.handleError(err)))
        );
      })
    );
  });

  fetchAllPartners$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PartnerActions.FETCH_ALL_PARTNERS),
      mergeMap(() => {
        return this.apiService.get(`/partners/all`).pipe(
          map((partners: PartnerAccount[]) =>
            PartnerActions.setPartners({ partners })
          ),
          catchError((err) => of(StatusActions.handleError(err)))
        );
      })
    );
  });

  fetchMyPartner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PartnerActions.FETCH_MY_PARTNER),
      switchMap(() => this.apiService.get(`/partners`)),
      map((partner) => PartnerActions.setPartners({ partners: [partner] })),
      repeat()
    );
  });

  constructor(private apiService: ApiService, private actions$: Actions) {}
}
