<app-modal [size]="ModalSize.XLARGE" [status]="modalStatus" *ngIf="rule">
  <div modal-header>
    <ng-container *ngIf="rule.status === FirewallStatus.disable"
      >DISABLED -</ng-container
    >
    {{ rule.name }}
  </div>
  <div modal-subheader>
    <app-modal-tabs
      (tabSwitched)="switchTab($event)"
      [tabs]="tabs"
    ></app-modal-tabs>
  </div>
  <div
    modal-body
    class="firewall-rule__page"
    *ngIf="activeTab === 'information'"
  >
    <app-firewall-address
      class="firewall-rule__address"
      [addressLocation]="AddressLocation.source"
      [addressData]="rule.srcaddr"
      data-testid="firewall-detail-addressSrc"
    ></app-firewall-address>
    <div class="firewall-rule__main">
      <h2 class="firewall-rule__title">{{ rule.name }}</h2>
      <div class="firewall-rule__details">
        <div id="service">
          <app-firewall-service [data]="rule.service"></app-firewall-service>
        </div>
        <div id="schedule">
          <app-firewall-schedule [data]="rule.schedule"></app-firewall-schedule>
        </div>
        <div id="action">
          <app-firewall-action [data]="rule.action"></app-firewall-action>
        </div>
        <div id="security-filters">
          <app-firewall-security-filters
            [data]="rule"
          ></app-firewall-security-filters>
        </div>
        <div id="source-nat">
          <app-firewall-source-nat [data]="rule"></app-firewall-source-nat>
        </div>
        <div id="urlfilters">
          <app-firewall-webfilters
            *ngIf="hasUrlFilters()"
            [data]="getWebFilterProfile()"
            [type]="type"
          ></app-firewall-webfilters>
        </div>
      </div>
    </div>
    <app-firewall-address
      class="firewall-rule__address"
      [addressLocation]="AddressLocation.destination"
      [addressData]="rule.dstaddr"
      data-testid="firewall-detail-addressDst"
    ></app-firewall-address>
  </div>
  <div modal-body *ngIf="activeTab === 'traffic-logs'">
    <app-traffic-logs-table
      [policyid]="policyid"
      [wafToggleEnabled]="false"
    ></app-traffic-logs-table>
  </div>
  <div modal-body *ngIf="activeTab === 'notes'">
    <div id="notes">
      <app-firewall-notes
        (notesUpdate)="updateRule($event)"
        [data]="rule.notes"
        [errors]="notesErrors"
        [buttonStatus]="buttonStatus"
        [noteLoaded]="noteLoaded"
      ></app-firewall-notes>
    </div>
  </div>
</app-modal>
