import { version } from '../version';

export const environment = {
  theme: 'silicon',
  apiBaseUrl: 'https://api.my.cloudgateway.co.uk',
  mockApiUrl: '',
  useMockAPI: false,
  name: 'production',
  production: true,
  version,
  region: 'eu-west-2',
  user_pool_id: 'eu-west-2_E1pKxyVSF',
  client_id: '239fonnb53lovm6oavksa4paid',
  media_url: 'https://media.my.cloudgateway.co.uk/',
  mapbox: {
    accessToken:
      'pk.eyJ1Ijoic2lsaWNvbi1jZ3ciLCJhIjoiY2tybmh2OXhtMXc5bDJ3cHZrOGFkNmt4bCJ9.O2cEBjFWzLZmB4G0bo0V4A',
  },
  cookie_domain: 'my.cloudgateway.co.uk',
  gtm_id: 'GTM-T2PQL36',
  terms_version: 2, // keep this as an integer, don't do "1.1"
  open_api_key: 'dxe4dhVb7p2xgocj6gcJO1LPDueGXNH8MuHavufj',
  postman_api_key: '',
};
