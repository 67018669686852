import { Component, Input } from '@angular/core';
import { WidgetType } from '@shared/models/widget.model';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent {
  @Input() type: WidgetType = WidgetType.CARD;

  public WidgetType = WidgetType;
}
