import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';

import * as fromApp from '../../store/app.reducer';
import * as fromStatus from '@store/status/status.reducer';
import { ApiErrorResponse, ErrorData } from '../models/error';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  // public errorSub: Subscription;

  constructor(private toastr: ToastrService, store: Store) {
    // this.errorSub = store
    //   .select(fromStatus.getError)
    //   .pipe(skipWhile((val) => val === null))
    //   .subscribe((error: ErrorData) => {
    //     const title = 'Something went wrong';
    //     let message = '';
    //     // Show error message if present
    //     if (error.data && error.data.message) {
    //       message = error.data.message;
    //     }
    //     this.toastr.warning(message, title);
    //   });
  }

  /**
   * Handle errors from save
   * @param err Error object from API or validation
   */
  handleSaveErrors(err: ApiErrorResponse | string) {
    if (typeof err === 'string') {
      this.toastr.warning('Error: ' + err, 'Something went wrong');
    } else {
      if ((err as ApiErrorResponse)?.error?.data) {
        // API error
        this.toastr.warning(
          'Error: ' + err.error.data.message,
          'Something went wrong',
          {
            progressBar: true,
          }
        );
      } else {
        this.toastr.warning('Please try again later.', 'Something went wrong', {
          progressBar: true,
        });
      }
    }
  }
}
