import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { PermissionsService } from '@shared/services/permissions.service';
import { Observable } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';

import { Account } from '../models/account';
import { AccountService } from '../services/account.service';

@Injectable({ providedIn: 'root' })
export class AccountsResolverService implements Resolve<Account[]> {
  constructor(
    private accountService: AccountService,
    private permissionsService: PermissionsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Account[]> | Promise<Account[]> | Account[] {
    return this.permissionsService.checkPermissions().pipe(
      switchMap(() => {
        return this.accountService.checkAccounts().pipe(take(1));
      })
    );
  }
}
