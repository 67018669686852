<div class="card__content">
  <h2
    class="card__content-heading font-{{ fontWeight }}"
    *ngIf="addressLocation === AddressLocation.source"
  >
    Source
    <app-help-icon>
      <p>
        This section shows the source IPs which this specific firewall rule
        applies to.
      </p>
    </app-help-icon>
  </h2>
  <h2
    class="card__content-heading font-{{ fontWeight }}"
    *ngIf="addressLocation === AddressLocation.destination"
  >
    Destination
    <app-help-icon>
      <p>
        This section shows the destination IPs which this specific firewall rule
        applies to.
      </p>
    </app-help-icon>
  </h2>
  <app-firewall-address-list
    [addressList]="addressData"
    [fontWeight]="fontWeight"
  ></app-firewall-address-list>
</div>
