<div class="info-box">
  <app-icon
    [title]="'information'"
    [type]="IconType.OUTLINED"
    [size]="IconSize.LARGE"
    >info</app-icon
  >
  <div class="info-box__text">
    <ng-content></ng-content>
  </div>
</div>
