<div class="auth-container">
  <header class="auth-container__header">
    <a routerLink="/"
      ><app-logo class="auth-container__header-logo"></app-logo
    ></a>
  </header>
  <main class="auth-container__content">
    <div class="auth-container__fields">
      <h1 class="auth-container__title">
        Welcome to the new, fast and powerful My Cloud Gateway Portal.
      </h1>
      <router-outlet></router-outlet>
    </div>
    <aside class="carousel-container">
      <app-carousel class="carousel"></app-carousel>
    </aside>
  </main>
  <footer class="auth-container__footer">
    &copy;{{ currentYear }} {{ copyrightLabel }}
  </footer>
</div>
