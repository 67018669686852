/**
 * @property { string }    directory   - directory to upload files to (default is '')
 * @property { string[] }  fileTypes   - array of file extensions to allow (default is [], which is any file)
 * @property { number }    fileSize - number in MB of maximum file size (default is 50)
 * @property { number }    fileCount   - maximum number of files to upload (default is 10)
 */
export interface FileOptions {
  directory: string;
  fileTypes: string[];
  fileSize: number;
  fileCount: number;
  endpoint?: string;
}

export enum UploadStatus {
  IN_PROGRESS = 'in-progress',
  UPLOADED = 'uploaded',
  ERROR = 'error',
}

export interface FilePreviewModel {
  /** uploadResponse is the response of api after file uploaded */
  uploadResponse?: any;
  file: File;
}

export interface ValidationError {
  file?: File;
  error: string; // is FileValidationType enum type
}
export enum FileValidationTypes {
  fileMaxSize = 'FILE_MAX_SIZE',
  fileMaxCount = 'FILE_MAX_COUNT',
  totalMaxSize = 'TOTAL_MAX_SIZE',
  extensions = 'EXTENSIONS',
  uploadType = 'UPLOAD_TYPE',
  customValidator = 'CUSTOM_VALIDATOR',
}

export interface UploadResponse {
  body?: any;
  status: UploadStatus;
  progress?: number;
}
