export enum FirewallStatus {
  enable = 'enable',
  disable = 'disable',
}

export enum AddressType {
  ipmask = 'ipmask', // deafult so not set
  iprange = 'iprange',
  fqdn = 'fqdn',
  geography = 'geography',
  addrgrp = 'addrgrp',
  vip = 'vip',
  staticnat = 'static-nat',
}

export enum URLFilterEntryType {
  'simple',
  'wildcard',
}

export enum FirewallAction {
  accept = 'accept',
  deny = 'deny',
}

export const Settings = {
  HIDDEN: 'hidden',
};

export enum AddressDataSource {
  address = 'firewall.address',
  addrgrp = 'firewall.addrgrp',
  vip = 'firewall.vip',
}
