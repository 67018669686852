import { Injectable } from '@angular/core';
import { environment } from '@environments/default/environment';
import { BehaviorSubject, Observer, from } from 'rxjs';

import { Auth, Hub } from 'aws-amplify';

import { CognitoAction } from '../models/cognito-action';
import { CognitoErrorResponse, CognitoErrors } from '../models/cognito-errors';
import { CognitoResponse } from '../models/cognito-response';
import { CognitoErrorService } from './cognito-error.service';
import { CognitoUser } from '@auth/models/cognito-user';

@Injectable()
export class CognitoService {
  // user's current auth state
  private authState: string = '';
  // currently logged in user
  private user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // flag if using sign in user, rather than user from subscriber
  private usingSignInUser: boolean = false;

  /**
   * Authentication Service built on top of AWS Cognito.
   * @param { CognitoErrorService } cognitoErrorService
   */
  constructor(private cognitoErrorService: CognitoErrorService) {
    // Handle page refresh
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        if (userData) {
          this.handleSignIn(userData);
        }
      })
      .catch(() => {});

    // Handle sign in and out
    Hub.listen('auth', (authState) => {
      if (authState.payload.event === 'signIn') {
        this.handleSignIn(authState.payload.data);
      } else if (authState.payload.event === 'signOut') {
        this.handleSignOut();
      }
    });
  }

  /**
   * Emit user data to subscribers
   * @param { CognitoUser } userData
   */
  private handleSignIn(userData: CognitoUser): void {
    this.authState = 'signedIn';

    // push user, if not using the signed in user
    if (!this.usingSignInUser) {
      this.user.next(userData);
    }
  }

  /**
   * Emits empty user data to subscribers
   */
  private handleSignOut(): void {
    this.authState = 'signedOut';

    this.user.next(null);
  }

  /**
   * Emit success to a call's observer.
   * @param { Observer<CognitoResponse>} observer - observer to emit success.
   * @param { CognitoAction } action - action that UI must complete next, if any.
   */
  public success(
    observer: Observer<CognitoResponse>,
    action?: CognitoAction | null
  ): void {
    // emit success response
    observer.next({
      success: true,
      action,
    });
    // close observer
    observer.complete();
  }

  /**
   * Emit failure to a call's observer.
   * @param { Observer<CognitoResponse>} observer - observer to emit failure.
   * @param { string } reason - string for UI to present to user to inform them why the call failed.
   */
  public failed(observer: Observer<CognitoResponse>, reason: string): void {
    // emit failure response
    observer.next({
      success: false,
      reason,
    });
    // close observer
    observer.complete();
  }

  /**
   * Return the current AWS Cognito user credentials
   * @returns { any } user credentials.
   */
  public getAmazonCredentials(): any {
    // can't return credentials, if we don't have a user yet :/
    const userValues = this.user.getValue();
    if (
      userValues &&
      userValues.signInUserSession &&
      userValues.signInUserSession.idToken &&
      userValues.signInUserSession.idToken.jwtToken
    ) {
      return userValues.signInUserSession.idToken.jwtToken;
    } else {
      return false;
    }
  }

  /**
   * Whether authentication has been attempted
   * @returns { boolean } if auth has been attempted.
   */
  public authAttempted(): boolean {
    return this.authState !== null;
  }

  /**
   * Return the current AWS Cognito user.
   * return { BehaviorSubject<any>} an observable of the current AWS Cognito user.
   */
  public getUser(): BehaviorSubject<any> {
    return this.user;
  }

  /**
   * Return the current user auth state
   * return a string containing the current AWS Cognito user's authState.
   */
  public getAuthState(): string {
    return this.authState;
  }

  /**
   * Sign in returns the user, rather than the observer,
   * so we use this to pass that to the rest of the system.
   * @param { any } user - user to set.
   */
  public setSignInUser(user: any): void {
    this.usingSignInUser = true;
    if (!user.attributes) {
      if (
        user.signInUserSession &&
        user.signInUserSession.idToken &&
        user.signInUserSession.idToken.payload
      ) {
        // Patch attribute details after logging in with 2FA
        user.attributes = user.signInUserSession.idToken.payload;
      } else if (user.challengeParam && user.challengeParam.userAttributes) {
        // Patch attributes after first login (after temp password)
        user.attributes = user.challengeParam.userAttributes;
      }
    }
    this.user.next(user);
  }

  /**
   * Emit an error if params don't appear valid.
   * @param { Observer<CognitoResponse>} observer - observer to emit error.
   * @param {[key: string]: string } params - params and associated messages ( message is the key, as value can be null ).
   * @return { boolean } check if params pass validation.
   */
  public validateParams(
    observer: Observer<CognitoResponse>,
    params: CognitoErrors
  ): boolean {
    let key: keyof typeof params;
    for (key in params) {
      if (params.hasOwnProperty(key)) {
        const value = params[key];
        if (!value || value.length < 1) {
          this.failed(observer, this.cognitoErrorService.getErrorMessage(key));
          return false;
        }
      }
    }

    return true;
  }

  handleAuthError(
    reason: CognitoErrorResponse,
    observer: Observer<CognitoResponse>,
    errorKey: keyof CognitoErrors
  ) {
    switch (reason.code) {
      case 'PasswordResetRequiredException':
        this.failed(
          observer,
          'We\'ve made some updates to the portal to make your experience better, these updates require you to reset your password by clicking on the <a href="/forgot-password">“Forgotten Password?”</a> link.'
        );
        break;
      case 'NotAuthorizedException':
        this.failed(observer, reason.message);
        break;
      case 'UserNotFoundException':
        this.failed(observer, 'Incorrect username or password');
        break;
      case 'CodeMismatchException':
        this.failed(
          observer,
          'Code incorrect, please check your code and try again.'
        );
        break;
      default:
        let message: string = '';
        if (environment.production !== true) {
          message =
            reason && reason.message
              ? reason.message
              : this.cognitoErrorService.getErrorMessage(errorKey);
        } else {
          message = this.cognitoErrorService.getErrorMessage(errorKey);
        }
        this.failed(observer, message);
        break;
    }
  }
}
