import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input() checked: boolean = false;
  @Output() changed: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  onChange(event: Event) {
    const target = event.target as HTMLFormElement;
    this.changed.emit(target.checked);
  }
}
