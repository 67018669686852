import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  ButtonFlexType,
  ButtonIconPosition,
  ButtonRole,
  ButtonSize,
  ButtonStatus,
  ButtonStyle,
  ButtonType,
} from '@shared/models/button.model';
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import { Subscription, take } from 'rxjs';
import { IconSize, IconStatus, IconType } from '@shared/models/icon.model';
import { Ticket, TicketType } from '../../../support/models/ticket';

import * as StatusActions from '@store/status/status.actions';
import { Store } from '@ngrx/store';
import { SupportService } from '@support/support.service';
import { ModalService } from '@shared/services/modal.service';
import { UserService } from '@shared/services/user.service';
import { ToggleOptions, ToggleType } from '@forms/models/form-toggle.model';
import { FontStyle } from '@shared/models/font';
import {
  FirewallAddRequest,
  FirewallRuleChangeType,
  FirewallModifyRequest,
  FirewallDeleteRequest,
  FirewallRuleRequest,
} from '@shared/models/firewall-change-request.model';
import { ErrorService } from '@shared/services/error.service';
import { AlertService } from '@shared/services/alert.service';
import { AccountService } from '@shared/services/account.service';
import { Account } from '@shared/models/account';
import { SupportHelperService } from '@support/support-helper.service';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-request-firewall-rule',
  templateUrl: './request-firewall-rule.component.html',
  styleUrls: ['./request-firewall-rule.component.scss'],
})
export class RequestFirewallRuleComponent implements OnInit, OnDestroy {
  @ViewChild('formOutlet', { read: ViewContainerRef })
  public formOutletRef!: ViewContainerRef;
  @ViewChild('form', { read: TemplateRef })
  public formRef!: TemplateRef<any>;

  public ModalSize = ModalSize;
  public ModalStatus = ModalStatus;
  public ButtonSize = ButtonSize;
  public ButtonRole = ButtonRole;
  public ButtonType = ButtonType;
  public ButtonStyle = ButtonStyle;
  public ButtonStatus = ButtonStatus;
  public ButtonFlexType = ButtonFlexType;
  public IconStatus = IconStatus;
  public IconSize = IconSize;
  public IconType = IconType;
  public FontStyle = FontStyle;
  public ButtonIconPosition = ButtonIconPosition;
  public FirewallRuleChangeType = FirewallRuleChangeType;

  public userSubscription!: Subscription;
  public modalStatus: ModalStatus = ModalStatus.OPEN;
  public submitButtonStatus: ButtonStatus = ButtonStatus.DISABLED;
  public modalCloseMessage: string =
    'Closing this modal will discard all changes. Are you sure you want to close the modal?';

  // Determined by form validity
  public buttonsEnabled = false;

  // Check if incident is submitted to conditionally display modal content
  public isSubmitted = false;

  // Initialise ticket ID variable to be updated after ticket submitted
  public userName: string = '';
  public ticketId: string | undefined = '';
  public ticketResponse?: Ticket;
  public formattedTicket?: Ticket;
  public ToggleType = ToggleType;
  public accountSubscription?: Subscription;
  public accountId!: string;

  public ruleChangeType: FirewallRuleChangeType = FirewallRuleChangeType.ADD;
  public toggleOptions: ToggleOptions[] = [
    {
      value: FirewallRuleChangeType.ADD,
      label: 'Add new rule',
      iconText: 'add',
    },
    {
      value: FirewallRuleChangeType.MODIFY,
      label: 'Modify existing rule',
      iconText: 'edit',
    },
    {
      value: FirewallRuleChangeType.DELETE,
      label: 'Delete existing rule',
      iconText: 'delete',
    },
  ];

  public maxRules = 5;
  public selectedRule = 0;

  // Default with an empty Add change
  public changes: FirewallRuleRequest[] = [
    {
      type: FirewallRuleChangeType.ADD,
      name: '',
      comment: '',
      accountId: '',
      sourceAddress: '',
      destinationAddress: '',
      service: '',
      securityProfile: '',
      sourceNat: '',
      destinationNat: '',
      urls: '',
      cc_emails: '',
    },
  ];

  constructor(
    private store: Store,
    private accountService: AccountService,
    private supportService: SupportService,
    private modalService: ModalService,
    private userService: UserService,
    private errorService: ErrorService,
    private supportHelperService: SupportHelperService,
    public alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.userService
      .getCurrentUser()
      .subscribe((user) => {
        this.userName = this.userService.getUserName(user);
      });
    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(take(1))
      .subscribe((account?: Account) => {
        if (account?.id) {
          this.accountId = account.id;

          // Set the accountID in the first and only change
          this.changes[0].accountId = account.id;
        }
      });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.accountSubscription?.unsubscribe();
  }

  /**
   * Redirects user to view the ticket they have created
   */
  public viewTicketDetails(): void {
    if (!this.ticketResponse) {
      return;
    }

    this.formattedTicket = this.supportService.formatServiceRequestTicket(
      this.ticketResponse,
      this.userName
    );

    this.modalService.viewTicket(this.ticketResponse, TicketType.SR);
  }

  /**
   * Enables navigation and submit buttons
   */
  public enableButtons(): void {
    this.buttonsEnabled = true;
    this.submitButtonStatus = ButtonStatus.ACTIVE;
  }

  /**
   * Disables navigation and submit buttons
   */
  public disableButtons(): void {
    this.buttonsEnabled = false;
    this.submitButtonStatus = ButtonStatus.DISABLED;
  }

  /**
   * Checks conditions before handling form type update
   */
  public handleChangeTypeToggle(type: string): void {
    if (this.isFormAltered()) {
      this.alertService.throwConfirmation(
        'Navigating to another change type will revert changes to this form',
        'Continue',
        'Warning!',
        () => {
          this.switchChangeType(type as FirewallRuleChangeType);
        }
      );
    } else {
      this.switchChangeType(type as FirewallRuleChangeType);
    }
  }

  /**
   * Compares the current form data object against a blank form object
   * @returns boolean
   */
  public isFormAltered(): boolean {
    const blankForm = this.supportHelperService.getBlankChange(
      this.ruleChangeType,
      this.accountId
    );

    if (isEqual(blankForm, this.changes[this.selectedRule])) {
      return false;
    }
    return true;
  }

  /**
   * Updates the visible form type
   */
  public switchChangeType(type: FirewallRuleChangeType): void {
    this.ruleChangeType = type;
    this.changes[this.selectedRule] = this.supportHelperService.getBlankChange(
      type,
      this.accountId
    );
    this.disableButtons();
  }

  /**
   * Updates selected rule page
   */
  public handleRuleSelect(index: number): void {
    if (this.buttonsEnabled && this.selectedRule !== index) {
      this.selectedRule = index;
      this.rerenderForm();
    }
  }

  /**
   * Handles events regarding modification of a rule change
   */
  public handleFormUpdated(change: FirewallRuleRequest): void {
    this.updateChange(change, this.selectedRule);
  }

  /**
   * Handles events regarding whether the current form is valid or not
   */
  public handleFormValid(valid: boolean): void {
    if (valid) {
      this.enableButtons();
    } else {
      this.disableButtons();
    }
  }

  /**
   * Append a change to the changes array
   */
  public addChange(): void {
    if (!this.buttonsEnabled) {
      return;
    }

    if (this.changes.length < this.maxRules) {
      this.changes.push(
        this.supportHelperService.getBlankChange(
          FirewallRuleChangeType.ADD,
          this.accountId
        ) as FirewallAddRequest
      );
      this.selectedRule = this.changes.length - 1;
      this.ruleChangeType = FirewallRuleChangeType.ADD;
      this.disableButtons();
      this.rerenderForm();
    }
  }

  /**
   * Modifies change array with new rule data
   */
  public updateChange(change: any, index: number): void {
    this.changes[index] = change;
  }

  /**
   * Removes an index from the changes array
   */
  public deleteChange(index: number): void {
    this.changes.splice(index, 1);
    this.selectedRule = 0;
    this.enableButtons();
  }

  /**
   * Angular requirement for clearing down form component and reloading it
   */
  public rerenderForm() {
    this.formOutletRef.clear();
    this.formOutletRef.createEmbeddedView(this.formRef);
  }

  /**
   * Resets and closes form
   */
  public cancelRequest(): void {
    this.alertService.throwConfirmation(
      this.modalCloseMessage,
      'Close',
      'Warning',
      () => (this.modalStatus = ModalStatus.CLOSED)
    );
  }

  /**
   * publish changes to API
   */
  public submitChangesRequest(): void {
    if (!this.buttonsEnabled) {
      return;
    }

    // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(StatusActions.setWaiting({ waiting: true }));
    this.disableButtons();
    this.submitButtonStatus = ButtonStatus.LOADING;
    this.supportService.requestFirewallRuleChanges(this.changes).subscribe({
      next: (response: any) => {
        // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
        this.ticketId = response.number;
        this.ticketResponse = response;
        this.isSubmitted = true;
      },
      error: (err) => {
        this.enableButtons();
        this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
        this.errorService.handleSaveErrors(err);
      },
    });
  }

  /**
   * Determines rule array length
   */
  public isMaxRulesReached(): boolean {
    return this.changes.length >= this.maxRules;
  }

  /**
   * Determines if the button should have a delete icon
   */
  public ruleButtonHasIcon(index: number): boolean {
    return this.selectedRule === index && this.changes.length > 1;
  }

  /**
   * Gets the ButtonStatus depending on buttons enabled and the button representing the selected rule
   */
  public getRuleButtonStatus(index: number): ButtonStatus {
    if (this.selectedRule === index) {
      return ButtonStatus.SELECTED;
    } else {
      if (this.buttonsEnabled) {
        return ButtonStatus.ACTIVE;
      } else {
        return ButtonStatus.DISABLED;
      }
    }
  }

  /**
   * Returns a typed version of the selected rule
   */
  public getTypedAddFormData(): FirewallAddRequest {
    return this.changes[this.selectedRule] as FirewallAddRequest;
  }

  /**
   * Returns a typed version of the selected rule
   */
  public getTypedModifyFormData(): FirewallModifyRequest {
    return this.changes[this.selectedRule] as FirewallModifyRequest;
  }

  /**
   * Returns a typed version of the selected rule
   */
  public getTypedDeleteFormData(): FirewallDeleteRequest {
    return this.changes[this.selectedRule] as FirewallDeleteRequest;
  }
}
