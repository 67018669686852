<app-widget [type]="WidgetType.CARDLESS">
  <ng-container widget-title
    >Traffic Logs
    <app-help-icon>
      <p *ngIf="!policyid">
        This table contains the most recent Traffic Logs on your network, you
        can filter and sort these records to narrow down the results. You can
        refresh the logs in real time to see traffic as it flows through your
        network.
      </p>
      <p *ngIf="policyid">
        This table contains the most recent traffic logs which are related to
        this specific firewall rule. By default the table shows the 10 most
        recent results, however you can change the number of results using the
        dropdown menu to show up to the 100 most recent logs.
      </p>
    </app-help-icon>
  </ng-container>
  <app-filter-group widget-actions>
    <app-form-toggle
      [toggleOptions]="toggleOptions"
      [(selectedOption)]="selectedLogType"
      *ngIf="showToggle"
      (selectedOptionChange)="handleToggle()"
      [type]="ToggleType.RADIO"
    ></app-form-toggle>
    <app-filter-text
      [(inputValue)]="srcIpSearch"
      (inputValueChange)="filterBySourceIP()"
      placeholder="Search by source IP"
    ></app-filter-text>
    <app-filter-text
      [(inputValue)]="dstIpSearch"
      (inputValueChange)="filterByDestinationIP()"
      placeholder="Search by destination IP"
    ></app-filter-text>
    <app-form-select
      *ngIf="selectedLogType === 'traffic'"
      [selectOptions]="actionOptions"
      [(selectedOption)]="searchAction"
      (selectedOptionChange)="filterByAction()"
    ></app-form-select>
    <app-form-select
      [selectOptions]="selectPageLimitOptions"
      [(selectedOption)]="limit"
    ></app-form-select>
    <app-button
      iconName="refresh"
      [iconPosition]="ButtonIconPosition.START"
      [role]="ButtonRole.REFRESH"
      (buttonClick)="refreshData()"
      >Refresh</app-button
    >
  </app-filter-group>
  <ng-container widget-content>
    <app-datatable
      [data]="filteredLogs"
      [displayedColumns]="displayedColumns"
      [limit]="limit"
      [status]="status"
      [unknownSize]="unknownSize"
      (fetchNextPage)="fetchAdditionalLogs()"
    >
      <ng-container cdkColumnDef="eventtime">
        <th cdk-header-cell *cdkHeaderCellDef>Date/Time</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{
            row.eventtime?.substring(0, row.eventtime?.length - 6)
              | date: "medium"
          }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="action">
        <th cdk-header-cell *cdkHeaderCellDef>Action</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          <ng-container *ngIf="row.action !== 'deny'">
            <app-icon
              class="cdk-column-action--success"
              [size]="IconSize.MEDIUM"
              title="Allow"
            >
              check
            </app-icon>
            Allow
          </ng-container>
          <ng-container *ngIf="row.action === 'deny'">
            <app-icon
              title="Deny"
              class="cdk-column-action--error"
              [size]="IconSize.MEDIUM"
            >
              close
            </app-icon>
            Deny
          </ng-container>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="devid">
        <th cdk-header-cell *cdkHeaderCellDef>Device ID</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{ row.devid }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="firewall_action">
        <th cdk-header-cell *cdkHeaderCellDef>Firewall Action</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{ row.action }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="srcip">
        <th cdk-header-cell *cdkHeaderCellDef>Source IP</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{ row.srcip }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="dstip">
        <th cdk-header-cell *cdkHeaderCellDef>Destination IP</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          <div
            *ngIf="row.dstcountry && row.dstcountry !== 'Reserved'"
            class="flex-container"
          >
            <app-country-flag
              [country]="getCountryCode(row.dstcountry)"
              [size]="IconSize.XXSMALL"
            ></app-country-flag>
            {{ row.dstip }}
          </div>
          <ng-container
            *ngIf="!row.dstcountry || row.dstcountry === 'Reserved'"
          >
            {{ row.dstip }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="service">
        <th cdk-header-cell *cdkHeaderCellDef>Service</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{ row.service }}
        </td>
      </ng-container>
      <ng-container cdkColumnDef="rcvdpkt">
        <th cdk-header-cell *cdkHeaderCellDef>Received Packets</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{ row.rcvdpkt }}
        </td>
      </ng-container>
      <ng-container cdkColumnDef="sentpkt">
        <th cdk-header-cell *cdkHeaderCellDef>Sent Packets</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{ row.sentpkt }}
        </td>
      </ng-container>
      <ng-container cdkColumnDef="policyid">
        <th cdk-header-cell *cdkHeaderCellDef>Policy ID</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{ row.policyid }}
        </td>
      </ng-container>
      <ng-container cdkColumnDef="policyname">
        <th cdk-header-cell *cdkHeaderCellDef>Policy Name</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{ row.policyname }}
        </td>
      </ng-container>
      <ng-container cdkColumnDef="http_method">
        <th cdk-header-cell *cdkHeaderCellDef>HTTP Method</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{ row.http_method }}
        </td>
      </ng-container>
      <ng-container cdkColumnDef="http_retcode">
        <th cdk-header-cell *cdkHeaderCellDef>HTTP Return Code</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{ row.http_retcode }}
        </td>
      </ng-container>
      <ng-container cdkColumnDef="http_response_bytes">
        <th cdk-header-cell *cdkHeaderCellDef>HTTP Response Bytes</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{ row.http_response_bytes }}
        </td>
      </ng-container>
      <ng-container cdkColumnDef="http_request_bytes">
        <th cdk-header-cell *cdkHeaderCellDef>HTTP Request Bytes</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{ row.http_request_bytes }}
        </td>
      </ng-container>
      <ng-container cdkColumnDef="http_host">
        <th cdk-header-cell *cdkHeaderCellDef>HTTP Host</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{ row.http_host }}
        </td>
      </ng-container>
      <ng-container cdkColumnDef="msg">
        <th cdk-header-cell *cdkHeaderCellDef>Message</th>
        <td cdk-cell *cdkCellDef="let row" class="cdk-cell--neutral">
          {{ row.msg }}
        </td>
      </ng-container>
    </app-datatable>
  </ng-container>
</app-widget>
