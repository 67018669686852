<div
  class="card carousel"
  aria-roledescription="carousel"
  *ngIf="theme === 'silicon'"
>
  <div class="carousel__card-container" aria-live="off" aria-live="polite">
    <ng-container *ngFor="let card of cards; let i = index">
      <div
        class="carousel__card"
        *ngIf="i === slideIndex"
        style="background-image: url('{{ card.image }}');"
        aria-roledescription="slide"
        attr.aria-label="{{ i }} of {{ cards.length }}"
        [@slideLeft]
      >
        <p class="carousel__card-text">{{ card.text }}</p>
      </div>
    </ng-container>
  </div>
  <div *ngIf="cards.length > 1" class="carousel__controls">
    <div
      class="carousel__control--prev"
      (click)="prev()"
      aria-label="previous"
      role="button"
      tabindex="0"
    >
      <app-icon title="Chevron left" [size]="IconSize.MEDIUM">chevron_left</app-icon>
    </div>
    <div
      class="carousel__control--play"
      (click)="togglePlaying()"
      aria-label="play / pause"
      role="button"
      tabindex="0"
    >
      <app-icon [title]="this.playing? 'Pause' : 'Play'" [size]="IconSize.MEDIUM">{{
        this.playing ? "pause" : "play_arrow"
      }}</app-icon>
    </div>
    <div
      class="carousel__control--next"
      (click)="next()"
      aria-label="next"
      role="button"
      tabindex="0"
    >
      <app-icon title="Chevron right" [size]="IconSize.MEDIUM">chevron_right</app-icon>
    </div>
  </div>
  <div class="carousel__pips" *ngIf="cards.length > 1">
    <div
      *ngFor="let card of cards; let i = index"
      (click)="selectSlide(i)"
      class="carousel__pip"
      attr.data-testid="carousel__pip--{{ i }}"
      [ngClass]="{ 'carousel__pip--active': slideIndex === i }"
      attr.aria-label="select slide {{ i }}"
      role="button"
      tabindex="0"
    ></div>
  </div>
</div>
