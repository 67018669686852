<ul class="form-toggle">
  <li
    class="form-toggle__item"
    tabindex="0"
    (keyup.enter)="updateValue(option.value)"
    *ngFor="let option of toggleOptions"
    data-testid="toggle-item"
  >
    <input
      class="form-toggle__input"
      [ngClass]="{ selected: selectedOption === option.value }"
      name="{{ name }}"
      [type]="type"
      id="{{ option.value }}"
      value="{{ option.value }}"
      [(ngModel)]="selectedOption"
      (click)="updateValue(option.value)"
      [checked]="selectedOption === option.value"
    />
    <label
      class="form-toggle__label font-{{ fontStyle }}"
      for="{{ option.value }}"
    >
      <app-icon
        *ngIf="option.iconText"
        [type]="IconType.REGULAR"
        class="form-toggle__icon"
        [title]="option.iconText"
      >
        {{ option.iconText }}
      </app-icon>
      {{ option.label }}
    </label>
  </li>
  <li aria-hidden="true" class="form-toggle__indicator"></li>
</ul>
