/**
 * To add a new allowed file type on here, add another line
 * cf. https://www.freeformatter.com/mime-types-list.html for mime tpyes
 * cf. https://www.filesignatures.net/ for file signatures
 * Other icon options are:
 * * Other icon options:
 * * 'file-charts'
 * * 'file-stats'
 * * 'file-search'
 * * 'file-video'
 * * 'file-audio'
 * * 'file-code'
 * * 'file-lock'
 * * 'file-check'
 * * 'file-add'
 */
type FileTypeMap = Record<
  string,
  { icon: string; type: string; signature: string[] }
>;

export const FileType: FileTypeMap = {
  // Images
  default: {
    type: '',
    icon: 'text_snippet',
    signature: [],
  },
  // Images
  jpg: {
    type: 'image/jpeg',
    icon: 'image',
    signature: ['FFD8FFE0', 'FFD8FFE1', 'FFD8FFE2', 'FFD8FFE3', 'FFD8FFE8'],
  },
  jpeg: {
    type: 'image/jpeg',
    icon: 'image',
    signature: ['FFD8FFE0', 'FFD8FFE1', 'FFD8FFE2', 'FFD8FFE3', 'FFD8FFE8'],
  },
  svg: {
    type: 'image/svg+xml',
    icon: 'image',
    signature: [],
  },
  png: {
    type: 'image/png',
    icon: 'image',
    signature: ['89504E47'],
  },
  gif: {
    type: 'image/gif',
    icon: 'image',
    signature: ['47494638'],
  },
  // Text files
  txt: {
    type: 'text/plain',
    icon: 'article',
    signature: [],
  },
  rtf: {
    type: 'text/rtf',
    icon: 'article',
    signature: ['7B5C7274'],
  },
  doc: {
    type: 'application/msword',
    icon: 'article',
    signature: ['D0CF11E0'],
  },
  docx: {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    icon: 'article',
    signature: ['504B0304'],
  },
  xls: {
    type: 'application/vnd.ms-excel',
    icon: 'table_view',
    signature: ['D0CF11E0'],
  },
  xlsx: {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    icon: 'table_view',
    signature: ['504B0304'],
  },
  csv: {
    type: 'text/csv',
    icon: 'table_view',
    signature: [],
  },
  pdf: {
    type: 'application/pdf',
    icon: 'picture_as_pdf',
    signature: ['25504446'],
  },
  // Other
  zip: {
    type: 'application/zip',
    icon: 'source',
    signature: ['504B0304'],
  },
};
