<app-widget class="ticket-details" [type]="WidgetType.CARDLESS">
  <ng-container widget-title>
    Ticket Information
    <app-help-icon>
      <p>
        The left side of this modal contains your ticket information, including
        the current status of your ticket, when it was created and the people
        involved in the discussion.
      </p>
    </app-help-icon>
  </ng-container>
  <div widget-content>
    <p class="ticket-details__heading">Title</p>
    <p class="ticket-details__title">{{ ticket.title }}</p>

    <div class="ticket-details__status-date">
      <div class="ticket-details__status-date__status">
        <p class="ticket-details__heading">Status</p>
        <ng-container [ngSwitch]="ticket.status">
          <p
            class="ticket-details__body ticket-details__body--open"
            *ngSwitchCase="TicketStatus.OPEN"
          >
            Open
          </p>
          <p
            class="ticket-details__body ticket-details__body--pending"
            *ngSwitchCase="TicketStatus.PENDING"
          >
            Pending
          </p>
          <p class="ticket-details__body" *ngSwitchCase="TicketStatus.RESOLVED">
            Resolved
          </p>
          <p class="ticket-details__body" *ngSwitchCase="TicketStatus.CLOSED">
            Closed
          </p>
          <p
            class="ticket-details__body ticket-details__body--open"
            *ngSwitchCase="TicketStatus.SCHEDULED"
          >
            Open (Scheduled)
          </p>
          <p class="ticket-details__body" *ngSwitchDefault>
            {{ ticket.status }}
          </p>
        </ng-container>
      </div>
      <div class="ticket-details__status-date__date">
        <p class="ticket-details__heading">Date Created</p>
        <p class="ticket-details__body" *ngIf="ticket.opened_at">
          {{ ticket.opened_at | date : "HH:mm" }} on
          {{ ticket.opened_at | date : "d/M/yy" }}
        </p>
      </div>
    </div>

    <div class="ticket-details__next-action-priority">
      <div>
        <p class="ticket-details__heading">Next Action</p>
        <p class="ticket-details__body">
          <ng-container [ngSwitch]="ticket.status">
            <span *ngSwitchCase="TicketStatus.PENDING">Customer</span>
            <span *ngSwitchCase="TicketStatus.OPEN">Cloud Gateway</span>
            <span *ngSwitchCase="TicketStatus.SCHEDULED">Cloud Gateway</span>
            <span *ngSwitchDefault>None</span>
          </ng-container>
        </p>
      </div>
      <div
        class="ticket-details__next-action-priority__priority"
        *ngIf="ticket.priority && type === TicketType.INCIDENTS"
      >
        <p class="ticket-details__heading">Priority</p>
        <p class="ticket-details__body">
          {{ TicketPriorities[ticket.priority] }}
        </p>
      </div>
    </div>
  </div>
</app-widget>
<app-widget class="people-details" [type]="WidgetType.CARDLESS">
  <ng-container widget-title>
    People
    <app-help-icon>
      <p>
        This section shows the people who are involved in this ticket. The
        'Requester' is the person who raised the ticket and the 'Assignee' is
        the Cloud Gateway team member who is currently assigned to your ticket.
      </p>
      <p>
        This section also includes a 'People on CC' list, which contains any
        other emails which are copied into all replies on this ticket.
      </p>
    </app-help-icon>
  </ng-container>
  <div widget-content>
    <section class="ticket-details__requester" *ngIf="ticket.requester?.name">
      <p class="ticket-details__heading">Requester (Customer)</p>
      <p class="ticket-details__body">{{ ticket.requester?.name }}</p>
    </section>
    <section class="ticket-details__responder" *ngIf="ticket.responder?.name">
      <p class="ticket-details__heading">Assignee (Cloud Gateway)</p>
      <p class="ticket-details__body">{{ ticket.responder?.name }}</p>
    </section>
    <section
      class="ticket-details__section ticket-details__section--cc"
      *ngIf="ticket.cc_emails && ticket.cc_emails.length > 0"
    >
      <p class="ticket-details__heading">People on CC</p>
      <ul>
        <li
          class="ticket-details__cc-email"
          [title]="user"
          *ngFor="let user of ticket.cc_emails"
        >
          {{ user }}
        </li>
      </ul>
    </section>
  </div>
</app-widget>
