import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SelectOptions } from '@forms/models/form-select.model';
import { Account } from '@shared/models/account';
import { TrafficLog } from '@shared/models/api-response/api-response-analytics.model';
import { ButtonIconPosition, ButtonRole } from '@shared/models/button.model';
import { IconSize } from '@shared/models/icon.model';
import { Status } from '@shared/models/status.model';
import { WidgetType } from '@shared/models/widget.model';
import { AccountService } from '@shared/services/account.service';
import { CountryService } from '@shared/services/country.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  Subject,
  Subscription,
  take,
} from 'rxjs';
import { DateTime } from 'luxon';
import { ToggleOptions, ToggleType } from '@forms/models/form-toggle.model';
import { InspectService } from 'src/app/inspect/inspect.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { FormsModule } from '@forms/forms.module';
import { PermissionsService } from '@shared/services/permissions.service';
import { Feature } from '@shared/models/features';

// Needs to be stand-alone as it uses components from both the shared and forms modules
@Component({
  selector: 'app-traffic-logs-table',
  standalone: true,
  templateUrl: './traffic-logs-table.component.html',
  styleUrls: ['./traffic-logs-table.component.scss'],
  imports: [CommonModule, SharedModule, FormsModule],
})
export class TrafficLogsTableComponent implements OnInit, OnDestroy {
  @Input() policyid?: string;
  @Input() wafToggleEnabled: boolean = true;

  public status: Status = Status.LOADING;
  public trafficLogs: TrafficLog[] = [];
  public filteredLogs: TrafficLog[] = [];
  private srcIpUpdate = new Subject<string>();
  private dstIpUpdate = new Subject<string>();

  public Status = Status;
  public ButtonRole = ButtonRole;
  public WidgetType = WidgetType;
  public ButtonIconPosition = ButtonIconPosition;
  public IconSize = IconSize;

  private accountId: string = '';
  private accountSubscription?: Subscription;

  public searchAction: string | null = null;
  public srcIpSearch: string = '';
  public dstIpSearch: string = '';
  public unknownSize: boolean = true;
  public displayedColumns: string[] = [];

  public toggleOptions: ToggleOptions[] = [
    {
      value: 'traffic',
      label: 'Traffic',
    },
    {
      value: 'waf',
      label: 'WAF',
    },
  ];
  public selectedLogType: string = 'traffic';

  public actionOptions: SelectOptions[] = [
    {
      value: null,
      label: 'All Actions',
    },
    {
      value: 'accept',
      label: 'Accept',
    },
    {
      value: 'deny',
      label: 'Deny',
    },
    {
      value: 'close',
      label: 'Close',
    },
    {
      value: 'server-rst',
      label: 'Server-rst',
    },
    {
      value: 'client-rst',
      label: 'Client-rst',
    },
    {
      value: 'dns',
      label: 'DNS',
    },
    {
      value: 'timeout',
      label: 'Timeout',
    },
  ];
  public selectPageLimitOptions: SelectOptions[] = [
    {
      value: 10,
      label: 'Show 10 results',
    },
    {
      value: 20,
      label: 'Show 20 results',
    },
    {
      value: 50,
      label: 'Show 50 results',
    },
  ];
  public limit: number = 10;
  private startTime: DateTime = DateTime.now().minus({ minutes: 15 });
  public ToggleType = ToggleType;
  public showToggle: boolean = false;

  constructor(
    private accountService: AccountService,
    private inspectService: InspectService,
    private countryService: CountryService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.status = Status.LOADING;
    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(take(1))
      .subscribe((account?: Account) => {
        if (account?.id) {
          this.accountId = account.id;
          this.showToggle =
            this.wafToggleEnabled &&
            this.permissionsService.isFeatureAllowedForAccount(
              Feature.WAF_LOGS,
              account
            );
          this.handleToggle();
        }
      });
    // Debounce search.
    this.srcIpUpdate
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe(() => {
        this.refreshData();
      });
    this.dstIpUpdate
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe(() => {
        this.refreshData();
      });
  }

  ngOnDestroy(): void {
    this.accountSubscription?.unsubscribe();
    this.srcIpUpdate.unsubscribe();
    this.dstIpUpdate.unsubscribe();
  }

  public refreshData(endTime?: string) {
    this.status = Status.LOADING;
    if (!endTime) {
      this.startTime = DateTime.now().minus({ minutes: 15 });
    } else {
      this.startTime = this.startTime.minus({ minutes: 15 });
    }

    const end = endTime ?? `${DateTime.now().toMillis()}000000`;
    this.inspectService
      .trafficLogs({
        accountId: this.accountId,
        start: `${this.startTime.toMillis()}000000`,
        end,
        srcIp: this.srcIpSearch,
        dstIp: this.dstIpSearch,
        action: this.searchAction,
        type: this.selectedLogType,
        policyid: this.policyid,
      })
      .pipe(
        catchError((error) => {
          this.status = Status.ERROR;
          throw error;
        })
      )
      .subscribe((results) => {
        if (!results.data || results.meta.type !== this.selectedLogType) {
          return;
        }
        this.status = Status.COMPLETE;
        if (endTime) {
          this.trafficLogs = this.trafficLogs.concat(results.data);
        } else {
          this.trafficLogs = results.data;
        }
        this.filteredLogs = [...this.trafficLogs];
        this.unknownSize = this.filteredLogs.length > this.limit;
      });
  }

  public getCountryCode(countryName: string): string | undefined {
    return this.countryService.getCountryCode(countryName);
  }

  public filterBySourceIP = () => {
    this.srcIpUpdate.next(this.srcIpSearch);
  };

  public filterByDestinationIP = () => {
    this.dstIpUpdate.next(this.dstIpSearch);
  };

  public filterByAction = () => {
    this.refreshData();
  };

  public fetchAdditionalLogs() {
    const lastItem = this.filteredLogs[this.filteredLogs.length - 1];
    const subtractedSecond = Number(lastItem.eventtime) - 1;

    this.refreshData(subtractedSecond.toString());
  }

  public handleToggle(): void {
    this.displayedColumns =
      this.selectedLogType === 'traffic'
        ? [
            'eventtime',
            'action',
            'srcip',
            'dstip',
            'service',
            'firewall_action',
            'rcvdpkt',
            'sentpkt',
          ]
        : [
            'eventtime',
            'srcip',
            'dstip',
            'service',
            'http_method',
            'http_retcode',
            'http_response_bytes',
            'http_request_bytes',
            'http_host',
            'policyname',
            'msg',
          ];
    if (this.selectedLogType === 'traffic' && !this.policyid) {
      this.displayedColumns.push('policyid');
      this.displayedColumns.push('policyname');
    }
    this.refreshData();
  }
}
