import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/default/environment';
import { LogoSize } from '@shared/models/logo.model';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  @Input() size: LogoSize = LogoSize.DEFAULT;

  public theme: string = environment.theme;
  public logoPath!: string;
  public loginClass: string = '';

  constructor(public router: Router) {}

  ngOnInit(): void {
    if (environment.theme === 'silicon') {
      this.logoPath =
        '/assets/img/CG-Logo-Thumb-Rounded-Inverted-White-Logo.svg';
      if (this.size === LogoSize.COMPACT) {
        this.loginClass = 'nav';
      }
    } else {
      this.logoPath = '/assets/img/vz-standalone-checkmark.svg';
    }
  }
}
