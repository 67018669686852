import { Component, Input } from '@angular/core';
import { IconSize, IconType, IconStatus } from '@shared/models/icon.model';

@Component({
  selector: 'app-firewall-source-nat',
  templateUrl: './firewall-source-nat.component.html',
  styleUrls: ['./firewall-source-nat.component.scss'],
})
export class FirewallSourceNatComponent {
  @Input() data: any;

  public IconSize = IconSize;
  public IconType = IconType;
  public IconStatus = IconStatus;

  constructor() {}
}
