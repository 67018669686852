import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Status } from '@shared/models/status.model';
import { SupportService } from '@support/support.service';

import { switchMap, tap } from 'rxjs/operators';
import { Ticket, TicketType } from '@support/models/ticket';
import { Router } from '@angular/router';
import { ModalService } from '@shared/services/modal.service';
import { Subscription } from 'rxjs';
import { TicketStatus } from '@support/models/ticket-status.constant';
import { Account } from '@shared/models/account';
import { AccountService } from '@shared/services/account.service';

@Component({
  selector: 'app-support-tickets',
  templateUrl: './support-tickets.component.html',
  styleUrls: ['./support-tickets.component.scss'],
})
export class SupportTicketsComponent implements OnInit, OnDestroy {
  @Input() type?: TicketType = TicketType.INCIDENTS;

  public openTickets: number = 0;
  public pendingTickets: number = 0;
  public resolvedTickets: number = 0;
  public closedTickets: number = 0;
  public ticketStatus: Status = Status.LOADING;
  public Status = Status;
  public TicketType = TicketType;

  private accountSubscription?: Subscription;
  private ticketSubscription?: Subscription;

  constructor(
    private supportService: SupportService,
    private router: Router,
    private modalService: ModalService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(
        tap(() => {
          this.ticketStatus = Status.LOADING;
        }),
        switchMap((account: Account | undefined) =>
          this.supportService.getTickets(this.type, account?.id)
        )
      )
      .subscribe({
        next: (relevantTickets: Ticket[]) => {
          const openTickets = relevantTickets.filter(
            (ticket: Ticket) =>
              ticket.status === TicketStatus.OPEN ||
              ticket.status === TicketStatus.SCHEDULED
          );

          const pendingTickets = relevantTickets.filter(
            (ticket: Ticket) => ticket.status === TicketStatus.PENDING
          );

          const resolvedTickets = relevantTickets.filter(
            (ticket: Ticket) => ticket.status === TicketStatus.RESOLVED
          );

          const closedTickets = relevantTickets.filter(
            (ticket: Ticket) => ticket.status === TicketStatus.CLOSED
          );

          this.openTickets = openTickets.length;
          this.pendingTickets = pendingTickets.length;
          this.resolvedTickets = resolvedTickets.length;
          this.closedTickets = closedTickets.length;
          this.ticketStatus = Status.COMPLETE;
        },
        error: () => {
          this.ticketStatus = Status.ERROR;
        },
      });
  }

  raiseTicketClick() {
    if (this.type === 'sr') {
      this.modalService.serviceRequestChoice();
    } else {
      this.modalService.raiseIncident();
    }
  }

  viewTicketsClick() {
    if (!this.type || this.type === 'sr') {
      this.router.navigate(['/support/incidents']);
    } else {
      this.router.navigate(['/support/service-requests']);
    }
  }

  ngOnDestroy() {
    this.accountSubscription?.unsubscribe();
    this.ticketSubscription?.unsubscribe();
  }
}
