import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

/* Pages */
import { DashboardComponent } from '@core/pages/dashboard/dashboard.component';
import { SummaryComponent } from '@core/pages/summary/summary.component';
import { HomeComponent } from '@core/pages/home/home.component';
import { FourohfourComponent } from '@core/pages/fourohfour/fourohfour.component';

/* Modals */
import { AddEditUserComponent } from './settings/modals/add-edit-user/add-edit-user.component';
import { ViewTicketComponent } from './support/modals/view-ticket/view-ticket.component';
import { RaiseIncidentComponent } from './support/modals/raise-incident/raise-incident.component';
import { ResetVPNAuthComponent } from './connect/modals/reset-vpn-auth/reset-vpn-auth.component';
import { VpnDetailComponent } from './connect/modals/vpn-detail/vpn-detail.component';
import { ViewFirewallRuleComponent } from './protect/modals/view-firewall-rule/view-firewall-rule.component';
import { RequestFirewallRuleComponent } from './protect/modals/request-firewall-rule/request-firewall-rule.component';
import { RaiseServiceRequestComponent } from './support/modals/raise-service-request/raise-service-request.component';
import { ServiceRequestChoiceComponent } from './support/modals/service-request-choice/service-request-choice.component';
import { TermsConditionsComponent } from './core/modals/terms-conditions/terms-conditions.component';
import { CreateSSLUserComponent } from './connect/modals/create-ssl-user/create-ssl-user.component';
import { ChangeVPNPasswordsComponent } from './connect/modals/change-vpn-passwords/change-vpn-passwords.component';

/* Components */
import { ContainerComponent } from '@core/widgets/container/container.component';
import { CookiePolicyComponent } from '@core/components/cookie-policy/cookie-policy.component';

/* Resolvers */
import { AccountsResolverService } from '@shared/resolvers/accounts-resolver.service';
import { PartnersResolverService } from '@shared/resolvers/partners-resolver.service';

/* Guards */
import { AuthGuard } from '@auth/services/auth.guard';
import { AccountGuard } from '@shared/account.guard';

/* Models */
import { Feature } from '@shared/models/features';
import { UserGuard } from '@shared/user.guard';
import { AddReportComponent } from 'src/app/reporting/modals/add-report/add-report.component';
import { ViewReportComponent } from 'src/app/reporting/modals/view-report/view-report.component';
import { PermissionsResolverService } from '@shared/resolvers/permissions-resolver.service';
import { DeleteSSLUsersComponent } from '@connect/modals/delete-ssl-users/delete-ssl-users.component';

const routes: Routes = [
  // TODO: work out why this doesn't get a user when first loaded
  // the cognitoService seems to not fire the authStateChange, which kicks off the user chain
  {
    path: '',
    canActivate: [AuthGuard],
    component: HomeComponent,
    resolve: [AccountsResolverService, PermissionsResolverService],
  },
  {
    path: 'add-edit-user',
    component: AddEditUserComponent,
    outlet: 'modal',
  },
  {
    path: 'add-report',
    component: AddReportComponent,
    outlet: 'modal',
  },
  {
    path: 'view-report',
    component: ViewReportComponent,
    outlet: 'modal',
  },
  {
    path: 'view-ticket',
    component: ViewTicketComponent,
    outlet: 'modal',
  },
  {
    path: 'raise-incident',
    component: RaiseIncidentComponent,
    outlet: 'modal',
  },
  {
    path: 'raise-service-request',
    component: RaiseServiceRequestComponent,
    outlet: 'modal',
  },
  {
    path: 'service-request-choice',
    component: ServiceRequestChoiceComponent,
    outlet: 'modal',
  },
  {
    path: 'create-ssl-user',
    component: CreateSSLUserComponent,
    outlet: 'modal',
  },
  {
    path: 'delete-ssl-users',
    component: DeleteSSLUsersComponent,
    outlet: 'modal',
  },
  {
    path: 'reset-vpn-auth',
    component: ResetVPNAuthComponent,
    outlet: 'modal',
  },
  {
    path: 'change-vpn-passwords',
    component: ChangeVPNPasswordsComponent,
    outlet: 'modal',
  },
  {
    path: 'vpn-detail/:phaseOne',
    component: VpnDetailComponent,
    outlet: 'modal',
  },
  {
    path: 'view-firewall-rule/:id',
    component: ViewFirewallRuleComponent,
    outlet: 'modal',
  },
  {
    path: 'view-firewall-proxy-rule/:id',
    component: ViewFirewallRuleComponent,
    outlet: 'modal',
  },
  {
    path: 'request-firewall-rule',
    component: RequestFirewallRuleComponent,
    outlet: 'modal',
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
    outlet: 'modal',
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent,
    data: { title: 'Cookie Policy' },
  },
  {
    path: '404',
    component: FourohfourComponent,
    data: { title: 'Page not found' },
  },
  {
    path: 'dev',
    canActivateChild: [AuthGuard, UserGuard],
    loadChildren: () => import('./dev/dev.module').then((m) => m.DevModule),
  },
  {
    path: 'connect',
    resolve: [PermissionsResolverService],
    canActivateChild: [AuthGuard, UserGuard],
    loadChildren: () =>
      import('./connect/connect.module').then((m) => m.ConnectModule),
  },
  {
    path: 'protect',
    resolve: [PermissionsResolverService],
    canActivateChild: [AuthGuard, UserGuard],
    loadChildren: () =>
      import('./protect/protect.module').then((m) => m.ProtectModule),
  },
  {
    path: 'dashboard',
    resolve: [AccountsResolverService, PermissionsResolverService],
    canActivateChild: [AuthGuard, AccountGuard, UserGuard],
    loadChildren: () =>
      import('./core/pages/dashboard/dashboard-routing.module').then(
        (m) => m.DashboardRoutingModule,
      ),
  },
  {
    path: 'summary',
    resolve: [
      AccountsResolverService,
      PartnersResolverService,
      PermissionsResolverService,
    ],
    canActivateChild: [AuthGuard, AccountGuard, UserGuard],
    loadChildren: () =>
      import('./core/pages/summary/summary-routing.module').then(
        (m) => m.SummaryRoutingModule,
      ),
  },
  {
    path: 'inspect',
    resolve: [PermissionsResolverService],
    canActivateChild: [AuthGuard, UserGuard],
    loadChildren: () =>
      import('./inspect/inspect.module').then((m) => m.InspectModule),
  },
  {
    path: 'support',
    resolve: [PermissionsResolverService],
    canActivateChild: [AuthGuard, UserGuard],
    loadChildren: () =>
      import('./support/support.module').then((m) => m.SupportModule),
  },
  {
    path: 'reporting',
    resolve: [PermissionsResolverService],
    canActivateChild: [AuthGuard, UserGuard],
    loadChildren: () =>
      import('./reporting/reporting.module').then((m) => m.ReportingModule),
  },
  {
    path: 'settings',
    resolve: [PermissionsResolverService],
    canActivateChild: [AuthGuard, UserGuard],
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
