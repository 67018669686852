import { Chart, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

export interface DonutChart extends Chart<'doughnut'> {
  annotation: any;
}

export interface DonutBaseChartDirective
  extends BaseChartDirective<'doughnut'> {
  chart: DonutChart;
}

export interface DonutChartOptions extends ChartOptions {
  rowGap?: boolean;
  columnGap?: boolean;
  gapSize?: number;
  cornerRadius?: number;
  donutType?: DonutType;
  cutout?: number | string;
}

export interface DonutChartDatapoint {
  aggregate: string;
  value: number;
}

/**
 * Types of Donut Chart
 * COUNT: number is just a number
 * DATA: number is number of bytes
 * PERCENT: number is a percentage (rest of chart will be empty)
 */
export enum DonutType {
  COUNT = 'count',
  DATA = 'data',
  PERCENT = 'percent',
}
