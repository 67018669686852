import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Observable,
  Subscription,
  distinctUntilChanged,
  skipWhile,
  take,
  combineLatest,
} from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromAccounts from '@store/accounts/accounts.selectors';

/* Services */
import { AccountService } from '@shared/services/account.service';
import { UserService } from '@shared/services/user.service';
import { PermissionsService } from '@shared/services/permissions.service';

/* Models */
import { Account } from '@shared/models/account';
import { User } from '@shared/models/user';
import { Feature } from '@shared/models/features';

@Component({
  selector: 'app-home',
  template: '',
})
export class HomeComponent implements OnInit, OnDestroy {
  private userSubscription!: Subscription;
  private userAccountPartnerSubscription!: Subscription;

  /**
   * Constructor
   * @param { AccountService } accountService - provider for accounts.
   * @param { UserService } userService - provider for users.
   * @param { Router } router - provider for routes.
   * @param { PermissionsService } permissionsService - provider for permissions.
   * @param { Store } store - provider for store.
   */
  constructor(
    private accountService: AccountService,
    private userService: UserService,
    private router: Router,
    private permissionsService: PermissionsService,
    private store: Store
  ) {}

  ngOnInit() {
    const userSub: Observable<User | null> = this.userService
      .getCurrentUser()
      .pipe(
        skipWhile((v) => !v), // ignore the null that comes at logout
        take(1)
      );
    const accountSub: Observable<Account | undefined> = this.accountService
      .fetchCurrentAccount()
      .pipe(
        distinctUntilChanged() // only take a values that's new (stops redrawing on double null for admins)
      );

    this.userAccountPartnerSubscription = combineLatest([
      userSub,
      accountSub,
    ]).subscribe((result: any) => {
      const [user, account] = result;
      if (user) {
        this.redirectUser(user, account);
      }
    });
  }

  /**
   * We want to redirect user to their home page depending on their permissions.
   */
  private redirectUser(user: User, account?: Account) {
    if (user && account) {
      // User and account are set, so we can navigate
      this.navigateBasedOnPermission(user, account);
    } else {
      // Set the current account
      if (user.accountId?.length && user.accountId[0] !== '[]') {
        // User has account IDs set on their user details
        this.accountService.updateLoadedAccountSubject(user.accountId[0]);
      } else {
        // User doesn't have an accountId, give them the first from selectAllAccounts
        this.store
          .select(fromAccounts.selectAllAccounts)
          .pipe(take(1))
          .subscribe((accounts: any) => {
            this.accountService.updateLoadedAccountSubject(accounts[0].id);
          });
      }
    }
  }

  private navigateBasedOnPermission(user: User, account?: Account) {
    if (
      this.permissionsService.isFeatureAllowed(Feature.DASHBOARD, account, user)
    ) {
      this.router.navigateByUrl('/dashboard');
    } else if (
      this.permissionsService.isFeatureAllowed(Feature.SSLVPN, account, user)
    ) {
      this.router.navigateByUrl('/connect/remote-access');
    } else if (
      this.permissionsService.isUserPermitted(user, ['USER__MANAGEMENT__READ'])
    ) {
      this.router.navigateByUrl('/settings/user-management');
    } else if (
      this.permissionsService.isFeatureAllowed(Feature.SUPPORT, account, user)
    ) {
      this.router.navigateByUrl('/support/incidents');
    } else {
      this.router.navigateByUrl('/404');
    }
  }

  /**
   * Destroy
   */
  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    this.userAccountPartnerSubscription.unsubscribe();
  }
}
