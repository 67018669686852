<label class="form__label" [for]="name">
  <div class="form__text"><ng-content></ng-content></div>
  <div class="form-input--select-wrapper">
    <ng-container *ngIf="type === SelectType.SINGLE">
      <select
        class="form-input form-input--select"
        [(ngModel)]="selectedOption"
        (ngModelChange)="selectedOptionChange.emit(this.selectedOption)"
        [id]="name"
        [name]="name"
        [ngClass]="classList"
        [disabled]="status === FieldStatus.DISABLED"
        (focus)="onFocus()"
        (blur)="onBlur()"
      >
        <option *ngFor="let option of selectOptions" [ngValue]="option.value">
          {{ option.label }}
        </option>
      </select>
      <app-icon title="Expand" [size]="IconSize.MEDIUM" class="form-input__icon"
        >expand_more</app-icon
      >
    </ng-container>
    <select
      *ngIf="type === SelectType.MULTIPLE"
      class="form-input form-input--multiselect"
      [(ngModel)]="selectedOption"
      (ngModelChange)="selectedOptionChange.emit(this.selectedOption)"
      [name]="name"
      multiple
      [ngClass]="classList"
      [disabled]="status === FieldStatus.DISABLED"
      (focus)="onFocus()"
      (blur)="onBlur()"
    >
      <option *ngFor="let option of selectOptions" [ngValue]="option.value">
        {{ option.label }}
      </option>
    </select>
  </div>
</label>

<p class="form__subtext" *ngIf="subtext">
  <small>{{ subtext }}</small>
</p>

<p class="form__error-message" *ngIf="errorMsg && showErrorMsg">
  {{ errorMsg }}
</p>
