import { createAction, props } from '@ngrx/store';
import { PartnerAccount } from '@shared/models/partner-account';
/* STORE ACTIONS */
export const SET_PARTNERS = '[Partners] Set Partners';
// export const GET_ALL_PARTNERS = '[Partners] Get All Partners';
export const CLEAR_PARTNERS = '[Partners] Clear Partners';

/* EFFECTS ACTIONS */
export const FETCH_PARTNER = '[Partners] Fetch Partner';
export const FETCH_MY_PARTNER = '[Partners] Fetch My Partner';
export const FETCH_ALL_PARTNERS = '[Partners] Fetch All Partners';

/* ACTIONS FOR STORE */
export const setPartners = createAction(
  SET_PARTNERS,
  props<{ partners: PartnerAccount[] }>()
);
// export const getAllPartners = createAction(GET_ALL_PARTNERS);
export const clearPartners = createAction(CLEAR_PARTNERS);

/* ACTIONS FOR EFFECTS */
export const fetchPartner = createAction(
  FETCH_PARTNER,
  props<{ id: string }>()
);
export const fetchMyPartner = createAction(FETCH_MY_PARTNER);
export const fetchAllPartners = createAction(FETCH_ALL_PARTNERS);
