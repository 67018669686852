import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Account } from '@shared/models/account';
import {
  WebFilter,
  URLFilter,
  URLFilterEntry,
  URLFilterAction,
  FirewallType,
} from '@shared/models/firewall.model';
import { AccountService } from '@shared/services/account.service';
import { ProtectService } from '../../protect.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Status } from '@shared/models/status.model';
import { IconSize, IconType, IconStatus } from '@shared/models/icon.model';
import { DatatableType } from '@shared/models/datatable.model';
import { PillSize, PillStatus } from '@shared/models/pill.model';

@Component({
  selector: 'app-firewall-webfilters',
  templateUrl: './firewall-webfilters.component.html',
  styleUrls: ['./firewall-webfilters.component.scss'],
})
export class FirewallWebfiltersComponent implements OnInit, OnDestroy {
  @Input() data!: WebFilter;
  @Input() type: FirewallType = FirewallType.REGULAR;

  private urlFilterSubscription!: Subscription;
  private accountSubscription!: Subscription;
  private accountId: string = '';

  public entries: URLFilterEntry[] = [];
  public filteredEntries: URLFilterEntry[] = [];
  public hasFilterData: boolean = false;
  public searchText = '';

  public tableColumns: string[] = ['url', 'action'];
  public tableStatus: Status = Status.LOADING;

  public URLFilterAction = URLFilterAction;
  public IconSize = IconSize;
  public IconType = IconType;
  public IconStatus = IconStatus;
  public DatatableType = DatatableType;
  public PillStatus = PillStatus;
  public PillSize = PillSize;

  constructor(
    private accountService: AccountService,
    private protectService: ProtectService
  ) {}

  ngOnInit() {
    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(take(1))
      .subscribe((account?: Account) => {
        if (account?.id) {
          this.accountId = account.id;
          this.getData();
        }
      });
  }

  private getData() {
    if (this.data?.web?.['urlfilter-table']?.id) {
      this.hasFilterData = true;
      const urlFilterId = this.data.web['urlfilter-table'].id;
      this.urlFilterSubscription = this.protectService
        .getURLFilters({
          filterId: urlFilterId,
          accountId: this.accountId,
          firewallType: this.type,
        })
        .subscribe((response: URLFilter) => {
          this.tableStatus = Status.COMPLETE;
          this.entries = response.entries || [];
          this.filteredEntries = response.entries || [];
        });
    }
  }

  ngOnDestroy() {
    if (this.urlFilterSubscription) {
      this.urlFilterSubscription.unsubscribe();
    }
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
  }

  filterByText(subStr: string) {
    if (!subStr) {
      this.filteredEntries = this.entries.slice();
    }
    this.filteredEntries = this.entries.filter(
      (entry: URLFilterEntry) => entry.url.toLowerCase().indexOf(subStr) !== -1
    );
  }
}
