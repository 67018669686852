import { Action, createReducer, on } from '@ngrx/store';

import { User } from '@shared/models/user';

import * as UserActions from './users.actions';
import * as StatusActions from '../status/status.actions';

export interface UserState {
  loaded: boolean;
  data: User[];
}

/* Initial states */
export const initialState: UserState = {
  loaded: false,
  data: [],
};

/* Setup reducers */
export function reducer(state: UserState | undefined, action: Action) {
  return usersReducer(state, action);
}

const setUsers = (
  state: UserState,
  action: { type: string; users: User[] }
) => {
  const newState = { ...state, loaded: true, data: action.users };

  return newState;
};

const addUser = (state: UserState, action: { user: User }) => {
  const newStateData: User[] = [...state.data];
  newStateData.unshift(action.user);

  return { ...state, data: newStateData };
};

const updateUser = (state: UserState, action: { user: User }) => {
  const newStateData: User[] = [...state.data];
  const userIndexToBeUpdated = newStateData.findIndex(
    (stateUser) => stateUser.sub === action.user.sub
  );
  newStateData[userIndexToBeUpdated] = action.user;

  return { ...state, data: newStateData };
};

const removeUser = (state: UserState, action: { userId: string }) => {
  const newStateData: User[] = [...state.data].filter(
    (stateUser) => stateUser.sub !== action.userId
  );

  return { ...state, data: newStateData };
};

const clearUsers = (state: UserState, action: { type: string }) => {
  const newState = { ...state, loaded: false, data: [] };
  return newState;
};

const usersReducer = createReducer(
  initialState,
  on(UserActions.setUsers, setUsers),
  on(UserActions.addUser, addUser),
  on(UserActions.updateUser, updateUser),
  on(UserActions.removeUser, removeUser),
  on(StatusActions.logout, clearUsers)
);
