import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  UrlTree,
  CanActivateChild,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AccountService } from './services/account.service';
import { PermissionsService } from './services/permissions.service';

// From https://stackoverflow.com/questions/50250361/how-to-elegantly-get-full-url-from-the-activatedroutesnapshot
const getResolvedUrl = (route: ActivatedRouteSnapshot): string => {
  return route.pathFromRoot
    .map((v) => v.url.map((segment) => segment.toString()).join('/'))
    .join('/');
};

@Injectable({ providedIn: 'root' })

/*
Checks if the Account ID is set before allowing access
Checks if an account has been loaded before nav
Checks to see if the target route is allowed in the accounts featureId
If no Account is set, or the route is not allowed on the Account, return to the homepage
*/
export class AccountGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private permissionsService: PermissionsService
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(route);
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.permissionsService.checkPermissions().pipe(
      switchMap(() => {
        return this.accountService.checkCurrentAccountID();
      }),
      switchMap(() => {
        return this.accountService.checkAccounts();
      }),
      switchMap((_accounts) => {
        return this.accountService.fetchCurrentAccount();
      }),
      map((account) => {
        if (!account) {
          return this.router.createUrlTree(['/']);
        }
        const feature = route?.routeConfig?.data?.id;
        const isAllowed: boolean =
          this.permissionsService.isFeatureAllowedForAccount(feature, account);
        if (isAllowed) {
          return true;
        } else {
          return this.router.createUrlTree(['/']);
        }
      })
    );
  }
}
