import {
  animate,
  animateChild,
  AnimationTriggerMetadata,
  group,
  keyframes,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ModalStatus } from '@shared/models/modal.model';

export const slideIn = (
  animationTime: string = '300ms'
): AnimationTriggerMetadata =>
  trigger('slideIn', [
    transition(':enter', [
      style({ transform: 'translateX(100%)' }),
      animate(
        `${animationTime} ease-in-out`,
        style({ transform: 'translateX(0%)' })
      ),
    ]),
    transition(':leave', [
      style({ transform: 'translateX(0%)' }),
      animate(
        `${animationTime} ease-in-out`,
        style({ transform: 'translateX(100%)' })
      ),
    ]),
  ]);

export const fadeAnimation = (animationTime: string = '0.6s') =>
  trigger('fadeAnimation', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(`${animationTime}`, style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate(`${animationTime}`, style({ opacity: 0 })),
    ]),
  ]);

export const openClose = (animationTime = '0.4s', easing = 'ease-in-out') =>
  trigger('openClose', [
    state(
      'open',
      style({
        height: '*',
      })
    ),
    state(
      'closed',
      style({
        height: '0',
      })
    ),
    transition('open => closed', [animate(`${animationTime} ${easing}`)]),
    transition('closed => open', [animate(`${animationTime} ${easing}`)]),
  ]);

export const fadeIn = (duration = '.2s') =>
  trigger('fadeIn', [
    transition(`void => ${ModalStatus.OPEN}`, [
      style({ opacity: 0 }),
      animate(duration, style({ opacity: 1 })),
      query('@slideUp', [animateChild()]),
    ]),
    transition(`${ModalStatus.OPEN} => ${ModalStatus.CLOSED}`, [
      query('@slideUp', [animateChild()]),
      style({ opacity: 1 }),
      animate(duration, style({ opacity: 0 })),
    ]),
  ]);

export const slideUp = (duration = '.4s', distance = '200px') =>
  trigger('slideUp', [
    state(
      'void',
      style({
        opacity: '0',
        transform: `translateY(${distance})`,
      })
    ),
    state(
      ModalStatus.CLOSED,
      style({
        opacity: '0',
        transform: `translateY(${distance})`,
      })
    ),
    transition(`void => ${ModalStatus.OPEN}`, [animate(duration)]),
    transition(`${ModalStatus.OPEN} => ${ModalStatus.CLOSED}`, [
      animate(duration),
    ]),
  ]);

export const flyInOut = (animationTime: string = '0.6s', easing = 'ease-out') =>
  trigger('flyInOut', [
    state(
      'inactive',
      style({
        opacity: 0,
      })
    ),
    transition(
      'inactive => active',
      animate(
        `${animationTime} ${easing}`,
        keyframes([
          style({
            transform: 'translate3d(100%, 0, 0)',
            opacity: 0,
          }),
          style({
            transform: 'none',
            opacity: 1,
          }),
        ])
      )
    ),
    transition(
      'active => removed',
      animate(
        `${animationTime} ${easing}`,
        keyframes([
          style({
            opacity: 1,
          }),
          style({
            transform: 'translate3d(100%, 0, 0)',
            opacity: 0,
          }),
        ])
      )
    ),
  ]);

export const tableRowAnimation = (
  duration: string = '100ms',
  easing: string = 'ease-out'
) =>
  trigger('tableRowAnimation', [
    transition(
      ':enter',
      [
        style({ height: 0, opacity: '0', transform: 'translateY(-100%)' }),
        animate(
          `${duration} {{ enterDelay }} ${easing}`,
          style({ height: '*', opacity: '1', transform: 'translateY(0)' })
        ),
      ],
      { params: { enterDelay2: '0ms' } }
    ),
  ]);

export const slideLeft = (
  duration: string = '300ms',
  easing: string = 'ease-out'
) =>
  trigger('slideLeft', [
    transition('void => *', [
      style({ left: '100%' }),
      animate(`${duration} ${easing}`, style({ left: 0 })),
    ]),
    transition('* => void', [
      animate(`${duration} ${easing}`, style({ left: '-100%' })),
    ]),
  ]);

export const introAnimation = trigger('introAnimation', [
  //The FULL intro animation
  transition('void => full', [
    // Setup initial styles
    query('.auth-container__content', [
      style({
        opacity: 0,
        overflow: 'hidden',
        flexBasis: 0,
      }),
    ]),
    query('.auth-container__footer', [
      style({
        opacity: 0,
      }),
    ]),
    query('.auth-container__header', [style({ transform: 'scale(2)' })]),
    // scale logo & move up (by scaling the content)
    group(
      [
        query('.auth-container__header', [
          style({ transform: 'scale(2)' }),
          animate(`350ms ease-out`, style({ transform: 'scale(1)' })),
        ]),
        query('.auth-container__content', [
          animate(`350ms ease-out`, style({ flexBasis: `40rem` })),
        ]),
      ],
      { delay: 350 }
    ),
    // fade in the content & footer (w/ 250ms delay)
    group(
      [
        query('.auth-container__content', [
          animate('350ms ease-out', style({ opacity: 1 })),
        ]),
        query('.auth-container__footer', [
          animate(`350ms ease-out`, style({ opacity: 1 })),
        ]),
      ],
      { delay: 250 }
    ),
  ]),
  // The SIMPLE in, just fade in.
  transition('void => simple', [
    style({ opacity: 0 }),
    animate(`350ms ease-out`, style({ opacity: 1 })),
  ]),
  // The Outro animation
  transition(':leave', [
    // Initial styles
    style({
      opacity: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 999,
      backgroundColor: 'var(--container__background)',
    }),
    query('.auth-container__loading', [
      style({ display: 'block', opacity: 0, flexBasis: 0, overflow: 'hidden' }),
    ]),
    // STEP 1 - fade out content & footer
    group([
      // add fake transition to children to keep them from being removed from the DOM
      query(
        '.auth-container__content *',
        [style({}), animate(350, style({}))],
        {
          optional: true,
        }
      ),
      query('.auth-container__footer', [
        style({ opacity: 1 }),
        animate(`350ms ease-out`, style({ opacity: 0 })),
      ]),
      query('.auth-container__content', [
        style({ opacity: 1, flexBasis: `40rem`, overflow: 'hidden' }),
        animate('350ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
    // STEP 2 - bring into center
    group([
      query('.auth-container__header', [
        style({ marginBottom: `3.4rem` }),
        animate(`350ms ease-out`, style({ marginBottom: `2rem` })),
      ]),
      query('.auth-container__content', [
        animate(`350ms ease-out`, style({ flexBasis: 0 })),
      ]),
      // give loader it's size, so the logo animated to the correct location
      query('.auth-container__loading', [
        animate(
          `350ms ease-out`,
          style({ flexBasis: '4.3rem', overflow: 'hidden' })
        ),
      ]),
    ]),
    // STEP 2 - show loader
    query('.auth-container__loading', [animate(350, style({ opacity: 1 }))]),
    // STEP 3 - pause before fading out
    animate(`250ms 1500ms`, style({ opacity: 0 })),
    // And... scene
  ]),
]);
