import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { IconSize } from '@shared/models/icon.model';
import { environment } from '@environments/default/environment';

@Component({
  selector: 'app-auth-container',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.scss'],
})
export class AuthContainerComponent implements OnInit {
  public IconSize = IconSize;
  public currentYear: number = 2023;

  public copyrightLabel = 'Cloud Gateway'; // Can be adjusted depending on the theme

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit() {
    // In case previous LocalStorage is still open
    this.localStorageService.removeLocalStorage('CognitoExpiry');
    this.currentYear = new Date().getFullYear();
  }
}
