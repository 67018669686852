import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/default/environment';

@Component({
  selector: 'app-version-number',
  templateUrl: './version-number.component.html',
  styleUrls: ['./version-number.component.scss'],
})
export class VersionNumberComponent implements OnInit {
  constructor() {}

  public versionNumber: string = '';

  ngOnInit(): void {
    this.versionNumber = environment.theme + environment.version['release'];
  }
}
