import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, EMPTY, of } from 'rxjs';
import { switchMap, tap, filter, catchError, take } from 'rxjs/operators';
import {
  ButtonDisplay,
  ButtonIconPosition,
  ButtonType,
} from '@shared/models/button.model';
import { CustomIcons } from '@shared/models/icon.model';
import { Status } from '@shared/models/status.model';
import { AccountService } from '@shared/services/account.service';
import { CountryService } from '@shared/services/country.service';
import { Account } from '@shared/models/account';
import { ResourceResponse } from '@shared/models/api-response/api-response-analytics.model';
import { ProtectService } from 'src/app/protect/protect.service';

@Component({
  selector: 'app-protect-summary',
  templateUrl: './protect-summary.component.html',
  styleUrls: ['./protect-summary.component.scss'],
})
export class ProtectSummaryComponent implements OnInit, OnDestroy {
  public country: string | undefined;
  public value!: string;
  public status: Status = Status.LOADING;

  private accountSubscription!: Subscription;
  private accountId?: string;

  public CustomIcons = CustomIcons;
  public ButtonDisplay = ButtonDisplay;
  public ButtonType = ButtonType;
  public ButtonIconPosition = ButtonIconPosition;

  constructor(
    private accountService: AccountService,
    private protectService: ProtectService,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {
    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(
        filter((account) => account?.id !== this.accountId),
        tap((account) => {
          if (account?.id !== this.accountId) {
            this.status = Status.LOADING;
          }
        }),
        take(1),
        switchMap((account: Account | undefined) => {
          if (!account) {
            return of();
          }
          return this.protectService
            .getThreatStats({
              accountId: account.id,
              resource: 'threat-country',
              interval: '7days',
            })
            .pipe(
              catchError(() => {
                this.accountId = account?.id;
                this.status = Status.ERROR;
                return EMPTY;
              })
            );
        })
      )
      .subscribe({
        next: (response: ResourceResponse) => {
          if (response?.data.length) {
            const firstCountryThreat = response.data[0];
            this.country = this.countryService.getCountryCode(
              firstCountryThreat.country
            );
            this.value = firstCountryThreat.sessions;
            this.status = Status.COMPLETE;
          } else {
            this.status = Status.NOT_AVAILABLE;
          }
        },
        error: () => {
          this.status = Status.ERROR;
        },
      });
  }

  ngOnDestroy(): void {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
  }
}
