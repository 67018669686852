import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule as AngularFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';

import { FormFieldComponent } from './components/form-field/form-field.component';
import { FormDropdownComponent } from './components/form-select/form-select.component';
import { FormToggleComponent } from './components/form-toggle/form-toggle.component';
import { FilterTextComponent } from './components/filter-text/filter-text.component';
import { FilterGroupComponent } from './components/filter-group/filter-group.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

@NgModule({
  declarations: [
    FormFieldComponent,
    FormDropdownComponent,
    FormToggleComponent,
    FilterTextComponent,
    FilterGroupComponent,
    CheckboxComponent,
    DateTimePickerComponent,
  ],
  exports: [
    FormFieldComponent,
    FormDropdownComponent,
    FormToggleComponent,
    FilterTextComponent,
    FilterGroupComponent,
    CheckboxComponent,
    DateTimePickerComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    AngularFormsModule,
    NgxDaterangepickerMd.forRoot(),
  ],
})
export class FormsModule {}
