<app-panel class="forgot-password">
  <ng-container panel-title>Password Reset</ng-container>
  <ng-container panel-subtitle *ngIf="!formSubmitted"
    >Enter your email address below and we'll send you a link to get back into
    your account.</ng-container
  >
  <ng-container *ngIf="formSubmitted" panel-subtitle>
    If this user exists, we have sent you a password reset email.<br /><br />
    <a class="secondary-link" routerLink="/login"> Return to Login</a>
  </ng-container>
  <form panel-content *ngIf="!formSubmitted" class="forgot-password__form">
    <app-form-field
      [type]="FieldType.TEXT"
      [name]="'email'"
      [(inputValue)]="email"
      [showErrorMsg]="false"
      [hideLabel]="true"
      placeholder="Enter your email"
      preIcon="email"
      [pattern]="EmailPattern"
      class="forgot-password__form-field"
      >Email Address</app-form-field
    >
    <app-button
      [display]="ButtonDisplay.BLOCK"
      [role]="ButtonRole.PRIMARY"
      [type]="ButtonType.SUBMIT"
      [status]="isValidEmail() ? buttonStatus : ButtonStatus.DISABLED"
      (buttonClick)="forgotPassword($event)"
      >Submit</app-button
    >
    <a class="forgot-password__back-link secondary-link" routerLink="/"
      >Back to Login</a
    >
  </form>
</app-panel>
