/**
 * Report - version returned from FAZ
 * @property {string} title - the title of the report
 * @property {string} subtitle - the subtitle of the report
 * @property {string} description - the description of the report
 * @property {File[]} files - instances of the report
 */
export interface Report {
  id: string;
  title: string;
  subtitle: string;
  description: string;
  category?: string;
  files?: File[];
}

/**
 * File
 * @property {string} name - the name of this instance of the report
 * @property {string} title - the title of the layout used in this report instance
 * @property {string} tid - the Forti ID
 * @property {string} timestamp - UNIX timestamp of when the report was started
 * @property {string} datetime - human-readbale time of when the report was started
 * @property {string} data - base64-encoded data
 * @property {string} dataType - the type of the data returned
 */
export interface File {
  name: string;
  title: string;
  tid: string;
  timestamp: number;
  datetime: string;
  data?: string;
  dataType?: string;
}

/**
 * ReportRecord - the version stored in DDB and sent through from front end
 * @property {string} id - The ID of the report (optional - only added after report is run/event set up)
 * @property {number} layoutId - The ID of the layout in FAZ
 * @property {string} title - The title of the layout used in FAZ (needed when retrieving report data)
 * @property {string} userId - The ID of the user setting up the report
 * @property {string} accountId - The ID of the account for which the report should be run
 * @property {string | string[]} reportId - The ID(s) of the report instances (tid returned from FAZ)
 * @property {ReportType} type - Immediate or Scheduled
 * @property {string} schedule - For scheduled, the cron schedule
 * @property {ReportStatus} reportStatus - For scheduled, whether it is enabled or disabled
 * @property {string[]} emails - The list of users to send to
 * @property {ReportPeriod} period - Time period of report (last-7-days, last-30-days, last-month)
 * @property {string} createdAt - Timestamp of DDB item creation
 * @property {string} updatedAt - Timestamp of DDB item update
 */
export interface ReportRecord {
  id?: string;
  layoutId: number;
  title: string;
  userId?: string;
  accountId?: string;
  files?: File[];
  type: ReportType;
  schedule?: ReportSchedule; // Only sent with type: "scheduled"
  reportStatus?: ReportStatus; // Only sent with type "scehduled"
  emails?: string[];
  period: ReportPeriod;
  createdAt?: number; // Set up in DDB
  updatedAt?: number; // Set up in DDB
}

export enum ReportType {
  SCHEDULED = 'scheduled',
  IMMEDIATE = 'immediate',
}

export enum ReportStatus {
  ENABLED = 'enabled', // for "scheduled"
  DISABLED = 'disabled', // for "scheduled"
  PENDING = 'pending', // for "immediate"
  COMPLETE = 'complete', // for "immediate"
}

export enum ReportPeriod {
  LAST_7_DAYS = 'last-7-days',
  LAST_30_DAYS = 'last-30-days',
  LAST_MONTH = 'last-month',
}

// Dictionary for report periods
// Should finish the sentence "The report period covers..."
export const ReportPeriodDict = {
  'last-7-days': 'the previous 7 days',
  'last-30-days': 'the previous 30 days',
  'last-month': 'the previous month',
};

export enum ReportSchedule {
  EVERY_MONDAY_AT_8AM = '0 8 ? * 2 *',
  FIRST_OF_THE_MONTH_AT_8AM = '0 8 1 * ? *',
}

// Dictionary for report schedules
// Should finish the sentence "The report runs..."
export const ReportScheduleDict = {
  [ReportSchedule.EVERY_MONDAY_AT_8AM]: 'every Monday at 8am',
  [ReportSchedule.FIRST_OF_THE_MONTH_AT_8AM]:
    'on the first of each month at 8am',
};
