import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
import { InlineSVGModule } from 'ng-inline-svg-2';

/* Modules */
import { SharedModule } from '@shared/shared.module';
import { ChartsModule } from '@charts/charts.module';
import { FormsModule } from '@forms/forms.module';

/* Pages */
import { HomeComponent } from './pages/home/home.component';
import { FourohfourComponent } from './pages/fourohfour/fourohfour.component';

/* Components */
import { NewsStoryComponent } from './components/news-story/news-story.component';
import { NavItemComponent } from './components/nav-item/nav-item.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { CountryOutlineComponent } from './components/country-outline/country-outline.component';
import { TopCountryComponent } from './components/top-country/top-country.component';
import { ConnectStatusComponent } from './components/connect-status/connect-status.component';

/* Widgets */
import { ContainerComponent } from './widgets/container/container.component';
import { HeaderComponent } from './widgets/header/header.component';
import { PrimaryNavigationComponent } from './widgets/primary-navigation/primary-navigation.component';
import { NewsStoryListComponent } from './widgets/news-story-list/news-story-list.component';
import { HeaderDetailsComponent } from './widgets/header/header-details/header-details.component';
import { AccountOverviewComponent } from './widgets/account-overview/account-overview.component';
import { ProtectSummaryComponent } from './widgets/protect-summary/protect-summary.component';
import { ConnectSummaryComponent } from './widgets/connect-summary/connect-summary.component';
import { InspectSummaryComponent } from './widgets/inspect-summary/inspect-summary.component';
import { AccountSummaryComponent } from './widgets/account-summary/account-summary.component';
import { AccountSummaryCompactComponent } from './widgets/account-summary-compact/account-summary-compact.component';
import { HttpCancelService } from '@shared/services/http-cancel.service';

/* Modals */
import { TermsConditionsComponent } from './modals/terms-conditions/terms-conditions.component';
import { SecondaryNavigationComponent } from './widgets/secondary-navigation/secondary-navigation.component';
import { VersionNumberComponent } from './components/version-number/version-number.component';

@NgModule({
  declarations: [
    ContainerComponent,
    HeaderComponent,
    PrimaryNavigationComponent,
    NewsStoryComponent,
    NewsStoryListComponent,
    NavItemComponent,
    HeaderDetailsComponent,
    UserDetailsComponent,
    CountryOutlineComponent,
    AccountOverviewComponent,
    CookieConsentComponent,
    ProtectSummaryComponent,
    TopCountryComponent,
    ConnectSummaryComponent,
    ConnectStatusComponent,
    InspectSummaryComponent,
    AccountSummaryComponent,
    HomeComponent,
    FourohfourComponent,
    AccountSummaryCompactComponent,
    TermsConditionsComponent,
    SecondaryNavigationComponent,
    VersionNumberComponent,
  ],
  exports: [
    CookieConsentComponent,
    CountryOutlineComponent,
    TermsConditionsComponent,
    ConnectSummaryComponent,
    ProtectSummaryComponent,
    InspectSummaryComponent,
    AccountOverviewComponent,
    AccountSummaryComponent,
    AccountSummaryCompactComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    SharedModule,
    ChartsModule,
    InlineSVGModule.forRoot(),
    QRCodeModule,
  ],
  providers: [HttpCancelService],
})
export class CoreModule {}
