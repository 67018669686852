import { Component, ElementRef, HostListener } from '@angular/core';
import { openClose } from '@shared/animations/animations';
import { IconSize } from '@shared/models/icon.model';
import { environment } from '@environments/default/environment';
import { LogoSize } from '@shared/models/logo.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [openClose()],
})
export class HeaderComponent {
  public navOpen: boolean = false;
  public IconSize = IconSize;
  public LogoSize = LogoSize;
  public theme: string = environment.theme;

  /**
   * Add a listen to document that will set the menu to closed on click
   * */
  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    if (
      !this.elementRef.nativeElement
        .querySelector('.header__mobile-nav')
        .contains(event.target) &&
      this.navOpen
    ) {
      this.navOpen = false;
    }
  }

  constructor(private elementRef: ElementRef) {}

  toggleMenu() {
    this.navOpen = !this.navOpen;
  }
}
