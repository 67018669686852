import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ResourceRequest,
  BandwidthRequest,
  TrafficLogsExportsRequest,
} from '@shared/models/api-response/api-response-analytics.model';
import { NetworkOverviewRequest } from '@shared/models/api-response/api-response-connection.model';
import {
  FirewallAPIRequest,
  URLFilterAPIRequest,
} from '@shared/models/api-response/api-response-firewall.model';
import {
  RouteRequest,
  SSLVPNRequest,
} from '@shared/models/api-response/api-response-remote.model';
import { URLFilter } from '@shared/models/firewall.model';
import { Report, ReportRecord } from '@shared/models/report.model';
import { AccountStoreItem } from '@store/account-store-item.model';
import { TicketType, Ticket } from '@support/models/ticket';
import { DateTime } from 'luxon';
import { AccountsState } from '@store/accounts/accounts.reducer';
import * as fromStatus from '@store/status/status.reducer';

/* Selectors */
const selectAccountsFeatureState =
  createFeatureSelector<AccountsState>('accounts');

export const selectAllAccounts = createSelector(
  selectAccountsFeatureState,
  (state: AccountsState) => {
    return state.data;
  }
);

/* Selectors combining multiple data */
export const selectCurrentAccountData = createSelector(
  selectAllAccounts,
  fromStatus.selectCurrentAccountID,
  (accounts: AccountStoreItem[], id: string | null) => {
    return accounts?.find(
      (accountToFind: AccountStoreItem) => accountToFind.id === id
    );
  }
);

export const selectCompanyName = createSelector(
  selectCurrentAccountData,
  (account) => account?.companyName
);

export const selectCurrentAccountContacts = createSelector(
  selectCurrentAccountData,
  (account) => account?.contacts
);

export const selectResourceData = (resourceRequest: ResourceRequest) => {
  return createSelector(selectAllAccounts, (accounts: AccountStoreItem[]) => {
    const thisAccount =
      accounts &&
      accounts.find(
        (accountToFind: AccountStoreItem) =>
          accountToFind.id === resourceRequest.accountId
      );
    if (!thisAccount || !thisAccount.resourceData) {
      return null;
    }
    // Find store item that matches this request
    // Return false as early as possible to save compute time
    // N.B. 'resource' is called 'type' by API - however it's not possible to use 'type' in action resourceRequest
    return thisAccount.resourceData.find((thisData) => {
      if (resourceRequest.resource !== thisData.meta.type) {
        return false;
      }
      if (
        !resourceRequest.interval &&
        resourceRequest.start &&
        resourceRequest.end
      ) {
        // Start and end should be same hour
        if (
          resourceRequest.start.hasSame(
            DateTime.fromISO(thisData.meta.start),
            'hour'
          ) === false
        ) {
          return false;
        }
        if (
          resourceRequest.end.hasSame(
            DateTime.fromISO(thisData.meta.end),
            'hour'
          ) === false
        ) {
          return false;
        }
      } else {
        if (resourceRequest.interval !== thisData.meta.interval) {
          return false;
        }
      }
      // accountId should always match, but we'll do a check just in case
      if (resourceRequest.accountId !== thisData.meta.accountId) {
        return false;
      }
      // All the variables match - use this data
      return true;
    });
  });
};

export const selectTrafficLogsExports = ({
  accountId,
}: TrafficLogsExportsRequest) => {
  return createSelector(selectAllAccounts, (accounts: AccountStoreItem[]) => {
    const thisAccount =
      accounts &&
      accounts.find(
        (accountToFind: AccountStoreItem) => accountToFind.id === accountId
      );
    if (!thisAccount || !thisAccount.trafficLogsExports) {
      return;
    }
    return thisAccount.trafficLogsExports;
  });
};

export const selectFirewallData = ({
  accountId,
  firewallType,
}: FirewallAPIRequest) => {
  return createSelector(selectAllAccounts, (accounts: AccountStoreItem[]) => {
    const thisAccount =
      accounts &&
      accounts.find(
        (accountToFind: AccountStoreItem) => accountToFind.id === accountId
      );
    if (!thisAccount || !thisAccount.firewallRules) {
      return;
    }
    return thisAccount.firewallRules[firewallType];
  });
};

export const selectFirewallURLFilters = ({
  accountId,
  filterId,
}: URLFilterAPIRequest) => {
  return createSelector(selectAllAccounts, (accounts: AccountStoreItem[]) => {
    const thisAccount =
      accounts &&
      accounts.find(
        (accountToFind: AccountStoreItem) => accountToFind.id === accountId
      );
    const urlFilter =
      thisAccount?.urlFilters &&
      thisAccount?.urlFilters.find(
        (filter: URLFilter) => filter.id === filterId
      );
    return urlFilter;
  });
};

export const selectBandwidthData = (request: BandwidthRequest) => {
  return createSelector(selectAllAccounts, (accounts: AccountStoreItem[]) => {
    const thisAccount =
      accounts &&
      accounts.find(
        (accountToFind: AccountStoreItem) =>
          accountToFind.id === request.accountId
      );
    return thisAccount?.bandwidthData?.find((thisData) => {
      if (!request.interval && request.start && request.end) {
        // Start and end should be same hour
        if (
          request.start.hasSame(
            DateTime.fromISO(thisData.meta.start),
            'hour'
          ) === false
        ) {
          return false;
        }
        if (
          request.end.hasSame(DateTime.fromISO(thisData.meta.end), 'hour') ===
          false
        ) {
          return false;
        }
      } else if (request.interval !== thisData.meta.interval) {
        return false;
      }
      // accountId should always match, but we'll do a check just in case
      if (request.accountId !== thisData.meta.accountId) {
        return false;
      }
      // All the variables match - use this data
      return true;
    });
  });
};

export const selectRouteData = ({ accountId }: RouteRequest) => {
  return createSelector(selectAllAccounts, (accounts: AccountStoreItem[]) => {
    const thisAccount =
      accounts &&
      accounts.find(
        (accountToFind: AccountStoreItem) => accountToFind.id === accountId
      );
    if (!thisAccount || !thisAccount.routeData) {
      return null;
    }
    return thisAccount.routeData;
  });
};

export const selectSSLVPNData = ({ accountId }: SSLVPNRequest) => {
  return createSelector(selectAllAccounts, (accounts: AccountStoreItem[]) => {
    const thisAccount =
      accounts &&
      accounts.find(
        (accountToFind: AccountStoreItem) => accountToFind.id === accountId
      );

    return thisAccount?.sslVPNData;
  });
};

export const selectNetworkOverview = ({
  accountId,
  cacheBreak = false,
}: NetworkOverviewRequest) => {
  return createSelector(selectAllAccounts, (accounts: AccountStoreItem[]) => {
    const thisAccount =
      accounts &&
      accounts.find(
        (accountToFind: AccountStoreItem) => accountToFind.id === accountId
      );
    if (!thisAccount || !thisAccount.networkOverviewData) {
      return null;
    }
    return thisAccount.networkOverviewData;
  });
};

export const selectTicketsData = (
  accountId: string,
  type: TicketType = TicketType.INCIDENTS
) => {
  return createSelector(
    selectAllAccounts,
    (accounts: AccountStoreItem[]): Ticket[] | undefined => {
      const relevantAccount =
        accounts &&
        accounts.find(
          (relevantAccountData: AccountStoreItem) =>
            relevantAccountData.id === accountId
        );

      // return relevant tickets array OR undefined
      return relevantAccount?.supportTickets?.[type];
    }
  );
};

export const selectReportList = (accountId: string) => {
  return createSelector(
    selectAllAccounts,
    (accounts: AccountStoreItem[]): Report[] | undefined => {
      const thisAccount = accounts?.find(
        (account: AccountStoreItem) => account.id === accountId
      );
      // return relevant tickets array OR undefined
      return thisAccount?.reportList;
    }
  );
};

export const selectReports = (accountId: string) => {
  return createSelector(
    selectAllAccounts,
    (accounts: AccountStoreItem[]): ReportRecord[] | undefined => {
      const thisAccount = accounts?.find(
        (account: AccountStoreItem) => account.id === accountId
      );
      // return relevant tickets array OR undefined
      return thisAccount?.reports;
    }
  );
};
