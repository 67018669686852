import { Injectable } from '@angular/core';
import { map, mergeMap, Observable, of } from 'rxjs';

/* Services */
import { ApiService } from '@shared/services/api.service';

/* Models */
import {
  File,
  Report,
  ReportRecord,
  ReportStatus,
  ReportType,
} from '@shared/models/report.model';

/* Store */
import { Store } from '@ngrx/store';
import * as AccountActions from '@store/accounts/accounts.actions';
import * as fromAccounts from '@store/accounts/accounts.selectors';
import * as fromStatus from '@store/status/status.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { environment } from '@environments/default/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private accountId: string | undefined = '';

  constructor(
    private apiService: ApiService,
    private store: Store,
    private actions$: Actions
  ) {
    const currentAccount$ = this.store.select(
      fromStatus.selectCurrentAccountID
    );
    currentAccount$.subscribe((accountID) => {
      if (accountID) {
        this.accountId = accountID;
      }
    });
  }

  public getReportList(): Observable<Report[]> {
    if (!this.accountId) {
      throw new Error('please select an account');
    }
    const accountId = this.accountId;

    return this.store.select(fromAccounts.selectReportList(accountId)).pipe(
      mergeMap((reports: Report[] | undefined) => {
        if (!reports) {
          // get data from API
          this.store.dispatch(
            AccountActions.fetchReportList({
              accountId,
            })
          );
          // set data to store
          return this.actions$.pipe(
            ofType(AccountActions.SET_REPORT_LIST),
            map((action: { type: string; data: any[] }): any[] => {
              // return data from API response to set to store
              return action.data;
            })
          );
        } else {
          // return data from store
          return of(reports);
        }
      })
    );
  }

  public getReports(cacheBreak: boolean = false): Observable<ReportRecord[]> {
    if (!this.accountId) {
      throw new Error('please select an account');
    }
    const accountId = this.accountId;

    return this.store.select(fromAccounts.selectReports(accountId)).pipe(
      mergeMap((reports: ReportRecord[] | undefined) => {
        if (!reports || cacheBreak) {
          // get data from API
          this.store.dispatch(
            AccountActions.fetchReports({
              accountId,
            })
          );
          // set data to store
          return this.actions$.pipe(
            ofType(AccountActions.SET_REPORTS),
            map(
              (action: {
                type: string;
                data: ReportRecord[];
              }): ReportRecord[] => {
                // return data from API response to set to store
                return action.data;
              }
            )
          );
        } else {
          // return data from store
          return of(reports);
        }
      })
    );
  }

  public getFile(file: File): Observable<boolean> {
    return new Observable((observer) => {
      const ticketParams: any = {};
      if (this.accountId) {
        ticketParams.accountId = this.accountId;
      }
      this.apiService
        .get(`/support/reports/file/${file.tid}`, ticketParams, {
          responseType: environment.name === 'local' ? 'blob' : 'text',
        })
        .subscribe({
          next: (response: Blob | string) => {
            const blob = this.getBlobFromResponse(response);
            // build dummy anchor tag to download PDF
            const filename = file.name || 'report';
            const elem: HTMLAnchorElement = window.document.createElement('a');
            elem.href = URL.createObjectURL(blob);
            elem.download = `${filename}`;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
            observer.next(true);
            observer.complete();
          },
          error: (err) => {
            // Need this as response is returned as a blob
            const reader: FileReader = new FileReader();
            reader.onloadend = (e) => {
              const result = <string>reader.result;
              observer.error(JSON.parse(result));
            };
            reader.readAsText(err.error);
          },
        });
    });
  }

  /* The response of serverless-offline is somehow different from a deployed Lambda function's reponse */
  private getBlobFromResponse(response: string | Blob) {
    if (environment.name === 'local') {
      return response as Blob;
    } else {
      const byteCharacters = Buffer.from(response as string, 'base64');
      return new Blob([byteCharacters], {
        type: 'application/pdf',
      });
    }
  }

  public addReport(report: ReportRecord): Observable<ReportRecord> {
    return new Observable((observer) => {
      if (report.type === ReportType.IMMEDIATE) {
        // Remove schedule from immediate type
        report.schedule = undefined;
      }
      if (report.type === ReportType.SCHEDULED) {
        // Remove emails from scheudled type (just sends to self)
        report.emails = undefined;
      }
      // Quick validation
      // -1 is the value for the -- Please select -- option
      if (report.layoutId === -1) {
        observer.error({
          layoutId: ['Please select a report layout'],
        });
        return;
      }

      let path = '/support/reports';
      if (this.accountId) {
        path += `?accountId=${this.accountId}`;
      }
      this.apiService.post(path, report).subscribe({
        next: () => {
          const newReport = {
            ...report,
            ...{
              accountId: this.accountId,
              createdAt: new Date().getTime(),
              reportStatus:
                report.type === ReportType.IMMEDIATE
                  ? ReportStatus.PENDING
                  : ReportStatus.ENABLED,
            },
          };
          // Add the new report to the store
          this.store.dispatch(AccountActions.addReport({ report: newReport }));
          observer.next(newReport);
          observer.complete();
        },
        error: (err) => {
          observer.error(err);
        },
      });
    });
  }

  public getReport(reportId: string): Observable<ReportRecord | undefined> {
    // Get all reports from store and filter them
    return this.getReports().pipe(
      mergeMap((reports: ReportRecord[] | undefined) => {
        return of(
          reports?.find((report: ReportRecord) => {
            return report.id === reportId;
          })
        );
      })
    );
  }

  public scheduleReport(
    reportId: string,
    changeStatusTo: ReportStatus
  ): Observable<boolean> {
    if (!this.accountId) {
      throw new Error('please select an account');
    }
    const accountId = this.accountId;
    const apiCall =
      changeStatusTo === ReportStatus.ENABLED
        ? this.apiService.put(
            `/support/reports/reschedule/${reportId}?accountId=${accountId}`
          )
        : this.apiService.destroy(
            `/support/reports/unschedule/${reportId}?accountId=${accountId}`
          );
    return new Observable((observer) => {
      apiCall.subscribe({
        next: (response) => {
          // update store item
          this.store.dispatch(
            AccountActions.updateReportStatus({
              accountId,
              reportId,
              reportStatus: changeStatusTo,
            })
          );
          observer.next(true);
          observer.complete();
        },
        error: (err) => {
          observer.error(err);
        },
      });
    });
  }
}
