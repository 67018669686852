import { SupportHours } from '@support/models/support-hours';
import { Ticket } from '@support/models/ticket';
import { Feature } from './features';
/**
 * Account.
 * @property { string } companyName - company.
 * @property { string } companyShortName - company short name.
 * @property { string } id - account ID.
 * @property { string } bandwidth - bandwidth allowance.
 * @property { number } accountStatus - 0 = INITIAL, 1 = ACTIVE, 2 = FROZEN, 3 = CLOSED.
 * @property { string } supportId - ID of account in FreshService.
 * @property { string } stackId - stack ID
 * @property { VPNStack } vpnStack - ID of the VPN stack
 * @property { string } nvaId - nva ID
 * @property { 'prod' | 'dev'' } stage - (deprecated) stage to use
 * @property { StackLocation } stackLocation - location of the stack (on prem, on cloud)
 * @property { bool } disableRAR - whether to disable the Request A Rule feature
 * @property { AccountContact[] | null } contacts - list of contacts for the account
 * @property { SupportHours } supportHours - whether they have 24/7 or standard support hours
 * @property { string[] } hostnames - hostnames used for Libre
 * @property { boolean } mfaEnabled - is MFA enabled on the VPN accounts
 * @property { number } sslVpnLicenceCount - how many SSL VPN licences do they have?
 * @property { RemoteAccessManagement } vpnManagement - are their VPNs user-managed or customer-managed
 * @property { Ticket[] } supportTickets.incidents - array of incident tickets
 * @property { Ticket[] } supportTickets.sr - array of service request tickets
 * @property { number[] } productId - the product ID(s) assigned to this account
 * @property { string[] } featureId - the feature IDs assigned to this account
 */
export interface Account {
  companyName: string;
  companyShortName: string;
  id: string;
  bandwidth?: number;
  accountStatus?: AccountStatus;
  supportId?: string;
  stackId?: string;
  vpnStack?: VPNStack | null;
  nvaId?: string;
  stage?: 'prod' | 'dev';
  stackLocation: StackLocation;
  disableRAR: boolean;
  contacts: AccountContact[] | null;
  supportHours: SupportHours;
  hostnames?: string[];
  mfaEnabled?: boolean;
  sslVpnLicenceCount?: number;
  vpnManagement?: RemoteAccessManagement;
  supportTickets?: {
    incidents?: Ticket[];
    sr?: Ticket[];
  };
  productId?: number[];
  featureId?: string[];
}

export enum RemoteAccessManagement {
  USER = 'user',
  CUSTOMER = 'customer',
}
export enum VPNStack {
  HC = 'hcvpn001',
  OWN = 'own-stack',
}

export enum StackLocation {
  ON_PREM = 'on_prem',
  ON_CLOUD = 'on_cloud',
  VPN_ONLY = 'vpn_only',
}

export enum StackID {
  PRISM = 'prism',
  PVT = 'pvt',
  GOV = 'gov',
}

export enum AccountStatus {
  DISABLED = 0,
  ENABLED = 1,
  DELETED = 2,
}

export type AccountContact = {
  name: string;
  position: string;
  image: string;
  email: string;
};
