import { Component, Input } from '@angular/core';
import {
  ConnectionStatus,
  ConnectionStatusDetail,
  ConnectionsAltText,
} from '@shared/models/connection.model';
import { IconSize, IconStatus, IconType } from '@shared/models/icon.model';
import { Status } from '@shared/models/status.model';

@Component({
  selector: 'app-connect-status',
  templateUrl: './connect-status.component.html',
  styleUrls: ['./connect-status.component.scss'],
})
export class ConnectStatusComponent {
  @Input() status: ConnectionStatus = ConnectionStatus.UP;
  @Input() details: ConnectionStatusDetail[] = [];
  @Input() loadingStatus: Status = Status.LOADING;

  public ConnectionStatus = ConnectionStatus;
  public ConnectionsAltText = ConnectionsAltText;
  public IconType = IconType;
  public IconSize = IconSize;
  public IconStatus = IconStatus;
  public Status = Status;
}
