<app-widget class="support-tickets">
  <ng-container widget-title *ngIf="type === TicketType.SR">
    Service Requests
    <app-help-icon>
      <p>
        From here you can view a summary of your 'Service Request' support
        tickets.
      </p>
    </app-help-icon>
  </ng-container>
  <ng-container widget-title *ngIf="type === TicketType.INCIDENTS">
    Incidents
    <app-help-icon>
      <p>
        From here you can view a summary of your 'Incident' support tickets.
      </p>
    </app-help-icon>
  </ng-container>
  <div
    widget-content
    class="support-tickets__content support-tickets__content--{{
      ticketStatus
    }}"
  >
    <app-ticket-data
      [open]="openTickets"
      [pending]="pendingTickets"
      [resolved]="resolvedTickets"
      [closed]="closedTickets"
      [status]="ticketStatus"
      (raiseTicket)="raiseTicketClick()"
      (viewTickets)="viewTicketsClick()"
      [type]="type"
    ></app-ticket-data>
  </div>
</app-widget>
