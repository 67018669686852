import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

import { Permission } from '../models/permission.model';
import { PermissionsService } from '@shared/services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsResolverService implements Resolve<Permission[]> {
  constructor(private permissionsService: PermissionsService) {}

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<Permission[]> | Promise<Permission[]> | Permission[] {
    return this.permissionsService.checkPermissions();
  }
}
