import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountGuard } from '@shared/account.guard';

import { DashboardComponent } from '@core/pages/dashboard/dashboard.component';
/* Resolvers */
import { AccountsResolverService } from '@shared/resolvers/accounts-resolver.service';
import { Feature } from '@shared/models/features';
import { ContainerComponent } from '@core/widgets/container/container.component';
import { AuthGuard } from '@auth/services/auth.guard';
import { UserGuard } from '@shared/user.guard';
import { PermissionsResolverService } from '@shared/resolvers/permissions-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    canActivate: [AuthGuard, AccountGuard, UserGuard],
    canActivateChild: [AccountGuard],
    data: {
      title: 'Dashboard',
      id: Feature.DASHBOARD,
    },
    resolve: [AccountsResolverService, PermissionsResolverService],
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
