<div
  class="modal modal--{{ size }}"
  (click)="close($event)"
  id="modal"
  [@fadeIn]="status"
  (@fadeIn.done)="animationDone()"
  role="dialog"
>
  <div class="modal__wrapper" [@slideUp]="status">
    <div class="modal__inner">
      <div #modalHeader class="modal__header">
        <ng-content select="[modal-header]"></ng-content>
        <a
          *ngIf="modalHeader.children.length !== 0 && closeable"
          class="modal__close"
          id="close--header"
          role="button"
          tabindex="0"
          (keyup.enter)="close($event)"
        >
          <app-icon
            title="Close"
            [size]="IconSize.MEDIUM"
            [weight]="IconWeight.BOLD"
            >close
          </app-icon>
        </a>
      </div>
      <a *ngIf="closeable" class="modal__close" id="close--inner"
        ><app-icon
          title="Close"
          [size]="IconSize.MEDIUM"
          [weight]="IconWeight.BOLD"
          >close</app-icon
        ></a
      >
      <div class="modal__content">
        <div class="modal__subheader">
          <ng-content select="[modal-subheader]"></ng-content>
        </div>

        <div class="modal__body">
          <ng-content select="[modal-body]"></ng-content>
        </div>

        <div class="modal__footer">
          <ng-content select="[modal-footer]"></ng-content>
        </div>
      </div>
    </div>

    <div class="modal__confirmation">
      <ng-content select="[modal-confirmation]"></ng-content>
    </div>
  </div>
</div>
