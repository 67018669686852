<app-widget class="protect-summary">
  <ng-container widget-content>
    <app-icon-title class="protect-summary__header">
      <app-custom-icon
        icon
        [type]="CustomIcons.PROTECT"
        [ariaLabel]="'Protect'"
      ></app-custom-icon>
      Protect
    </app-icon-title>
    <div *appPermitted="'PROTECT__THREATS'" class="protect-summary__content">
      <h3 class="protect-summary__title">
        Top Threat Country Blocked (last 7 days)
      </h3>
      <app-top-country
        class="protect-summary__top-country"
        widget-content
        [country]="country"
        [value]="value"
        [status]="status"
      ></app-top-country>
    </div>
  </ng-container>
  <app-button
    widget-footer
    *appPermitted="'PROTECT__DASHBOARD'"
    [display]="ButtonDisplay.BLOCK"
    [type]="ButtonType.BUTTON"
    iconName="chevron_right"
    [iconPosition]="ButtonIconPosition.END"
    appRouterLink="protect"
    data-testid="view-protect"
    >View Protect</app-button
  >
</app-widget>
