import { Component, Input } from '@angular/core';
import { FileType } from '@shared/components/file-picker/filetype-map';
import { FilenamePipe } from '@shared/pipes/filename.pipe';

@Component({
  selector: 'app-view-files',
  templateUrl: './view-files.component.html',
  styleUrls: ['./view-files.component.scss'],
})
export class ViewFilesComponent {
  @Input() files?: string[];

  constructor(private filenamePipe: FilenamePipe) {}

  /**
   * Get the file icon to use for a file at a URL
   * @param url The URL of the file
   */
  public getFileIcon(url: string) {
    const filename = this.filenamePipe.transform(url);
    const extension = filename.split('.').pop();
    let icon = FileType.default.icon;
    if (extension && FileType[extension]) {
      icon = FileType[extension].icon;
    }
    return `${icon}`;
  }
}
