import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { FormFieldComponent } from '@forms/components/form-field/form-field.component';
import { FieldStatus, FieldType } from '@forms/models/form-field.model';
import { Status } from '@shared/models/status.model';

@Component({
  selector: 'app-mfa-code',
  templateUrl: './mfa-code.component.html',
  styleUrls: ['./mfa-code.component.scss'],
})
export class MfaCodeComponent
  implements AfterViewInit, OnChanges, AfterViewChecked
{
  public FieldType = FieldType;
  public FieldStatus = FieldStatus;
  public Status = Status;

  public enteredCode: (string | null)[] = [null, null, null, null, null, null];
  public fieldStatus: FieldStatus = FieldStatus.ACTIVE;

  private autoSelectInput: FormFieldComponent | null = null;

  @ViewChildren('codeInput')
  codeInputs!: QueryList<FormFieldComponent>;

  @Input() status: Status = Status.NOT_AVAILABLE;
  @Output() mfaCodeChange = new EventEmitter<string>();

  ngAfterViewInit(): void {
    const firstInput = this.codeInputs.first;
    this.autoSelectInput = firstInput;
  }

  ngAfterViewChecked(): void {
    if (this.autoSelectInput) {
      // Wait a tick before selecting the input.
      // Even if I had a check here if the input was "ACTIVE", it still didn't select
      setTimeout(() => {
        this.autoSelectInput?.select();
        this.autoSelectInput = null;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.status) {
      const { previousValue, currentValue } = changes.status;
      this.fieldStatus = this.fieldDisabled(currentValue)
        ? FieldStatus.DISABLED
        : FieldStatus.ACTIVE;
      // If status has changed from disabled to active, go to final code input
      if (
        this.fieldDisabled(previousValue) &&
        !this.fieldDisabled(currentValue)
      ) {
        const lastInput = this.codeInputs.last;
        this.autoSelectInput = lastInput;
      }
    }
  }

  // Should the MFA code fields be disabled?
  // CHecks if the Status is LOADING or COMPLETE
  private fieldDisabled(status: Status): boolean {
    return [Status.LOADING, Status.COMPLETE].includes(status);
  }

  public onDigitInput(
    digitEvent: {
      event: KeyboardEvent;
      source: FormFieldComponent;
    },
    index: number
  ) {
    const maxIndex = this.enteredCode.length;
    const allowedKeys = ['Backspace', 'Tab'];
    const { event } = digitEvent;
    const regex = /\d/;
    let key = event.key;

    if (!regex.test(key) && allowedKeys.indexOf(event.code) === -1) {
      const target = event.target as HTMLFormElement;
      this.enteredCode[index] = null;
      target.value = '';
      event.preventDefault();
      return;
    }

    let nextIndex = index + 1;

    if (event.code === 'Backspace') {
      this.enteredCode[index] = null;
      const currentInput = this.codeInputs.toArray()[index];
      currentInput.clear();
      nextIndex = Math.max(0, index - 1);
    } else if (event.code === 'Tab') {
      if (event.shiftKey === true) {
        // Go back one space
        nextIndex = Math.max(0, index - 1);
      } else {
        // Go forward one space
        nextIndex = Math.min(maxIndex, index + 1);
      }
    } else {
      this.enteredCode[index] = key;
    }

    if (nextIndex >= 0 && nextIndex < maxIndex) {
      this.onFocus(nextIndex);
    } else {
      this.sendCode();
    }
  }

  /**
   * Select the input when focused
   * @param index Index of the focused field
   */
  public onFocus(index: number) {
    const selectedInput = this.codeInputs.toArray()[index];
    setTimeout(() => {
      selectedInput.select();
    }, 50);
  }

  public sendCode() {
    const fullCode = this.enteredCode.join('');
    if (fullCode.length == 6) {
      this.mfaCodeChange.emit(fullCode);
    }
  }
}
