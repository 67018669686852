import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as defaultTheme from '../../themes/default.theme';
import '../../chart-types/rounded-donut.chart';
import { Status } from '@shared/models/status.model';
import { ChartSize } from '../../models/chart.model';
import {
  LegendItem,
  LegendSize,
  LegendPosition,
  LegendType,
} from '../../models/legend.model';
import {
  DonutChartDatapoint,
  DonutChartOptions,
  DonutType,
} from '../../models/donut.model';
import { ChartData } from 'chart.js';
import { formatNumber } from '@angular/common';
import { UtilitiesService } from '@shared/services/utilities.service';
import { BytesPipe, ByteUnit } from '@shared/pipes/bytes.pipe';

// How big should the smallest value on the chart be
// Used when formatting numbers for the chart
// The larger the chart, the smaller the smallest segment can be
const minValueLookup = {
  [ChartSize.LARGE]: 0.01,
  [ChartSize.MEDIUM]: 0.02,
  [ChartSize.SMALL]: 0.02,
};
@Component({
  selector: 'app-donut-chart-legend',
  templateUrl: './donut-chart-legend.component.html',
  styleUrls: ['./donut-chart-legend.component.scss'],
})
export class DonutChartLegendComponent implements OnChanges {
  @Input() data: DonutChartDatapoint[] = [];
  @Input() type: DonutType = DonutType.COUNT;
  @Input() status: Status = Status.LOADING;
  @Input() size: ChartSize = ChartSize.LARGE;
  @Input() legendSize: LegendSize = LegendSize.MEDIUM;
  @Input() legendPosition: LegendPosition = LegendPosition.BOTTOM;
  @Input() legendType: LegendType = LegendType.LIST;
  @Input() legendHeadings?: string[];
  @Input() canvasAriaLabel?: string;

  public Status = Status;
  public LegendSize = LegendSize;
  public LegendPosition = LegendPosition;
  public chartOptions: DonutChartOptions = {
    rowGap: false,
    columnGap: true,
    gapSize: 4,
    cornerRadius: 6,
    cutout: '75%',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        ...defaultTheme.tooltipSettings,
        multiKeyBackground: 'transparent',
        enabled: true,
        callbacks: {
          label: (tooltipItem: any) => {
            let returnString = '';
            const datasetIndex = tooltipItem.datasetIndex; // Index of data set
            const datapointIndex = tooltipItem.dataIndex; // Index of datapoint within that set
            if (datasetIndex === undefined || datapointIndex === undefined) {
              // No data
              return returnString;
            }
            const label = tooltipItem.label || '';
            const value = this.formatValue(this.data[datapointIndex].value);
            returnString += `${label}: ${value}`;
            return returnString;
          },
        },
      },
    },
  };
  public chartLabels: any[] = [];
  public chartData: ChartData<'roundedDonut', any, any> = {
    datasets: [],
    labels: [],
  };
  public legendItems: LegendItem[][] = [[]];

  constructor(
    private utilitiesService: UtilitiesService,
    private bytesPipe: BytesPipe,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type?.currentValue) {
      this.chartOptions.donutType = changes.type.currentValue;
    }
    if (changes.data?.currentValue) {
      this.data = changes.data.currentValue;
      // Deal with data
      const chartLabels: string[] = [];
      const chartData: number[] = [];
      this.data.forEach((result) => {
        chartLabels.push(result.aggregate);
        chartData.push(result.value);
      });

      // Get min and total values
      const minValue = Math.min(...chartData);
      const totalValue = chartData.reduce(
        (acc, datapoint) => acc + datapoint,
        0,
      );

      // Make gap smaller if diff in values is too big
      if (minValue < totalValue * minValueLookup[this.size]) {
        this.chartOptions.gapSize = 2;
        this.chartOptions = { ...this.chartOptions };
      }

      // Force all values to be at least a minimum percentage on chart
      const forceMin = Math.ceil(totalValue * minValueLookup[this.size]);
      this.chartData.datasets = [
        {
          data: chartData.map((datapoint) => {
            return Math.max(datapoint, forceMin);
          }),
        },
      ];
      this.chartData.labels = chartLabels;
      this.chartLabels = chartLabels;
      this.generateLegendItems();
    }
  }

  generateLegendItems(): void {
    const backgroundColors =
      defaultTheme.donutChartColors.backgroundColor || [];
    this.legendItems = [[]];
    this.data.forEach((dataset, i) => {
      const colorIndex = i % backgroundColors.length;
      const thisBackgroundColor = backgroundColors[colorIndex];
      const thisValue = this.formatValue(dataset.value);
      this.legendItems[0].push({
        value: thisValue,
        sortvalue: dataset.value,
        label: this.chartLabels[i] + '',
        swatchColor: thisBackgroundColor,
      });
    });
  }

  private formatValue(datapoint: number): string {
    let thisValue;
    switch (this.type) {
      case DonutType.COUNT:
        thisValue = formatNumber(datapoint, 'en-GB');
        break;
      case DonutType.DATA:
        thisValue = this.bytesPipe.transform(
          datapoint,
          true,
          2,
          'B',
          this.getDataUnit(datapoint) as ByteUnit,
        );
        break;
      case DonutType.PERCENT:
      default:
        thisValue = formatNumber(datapoint * 100, 'en-GB', '1.0-2') + '%';
        break;
    }
    return thisValue;
  }

  private getDataUnit(datapoint: number): string {
    const unitToUse = this.utilitiesService.checkUnitToUse(datapoint);
    switch (unitToUse) {
      case 'tera':
        return 'TB';
      case 'giga':
        return 'GB';
      case 'mega':
        return 'MB';
      case 'kilo':
        return 'KB';
      case 'unit':
      default:
        return 'B';
    }
  }
}
