import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { User } from '@shared/models/user';

import * as fromAccounts from '@store/accounts/accounts.selectors';

import { UserService } from '@shared/services/user.service';
import { CustomIcons, IconSize } from '@shared/models/icon.model';

import { mfaTypes } from '@shared/models/mfa-types';
import { Directions } from '@shared/models/directions.model';

@Component({
  selector: 'app-header-details',
  templateUrl: './header-details.component.html',
  styleUrls: ['./header-details.component.scss'],
})
export class HeaderDetailsComponent implements OnInit, OnDestroy {
  private userSubscription!: Subscription;
  private accountSubscription!: Subscription;
  public user!: User | null;
  public userName: string = '';
  public companyName$!: Observable<string | undefined>;

  public CustomIcons = CustomIcons;
  public IconSize = IconSize;
  public mfaTypes = mfaTypes;
  public usersCurrentPreferredMFA?: string;
  public tooltipVisible: boolean = false;
  public Directions = Directions;

  constructor(
    private userService: UserService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.userSubscription = this.userService
      .getCurrentUser()
      .subscribe((user) => {
        if (user) {
          this.user = user;
        } else {
          this.user = null;
        }
        this.userName = this.userService.getUserName(user);
        this.userService.getPreferredMFA().then((data) => {
          this.usersCurrentPreferredMFA = data;
        });
      });

    this.companyName$ = this.store.select(fromAccounts.selectCompanyName);
  }

  ngOnDestroy(): void {
    if (this.userSubscription) this.userSubscription.unsubscribe();
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
  }
}
