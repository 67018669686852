import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AccountContact } from '@shared/models/account';
import {
  ButtonDisplay,
  ButtonRole,
  ButtonType,
} from '@shared/models/button.model';

import * as fromAccounts from '@store/accounts/accounts.selectors';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-account-overview',
  templateUrl: './account-overview.component.html',
  styleUrls: ['./account-overview.component.scss'],
})
export class AccountOverviewComponent implements OnInit {
  public ButtonType = ButtonType;
  public ButtonRole = ButtonRole;
  public ButtonDisplay = ButtonDisplay;

  public accountContacts$!: Observable<AccountContact[] | null | undefined>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.accountContacts$ = this.store
      .select(fromAccounts.selectCurrentAccountContacts)
      .pipe(filter((val) => val?.length !== 0));
  }
}
