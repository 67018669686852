<span
  class="country-outline"
  [inlineSVG]="'/assets/img/countries/' + country + '.svg'"
  [fallbackSVG]="'/assets/img/World_map_-_low_resolution.svg'"
  [setSVGAttributes]="{
    width: '100%',
    height: '30rem',
    'aria-label': 'Country outline indicator',
    alt: 'Threat country infographic'
  }"
></span>
