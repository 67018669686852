import { Observable, Observer, of, mergeMap, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

/* Services */
import { ApiService } from '@shared/services/api.service';

/* Models */
import { NewIncident, NewTicket, Ticket, TicketType } from './models/ticket';
import { TicketComment } from './models/comment';
import {
  FirewallAddRequest,
  FirewallRuleRequest,
  FirewallModifyRequest,
  FirewallDeleteRequest,
  FirewallRuleChangeType,
} from '@shared/models/firewall-change-request.model';

import * as AccountActions from '@store/accounts/accounts.actions';
import * as fromAccounts from '@store/accounts/accounts.selectors';
import * as fromStatus from '@store/status/status.reducer';
import { ValidationError } from '@shared/models/error';
import { SupportValidationService } from './support-validation.service';
import { TicketStatus } from './models/ticket-status.constant';
import { environment } from '@environments/default/environment';
import { SupportHelperService } from './support-helper.service';

@Injectable({ providedIn: 'root' })
export class SupportService {
  private accountId?: string;

  constructor(
    private apiService: ApiService,
    private store: Store,
    private actions$: Actions,
    private supportHelperService: SupportHelperService,
    private supportValidationService: SupportValidationService
  ) {
    const currentAccount$ = this.store.select(
      fromStatus.selectCurrentAccountID
    );
    currentAccount$.subscribe((accountID) => {
      if (accountID) {
        this.accountId = accountID;
      }
    });
  }

  /**
   * Raise a ticket (type "incident")
   * @param {any} data - ticket data.
   * @returns {Observable<Ticket>} observer to subscribe to for result.
   */
  public raiseIncident(ticket: NewIncident): Observable<Ticket> {
    return new Observable((observer) => {
      const validation = this.supportValidationService.validateIncident(ticket);
      if (validation !== true) {
        observer.error(validation);
      } else {
        const formedIncident: Ticket = this.formIncident(ticket);
        this.apiService.post('/support/incidents', formedIncident).subscribe({
          next: (response) => {
            observer.next(response);
            observer.complete();
          },
          error: (response) => {
            observer.error(response);
          },
        });
      }
    });
  }

  private formIncident(ticket: NewIncident): Ticket {
    const {
      description,
      affected_users,
      available_to_join,
      dstip,
      impact,
      srcip,
      related_to_connectivity,
      ...request
    } = ticket;
    const isAvailable = available_to_join ? `Yes` : 'No';
    const newDescription = `
    ${ticket.priority === 1 ? `Available to Join: ${isAvailable}<br/>` : ''}
    Description: ${description}<br/>
    Impact: ${impact}<br/>
    ${affected_users ? `Affected Users: ${affected_users}<br/>` : ''}
    ${related_to_connectivity ? `Issue is related to connectivity.<br/>` : ''}
    ${related_to_connectivity && dstip ? `Destination IP: ${dstip}<br/>` : ''}
    ${related_to_connectivity && srcip ? `Source IP: ${srcip}<br/>` : ''}
    `;
    return { ...request, ...{ description: newDescription } };
  }

  // TODO: [SIL-207] pull in requester and add where needed
  /**
   * Get ticket by ID
   * @param {string} id ID of the ticket
   * @returns {Observable<Ticket>} observer to subscribe to for result.
   */
  public getTicket(id: string): Observable<Ticket> {
    // get current-account from local storage to pass accountId for non-single account users
    const ticketParams: any = {};
    if (this.accountId) {
      ticketParams.accountId = this.accountId;
    }
    return new Observable((observer) => {
      this.apiService.get(`/support/tickets/${id}`, ticketParams).subscribe({
        next: (response) => {
          // Add ticket data with additional details to data store
          // API sends them as oldest first, we want newest first.
          response.data.comments = response.data.comments.reverse();
          observer.next(response.data);
          observer.complete();
        },
        error: (response) => {
          if (response?.code) {
            observer.error(response.message);
            observer.complete();
          } else {
            observer.error('That ticket is not available');
            observer.complete();
          }
        },
      });
    });
  }

  /**
   * Get all tickets
   * @param {TicketType} type type of tickets to get (incident/sr)
   * @returns {Observable<Ticket[]>} observer to subscribe to for result.
   */
  public getTickets(
    type: TicketType = TicketType.INCIDENTS,
    accountId?: string
  ): Observable<Ticket[]> {
    const thisAccountId = accountId || this.accountId;
    if (!thisAccountId) {
      throw new Error('please select an account');
    }

    return this.store
      .select(fromAccounts.selectTicketsData(thisAccountId, type))
      .pipe(
        mergeMap((relevantTickets: Ticket[] | undefined) => {
          if (!relevantTickets) {
            // get data from API
            this.store.dispatch(
              AccountActions.fetchTickets({
                accountId: thisAccountId,
                ticketType: type,
              })
            );
            // set data to store
            return this.actions$.pipe(
              ofType(AccountActions.SET_TICKETS),
              map((action: { type: string; data: Ticket[] }): any[] => {
                // return data from API response (tickets array)
                // to set to store
                return action.data;
              })
            );
          } else {
            // return data from store
            return of(relevantTickets);
          }
        })
      );
  }

  /**
   *
   * @param { TicketComment }       comment          Comment to add
   * @param { string }              comment.comment  Text body of comment to add
   * @param { string[] }            comment.file     Any additional files to upload
   * @param { string }              comment.author   Author of ticket
   * @param { string }              comment.date     Date of ticket
   * @param { string }              comment.ticketId ID of ticket
   */
  addComment(
    comment: TicketComment
  ): Observable<{ data: TicketComment } | string> {
    return new Observable(
      (observer: Observer<{ data: TicketComment } | string>) => {
        const validation =
          this.supportValidationService.validateComment(comment);
        if (validation !== true) {
          observer.error(validation);
          observer.complete();
        } else {
          this.apiService.post('/support/comment', comment).subscribe({
            next: (response) => {
              if (
                !comment.author.email?.includes('@cloudgateway.co.uk') ||
                !environment.production
              ) {
                this.store.dispatch(
                  AccountActions.updateTicketStatus({
                    accountId: this.accountId as string,
                    ticketId: comment.ticketId as string,
                    ticketStatus: TicketStatus.OPEN,
                  })
                );
              }
              observer.next(response);
              observer.complete();
            },
            error: (response) => {
              observer.error(response);
              observer.complete();
            },
          });
        }
      }
    );
  }

  /**
   *
   * @param { FirewallAddRequest } firewallChangesRequest  The collated data of a firewall change request
   *
   * */
  public requestFirewallRuleChanges(
    firewallChangesRequest: FirewallRuleRequest[]
  ): Observable<Ticket | ValidationError> {
    let description = '';
    let cc_emails: string[] = [];
    let emails = '';
    let valid: boolean | ValidationError = true;

    firewallChangesRequest.forEach((changeRequest) => {
      switch (changeRequest.type) {
        case FirewallRuleChangeType.ADD:
          const typedChangeRequest = changeRequest as FirewallAddRequest;

          valid =
            this.supportValidationService.validateFirewallAddRequest(
              typedChangeRequest
            );

          if (valid === true) {
            if (description !== '') {
              description += '<br />';
            }

            description +=
              this.supportHelperService.getFormattedAddRuleRequest(
                typedChangeRequest
              );

            // Add cc_emails to request-level emails
            if (emails !== '') {
              emails += ',';
            }
            emails += typedChangeRequest.cc_emails;
          }

          break;
        case FirewallRuleChangeType.MODIFY:
          valid = this.supportValidationService.validateFirewallModifyRequest(
            changeRequest as FirewallModifyRequest
          );

          if (valid === true) {
            if (description !== '') {
              description += '<br />';
            }

            description +=
              this.supportHelperService.getFormattedModifyRuleRequest(
                changeRequest as FirewallModifyRequest
              );
          }

          break;
        case FirewallRuleChangeType.DELETE:
        default:
          valid = this.supportValidationService.validateFirewallDeleteRequest(
            changeRequest as FirewallDeleteRequest
          );

          if (valid === true) {
            if (description !== '') {
              description += '<br />';
            }

            description +=
              this.supportHelperService.getFormattedDeleteRuleRequest(
                changeRequest as FirewallDeleteRequest
              );
          }

          break;
      }
    });

    if (valid !== true) {
      return new Observable((observer: Observer<ValidationError>) => {
        observer.error(valid);
        observer.complete();
      });
    }

    // Get array of emails without duplicates
    cc_emails = this.consolidateEmailsToArray(emails);

    const ticket: NewTicket = {
      description,
      title: 'Firewall / UTM Policy Change',
      accountId: firewallChangesRequest[0].accountId,
      file: [],
      cc_emails,
    };

    return this.raiseServiceRequest(ticket);
  }

  public raiseServiceRequest(ticket: Ticket): Observable<Ticket> {
    return new Observable((observer) => {
      const validation = this.supportValidationService.validateSR(ticket);
      if (validation !== true) {
        observer.error(validation);
      } else {
        this.apiService.post('/support/service-requests', ticket).subscribe({
          next: (response) => {
            observer.next(response);
            observer.complete();
          },
          error: (response) => {
            observer.error(response);
          },
        });
      }
    });
  }

  public formatServiceRequestTicket(ticket: Ticket, usersName: string): Ticket {
    // values not returned from the API set here
    // including default values and current user's name as the requester

    // ticket is open
    ticket.resolved_at = null;
    ticket.closed_at = null;
    ticket.status = 2;
    // ticket open date is "now"
    const now = new Date();
    ticket.opened_at = now.toISOString();
    // "Moderate" priority by default
    ticket.priority = 3;
    // requester is current user
    ticket.requester = {
      name: usersName,
    };

    return ticket;
  }

  /**
   * Returns an array of emails without duplicates
   */
  public consolidateEmailsToArray(ccEmails: string): string[] {
    if (!ccEmails) {
      return [];
    }

    // Split emails into an array
    let emails: string[] = ccEmails?.replace(/\s/g, '').split(',') ?? undefined;

    if (emails.length) {
      // Remove duplicates
      let seen: { [key: string]: boolean } = {};
      emails = emails.filter((email) => {
        if (seen[email]) {
          return false;
        } else {
          seen[email] = true;

          return true;
        }
      });

      // Reform into string
      return emails;
    } else {
      return [];
    }
  }
}
