<span
  class="country-flag country-flag--{{ size }}"
  [title]="country?.toUpperCase()"
  [inlineSVG]="'/assets/img/flags/' + country + '.svg'"
  [fallbackSVG]="'/assets/img/not_listed_location_white_24dp.svg'"
  [setSVGAttributes]="{
    class: 'country-flag__svg',
    'aria-label': 'Country flag indicator',
    alt: country?.toUpperCase()
  }"
></span>
