import { environment } from '@environments/default/environment';
import { Injectable } from '@angular/core';
import { CognitoService } from '@auth/services/cognito.service';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MediaService {
  /**
   * The base URL of the Media Bucket
   *
   * @type { string }
   */
  public mediaBucketUrl = environment.media_url;

  /**
   * MediaService constructor
   *
   * @param { SoakCognitoService } cognito - provider for cognito servies.
   */
  constructor(private cognito: CognitoService, private http: HttpClient) {}

  /**
   * Send a fetch request with headers and open file in a new window
   *
   * @param { string } path - path to send request to.
   */
  public open(path: string) {
    this.getFileFromBucket(path, (response: any) => {
      const newWindow = window.open();
      if (newWindow) {
        newWindow.location.href = window.URL.createObjectURL(response);
      }
    });
  }

  /**
   * Find the media bucket URL and start to fetch the file
   *
   * @param { string } path - path to send get request to.
   * @param { string } filename - name of the file.
   */
  public download(path: string, filename: string) {
    this.getFileFromBucket(path, (response: any) => {
      let dataType = response.type;
      let binaryData = [];
      binaryData.push(response);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      if (filename) {
        downloadLink.setAttribute('download', filename);
      }
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }

  private getFileFromBucket(path: string, callback: Function) {
    const url = `${this.mediaBucketUrl}${path}`;
    this.http
      .get(url, {
        ...this.getHeaders(),
        ...{
          responseType: 'blob',
        },
      })
      .subscribe((response) => {
        callback(response);
      });
  }

  /**
   * Prepare headers
   *
   * @return {headers}
   */
  private getHeaders(): { headers: {} } {
    return {
      headers: {
        Authorization: this.cognito.getAmazonCredentials(),
      },
    };
  }
}
