import { Component, Input } from '@angular/core';
import { AddressLocation, FirewallRule } from '@shared/models/firewall.model';
import { FontWeight } from '@shared/models/font';

@Component({
  selector: 'app-firewall-rule-information',
  templateUrl: './firewall-rule-information.component.html',
  styleUrls: ['./firewall-rule-information.component.scss'],
})
export class FirewallRuleInformationComponent {
  @Input() ruleData!: FirewallRule;

  public AddressLocation = AddressLocation;
  public FontWeight = FontWeight;
}
