import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { take } from 'rxjs/operators';
import { FileType } from './filetype-map';
import {
  FileOptions,
  FilePreviewModel,
  FileValidationTypes,
  UploadResponse,
  UploadStatus,
  ValidationError,
} from '../../models/file-options';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { ButtonRole, ButtonType } from '@shared/models/button.model';
import { IconSize } from '@shared/models/icon.model';
import { Observable } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { BytesPipe } from '@shared/pipes/bytes.pipe';

@Component({
  selector: 'app-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
})
export class FilePickerComponent implements OnInit {
  @Input() fileOptions!: FileOptions;
  @Input() uploadError?: string;
  @Input() label?: string;
  @Output() uploadSuccess = new EventEmitter<FilePreviewModel>();
  @Output() uploadFail = new EventEmitter<HttpErrorResponse>();
  @Output() removeSuccess = new EventEmitter<FilePreviewModel>();

  public supportingFiles: string[] = [];
  public files: NgxFileDropEntry[] = [];
  public fileTypes: string = '';
  public ButtonRole = ButtonRole;
  public ButtonType = ButtonType;
  public IconSize = IconSize;
  public UploadStatus = UploadStatus;

  public uploadedFiles: FilePreviewModel[] = [];

  public endpoint?: string;

  constructor(private apiService: ApiService, private bytesPipe: BytesPipe) {}

  ngOnInit() {
    this.fileTypes = this.fileOptions.fileTypes
      .map((fileType) => `.${fileType}`)
      .join();
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    if (this.files.length > this.fileOptions.fileCount) {
      this.onValidationError({ error: FileValidationTypes.fileMaxCount });
      return;
    }
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        const fileSizeMb = Number(
          this.bytesPipe.transform(file.size, false, 2, 'B', 'MB')
        );
        if (fileSizeMb > this.fileOptions.fileSize) {
          this.onValidationError({
            error: FileValidationTypes.fileMaxSize,
            file,
          });
          return;
        }
        this.uploadFile(file).subscribe({
          next: (data: any) => {
            const foundFile = this.uploadedFiles.find(
              (fileObj) => fileObj.file.name === file.name
            );
            if (!foundFile) {
              this.uploadedFiles.push({ uploadResponse: data, file });
            } else {
              foundFile.uploadResponse = data;
              foundFile.file = file;
              if (data.status == UploadStatus.UPLOADED) {
                this.uploadSuccess.emit({ uploadResponse: data, file });
              }
            }
          },
          error: (err: UploadResponse) => {
            this.uploadError = err.body.message;
          },
        });
      });
    }
  }

  public onValidationError(error: ValidationError) {
    switch (error.error) {
      case FileValidationTypes.fileMaxSize:
        this.uploadError = `${error.file?.name} exceeds the maximum size of ${this.fileOptions.fileSize}MB.`;
        break;
      case FileValidationTypes.fileMaxCount:
        this.uploadError = `You have exceeded the maximum amount of files (${this.fileOptions.fileCount}).`;
        break;
      case FileValidationTypes.extensions:
        this.uploadError = `${
          error.file?.name
        } is the wrong type of file (allowed file types: ${this.fileOptions.fileTypes.join(
          ', '
        )}).`;
        break;
      default:
        this.uploadError = `${error.file?.name} does not match its file extension (expected ${error.file?.type}).`;
        break;
    }
  }

  public getIcon(file: File): string {
    const extension = file.name.split('.').pop();
    let icon = FileType.default.icon;
    if (extension && FileType[extension]) {
      icon = FileType[extension].icon;
    }

    return `${icon}`;
  }

  /**
   * Upload a file to the API
   * @param fileItem The file item to upload
   */
  public uploadFile(fileItem: File) {
    const fileName = `${Date.now()}_${fileItem.name}`;
    return new Observable((observer) => {
      const formData = new FormData();
      formData.append('directory', this.fileOptions.directory);
      formData.append('fileName', fileName);
      formData.append('file', fileItem);
      this.apiService
        .postFile(this.fileOptions.endpoint ?? '/settings/upload', formData)
        .subscribe({
          next: (result: any) => {
            if (result.type === HttpEventType.UploadProgress) {
              const percentDone = Math.round(
                (100 * result.loaded) / result.total
              );
              observer.next({
                status: UploadStatus.IN_PROGRESS,
                progress: percentDone,
                fileName,
              });
            } else if (result.type === HttpEventType.Response) {
              observer.next({
                body: result.body,
                status: UploadStatus.UPLOADED,
                fileName,
              });
              observer.complete();
            }
          },
          error: (err: any) => {
            if (err.error && err.error.data && err.error.data.message) {
              // Error is sent from API = pass through error object to add to file's uploadResponse
              const errorBody = {
                code: 500,
                message: err.error.data.message,
              };
              observer.error({
                body: errorBody,
                status: UploadStatus.ERROR,
              });
            } else {
              observer.error({
                body: {
                  code: 500,
                  message: `There was a problem uploading your file: ${err.name} - ${err.statusText}`,
                },
                status: UploadStatus.ERROR,
              });
            }
            observer.complete();
          },
        });
    });
  }

  /**
   * Remove the file via the API
   *
   * @param fileItem The file item to delete
   */
  public removeFile(fileItem: FilePreviewModel, index: number) {
    const fileName = encodeURIComponent(fileItem.uploadResponse.fileName);
    const directory = encodeURIComponent(this.fileOptions.directory);
    return this.apiService
      .deleteFile(
        `/settings/remove?fileName=${fileName}&directory=${directory}`
      )
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.removeSuccess.emit(this.uploadedFiles[index]);
          this.uploadedFiles.splice(index, 1);
        },
      });
  }
}
