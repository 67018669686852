<h3 class="firewall-action__title">Action</h3>
<ng-container *ngIf="data === FirewallAction.accept">
  <h4 class="firewall-action__subtitle">
    <app-icon
      [size]="IconSize.MEDIUM"
      [type]="IconType.OUTLINED"
      [status]="IconStatus.SUCCESS"
      title="Allow"
      >done</app-icon
    >Allow
  </h4>
  <p class="firewall-action__text">This rule allows matching traffic.</p>
</ng-container>
<ng-container *ngIf="data === FirewallAction.deny">
  <h4 class="firewall-action__subtitle">
    <app-icon
      [size]="IconSize.MEDIUM"
      [type]="IconType.OUTLINED"
      [status]="IconStatus.ERROR"
      title="Deny"
      >close</app-icon
    >Deny
  </h4>
  <p class="firewall-action__text">This rule stops matching traffic.</p>
</ng-container>
