import { Component, Input } from '@angular/core';
import { PillSize, PillStatus } from '@shared/models/pill.model';

@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
})
export class PillComponent {
  @Input() type: PillStatus = PillStatus.DEFAULT;
  @Input() size: PillSize = PillSize.MEDIUM;

  constructor() {}
}
