import { ArcElement, DoughnutController } from 'chart.js';
import { DonutChartOptions, DonutType } from '../models/donut.model';
import { StylesService } from '@shared/services/styles.service';
import { AnyObject } from 'chart.js/dist/types/basic';

const stylesService = new StylesService();

export class RoundedDonutController extends DoughnutController {
  draw() {
    const thisChart = this.chart;
    const ctx = thisChart.ctx;
    const options = Object.assign(
      RoundedDonutController.defaults as AnyObject,
      <DonutChartOptions>this.chart.config.options
    );
    const rowGap = options.rowGap === true ? options.gapSize ?? 0 : 0;
    const columnGap = options.columnGap === true ? options.gapSize ?? 0 : 0;
    const radian = Math.PI / 2;
    const { data } = this.getMeta();

    data.forEach((arc: any) => {
      arc = <ArcElement>arc;
      let cornerRadius = options.cornerRadius ?? 0;
      const minimumSliceWidth = cornerRadius * 2;
      let thisColumnGap = columnGap;
      const innerRadius = arc.innerRadius + rowGap; // To make gap between multidatasets
      const thickness = arc.outerRadius - innerRadius;
      // If corner radius is too big, max it out at 50% of thickness
      if (thickness < cornerRadius * 2) {
        cornerRadius = thickness / 2;
      }

      // If length of pie piece isn't big enough for corners, reduce them
      const thisCircumference = arc.circumference * innerRadius;
      if (thisCircumference < thisColumnGap + cornerRadius * 2) {
        cornerRadius = (thisCircumference - thisColumnGap) / 2;
        if (cornerRadius < 0) {
          // Still not enough space - reduce thisColumnGap (clamp to 1)
          cornerRadius = 0;
          thisColumnGap = Math.max(thisCircumference - minimumSliceWidth, 1);
        }
      }

      // gapSizeRadians = thisColumnGap in radians
      const gapSizeRadiansInner = thisColumnGap / innerRadius;
      const gapSizeRadiansOuter = thisColumnGap / arc.outerRadius;
      // cornerRadians = cornerRadius in radians
      const cornerRadiansInner = cornerRadius / innerRadius;
      const cornerRadiansOuter = cornerRadius / arc.outerRadius;

      // Variables in use below
      const startAngleInner = radian - arc.startAngle - gapSizeRadiansInner;
      const endAngleInner = radian - arc.endAngle + gapSizeRadiansInner;
      const startAngleOuter = radian - arc.startAngle - gapSizeRadiansOuter;
      const endAngleOuter = radian - arc.endAngle + gapSizeRadiansOuter;
      const arcStartInner =
        arc.startAngle + gapSizeRadiansInner + cornerRadiansInner;
      const arcEndInner =
        arc.endAngle - gapSizeRadiansInner - cornerRadiansInner;
      const arcStartOuter =
        arc.startAngle + gapSizeRadiansOuter + cornerRadiansOuter;
      const arcEndOuter =
        arc.endAngle - gapSizeRadiansOuter - cornerRadiansOuter;

      ctx.save();
      // Draw the complete, faded tracks behind first
      if (options.donutType === DonutType.PERCENT) {
        ctx.fillStyle =
          stylesService.styles[
            `--donut-chart__track-${arc.$context.dataIndex}`
          ];
        ctx.beginPath();
        ctx.arc(arc.x, arc.y, arc.outerRadius, 0, Math.PI * 2, true); // Outer circle
        ctx.arc(arc.x, arc.y, innerRadius, 0, Math.PI * 2, false); // Inner circle
        ctx.closePath();
        ctx.fill();
      }
      ctx.translate(arc.x, arc.y); // Measurements based on centre of grid
      ctx.fillStyle = arc.options.backgroundColor;
      // Whole path
      ctx.beginPath();
      ctx.moveTo(
        arc.outerRadius * Math.sin(startAngleOuter - cornerRadiansOuter),
        arc.outerRadius * Math.cos(startAngleOuter - cornerRadiansOuter)
      );
      // Corner 1
      ctx.quadraticCurveTo(
        arc.outerRadius * Math.sin(startAngleOuter),
        arc.outerRadius * Math.cos(startAngleOuter),
        (arc.outerRadius - cornerRadius) * Math.sin(startAngleOuter),
        (arc.outerRadius - cornerRadius) * Math.cos(startAngleOuter)
      );
      ctx.lineTo(
        (innerRadius + cornerRadius) * Math.sin(startAngleOuter),
        (innerRadius + cornerRadius) * Math.cos(startAngleOuter)
      );
      // Corner 2
      ctx.quadraticCurveTo(
        innerRadius * Math.sin(startAngleInner),
        innerRadius * Math.cos(startAngleInner),
        innerRadius * Math.sin(startAngleInner - cornerRadiansInner),
        innerRadius * Math.cos(startAngleInner - cornerRadiansInner)
      );
      // Inner arc
      ctx.arc(
        0,
        0,
        innerRadius,
        arcStartInner,
        arcEndInner,
        arcStartInner > arcEndInner
      );
      // Corner 3
      ctx.quadraticCurveTo(
        innerRadius * Math.sin(endAngleInner),
        innerRadius * Math.cos(endAngleInner),
        (innerRadius + cornerRadius) * Math.sin(endAngleInner),
        (innerRadius + cornerRadius) * Math.cos(endAngleInner)
      );
      ctx.lineTo(
        (arc.outerRadius - cornerRadius) * Math.sin(endAngleOuter),
        (arc.outerRadius - cornerRadius) * Math.cos(endAngleOuter)
      );
      // Corner 4
      ctx.quadraticCurveTo(
        arc.outerRadius * Math.sin(endAngleOuter),
        arc.outerRadius * Math.cos(endAngleOuter),
        arc.outerRadius * Math.sin(endAngleOuter + cornerRadiansOuter),
        arc.outerRadius * Math.cos(endAngleOuter + cornerRadiansOuter)
      );
      // Outer arc
      ctx.arc(
        0,
        0,
        arc.outerRadius,
        arcEndOuter,
        arcStartOuter,
        arcStartOuter < arcEndOuter
      );

      ctx.closePath();
      ctx.fill();
      ctx.restore();
    });
  }
}

RoundedDonutController.id = 'roundedDonut';
RoundedDonutController.defaults = DoughnutController.defaults || {};
RoundedDonutController.defaults.cornerRadius = 6;
RoundedDonutController.defaults.gapSize = 4;
RoundedDonutController.defaults.columnGap = true;
RoundedDonutController.defaults.rowGap = true;
RoundedDonutController.defaults.cutout = '75%';
RoundedDonutController.defaults.donutType = DonutType.COUNT;

// declare module 'chart.js' {
//   interface ChartTypeRegistry {
//     roundedDonut: ChartTypeRegistry['doughnut'];
//   }
// }
