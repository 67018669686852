import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';

import { TicketDataLayout } from '@shared/components/ticket-data/ticket-data-layout.datatype';
import { StackLocation } from '@shared/models/account';
import { ButtonDisplay, ButtonIconPosition } from '@shared/models/button.model';
import { Feature } from '@shared/models/features';
import { Status } from '@shared/models/status.model';
import { AccountService } from '@shared/services/account.service';
import { BandwidthRequest } from '@shared/models/api-response/api-response-analytics.model';
import { DataService } from '@shared/services/data.service';

import { Ticket, TicketType } from '@support/models/ticket';
import { BandwidthDatapoint } from '@charts/models/bandwidth.model';
import { SupportService } from '@support/support.service';

import { AccountStoreItem } from '@store/account-store-item.model';
import { ChartSize } from '@charts/models/chart.model';
import { filter, take } from 'rxjs/operators';
import { ModalService } from '@shared/services/modal.service';
import { IconSize } from '@shared/models/icon.model';
import { openClose } from '@shared/animations/animations';
import { TicketStatus } from '@support/models/ticket-status.constant';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account-summary-compact',
  templateUrl: './account-summary-compact.component.html',
  styleUrls: ['./account-summary-compact.component.scss'],
  animations: [openClose()],
})
export class AccountSummaryCompactComponent implements OnDestroy {
  @Input() account!: AccountStoreItem;

  public bandwidthData: BandwidthDatapoint[] = [];
  public bandwidthStatus: Status = Status.LOADING;

  public ButtonDisplay = ButtonDisplay;
  public ButtonIconPosition = ButtonIconPosition;
  public TicketDataLayout = TicketDataLayout;
  public ChartSize = ChartSize;
  public IconSize = IconSize;

  public Status = Status;
  public supportStatus: Status = Status.LOADING;

  public openTickets: number = 0;
  public closedTickets: number = 0;

  private dataLoaded: boolean = false;
  public hasDetails: boolean = false;

  private ticketSubscription?: Subscription;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private supportService: SupportService,
    private dataService: DataService,
    private modalService: ModalService
  ) {}

  public raiseIncident(): void {
    this.modalService.raiseIncident(this.account.id);
  }

  public viewTickets(): void {
    this.accountService.updateLoadedAccountSubject(this.account.id);
    this.router.navigateByUrl('/support/incidents');
  }

  private getData(): void {
    this.loadSupportTicketData();
    this.loadBandwidthData();
  }

  private loadSupportTicketData(): void {
    this.supportStatus = Status.LOADING;
    if (!this.account.supportId || this.account.supportId === '0') {
      this.supportStatus = Status.NO_DATA;
      return;
    }

    // "incidents" hardcoded because this component only displays incidents ticket type
    this.ticketSubscription = this.supportService
      .getTickets(TicketType.INCIDENTS, this.account.id)
      .subscribe({
        next: (incidents: Ticket[]) => {
          const openIncidents = incidents.filter(
            (ticket: Ticket) => ticket.status === TicketStatus.OPEN
          );

          const closedIncidents = incidents.filter(
            (ticket: Ticket) => ticket.status === TicketStatus.CLOSED
          );

          this.openTickets = openIncidents.length;
          this.closedTickets = closedIncidents.length;
          this.supportStatus = Status.COMPLETE;
        },
        error: () => {
          this.supportStatus = Status.ERROR;
        },
      });
  }

  private loadBandwidthData(): void {
    if (this.account.stackLocation === StackLocation.VPN_ONLY) {
      this.bandwidthStatus = Status.NOT_AVAILABLE;
    } else {
      this.bandwidthStatus = Status.LOADING;
      const request: BandwidthRequest = {
        accountId: this.account.id,
        interval: '30days',
        groupBy: '2h',
      };
      this.dataService
        .getBandwidthData(request)
        .pipe(
          filter((val) => val !== null),
          take(1)
        )
        .subscribe({
          next: (data) => {
            if (data) {
              this.bandwidthData = data;
              this.bandwidthStatus = Status.COMPLETE;
            }
          },
          error: () => {
            this.bandwidthStatus = Status.ERROR;
          },
        });
    }
  }

  public viewDetails() {
    this.accountService.updateLoadedAccountSubject(this.account.id);
    if (this.account.stackLocation === StackLocation.VPN_ONLY) {
      this.router.navigateByUrl('/connect/remote-access');
    } else {
      this.router.navigateByUrl(Feature.DASHBOARD);
    }
  }

  public viewMore() {
    this.hasDetails = !this.hasDetails;
    if (!this.dataLoaded) {
      this.getData();
      this.dataLoaded = true;
    }
  }

  ngOnDestroy() {
    this.ticketSubscription?.unsubscribe();
  }
}
