import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { Permission } from '@shared/models/permission.model';
import * as PermissionsActions from './permissions.actions';
import * as StatusActions from '../status/status.actions';

/* Initial state */
export interface PermissionsState {
  loaded: boolean;
  data: Permission[];
}

export const initialState: PermissionsState = {
  loaded: false,
  data: [],
};

/* Setup reducers */
export const reducer = (
  state: PermissionsState | undefined,
  action: Action
) => {
  return _reducer(state, action);
};

const setPermissions = (
  state: PermissionsState,
  action: { type: string; permissions: Permission[] }
) => {
  if (!action.permissions) {
    return state;
  }
  const newPermissions = [...action.permissions];
  newPermissions.sort((a: Permission, b: Permission) => (a.id > b.id ? 1 : -1));
  return { loaded: true, data: newPermissions };
};

const clearPermissions = (
  state: PermissionsState,
  action: { type: string }
) => {
  return { ...state, loaded: false, data: [] };
};

const _reducer = createReducer(
  initialState,
  on(PermissionsActions.setPermissions, setPermissions),
  on(StatusActions.logout, clearPermissions)
);

/* Selectors */
const selectPermissionsFeatureState =
  createFeatureSelector<PermissionsState>('permissions');

export const selectAllPermissions = createSelector(
  selectPermissionsFeatureState,
  (state: PermissionsState) => {
    return state.data;
  }
);
