import { Component, Input } from '@angular/core';
import { FirewallAction } from '@shared/models/firewall.datatypes';
import { IconSize, IconType, IconStatus } from '@shared/models/icon.model';

@Component({
  selector: 'app-firewall-action',
  templateUrl: './firewall-action.component.html',
  styleUrls: ['./firewall-action.component.scss'],
})
export class FirewallActionComponent {
  @Input() data!: FirewallAction;

  public FirewallAction = FirewallAction;
  public IconSize = IconSize;
  public IconType = IconType;
  public IconStatus = IconStatus;

  constructor() {}
}
