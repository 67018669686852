<div class="form__label">
  <div class="form__text__block" *ngIf="!hideLabel">
    <div class="form__text"><ng-content></ng-content></div>
    <div *ngIf="extraText" class="form__text">
      <a
        class="secondary-link"
        *ngIf="extraText.link"
        [routerLink]="[extraText.link]"
      >
        {{ extraText.text }}
      </a>
      <app-pill
        *ngIf="extraText.pill"
        [type]="extraText.pill"
        [size]="PillSize.XSMALL"
        >{{ extraText.text }}</app-pill
      >
      <p *ngIf="!extraText.link && !extraText.pill" class="form__extra-text">
        {{ extraText.text }}
      </p>
    </div>
  </div>
  <div class="form__input-container">
    <span *ngIf="preInput" class="form__preinput" [ngClass]="classList">{{
      preInput
    }}</span>

    <app-icon
      *ngIf="preIcon"
      [size]="IconSize.MEDIUM"
      [type]="preIconObject.type"
      class="form__preicon"
      [title]="preIconObject.name"
      >{{ preIconObject.name }}</app-icon
    >

    <!-- TEXT -->
    <input
      class="form__input form__input--text"
      *ngIf="type === FieldType.TEXT"
      [type]="type"
      [id]="name"
      [name]="name"
      [ngClass]="classList"
      [disabled]="isDisabled"
      [placeholder]="placeholder"
      [(ngModel)]="inputValue"
      (ngModelChange)="inputValueChange.emit(inputValue)"
      [autocomplete]="autocomplete"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [pattern]="pattern"
      [attr.aria-label]="name"
      [attr.required]="required"
      #input
    />

    <!-- PASSWORD -->
    <input
      class="form__input form__input--password"
      *ngIf="type === FieldType.PASSWORD"
      [ngClass]="classList"
      [type]="visiblePassword ? FieldType.TEXT : FieldType.PASSWORD"
      [id]="name"
      [name]="name"
      [disabled]="isDisabled"
      [placeholder]="placeholder"
      [(ngModel)]="inputValue"
      (ngModelChange)="inputValueChange.emit(inputValue)"
      [autocomplete]="autocomplete"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [pattern]="pattern"
      [attr.required]="required"
      [attr.aria-label]="name"
      #input
    />
    <app-icon
      class="form__input--password-icon"
      *ngIf="type === FieldType.PASSWORD"
      [size]="IconSize.MEDIUM"
      (click)="togglePasswordVisibility()"
      title="Toggle visibility"
      >{{ visiblePassword ? "visibility_off" : "visibility" }}</app-icon
    >

    <!-- CODE -->
    <input
      class="form__input form__input--code"
      *ngIf="type === FieldType.CODE"
      placeholder="-"
      type="text"
      maxlength="1"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
      (focus)="onFocus()"
      [(ngModel)]="inputValue"
      [ngClass]="classList"
      [disabled]="isDisabled"
      [attr.aria-label]="name"
      autocomplete="off"
      #input
    />

    <!-- TEXTAREA -->
    <textarea
      *ngIf="type === FieldType.TEXTAREA"
      class="form__input form__input--textarea"
      [ngClass]="classList"
      [id]="name"
      [name]="name"
      [disabled]="isDisabled"
      [placeholder]="placeholder"
      [(ngModel)]="inputValue"
      (ngModelChange)="inputValueChange.emit(inputValue)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [attr.required]="required"
      [attr.aria-label]="name"
    ></textarea>
  </div>
</div>

<p class="form__subtext" *ngIf="subtext">
  <small>{{ subtext }}</small>
</p>

<p class="form__error-message" *ngIf="errorMsg?.length && showErrorMsg">
  {{ errorMsg }}
</p>
