import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Account } from '@shared/models/account';
import {
  ButtonType,
  ButtonRole,
  ButtonStatus,
} from '@shared/models/button.model';
import {
  AddressLocation,
  ExtendedFirewallRule,
  FirewallRule,
  FirewallType,
  WebFilter,
} from '@shared/models/firewall.model';
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import { AccountService } from '@shared/services/account.service';
import { ModalService } from '@shared/services/modal.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ProtectService } from '../../protect.service';
import { FirewallStatus } from '@shared/models/firewall.datatypes';
import { Subscription, of, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from '@shared/services/error.service';
import { Store } from '@ngrx/store';
import * as StatusActions from '@store/status/status.actions';
import * as fromStatus from '@store/status/status.reducer';
import { PermissionsService } from '@shared/services/permissions.service';
import { UserService } from '@shared/services/user.service';
import { Feature } from '@shared/models/features';

@Component({
  selector: 'app-view-firewall-rule',
  templateUrl: './view-firewall-rule.component.html',
  styleUrls: ['./view-firewall-rule.component.scss'],
})
export class ViewFirewallRuleComponent implements OnInit, OnDestroy {
  public ButtonType = ButtonType;
  public ButtonRole = ButtonRole;
  public ModalSize = ModalSize;
  public AddressLocation = AddressLocation;
  public ModalStatus = ModalStatus;
  public FirewallStatus = FirewallStatus;
  public ButtonStatus = ButtonStatus;

  private accountSubscription!: Subscription;
  private routeSubscription!: Subscription;
  private userSubscription!: Subscription;
  private notesSubscription!: Subscription;
  private accountId!: string;
  public policyid: string = '';
  public notesErrors = null;
  public noteLoaded = false;

  public modalStatus: ModalStatus = ModalStatus.OPEN;
  public rule!: ExtendedFirewallRule;

  public waitingSub?: Subscription;
  private waiting$ = this.store.select(fromStatus.selectWaiting);
  public buttonStatus: ButtonStatus = ButtonStatus.ACTIVE;

  public type!: FirewallType;
  public activeTab = 'information';
  public tabs = [
    {
      id: 'information',
      label: 'Information',
    },
  ];

  constructor(
    private modalService: ModalService,
    private accountService: AccountService,
    private protectService: ProtectService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private store: Store,
    private permissionsService: PermissionsService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    const context = this.modalService.getContext();
    if (!context) {
      this.loadDataOnRefresh();
    } else {
      this.loadDataOnNavigation(context);
    }
    this.waitingSub = this.waiting$.subscribe((val) => {
      this.buttonStatus = !val ? ButtonStatus.ACTIVE : ButtonStatus.LOADING;
    });
  }

  loadDataOnRefresh() {
    this.routeSubscription = this.route.url
      .pipe(
        switchMap((url) => {
          if (!url?.[1]) {
            this.modalStatus = ModalStatus.CLOSED;
            return throwError(() => new Error('Policy ID argument not found'));
          }

          this.policyid = url[1].path;
          this.type =
            url[0].path === 'view-firewall-rule'
              ? FirewallType.REGULAR
              : FirewallType.PROXY;

          return this.accountService.fetchCurrentAccount();
        }),
        take(1),
        switchMap((account: Account | undefined) => {
          if (account?.id) {
            this.accountId = account.id;
            this.addTabBasedOnPermission(account);
            return this.protectService.getFirewallRules({
              accountId: this.accountId,
              firewallType: this.type,
            });
          } else {
            return throwError(() => new Error('Account not found'));
          }
        }),
        take(1),
        switchMap((rules: FirewallRule[] | undefined) => {
          if (!rules) {
            this.modalStatus = ModalStatus.CLOSED;
            return throwError(() => new Error('Rules not found'));
          }
          this.rule = rules.find((rule: FirewallRule) => {
            return rule.policyid === +this.policyid;
          }) as ExtendedFirewallRule;
          return this.getNotes();
        })
      )
      .subscribe((response: { data: { notes: string } }) => {
        this.rule = { ...this.rule, ...{ notes: response.data.notes } };
        this.noteLoaded = true;
      });
  }

  getNotes() {
    // If it's not an implicit deny
    if (this.rule.uuid !== '00000000-0000-0000-0000-000000000000') {
      this.tabs.push({
        id: 'notes',
        label: 'Notes',
      });
      return this.protectService.getFirewallNotes(
        this.accountId,
        this.rule.uuid
      );
    } else {
      return of({ data: { notes: {} } });
    }
  }

  loadDataOnNavigation(context: any) {
    this.rule = context;
    this.policyid = context.policyid;
    this.type = context.type;
    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(take(1))
      .subscribe((account?: Account) => {
        if (account?.id) {
          this.accountId = account.id;
          this.addTabBasedOnPermission(account);
        }
      });
    this.notesSubscription = this.getNotes().subscribe(
      (response: { data: { notes: string } }) => {
        this.rule = { ...this.rule, ...{ notes: response.data.notes } };
        this.noteLoaded = true;
      }
    );
  }

  ngOnDestroy(): void {
    this.accountSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
    this.notesSubscription?.unsubscribe();
    this.waitingSub?.unsubscribe();
  }

  public hasSecurityProfiles() {
    return (
      this.rule['av-profile'] ||
      this.rule['dnsfilter-profile'] ||
      this.rule['application-list'] ||
      this.rule['ips-sensor'] ||
      this.rule['ssl-ssh-profile'] ||
      (this.rule['webfilter-profile'] && !this.rule['webfilter-profile'].web)
    );
  }

  public hasUrlFilters() {
    return this.rule['webfilter-profile']?.web?.['urlfilter-table'];
  }

  public getWebFilterProfile() {
    return this.rule['webfilter-profile'] as WebFilter;
  }

  public updateRule(notes: string) {
    this.store.dispatch(StatusActions.setWaiting({ waiting: true }));
    this.protectService
      .updateFirewallRule({ notes }, this.accountId, this.rule.uuid)
      .pipe(
        catchError((error) => {
          this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
          this.errorService.handleSaveErrors(error);
          this.notesErrors = error;
          throw error;
        })
      )
      .subscribe(() => {
        this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
        this.toastr.success('Notes updated successfully', 'Success', {
          progressBar: true,
        });
      });
  }

  public switchTab(tabName: string) {
    this.activeTab = tabName;
  }

  public addTabBasedOnPermission(account: Account) {
    this.userSubscription = this.userService
      .getCurrentUser()
      .subscribe((user) => {
        if (user) {
          if (
            this.permissionsService.isFeatureAllowed(
              Feature.TRAFFIC_LOGS,
              account,
              user
            )
          ) {
            this.tabs.push({
              id: 'traffic-logs',
              label: 'Traffic Logs',
            });
          }
        }
      });
  }
}
