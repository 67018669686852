import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'protocol',
})
export class ProtocolPipe implements PipeTransform {
  transform(value: any, type: 'TCP' | 'UDP'): any {
    const values = value.split(' ');

    const output = [];

    for (const valueElement of values) {
      output.push(`${type}:any \u2192 ${valueElement}`);
    }

    return `(${output.join(', ')})`;
  }
}
