import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@shared/models/user';
import { Ticket, TicketType } from '@support/models/ticket';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private context: any = null;

  /**
   * ModalService constructor
   * @param { Router } router - provider for routing services.
   */
  constructor(private router: Router) {}

  /**
   * Close all modals
   * Don't call this directly or animation won't work
   * Change the `status` of the modal to ModalStatus.CLOSED
   */
  public closeAll(): Promise<boolean> {
    return this.router.navigate([{ outlets: { modal: null } }]);
  }

  /**
   * Get the current modal's context
   * @returns { any } return the context for the current modal.
   */
  public getContext(): any {
    return this.context;
  }

  /**
   * Show a modal with the associated context
   */
  public show(modal: string, context: any = null): void {
    this.context = context;
    const routerUrl = this.router.url.includes('(')
      ? this.router.url.split('(')[0]
      : this.router.url;
    this.router.navigateByUrl(`${routerUrl}(modal:${modal})`);
  }

  /**
   * Show the 'view ticket' modal
   * @param { Ticket } ticket object - ticket data for modal
   * @param { TicketType } type incident or SR
   */
  public viewTicket(ticket: Ticket, type: TicketType): void {
    this.context = { ...ticket };
    const routerUrl = this.router.url.includes('(')
      ? this.router.url.split('(')[0]
      : this.router.url;
    this.router.navigateByUrl(
      `${routerUrl}(modal:view-ticket;ticket=${ticket.id};type=${type})`
    );
  }

  /**
   * Show the 'raise incident' modal
   */
  public raiseIncident(accountId?: string | null): void {
    this.context = {};
    let url = '(modal:raise-incident';
    if (accountId) {
      url += `;accountId=${accountId}`;
    }
    url += ')';
    this.router.navigateByUrl(`${this.router.url}${url}`);
  }

  /**
   * Show the 'edit user' modal
   * @param { User } user object - user data for modal
   */
  public editUser(user: User, isProfile: boolean = false): void {
    this.context = { ...user, ...{ isProfile } };
    this.router.navigateByUrl(
      `${this.router.url}(modal:add-edit-user;username=${user.username})`
    );
  }

  public addUser(): void {
    this.context = null;
    this.router.navigateByUrl(`${this.router.url}(modal:add-edit-user)`);
  }

  /**
   * Show the 'raise service request' modal
   */
  public raiseServiceRequest(accountId?: string | null): void {
    this.context = {};
    const routerUrl = this.router.url.includes('(')
      ? this.router.url.split('(')[0]
      : this.router.url;
    let url = '(modal:raise-service-request';
    if (accountId) {
      url += `;accountId=${accountId}`;
    }
    url += ')';

    this.router.navigateByUrl(`${routerUrl}${url}`);
  }

  public serviceRequestChoice(accountId?: string | null): void {
    this.context = {};
    let url = '(modal:service-request-choice';
    if (accountId) {
      url += `;accountId=${accountId}`;
    }
    url += ')';
    this.router.navigateByUrl(`${this.router.url}${url}`);
  }

  public createSSLUser(): void {
    this.router.navigateByUrl(`${this.router.url}(modal:create-ssl-user)`);
  }
}
