<div
  class="datatable"
  [ngClass]="{
    'datatable--bordered': showBorder === true,
    'datatable--compact': type === DatatableType.COMPACT
  }"
>
  <table cdk-table [dataSource]="pagedData" [ngClass]="classList">
    <caption
      class="cdk-table__skeleton"
      *ngIf="status !== Status.COMPLETE || pagedData.length === 0"
    >
      <app-loading *ngIf="status === Status.LOADING"> </app-loading>

      <app-loading-error *ngIf="status === Status.ERROR">
        There was an error fetching the data
      </app-loading-error>

      <app-loading-error *ngIf="status === Status.NOT_AVAILABLE">
        The data is not available
      </app-loading-error>

      <div
        class="cdk-table__skeleton__message cdk-table__skeleton__message--empty"
        *ngIf="isEmpty"
      >
        <app-icon title="Empty" className="icon--md">hide_source</app-icon>
        <p>{{ noDataMessage }}</p>
      </div>
    </caption>

    <ng-content></ng-content>

    <tr
      cdk-header-row
      *cdkHeaderRowDef="displayedColumns"
      (click)="handleHeaderRowClick($event)"
    >
      <th></th>
    </tr>
    <tr
      cdk-row
      (click)="handleRowClick(row)"
      (keyup.enter)="handleRowClick(row)"
      *cdkRowDef="let row; let idx = dataIndex; columns: displayedColumns"
      [@tableRowAnimation]="makeParams(row)"
      tabindex="0"
    >
      <td></td>
    </tr>
  </table>

  <div
    class="datatable__footer"
    *ngIf="status === Status.COMPLETE && showFooter === true"
  >
    <div class="datatable__stats" *ngIf="data.length > 1">
      <ng-container *ngIf="data.length <= limit">
        Showing all {{ data.length }} results
      </ng-container>
      <ng-container *ngIf="!unknownSize && data.length > limit">
        Showing {{ (currentPage - 1) * limit + 1 }}&ndash;{{
          currentPage * limit > data.length ? data.length : currentPage * limit
        }}
        of {{ data.length }} results
      </ng-container>
      <ng-container *ngIf="unknownSize">
        Showing {{ (currentPage - 1) * limit + 1 }}&ndash;{{
          currentPage * limit > data.length ? data.length : currentPage * limit
        }}
        results
      </ng-container>
    </div>
    <app-pagination
      *ngIf="status === Status.COMPLETE"
      [currentPage]="currentPage"
      [lastPage]="lastPage"
      (navigate)="pageData($event, true)"
    ></app-pagination>
  </div>
</div>
