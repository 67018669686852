import { FontSpec } from 'chart.js';
import { StylesService } from '@shared/services/styles.service';
import { CursorOptions } from '../models/line-chart.model';

const stylesService = new StylesService();

export const options = {
  gridColor: stylesService.styles['--chart__gridlines'],
  color: stylesService.styles['--chart__text'],
  tickColor: stylesService.styles['--chart__text'],
  titleColor: stylesService.styles['--chart__text'],
  legendColor: stylesService.styles['--chart__text'],
};

export const bandwidthChartColors: any[] = [
  {
    backgroundColor: stylesService.styles['--bandwidth-chart__in-colour'],
    hoverBackgroundColor:
      stylesService.styles['--bandwidth-chart__in-colour--hover'],
  },
  {
    backgroundColor: stylesService.styles['--bandwidth-chart__out-colour'],
    hoverBackgroundColor:
      stylesService.styles['--bandwidth-chart__out-colour--hover'],
  },
];

export const donutChartColors: any = {
  backgroundColor: [
    stylesService.styles['--donut-chart__background--0'],
    stylesService.styles['--donut-chart__background--1'],
    stylesService.styles['--donut-chart__background--2'],
    stylesService.styles['--donut-chart__background--3'],
    stylesService.styles['--donut-chart__background--4'],
    stylesService.styles['--donut-chart__background--5'],
    stylesService.styles['--donut-chart__background--6'],
    stylesService.styles['--donut-chart__background--7'],
    stylesService.styles['--donut-chart__background--8'],
    stylesService.styles['--donut-chart__background--9'],
  ],
  hoverBackgroundColor: [
    stylesService.styles['--donut-chart__background--0-hover'],
    stylesService.styles['--donut-chart__background--1-hover'],
    stylesService.styles['--donut-chart__background--2-hover'],
    stylesService.styles['--donut-chart__background--3-hover'],
    stylesService.styles['--donut-chart__background--4-hover'],
    stylesService.styles['--donut-chart__background--5-hover'],
    stylesService.styles['--donut-chart__background--6-hover'],
    stylesService.styles['--donut-chart__background--7-hover'],
    stylesService.styles['--donut-chart__background--8-hover'],
    stylesService.styles['--donut-chart__background--9-hover'],
  ],
  borderColor: stylesService.styles['--donut-chart__border'],
  hoverBorderColor: stylesService.styles['--donut-chart__border--hover'],
  borderWidth: 0,
};

// For percent charts, the second color is transparent
export const donutPercentChartColors: any = {
  backgroundColor: [
    stylesService.styles['--donut-chart__background--0-0'],
    stylesService.styles['--donut-chart__background--1-0'],
    stylesService.styles['--donut-chart__background--2-0'],
    stylesService.styles['--donut-chart__background--3-0'],
  ],
  hoverBackgroundColor: [
    stylesService.styles['--donut-chart__background--0-0-hover'],
    stylesService.styles['--donut-chart__background--1-0-hover'],
    stylesService.styles['--donut-chart__background--2-0-hover'],
    stylesService.styles['--donut-chart__background--3-0-hover'],
  ],
  borderWidth: 0,
};

export const lineChartColors: any[] = [
  {
    borderColor: stylesService.styles['--line-chart--blocked-border'],
    hoverBorderColor: stylesService.styles['--line-chart--blocked-border'],
    borderWidth: 1,
    backgroundColor: stylesService.styles['--line-chart--blocked-background'],
  },
  {
    borderColor: stylesService.styles['--line-chart--allowed-border'],
    hoverBorderColor: stylesService.styles['--line-chart--allowed-border'],
    borderWidth: 1,
    backgroundColor: stylesService.styles['--line-chart--allowed-background'],
  },
];

export const lineChartPoints: any[] = [
  {
    pointBorderColor: stylesService.styles['--line-chart--blocked-border'],
    pointHoverBorderColor: stylesService.styles['--line-chart--blocked-border'],
    pointBorderWidth: 0,
    pointHoverBorderWidth: 1,
    pointBackgroundColor: 'transparent',
    pointHoverBackgroundColor:
      stylesService.styles['--line-chart--blocked-background'],
    pointRadius: 0,
    pointHoverRadius: 4,
  },
  {
    pointBorderColor: stylesService.styles['--line-chart--allowed-border'],
    pointHoverBorderColor: stylesService.styles['--line-chart--allowed-border'],
    pointBorderWidth: 0,
    pointHoverBorderWidth: 1,
    pointBackgroundColor: 'transparent',
    pointHoverBackgroundColor:
      stylesService.styles['--line-chart--allowed-background'],
    pointRadius: 0,
    pointHoverRadius: 4,
  },
];

export const lineChartCursor: CursorOptions = {
  color: stylesService.styles['--line-chart--cursor-color'],
  dash: [2, 2],
  width: 2,
};

export const annotationColors = {
  borderColorIn: stylesService.styles['--bandwidth-chart__in95-colour'],
  borderColorOut: stylesService.styles['--bandwidth-chart__out95-colour'],
};

export const fontSettings: { font: FontSpec; color: string } = {
  font: {
    size: 12,
    family: 'Montserrat',
    style: 'normal',
    weight: 'normal',
    lineHeight: 1.2,
  },
  color: stylesService.styles['--chart__text'],
};

export const tooltipSettings = {
  titleFont: {
    family: fontSettings.font.family,
    size: fontSettings.font.size + 2,
    style: fontSettings.font.style,
  },
  titleColor: fontSettings.color,
  titleMarginBottom: 4,
  bodyFont: {
    family: fontSettings.font.family,
    size: fontSettings.font.size,
    style: fontSettings.font.style,
  },
  bodyColor: fontSettings.color,
  bodySpacing: 6,
  backgroundColor: stylesService.styles['--tooltip__background'],
  borderColor: stylesService.styles['--tooltip__border'],
  borderWidth: 1,
};
