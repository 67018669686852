import { Action, createReducer, on } from '@ngrx/store';

import { Account } from '@shared/models/account';
import { AccountStoreItem } from '../account-store-item.model';
import {
  FirewallAPIResponse,
  URLFilterAPIResponse,
} from '@shared/models/api-response/api-response-firewall.model';
import {
  BandwidthResponse,
  ResourceResponse,
  TrafficLogsExportsResponse,
  TrafficLogsResponse,
} from '@shared/models/api-response/api-response-analytics.model';
import {
  RouteResponse,
  SSLVPNResponse,
} from '@shared/models/api-response/api-response-remote.model';
import { NetworkOverviewResponse } from '@shared/models/api-response/api-response-connection.model';
import { FirewallRule, FirewallType } from '@shared/models/firewall.model';
import {
  FirewallAction,
  FirewallStatus,
  Settings,
} from '@shared/models/firewall.datatypes';

import * as AccountActions from './accounts.actions';
import * as StatusActions from '../status/status.actions';
import {
  Report,
  ReportRecord,
  ReportStatus,
} from '@shared/models/report.model';
import { SSLVPN } from 'src/app/connect/models/ssl-vpn.model';
import { Ticket, TicketType } from '@support/models/ticket';
import { TicketStatus } from '@support/models/ticket-status.constant';

export interface AccountsState {
  loaded: boolean;
  data: AccountStoreItem[];
}

export const initialState: AccountsState = {
  loaded: false,
  data: [],
};

/* Setup reducers */
export function reducer(state: AccountsState | undefined, action: Action) {
  return accountsReducer(state, action);
}

const setAccounts = (
  _state: AccountsState,
  action: { type: string; accounts: Account | Account[] }
) => {
  let accounts: Account[];
  if (!Array.isArray(action.accounts)) {
    accounts = [action.accounts];
  } else {
    accounts = [...action.accounts];
  }
  const sortedAccounts = accounts.sort((a: Account, b: Account) =>
    a.companyName.toUpperCase() > b.companyName.toUpperCase() ? 1 : -1
  );
  return { loaded: true, data: sortedAccounts };
};

const setBandwidthData = (
  state: AccountsState,
  action: { type: string; bandwidthData: BandwidthResponse }
) => {
  if (!state.data) {
    return state;
  }

  const newState = { ...state };
  let accountData = newState.data.find(
    (account: AccountStoreItem) =>
      account.id === action.bandwidthData.meta.accountId
  );
  if (!accountData) {
    throw new Error('Cannot find account');
  }

  const index = newState.data.indexOf(accountData);
  const newAccount = { ...accountData };
  // Check to see if this is an update or an addition
  if (!newAccount.bandwidthData) {
    newAccount.bandwidthData = [action.bandwidthData];
  } else {
    const newBandwidthData = [...newAccount.bandwidthData];
    const matchingDataIndex = newBandwidthData.findIndex((thisData) => {
      const intervalMatches =
        action.bandwidthData.meta.interval === thisData.meta.interval;
      const accountMatches =
        action.bandwidthData.meta.accountId === thisData.meta.accountId;
      return intervalMatches && accountMatches;
    });
    if (matchingDataIndex === -1) {
      // No match - new item
      newBandwidthData.push(action.bandwidthData);
    } else {
      // Update existing
      newBandwidthData[matchingDataIndex] = action.bandwidthData;
    }
    newAccount.bandwidthData = newBandwidthData;
  }
  const newStateData = [...newState.data];
  newStateData[index] = newAccount;
  newState.data = newStateData;

  return newState;
};

const setResourceData = (
  state: AccountsState,
  action: { type: string; resourceData: ResourceResponse }
) => {
  if (!state.data) {
    return state;
  }

  const newState = { ...state };
  const accountToUpdate = newState.data.find(
    (account: AccountStoreItem) =>
      account.id === action.resourceData.meta.accountId
  );
  if (!accountToUpdate) {
    throw new Error('Cannot find account');
  }

  const index = newState.data.indexOf(accountToUpdate);
  const newAccount = { ...accountToUpdate };
  // Check to see if this is an update or an addition
  if (!newAccount.resourceData) {
    newAccount.resourceData = [action.resourceData];
  } else {
    const newResourceData = [...newAccount.resourceData];
    // Check if store has an item with matching meta start, end, aggregate and accountId
    const matchingDataIndex = newResourceData.findIndex((thisData) => {
      const startMatches =
        action.resourceData.meta.start === thisData.meta.start;
      const endMatches = action.resourceData.meta.end === thisData.meta.end;
      const resourceMatches =
        action.resourceData.meta.type === thisData.meta.type;
      const accountMatches =
        action.resourceData.meta.accountId === thisData.meta.accountId;
      return startMatches && endMatches && resourceMatches && accountMatches;
    });
    if (matchingDataIndex === -1) {
      // No match - new item
      newResourceData.push(action.resourceData);
    } else {
      // Update existing
      newResourceData[matchingDataIndex] = action.resourceData;
    }
    newAccount.resourceData = newResourceData;
  }
  const newStateData = [...newState.data];
  newStateData[index] = newAccount;
  newState.data = newStateData;

  return newState;
};

const setTrafficLogsExports = (
  state: AccountsState,
  action: { trafficLogsExports: TrafficLogsExportsResponse }
) => {
  if (!state.data) {
    return state;
  }

  const newStateData = [...state.data];
  let accountToUpdate = newStateData.find(
    (account: AccountStoreItem) =>
      account.id === action.trafficLogsExports.meta.accountId
  );
  if (!accountToUpdate) {
    throw new Error('Cannot find account');
  }

  const index = newStateData.indexOf(accountToUpdate);
  accountToUpdate = {
    ...accountToUpdate,
    trafficLogsExports: action.trafficLogsExports.data,
  };
  newStateData[index] = accountToUpdate;
  return { ...state, data: newStateData };
};

const setRouteData = (
  state: AccountsState,
  action: { type: string; routeData: RouteResponse }
) => {
  if (!state.data) {
    return state;
  }
  const newStateData = [...state.data];
  let accountData = newStateData.find(
    (account: AccountStoreItem) =>
      account.id === action.routeData.meta.accountId
  );
  if (!accountData) {
    throw new Error('Cannot find account');
  }
  const index = newStateData.indexOf(accountData);
  accountData = { ...accountData, routeData: action.routeData.data };
  newStateData[index] = accountData;
  return { ...state, data: newStateData };
};

const setSSLVPNData = (
  state: AccountsState,
  action: { type: string; sslVPNData: SSLVPNResponse }
) => {
  const newStateData = [...state.data];
  const { thisAccount, accountIndex } = findAccount(
    newStateData,
    action.sslVPNData.meta.accountId
  );
  newStateData[accountIndex] = {
    ...thisAccount,
    sslVPNData: action.sslVPNData.data,
  };

  return { ...state, data: newStateData };
};

const addSSLUser = (state: AccountsState, action: { sslUser: SSLVPN }) => {
  const newStateData: AccountStoreItem[] = [...state.data];
  const { thisAccount, accountIndex } = findAccount(
    newStateData,
    action.sslUser.accountId
  );
  const sslVPNData: SSLVPN[] = thisAccount.sslVPNData
    ? [...thisAccount.sslVPNData]
    : [];

  sslVPNData.unshift(action.sslUser);
  newStateData[accountIndex] = { ...thisAccount, sslVPNData: sslVPNData };

  return { ...state, data: newStateData };
};

const removeSSLUsers = (
  state: AccountsState,
  action: { sslUsers: SSLVPN[]; accountId: string }
) => {
  const newStateData: AccountStoreItem[] = [...state.data];
  const { thisAccount, accountIndex } = findAccount(
    newStateData,
    action.accountId
  );

  let sslVPNData: SSLVPN[] = thisAccount.sslVPNData
    ? [...thisAccount.sslVPNData]
    : [];

  sslVPNData = sslVPNData.filter(
    (user: SSLVPN) =>
      !action.sslUsers.some((sslUser) => {
        return sslUser.adUsername === user.adUsername;
      })
  );
  newStateData[accountIndex] = { ...thisAccount, sslVPNData: sslVPNData };

  return { ...state, data: newStateData };
};

const setNetworkOverview = (
  state: AccountsState,
  action: { type: string; networkOverviewData: NetworkOverviewResponse }
) => {
  if (!state.data) {
    return state;
  }
  const newStateData = [...state.data];
  let accountData = newStateData.find(
    (account: AccountStoreItem) =>
      account.id === action.networkOverviewData.meta.accountId
  );
  if (accountData) {
    const index = newStateData.indexOf(accountData);
    accountData = {
      ...accountData,
      networkOverviewData: action.networkOverviewData.data,
    };
    newStateData[index] = accountData;
    const newState = Object.assign({ ...state }, { data: newStateData });
    return newState;
  } else {
    throw new Error('Cannot find account');
  }
};

const setFirewallData = (
  state: AccountsState,
  action: {
    firewallData: FirewallAPIResponse;
    firewallType: FirewallType;
  }
) => {
  const firewallType = action.firewallType;
  if (!state.data) {
    return state;
  }
  const id = action.firewallData.meta.accountId;
  const newStateData = [...state.data];
  let accountData = newStateData.find(
    (account: AccountStoreItem) => account.id === id
  );
  if (!accountData) {
    throw new Error('Cannot find account');
  }
  const index = newStateData.indexOf(accountData);
  const firewallRules = configureFirewallData(action.firewallData.data);
  accountData = {
    ...accountData,
    ...{
      firewallRules: {
        ...accountData.firewallRules,
        ...{ [firewallType]: firewallRules },
      },
    },
  };
  newStateData[index] = accountData;
  return { ...state, data: newStateData };
};

const configureFirewallData = (data: FirewallRule[]) => {
  if (!data) {
    return [];
  }
  return data.flatMap((rule, index) => {
    const defaultName =
      rule.action === FirewallAction.deny ? 'Implicit Deny' : 'Implicit Allow';
    let newRule: FirewallRule = { ...rule };
    const defaultRule = new FirewallRule(defaultName);
    // for some reason there seems to be a default SSL profile, which is only sometimes set.
    // In cases it's not in the response, add it here
    if (!newRule['ssl-ssh-profile']) {
      newRule['ssl-ssh-profile'] = {
        q_origin_key: 'no-inspection',
        name: 'no-inspection',
        datasource: 'firewall.ssl-ssh-profile',
        'css-class': 'ftnt-profile-ssl-ssh-inspection',
      };
    }
    newRule.priority = index;
    // disabled and hidden logic
    // should be done in the API, but adding here too for safety
    let hidden: boolean = false;
    const disabled = rule.status === FirewallStatus.disable;
    if (newRule.comments) {
      hidden = newRule.comments.indexOf(Settings.HIDDEN) !== -1;
    }
    newRule = setSecurityProfiles(newRule);
    if (!hidden && !disabled) {
      // Take the default rule and merge it with the incoming rule.
      return [{ ...defaultRule, ...newRule }];
    }
    return [];
  });
};

// Adds the security profiles as an array (easier to loop through in front-end)
const setSecurityProfiles = (rule: FirewallRule): FirewallRule => {
  if (!rule.securityprofiles) {
    rule.securityprofiles = new Array();
  }
  if (rule['av-profile']) {
    rule.securityprofiles.push({ ...rule['av-profile'], pilltext: 'AV' });
  }
  if (rule['webfilter-profile']) {
    rule.securityprofiles.push({
      ...rule['webfilter-profile'],
      pilltext: 'WEB',
    });
  }
  if (rule['dnsfilter-profile']) {
    rule.securityprofiles.push({
      ...rule['dnsfilter-profile'],
      pilltext: 'DNS',
    });
  }
  if (rule['application-list']) {
    rule.securityprofiles.push({
      ...rule['application-list'],
      pilltext: 'APP',
    });
  }
  if (rule['ips-sensor']) {
    rule.securityprofiles.push({ ...rule['ips-sensor'], pilltext: 'IPS' });
  }
  if (rule['ssl-ssh-profile']) {
    let text;
    switch (rule['ssl-ssh-profile'].name) {
      case 'no-inspection':
        text = 'SSLNI';
        break;
      case 'certificate-inspection':
        text = 'SSLCI';
        break;
      default:
        text = 'SSL';
        break;
    }
    rule.securityprofiles.push({ ...rule['ssl-ssh-profile'], pilltext: text });
  }
  return rule;
};

const setFirewallURLFilters = (
  state: AccountsState,
  action: { type: string; filterData: URLFilterAPIResponse }
) => {
  if (!state.data) {
    return state;
  }

  const newStateData = [...state.data];
  const newFilterData = action.filterData.data;
  const id = action.filterData.meta.accountId;
  let accountData = newStateData.find(
    (account: AccountStoreItem) => account.id === id
  );
  if (!accountData) {
    throw new Error('Cannot find account');
  }

  const index = newStateData.indexOf(accountData);
  let urlFilters;
  if (!accountData.urlFilters) {
    urlFilters = [newFilterData];
  } else {
    urlFilters = [...accountData.urlFilters, ...[newFilterData]];
  }
  accountData = { ...accountData, urlFilters };
  newStateData[index] = accountData;
  return { ...state, data: newStateData };
};

const setTickets = (state: AccountsState, action: { data: any; meta: any }) => {
  const newStateData: any = [...state.data];

  let accountIndex: number = -1;
  let relevantAccountsData: any[] = newStateData.filter(
    (account: any, index: number) => {
      if (account.id === action.meta.accountId) {
        accountIndex = index;
        return true;
      }
      return false;
    }
  );

  if (accountIndex === -1) {
    throw new Error('Cannot find account');
  }
  const ticketsType: string = action.meta.type;
  const ticketsData: any = action.data;

  let relevantAccountsDataObj: Account = relevantAccountsData[0];

  if (!Object.keys(relevantAccountsDataObj).includes('supportTickets')) {
    const relevantAccountDataCopy = {
      ...relevantAccountsDataObj,
      supportTickets: {},
    };

    relevantAccountsDataObj = relevantAccountDataCopy;
  }

  let { supportTickets } = relevantAccountsDataObj;
  supportTickets = {
    ...supportTickets,
    [ticketsType]: ticketsData,
  };

  const accountDataWithTickets = {
    ...relevantAccountsDataObj,
    supportTickets,
  };

  newStateData[accountIndex] = accountDataWithTickets;
  return { ...state, data: newStateData };
};

const setReportList = (
  state: AccountsState,
  action: { data: any; meta: any }
) => {
  const newStateData: AccountStoreItem[] = [...state.data];
  const accountIndex = newStateData.findIndex(
    (account: AccountStoreItem) => account.id === action.meta.accountId
  );

  if (accountIndex === -1) {
    throw new Error('Cannot find account');
  }

  const thisAccount = newStateData[accountIndex];
  const reportListData: Report[] = action.data;

  newStateData[accountIndex] = { ...thisAccount, reportList: reportListData };

  return { ...state, data: newStateData };
};

const setReports = (state: AccountsState, action: { data: any; meta: any }) => {
  const newStateData: AccountStoreItem[] = [...state.data];
  const accountIndex = newStateData.findIndex(
    (account: AccountStoreItem) => account.id === action.meta.accountId
  );
  if (accountIndex === -1) {
    throw new Error('Cannot find account');
  }

  const thisAccount = newStateData[accountIndex];
  const reportsData: ReportRecord[] = action.data;

  newStateData[accountIndex] = { ...thisAccount, reports: reportsData };

  return { ...state, data: newStateData };
};

const addReport = (state: AccountsState, action: { report: ReportRecord }) => {
  const newStateData: AccountStoreItem[] = [...state.data];
  const accountIndex = newStateData.findIndex(
    (account: AccountStoreItem) => account.id === action.report.accountId
  );
  if (accountIndex === -1) {
    throw new Error('Cannot find account');
  }

  const thisAccount = newStateData[accountIndex];
  const reportsData: ReportRecord[] = thisAccount.reports
    ? [...thisAccount.reports]
    : [];

  reportsData.push(action.report);
  newStateData[accountIndex] = { ...thisAccount, reports: reportsData };

  return { ...state, data: newStateData };
};

const updateReportStatus = (
  state: AccountsState,
  action: { accountId: string; reportId: string; reportStatus: ReportStatus }
) => {
  const newStateData: AccountStoreItem[] = [...state.data];
  const thisAccountIndex = newStateData.findIndex(
    (account: AccountStoreItem) => account.id === action.accountId
  );
  if (thisAccountIndex === -1) {
    throw new Error(`Cannot find account with ID ${action.accountId}`);
  }

  const thisAccount = newStateData[thisAccountIndex];
  if (!thisAccount.reports) {
    throw new Error('That account does not have any reports');
  }

  const thisAccountReports = [...thisAccount.reports];
  const thisReportIndex = thisAccountReports.findIndex(
    (report) => report.id === action.reportId
  );
  if (thisReportIndex === -1) {
    throw new Error(`Cannot find report with ID ${action.reportId}`);
  }

  const newReportObject = {
    ...thisAccountReports[thisReportIndex],
    reportStatus: action.reportStatus,
  };

  thisAccountReports[thisReportIndex] = newReportObject;
  newStateData[thisAccountIndex] = {
    ...thisAccount,
    reports: thisAccountReports,
  };

  return { ...state, data: newStateData };
};

const updateTicketStatus = (
  state: AccountsState,
  action: {
    accountId: string;
    ticketId: string;
    ticketStatus: TicketStatus;
  }
) => {
  const newStateData: AccountStoreItem[] = [...state.data];
  const thisAccountIndex = newStateData.findIndex(
    (account: AccountStoreItem) => account.id === action.accountId
  );
  if (thisAccountIndex === -1) {
    throw new Error(`Cannot find account with ID ${action.accountId}`);
  }

  const thisAccount = newStateData[thisAccountIndex];
  if (!thisAccount.supportTickets) {
    throw new Error('That account does not have any tickets');
  }

  const thisAccountTickets = structuredClone(thisAccount.supportTickets);
  let ticketType: TicketType = TicketType.INCIDENTS;
  let thisTicketIndex = -1;
  if (thisAccountTickets && thisAccountTickets[ticketType]) {
    thisTicketIndex = (thisAccountTickets[ticketType] as Ticket[]).findIndex(
      (ticket) => ticket.id === action.ticketId
    );
  }

  // If we cannot find in incidents - must be an SR
  if (thisTicketIndex === -1) {
    thisTicketIndex = (thisAccountTickets[TicketType.SR] as Ticket[]).findIndex(
      (ticket) => ticket.id === action.ticketId
    );
    ticketType = TicketType.SR;
  }
  if (thisTicketIndex === -1) {
    throw new Error(`Cannot find ticket with ID ${action.ticketId}`);
  }

  const newTicketObject = {
    ...(thisAccountTickets[ticketType] as Ticket[])[thisTicketIndex],
    ...{ status: action.ticketStatus },
  };

  (thisAccountTickets[ticketType] as Ticket[])[thisTicketIndex] =
    newTicketObject;
  newStateData[thisAccountIndex] = {
    ...thisAccount,
    supportTickets: thisAccountTickets,
  };

  return { ...state, data: newStateData };
};

const clearAccounts = (_state: AccountsState, _action: { type: string }) => {
  return { loaded: false, data: [] };
};

const accountsReducer = createReducer(
  initialState,
  on(AccountActions.setAccounts, setAccounts),
  on(AccountActions.setBandwidthData, setBandwidthData),
  on(AccountActions.setResourceData, setResourceData),
  on(AccountActions.setTrafficLogsExports, setTrafficLogsExports),
  on(AccountActions.setRouteData, setRouteData),
  on(AccountActions.setSSLVPNData, setSSLVPNData),
  on(AccountActions.addSSLUser, addSSLUser),
  on(AccountActions.removeSSLUsers, removeSSLUsers),
  on(AccountActions.setNetworkOverview, setNetworkOverview),
  on(AccountActions.setFirewallData, setFirewallData),
  on(AccountActions.setFirewallURLFilters, setFirewallURLFilters),
  on(AccountActions.setTickets, setTickets),
  on(AccountActions.setReportList, setReportList),
  on(AccountActions.setReports, setReports),
  on(AccountActions.addReport, addReport),
  on(AccountActions.updateReportStatus, updateReportStatus),
  on(AccountActions.updateTicketStatus, updateTicketStatus),
  on(StatusActions.logout, clearAccounts)
);

/* Find the right account in the state */
const findAccount = (
  newStateData: AccountStoreItem[],
  actionAccountId?: string
) => {
  const accountIndex = newStateData.findIndex(
    (account: AccountStoreItem) => account.id === actionAccountId
  );
  if (accountIndex === -1) {
    throw new Error('Cannot find account');
  }

  const thisAccount = newStateData[accountIndex];
  return { thisAccount, accountIndex };
};
