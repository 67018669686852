import { Component, Input } from '@angular/core';
import { IconSize } from '../../models/icon.model';

@Component({
  selector: 'app-country-flag',
  templateUrl: './country-flag.component.html',
  styleUrls: ['./country-flag.component.scss'],
})
export class CountryFlagComponent {
  @Input() country: string | undefined;
  @Input() size: IconSize = IconSize.MEDIUM;

  constructor() {}
}
