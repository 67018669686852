import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { forkJoin, take } from 'rxjs';
import { PermissionsOperator } from '@shared/models/permission.model';
import { AccountService } from '@shared/services/account.service';
import { PermissionsService } from '@shared/services/permissions.service';
import { UserService } from '@shared/services/user.service';

/**
 * How to use this directive:
 *
 * <button *appPermitted="'PROTECT__DASHBOARD'"></button>
 * Pass in the permission string the user must have (permission numbers are no longer valid)
 *
 * <button *appPermitted="['PROTECT__DASHBOARD', 'CONNECT__DASHBOARD']"></button>
 * Pass in an array of permission strings. By default, the user must have ALL these permissions
 *
 * <button *appPermitted="['PROTECT__DASHBOARD', 'CONNECT__DASHBOARD']; operator: PermissionsOperator.OR"></button>
 * To pass an array of permissions that the user only has to have one of,
 * pass in an PermissionsOperator.OR 'operator' argument
 * (currently available operators are PermissionsOperator.OR and PermissionsOperator.AND)
 * - Operators will work separately on user and account permissions
 *   N.B. if the array is ['PROTECT__DASHBOARD', 'CONNECT__DASHBOARD', 'ACC__SUMMARY', 'ACC__NETWORK_OVERVIEW']
 *        and the operator is PermissionsOperator.OR, the directive will check that
 *        a) the user has PROTECT__DASHBOARD or CONNECT__DASHBOARD permission *AND*
 *        b) the account has ACC__SUMMARY or ACC__NETWORK_OVERVIEW permission
 *        A combination of user and account permissions will always check both
 *        the user and account conditions are fulfilled
 *
 */

@Directive({
  selector: '[appPermitted]',
})
export class PermittedDirective implements OnInit {
  @Input() appPermitted?: string | string[];
  @Input('appPermittedOperator') operator: PermissionsOperator =
    PermissionsOperator.AND;

  constructor(
    private accountService: AccountService,
    private permissionsService: PermissionsService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService
  ) {}

  ngOnInit() {
    // Check permissions - if `appPermitted` is not defined then allow it
    let isUserPermitted = true;
    let isAccountPermitted = true;
    // Sort into user and account permissions
    const userPermissions: string[] = [];
    const accountPermissions: string[] = [];

    if (this.appPermitted) {
      if (!Array.isArray(this.appPermitted)) {
        this.appPermitted = [this.appPermitted];
      }
      this.appPermitted.forEach((permission) => {
        // Add permissions starting 'ACC' to accountPermissions
        if (permission.substring(0, 3) === 'ACC') {
          accountPermissions.push(permission);
        } else {
          userPermissions.push(permission);
        }
      });
    }

    forkJoin({
      account: this.accountService.fetchCurrentAccount().pipe(take(1)),
      user: this.userService.getCurrentUser().pipe(take(1)),
    }).subscribe(({ account, user }) => {
      if (user && userPermissions.length) {
        isUserPermitted = this.permissionsService.isUserPermitted(
          user,
          userPermissions,
          this.operator
        );
      }

      if (account && accountPermissions.length) {
        isAccountPermitted = this.permissionsService.isAccountPermitted(
          account,
          accountPermissions,
          this.operator
        );
      }

      // Check if permitted and doesn't exist in the DOM yet
      if (isUserPermitted && isAccountPermitted && !this.viewContainer.length) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (!isUserPermitted || !isAccountPermitted) {
        this.viewContainer.clear();
      }
    });
  }
}
