import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  ActivationEnd,
  NavigationEnd,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieConsentType } from '@shared/models/cookie-consent-type';
import { HttpCancelService } from '@shared/services/http-cancel.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subscription, Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import * as fromStatus from './store/status/status.reducer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public cookieSub: Subscription;
  public cookieConsent!: CookieConsentType;
  public cookieConsent$!: Observable<CookieConsentType>;
  loading = true;

  constructor(
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private titleService: Title,
    private httpCancelService: HttpCancelService,
  ) {
    this.cookieConsent$ = this.store.select(fromStatus.selectCookieConsent);
    this.cookieSub = this.cookieConsent$.subscribe(
      (value: CookieConsentType) => {
        this.cookieConsent = value;
      },
    );
  }

  ngOnInit(): void {
    // Send pageview to GTM
    window.addEventListener('beforeunload', () => {
      this.loading = true; // Set loading to true before reload
    });
    window.addEventListener('load', () => {
      this.loading = false; // Set loading to false after reload
    });
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        take(1),
      )
      .subscribe((item: NavigationEnd) => {
        const gtmTag = {
          event: 'pageview',
          pageName: item.url,
        };
        if (this.cookieConsent === CookieConsentType.ALL) {
          this.gtmService.pushTag(gtmTag);
        }
      });

    // Way to get child route data in parent
    // From: https://stackoverflow.com/a/58654690
    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((data) => {
        // Set page title
        const newTitle = data?.title ? `${data.title} - ${appTitle}` : appTitle;
        this.titleService.setTitle(newTitle);
      });
    this.cancelPendingRequests();
  }

  private cancelPendingRequests() {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.httpCancelService.cancelPendingRequests();
      }
    });
  }
}
