import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartSize } from '@charts/models/chart.model';
import {
  ButtonDisplay,
  ButtonIconPosition,
  ButtonType,
} from '@shared/models/button.model';
import { CustomIcons } from '@shared/models/icon.model';
import { Status } from '@shared/models/status.model';
import { DonutChartDatapoint, DonutType } from '@charts/models/donut.model';
import { LegendSize } from '@charts/models/legend.model';
import { AccountService } from '@shared/services/account.service';
import { InspectService } from 'src/app/inspect/inspect.service';
import { Subscription, EMPTY } from 'rxjs';
import { switchMap, tap, filter, catchError } from 'rxjs/operators';
import { Account } from '@shared/models/account';

@Component({
  selector: 'app-inspect-summary',
  templateUrl: './inspect-summary.component.html',
  styleUrls: ['./inspect-summary.component.scss'],
})
export class InspectSummaryComponent implements OnInit, OnDestroy {
  public CustomIcons = CustomIcons;
  public ButtonDisplay = ButtonDisplay;
  public ButtonType = ButtonType;
  public ButtonIconPosition = ButtonIconPosition;
  public LegendSize = LegendSize;
  public ChartSize = ChartSize;
  public DonutType = DonutType;

  public sessionsData: DonutChartDatapoint[] = [];
  public sessionsStatus: Status = Status.LOADING;

  private dataSubscription!: Subscription;

  public accountId?: string;

  constructor(
    private accountService: AccountService,
    private inspectService: InspectService
  ) {}

  ngOnInit(): void {
    this.sessionsStatus = Status.LOADING;
    this.dataSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(
        filter((account) => account?.id !== this.accountId),
        tap((account) => {
          if (account?.id !== this.accountId) {
            this.sessionsStatus = Status.LOADING;
          }
        }),
        switchMap((account: Account | undefined) =>
          this.inspectService
            .usageResources({
              accountId: account?.id,
              resource: 'sessions',
              interval: '7days',
            })
            .pipe(
              catchError(() => {
                this.accountId = account?.id;
                this.sessionsStatus = Status.ERROR;
                return EMPTY;
              })
            )
        )
      )
      .subscribe({
        next: (results) => {
          if (results) {
            if (
              results.meta.accountId &&
              results.meta.accountId !== this.accountId
            ) {
              this.accountId = results.meta.accountId;

              if (results.data.blocked === 0 && results.data.allowed === 0) {
                this.sessionsStatus = Status.NO_DATA;
              } else {
                this.sessionsData = [
                  {
                    aggregate: 'Blocked',
                    value: results.data.blocked || 0,
                  },
                  {
                    aggregate: 'Allowed',
                    value: results.data.allowed || 0,
                  },
                ];
                this.sessionsStatus = Status.COMPLETE;
              }
            }
          }
        },
        error: () => {
          this.sessionsStatus = Status.ERROR;
        },
      });
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
}
