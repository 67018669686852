import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';

import { CookieConsentType } from '@shared/models/cookie-consent-type';
import { ErrorData } from '@shared/models/error';

import * as StatusActions from './status.actions';
import * as AccountActions from '../accounts/accounts.actions';
import * as PartnerActions from '../partners/partners.actions';
import * as PermissionsActions from '../permissions/permissions.actions';
import { User } from '@shared/models/user';

interface ResetTunnelStatus {
  message: string;
  site: string;
  running: boolean;
}

export class StatusState {
  waiting!: boolean;
  error!: ErrorData | null;
  cookieConsent!: CookieConsentType;
  currentAccountId!: string | null;
  currentUser!: User | null;
  resetTunnelStatus!: ResetTunnelStatus[] | null;
}

const statusInitialState: StatusState = {
  waiting: false,
  error: null,
  cookieConsent: CookieConsentType.NONE,
  currentAccountId: null,
  currentUser: null,
  resetTunnelStatus: null,
};

/* Setup reducers */
export function reducer(state: StatusState | undefined, action: Action) {
  return statusReducer(state, action);
}

const handleError = (
  state: StatusState,
  action: { type: string; error: ErrorData }
) => {
  return {
    ...state,
    error: action.error,
    waiting: false,
  };
};

const setWaiting = (
  state: StatusState,
  action: { type: string; waiting: boolean }
) => {
  return {
    ...state,
    waiting: action.waiting,
  };
};

const updateResetTunnelStatus = (
  state: StatusState,
  action: { type: string; message: string; site: string; running: boolean }
) => {
  if (!state.resetTunnelStatus) {
    return state;
  }

  const newStatusArray = state.resetTunnelStatus.map((status) => {
    if (status.site === action.site) {
      return {
        message: action.message ?? status.message,
        site: action.site,
        running: action.running,
      };
    }

    return status;
  });

  return {
    ...state,
    resetTunnelStatus: newStatusArray,
  };
};

const addResetTunnelStatus = (
  state: StatusState,
  action: { type: string; message: string; site: string }
) => {
  const newStatusArray = [...(state.resetTunnelStatus ?? [])];
  if (state.resetTunnelStatus?.length) {
    const existingStatusInState = newStatusArray.findIndex(
      (status) => status.site === action.site
    );
    if (existingStatusInState !== -1) {
      newStatusArray[existingStatusInState] = {
        message: action.message,
        site: action.site,
        running: true,
      };
    } else {
      newStatusArray.push({
        message: action.message,
        site: action.site,
        running: true,
      });
    }
  } else {
    newStatusArray.push({
      message: action.message,
      site: action.site,
      running: true,
    });
  }

  return {
    ...state,
    resetTunnelStatus: newStatusArray,
  };
};

const startWaiting = (state: StatusState) => {
  return {
    ...state,
    waiting: true,
  };
};

const stopWaiting = (state: StatusState) => {
  return {
    ...state,
    waiting: false,
  };
};

const setCookieConsent = (
  state: StatusState,
  action: { type: string; cookieConsent: CookieConsentType }
) => {
  return {
    ...state,
    cookieConsent: action.cookieConsent,
  };
};

const setCurrentAccountId = (
  state: StatusState,
  action: { type: string; id: string }
) => {
  return {
    ...state,
    currentAccountId: action.id,
  };
};

const setCurrentUser = (state: StatusState, action: { user: User }) => {
  return {
    ...state,
    currentUser: action.user,
  };
};

const clearStatus = (state: StatusState) => {
  return {
    ...state,
    currentAccountId: null,
    currentUser: null,
    error: null,
    waiting: false,
  };
};

const statusReducer = createReducer(
  statusInitialState,
  on(StatusActions.handleError, handleError),
  on(StatusActions.setWaiting, setWaiting),
  on(StatusActions.setCookieConsent, setCookieConsent),
  on(AccountActions.fetchAccounts, startWaiting),
  on(AccountActions.setAccounts, stopWaiting),
  on(PartnerActions.fetchAllPartners, startWaiting),
  on(PartnerActions.setPartners, stopWaiting),
  on(PermissionsActions.fetchPermissions, startWaiting),
  on(PermissionsActions.setPermissions, stopWaiting),
  on(StatusActions.setCurrentAccountID, setCurrentAccountId),
  on(StatusActions.setCurrentUser, setCurrentUser),
  on(StatusActions.logout, clearStatus),
  on(StatusActions.addResetTunnelStatus, addResetTunnelStatus),
  on(StatusActions.updateResetTunnelStatus, updateResetTunnelStatus)
);

const selectUserFeatureState = createFeatureSelector<StatusState>('status');

export const selectWaiting = createSelector(
  selectUserFeatureState,
  (state) => state.waiting
);
export const selectError = createSelector(
  selectUserFeatureState,
  (state) => state.error
);
export const selectCookieConsent = createSelector(
  selectUserFeatureState,
  (state) => state.cookieConsent
);
export const selectCurrentAccountID = createSelector(
  selectUserFeatureState,
  (state) => state.currentAccountId
);
export const selectCurrentUser = createSelector(
  selectUserFeatureState,
  (state) => state.currentUser
);
export const selectResetTunnelStatus = (site: string) => {
  return createSelector(selectUserFeatureState, (state) => {
    return (
      state.resetTunnelStatus?.find((status) => status.site === site) ?? null
    );
  });
};
