<div class="firewall-rule-information__content">
  <h2 class="firewall-rule-information__title">Existing Rule Information</h2>
  <div
    *ngIf="ruleData && ruleData.policyid !== null"
    class="firewall-rule-information__fields"
  >
    <div class="firewall-rule-information__name">
      <app-firewall-name [name]="ruleData.name"> </app-firewall-name>
    </div>
    <app-firewall-address
      class="firewall-rule-information__source"
      [addressLocation]="AddressLocation.source"
      [addressData]="ruleData.srcaddr"
      [fontWeight]="FontWeight.MEDIUM"
      data-testid="firewall-detail-addressSrc"
    ></app-firewall-address>
    <app-firewall-address
      class="firewall-rule-information__destination"
      [addressLocation]="AddressLocation.destination"
      [addressData]="ruleData.dstaddr"
      [fontWeight]="FontWeight.MEDIUM"
      data-testid="firewall-detail-addressDst"
    ></app-firewall-address>
    <div class="firewall-rule-information__service">
      <app-firewall-service [data]="ruleData.service"></app-firewall-service>
    </div>
    <div class="firewall-rule-information__schedule">
      <app-firewall-schedule [data]="ruleData.schedule"></app-firewall-schedule>
    </div>
    <div class="firewall-rule-information__action">
      <app-firewall-action [data]="ruleData.action"></app-firewall-action>
    </div>
    <div class="firewall-rule-information__security">
      <app-firewall-action [data]="ruleData.action"></app-firewall-action>
    </div>
    <div class="firewall-rule-information__source-nat">
      <app-firewall-source-nat [data]="ruleData"></app-firewall-source-nat>
    </div>
  </div>
</div>
