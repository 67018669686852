import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthContainerComponent } from './widgets/auth-container/auth-container.component';
import { LoginComponent } from './pages/login/login.component';
import { TwoFactorAuthComponent } from './pages/two-factor-auth/two-factor-auth.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SetFirstPasswordComponent } from './pages/set-first-password/set-first-password.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AuthContainerComponent,
    children: [
      {
        path: '',
        canActivateChild: [AuthGuard],
        data: { isAuthRoute: true },
        children: [
          {
            path: 'login',
            component: LoginComponent,
          },
          {
            path: 'forgot-password',
            component: ForgotPasswordComponent,
          },
          {
            path: 'verify',
            component: TwoFactorAuthComponent,
          },
          {
            path: 'new-password',
            component: ResetPasswordComponent,
          },
          {
            path: 'set-first-password',
            component: SetFirstPasswordComponent,
          },
        ],
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
