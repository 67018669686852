import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import {
  TimeoutService,
  TimeoutStatus,
} from '@shared/services/timeout.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(-100%)' }),
        animate(100),
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class ContainerComponent implements OnInit {
  constructor(private timeoutService: TimeoutService) {}

  ngOnInit(): void {
    // Start timeout service if not already going (e.g. on page reload)
    if (this.timeoutService.status === TimeoutStatus.STOPPED) {
      this.timeoutService.start();
    }
  }
}
