import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Account } from '@shared/models/account';
import { Feature } from '@shared/models/features';
import { PartnerAccount } from '@shared/models/partner-account';
import { User } from '@shared/models/user';
import { AccountService } from '@shared/services/account.service';
import { PermissionsService } from '@shared/services/permissions.service';
import { UserService } from '@shared/services/user.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, skipWhile, take } from 'rxjs/operators';
import { environment } from '@environments/default/environment';
import { NavRoute, primaryNavData } from '@shared/models/nav-data';
import { Store } from '@ngrx/store';
import * as fromAccounts from '@store/accounts/accounts.selectors';
import { Router } from '@angular/router';
import { SelectOptions } from '@forms/models/form-select.model';
import { LogoSize } from '@shared/models/logo.model';
import { NavigationService } from '@core/navigation.service';

@Component({
  selector: 'app-primary-navigation',
  templateUrl: './primary-navigation.component.html',
  styleUrls: ['./primary-navigation.component.scss'],
})
export class PrimaryNavigationComponent implements OnInit, OnDestroy {
  @Input() isMobile: boolean = false;
  @Input() open: boolean = false;

  public user!: User | null;

  private userAccountPartnerSubscription!: Subscription;

  public currentAccount?: Account;
  public currentPartner?: PartnerAccount;
  public currentUser!: User | null;

  public version: string = '0.0.123';
  public environment = environment;
  public theme: string = environment.theme;

  public primaryNavData?: NavRoute[] = [];

  public Feature = Feature;
  public LogoSize = LogoSize;
  hideSupport: boolean | undefined;

  public accountList: SelectOptions[] = [];

  constructor(
    private userService: UserService,
    private accountService: AccountService,
    private permissionsService: PermissionsService,
    private store: Store,
    private router: Router,
    private navigationService: NavigationService
  ) {
    this.primaryNavData = primaryNavData;
  }

  /**
   * Init
   */
  ngOnInit(): void {
    const user: Observable<User | null> = this.userService
      .getCurrentUser()
      .pipe(
        skipWhile((v) => !v), // ignore the null that comes at logout
        take(1),
        distinctUntilChanged()
      );

    const account: Observable<Account | undefined> = this.accountService
      .fetchCurrentAccount()
      .pipe(
        take(1),
        distinctUntilChanged() // only take a values that's new (stops redrawing on double null for admins)
      );

    this.userAccountPartnerSubscription = combineLatest([
      user,
      account,
    ]).subscribe(([user, account]) => {
      if (account) {
        this.currentAccount = { ...account };
      }
      this.currentUser = user;
      this.drawNavMenu();
    });

    this.store
      .select(fromAccounts.selectAllAccounts)
      .pipe(take(1))
      .subscribe((accounts: any) => {
        this.accountList = accounts.map((singleAccount: Account) => ({
          label:
            singleAccount.companyName.length > 22
              ? singleAccount.companyName.substring(0, 23) + '...'
              : singleAccount.companyName,
          value: singleAccount.id,
        }));
      });
  }

  ngOnDestroy() {
    this.userAccountPartnerSubscription.unsubscribe();
  }

  private updateNavItems = (navData: NavRoute[]) => {
    navData = this.addSupportChildren(navData);
    let previousActiveItem: NavRoute;
    // Remove items from navData if needed
    return navData.map((navItem, index) => {
      if (!navItem.path || !navItem.data?.id) {
        return navItem;
      }

      const isFeatureAllowed = this.permissionsService.isFeatureAllowed(
        navItem.data?.id,
        this.currentAccount,
        this.currentUser
      );

      navItem.link = this.navigationService.getMenuLink(navItem);

      navItem.hidden = !isFeatureAllowed;
      navItem.newSection = !previousActiveItem
        ? true
        : navData[index].data?.sectionIndex !==
          previousActiveItem.data?.sectionIndex;
      if (isFeatureAllowed && !navItem.hidden && !navItem.disabled) {
        previousActiveItem = navItem;
      }
      return navItem;
    });
  };

  private addSupportChildren(navData: NavRoute[]) {
    const index = navData.findIndex(
      (navItem) => navItem.path === Feature.SUPPORT
    );
    const supportChildren = navData
      .find((navItem) => navItem.path == Feature.SUPPORT)
      ?.children?.map((supportChild) => ({
        ...supportChild,
        ...{ path: 'support/' + supportChild.path },
      }));

    if (index !== -1 && supportChildren) {
      navData = navData.filter((navItem) => navItem.path !== Feature.SUPPORT);

      return [
        ...navData.slice(0, index),
        ...supportChildren,
        ...navData.slice(index),
      ];
    }

    return navData;
  }

  /**
   * Once the users group has been retrieved, decide how to draw the menu:
   */
  public drawNavMenu() {
    if (this.primaryNavData) {
      this.primaryNavData = this.updateNavItems(this.primaryNavData);
    }
  }

  public accountChange(id: string) {
    this.accountService.updateLoadedAccountSubject(id);
    this.router.navigateByUrl('/');
  }
}
