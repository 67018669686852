<div class="comment">
  <div
    class="comment__header"
    [ngClass]="{
      'comment__header--recent': type === CommentType.RECENT
    }"
  >
    <p
      class="comment__title"
      [ngClass]="{
        'comment__title--recent': type === CommentType.RECENT
      }"
    >
      By {{ comment.author.name }} at
      {{ comment.date | date : "HH:mm" }}
      on
      {{ comment.date | date : "mediumDate" }}
    </p>
    <ng-container *ngIf="type !== CommentType.DEFAULT">
      <p
        class="comment__type comment__type--initial"
        *ngIf="type === CommentType.INITIAL"
      >
        Initial Request
      </p>
      <p
        class="comment__type comment__type--recent"
        *ngIf="type === CommentType.RECENT"
      >
        Most Recent
      </p>
    </ng-container>
  </div>
  <div class="comment__body">
    <div [innerHTML]="comment.comment"></div>
    <app-view-files [files]="comment.file"></app-view-files>
  </div>
</div>
