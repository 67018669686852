<h3 class="firewall-source-nat__title">Source NAT</h3>
<ng-container *ngIf="data?.sourcenatip">
  <h4 class="firewall-source-nat__subtitle">
    <app-icon
      [size]="IconSize.MEDIUM"
      [type]="IconType.OUTLINED"
      [status]="IconStatus.SUCCESS"
      title="Enabled"
      >done</app-icon
    >Enabled
  </h4>
  <ul class="firewall-source-nat__list">
    <li>
      <ng-container
        *ngIf="data?.sourcenatipb && data?.sourcenatip !== data?.sourcenatipb"
        >Primary:
      </ng-container>
      {{ data?.sourcenatip }}
    </li>
    <li *ngIf="data?.sourcenatipb && data?.sourcenatip !== data?.sourcenatipb">
      Secondary: {{ data.sourcenatipb }}
    </li>
  </ul>
  <p class="firewall-source-nat__text">Source NAT is enabled on this rule.</p>
</ng-container>
<ng-container *ngIf="!data?.sourcenatip">
  <h4 class="firewall-source-nat__subtitle">
    <app-icon
      [size]="IconSize.MEDIUM"
      [type]="IconType.OUTLINED"
      [status]="IconStatus.ERROR"
      title="Disabled"
      >close</app-icon
    >Disabled
  </h4>
  <p class="firewall-source-nat__text">Source NAT is disabled on this rule.</p>
</ng-container>
