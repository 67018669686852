import { Injectable } from '@angular/core';
import { CognitoService } from '@auth/services/cognito.service';
import { environment } from '@environments/default/environment';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private socket!: WebSocket;
  private intervalSub: { [key: string]: Subscription } = {};

  constructor(private cognitoService: CognitoService, private store: Store) {}

  connect(
    path: string,
    actionOnOpen: any,
    actionOnUpdate: any,
    actionOnClose: any,
    intervalSub?: Subscription,
    intervalSubId?: string
  ): void {
    if (intervalSub && intervalSubId) {
      this.intervalSub[intervalSubId] = intervalSub;
    }
    this.socket = new WebSocket(
      `${environment.apiBaseUrl
        .replace('api', 'ws-api')
        .replace(
          'https',
          'wss'
        )}/${path}?token=${this.cognitoService.getAmazonCredentials()}`
    );

    this.socket.onopen = () => {
      this.store.dispatch(actionOnOpen());
      console.log('WebSocket connection established.');
    };

    this.socket.onmessage = (event) => {
      const message = event.data;
      console.log('Received message:', message);
      this.store.dispatch(actionOnUpdate(message));
    };

    this.socket.onclose = (event) => {
      this.store.dispatch(actionOnClose());
      console.log('WebSocket connection closed:', event);
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  sendMessage(message: string): void {
    this.socket.send(message);
  }

  closeConnection(intervalSubId?: string): void {
    this.socket.close();
    if (intervalSubId) {
      this.intervalSub[intervalSubId].unsubscribe();
    }
  }
}
