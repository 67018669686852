<div class="container">
  <div class="row">
    <div class="column">
      <div class="content">
        <h1 class="first-heading">Cookie Policy for Cloud Gateway Portal</h1>

        <h2 class="second-heading">Introduction</h2>
        <p>
          Cloud Gateway Limited [“us”, “we”, or “our”] uses cookies on
          cloudgateway.co.uk and my.cloudgateway.co.uk [the “service”]. By using
          the service, you consent to the use of cookies.
        </p>
        <p>
          We use cookies to recognise your browser or device, learn more about
          your interests, and provide you with essential features and services.
        </p>

        <h2 class="second-heading">What are Cookies</h2>
        <p>
          As is common practice with almost all professional websites this site
          uses cookies, which are tiny files that are downloaded to your
          computer, to improve your experience. This page describes what
          information they gather, how we use it and why we sometimes need to
          store these cookies. We will also share how you can prevent these
          cookies from being stored however this may downgrade or 'break'
          certain elements of the site's functionality.
        </p>
        <p>
          For more general information on cookies, please read
          <a href="https://www.privacypolicyonline.com/what-are-cookies/"
            >"What Are Cookies"</a
          >.
        </p>

        <h2 class="second-heading">How Cloud Gateway Limited Uses Cookies</h2>
        <p>
          When you use and access the Service, we may place a number of cookie
          files in your web browser.
        </p>
        <p>
          We use cookies for a variety of reasons detailed below. Unfortunately
          in most cases there are no industry standard options for disabling
          cookies without completely disabling the functionality and features
          they add to this site. It is recommended that you leave on all cookies
          if you are not sure whether you need them or not in case they are used
          to provide a service that you use.
        </p>
        <p>
          We use both session and persistent cookies on the Service and we use
          different types of cookies to run the Service.
        </p>

        <h2 class="second-heading">Managing Cookies</h2>

        <h3 class="third-heading">Cookie preferences on Cloud Gateway sites</h3>
        <p>
          Our cookies allow you to take advantage of some essential and useful
          features. Blocking some types of cookies may impact your experience of
          our sites.
        </p>
        <p>
          If you'd like to delete cookies or instruct your web browser to delete
          or refuse cookies, please read the section below [Browser Settings].
        </p>

        <h3 class="third-heading">Browser Settings</h3>
        <p>
          You can manage browser cookies through your browser settings. The
          'Help' feature on most browsers will tell you how to remove cookies
          from your device, prevent your browser from accepting new cookies, how
          to have the browser notify you when you receive a new cookie, how to
          disable cookies, and when cookies will expire. Check the support site
          for your browser to understand privacy settings available to you.
        </p>
        <p>
          If you block or reject some of our cookies through your browser's
          settings, you might not be able to use certain services that require
          you to sign in to an account and some features and services may not
          work. You might also have to manually adjust your preferences or
          settings every time you visit Cloud Gateway sites. If your browser
          includes Intelligent Tracking Prevention (ITP), preferences specific
          to our sites may reset on an ongoing basis. These resets are managed
          by your browser, not by us.
        </p>
        <p>Therefore it is recommended that you do not disable cookies.</p>

        <h3 class="third-heading">What types of cookies do we use</h3>

        <h4 class="fourth-heading">Necessary cookies</h4>
        <p>
          Necessary cookies help make a website usable by enabling basic
          functions like page navigation and access to secure areas of the
          services. The service cannot function properly without these cookies.
        </p>
        <h5>Account related cookies</h5>
        <p>
          If you create an account with us then we will use cookies for the
          management of the signup process and general administration. These
          cookies will usually be deleted when you log out however in some cases
          they may remain afterwards to remember your site preferences when
          logged out.
        </p>
        <h5>Login related cookies</h5>
        <p>
          We use cookies when you are logged in so that we can remember this
          fact. This prevents you from having to log in every single time you
          visit a new page. These cookies are typically removed or cleared when
          you log out to ensure that you can only access restricted features and
          areas when logged in.
        </p>

        <h4 class="fourth-heading">Preferences cookies</h4>
        <p>
          Preference cookies enable a service to remember information that
          changes the way the service behaves or looks, like your preferred
          language or the region that you are in. In order to provide you with a
          great experience on this site we provide the functionality to set your
          preferences for how this site runs when you use it. In order to
          remember your preferences we need to set cookies so that this
          information can be called whenever you interact with a page that is
          affected by your preferences.
        </p>

        <h2 class="second-heading">Third Party Cookies</h2>

        <p>
          Approved third parties may also set cookies when you interact with our
          services. Third parties include search engines, providers of
          measurement and analytics services, social media networks, and
          advertising companies. These third parties use cookies in the process
          of delivering content, including ads relevant to your interests, to
          measure the effectiveness of their ads, and to perform services on
          behalf of Cloud Gateway.
        </p>
        <ul>
          <li>
            <p>
              The sites use Google Analytics which is one of the most widespread
              and trusted analytics solutions on the web for helping us to
              understand how you use the site and ways that we can improve your
              experience. These cookies may track things such as how long you
              spend on the site and the pages that you visit so we can continue
              to produce engaging content.
            </p>
            <p>
              For more information on Google Analytics cookies, see the official
              Google Analytics page.
            </p>
          </li>
          <li>
            From time to time we test new features and make subtle changes to
            the way that the site is delivered. When we are still testing new
            features these cookies may be used to ensure that you receive a
            consistent experience whilst on the site whilst ensuring we
            understand which optimisations our users appreciate the most.
          </li>
        </ul>
        <p>
          Below is a list of the third parties that may set cookies when you use
          Cloud Gateway Offerings. You can learn more about how these third
          parties use information collected through cookies by reviewing the
          privacy policies on their sites.
        </p>
        <table>
          <tbody>
            <tr>
              <td>Google</td>
              <td>google.com</td>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>analytics.google.com</td>
            </tr>
            <tr>
              <td>LinkedIn</td>
              <td>linkedin.com</td>
            </tr>
            <tr>
              <td>Twitter</td>
              <td>twitter.com</td>
            </tr>
            <tr>
              <td>YouTube</td>
              <td>youtube.com</td>
            </tr>
            <tr>
              <td>Hubspot</td>
              <td>hubspot.com</td>
            </tr>
            <tr>
              <td>Techtarget</td>
              <td>atechtarget.com</td>
            </tr>
            <tr>
              <td>Sentry</td>
              <td>sentry.io</td>
            </tr>
            <tr>
              <td>cookie-consent</td>
              <td>This is used to store your user cookie preference.</td>
            </tr>
          </tbody>
        </table>

        <p>
          We also embed features from third parties on our sites, and those
          third parties may set cookies if you engage with those features. For
          example, we embed chat functionality from Hubspot. These experiences
          set cookies on your device once you click on a chat link to maintain
          the open chat functionality for the duration of your session.
        </p>

        <h2 class="second-heading">More Information</h2>
        <p>
          Hopefully that has clarified things for you and as was previously
          mentioned if there is something that you aren't sure whether you need
          or not it's usually safer to leave cookies enabled in case it does
          interact with one of the features you use on our site.
        </p>
        <p>
          However if you are still looking for more information then you can
          contact us through one of these contact methods:
        </p>
        <ul>
          <li>
            By visiting this link:
            <a href="https://cloudgateway.co.uk/contact-us/"
              >https://cloudgateway.co.uk/contact-us/</a
            >
          </li>
          <li>
            By emailing
            <a href="mailto:info@cloudgateway.co.uk">info&#64;cloudgateway.co.uk</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
