<app-modal [size]="ModalSize.XSMALL" [status]="modalStatus">
  <div modal-header>Create Remote Access Users</div>
  <div modal-subheader>
    <app-modal-tabs
      (tabSwitched)="switchTab($event)"
      [tabs]="tabs"
    ></app-modal-tabs>
  </div>
  <div
    modal-body
    class="create-ssl-user__body"
    *ngIf="activeTab === 'create-user'"
  >
    <div class="create-ssl-user__content">
      <app-info-box
        >You can create new users in Remote Access using the form below. Once a
        user has been created they will appear in the 'Inactive users' list
        until they access the network. You can remove this user in the future
        via the 'Manage Users' option.</app-info-box
      >
      <h4 class="create-ssl-user__content-heading">New User Information</h4>
      <div class="create-ssl-user__fields">
        <app-form-field
          [type]="FieldType.TEXT"
          [name]="'first-name'"
          placeholder="First Name"
          [(inputValue)]="user.firstname"
          [errorMsg]="errors.firstname"
          [status]="formfieldStatus"
          [showErrorMsg]="true"
          (inputBlur)="handleBlur('firstname')"
          (inputFocus)="handleFocus('firstname')"
          >First Name
        </app-form-field>
        <app-form-field
          [type]="FieldType.TEXT"
          [name]="'last-name'"
          placeholder="Last Name"
          [(inputValue)]="user.lastname"
          [errorMsg]="errors.lastname"
          [status]="formfieldStatus"
          [showErrorMsg]="true"
          (inputBlur)="handleBlur('lastname')"
          (inputFocus)="handleFocus('lastname')"
          >Last Name
        </app-form-field>
        <app-form-field
          [type]="FieldType.TEXT"
          [name]="'email'"
          placeholder="Email"
          [(inputValue)]="user.email"
          [errorMsg]="errors.email"
          [status]="formfieldStatus"
          [showErrorMsg]="true"
          (inputBlur)="handleBlur('email')"
          (inputFocus)="handleFocus('email')"
          id="email"
          name="email"
          >Email Address
        </app-form-field>
      </div>
    </div>
  </div>
  <div modal-body *ngIf="activeTab === 'create-multiple-users'">
    <app-bulk-upload-users (modalClosed)="cancel()"></app-bulk-upload-users>
  </div>
  <div
    modal-footer
    class="create-ssl-user__footer"
    *ngIf="activeTab === 'create-user'"
  >
    <app-button data-testid="cancel-button" (buttonClick)="cancel()"
      >Cancel</app-button
    >
    <app-button
      (buttonClick)="save()"
      [type]="ButtonType.SUBMIT"
      [role]="ButtonRole.PRIMARY"
      [display]="ButtonDisplay.INLINE"
      [status]="submitButtonStatus"
      data-testid="save-button"
      >Create New User</app-button
    >
  </div>
</app-modal>
