import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Chart, ChartData, ChartType } from 'chart.js';
import * as defaultTheme from '../../themes/default.theme';
import { BaseChartDirective } from 'ng2-charts';
import {
  DonutChartOptions,
  DonutBaseChartDirective,
  DonutType,
} from '../../models/donut.model';
import { RoundedDonutController } from '../../chart-types/rounded-donut.chart';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
})
export class DonutChartComponent implements OnInit {
  @Input() donutType: DonutType = DonutType.COUNT;
  @Input() chartData: ChartData<'roundedDonut', any, any> = {
    datasets: [],
    labels: [],
  };
  @Input() chartLabels: any[] = [];
  @Input() chartOptions: DonutChartOptions = {};
  @Input() chartPlugins: any[] = [];
  @Input() chartLabelString: string | undefined;
  @Input() ariaLabel?: string;
  @ViewChild(BaseChartDirective) chartComponent:
    | DonutBaseChartDirective
    | undefined;

  @HostListener('window:resize')
  onWindowResize() {
    //debounce resize, wait for resize to finish before doing stuff
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      this.updateSize();
    }, 500);
  }
  private resizeTimeout!: ReturnType<typeof setTimeout>;
  private theme: any = defaultTheme;

  public chartType: ChartType = 'roundedDonut';

  constructor() {
    Chart.register(RoundedDonutController);
  }

  ngOnInit(): void {
    const colorOptions =
      this.donutType === DonutType.PERCENT
        ? this.theme.donutPercentChartColors
        : this.theme.donutChartColors;
    this.chartOptions = {
      ...this.chartOptions,
      ...colorOptions,
      donutType: this.donutType,
      ticks: {
        display: false,
      },
    };
  }

  private updateSize() {
    const chartCanvas = this.chartComponent?.chart?.canvas;
    if (chartCanvas) {
      chartCanvas.style.width = '0';
      setTimeout(() => {
        chartCanvas.style.width = 'auto';
        this.chartComponent?.chart.resize();
      }, 50);
    }
  }
}
