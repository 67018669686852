<div class="page container">
  <app-connect-summary
    *appPermitted="['ACC__CONNECT_SUMMARY']"
    id="connect-summary"
  ></app-connect-summary>
  <app-protect-summary
    *appPermitted="['ACC__PROTECT_SUMMARY']"
    id="protect-summary"
  ></app-protect-summary>
  <app-inspect-summary
    *appPermitted="['ACC__INSPECT_SUMMARY']"
    id="inspect-summary"
  ></app-inspect-summary>
  <app-account-overview
    id="account-overview"
    *appPermitted="['ACC__ACCOUNT_OVERVIEW']"
  ></app-account-overview>
  <app-support-tickets
    id="support-tickets"
    *appPermitted="[
      'SUPPORT__INCIDENTS__READ',
      'ACC__SUPPORT_INCIDENTS_SUMMARY'
    ]"
    [type]="TicketType.INCIDENTS"
  ></app-support-tickets>
</div>
