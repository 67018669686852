<app-modal
  [size]="ModalSize.XSMALL"
  [status]="modalStatus"
  class="raise-incident"
  [warningOnClose]="!isSubmitted"
>
  <div modal-header>Raise a new incident</div>
  <div modal-body>
    <form *ngIf="isSubmitted === false" modal-body class="form">
      <app-form-field
        [type]="FieldType.TEXT"
        [name]="'subject'"
        placeholder="Subject"
        class="raise-incident__form-field"
        [(inputValue)]="ticket.title"
        [errorMsg]="errors.title"
        (inputBlur)="handleBlur('title')"
        (inputFocus)="handleFocus('title')"
        >Subject</app-form-field
      >
      <div
        [ngClass]="{
          'raise-incident__priority-account': accounts.length > 1
        }"
      >
        <app-form-select
          [selectOptions]="prioritySelectOptions"
          [(selectedOption)]="ticket.priority"
          class="raise-incident__form-field"
          [errorMsg]="errors.priority"
          (selectBlur)="handleBlur('priority')"
          (selectFocus)="handleFocus('priority')"
          >Priority</app-form-select
        >
        <app-form-select
          *ngIf="accounts.length > 1"
          [selectOptions]="accountSelectOptions"
          [(selectedOption)]="ticket.accountId"
          class="raise-incident__form-field"
          [errorMsg]="errors.accountId"
          id="account-select"
          >Account</app-form-select
        >
      </div>
      <ng-container *ngIf="ticket.priority === 1">
        <div class="raise-incident__label">
          Is someone from your team available now to join a call if required?
        </div>
        <app-checkbox
          id="available_to_join"
          [checked]="ticket.available_to_join"
          type="checkbox"
        >
        </app-checkbox>
      </ng-container>

      <app-form-field
        [type]="FieldType.TEXT"
        [name]="'cc_emails'"
        [extraText]="{ text: 'OPTIONAL', pill: PillStatus.PRIMARY }"
        placeholder="e.g colleague@mycompany.com"
        class="raise-incident__form-field"
        [(inputValue)]="ccEmails"
        [errorMsg]="errors.cc_emails"
        (inputBlur)="handleBlur('cc_emails')"
        (inputFocus)="handleFocus('cc_emails')"
        >Copy people into this request (cc)</app-form-field
      >

      <app-info-box class="raise-incident__form-field">
        You can copy in users in the field above using a comma separated list.
        In order to ensure we can action your incident quickly,
        <strong
          >if applicable please copy in an authorised approver when
          submitting</strong
        >
        your ticket.
      </app-info-box>

      <app-form-field
        [type]="FieldType.TEXT"
        [name]="'impact'"
        class="raise-incident__form-field"
        [(inputValue)]="ticket.impact"
        [errorMsg]="errors.impact"
        [placeholder]="'i.e users are unable to connect to the network'"
        (inputBlur)="handleBlur('impact')"
        (inputFocus)="handleFocus('impact')"
        >How does this issue impact your service?</app-form-field
      >

      <app-form-field
        [type]="FieldType.TEXT"
        [name]="'affected_users'"
        class="raise-incident__form-field"
        [(inputValue)]="ticket.affected_users"
        [errorMsg]="errors.affected_users"
        [placeholder]="'i.e \'John Smith\', or john.smith@mycompany.com'"
        [extraText]="{ text: 'OPTIONAL', pill: PillStatus.PRIMARY }"
        (inputBlur)="handleBlur('affected_users')"
        (inputFocus)="handleFocus('affected_users')"
        >Details of known affected users:</app-form-field
      >

      <app-form-field
        [type]="FieldType.TEXTAREA"
        [name]="'description'"
        class="raise-incident__form-field"
        [(inputValue)]="ticket.description"
        [errorMsg]="errors.description"
        (inputBlur)="handleBlur('description')"
        (inputFocus)="handleFocus('description')"
        [placeholder]="'i.e a connection on my network is down'"
        >Describe the issue that you are experiencing?</app-form-field
      >
      <div class="raise-incident__label">
        Is your issue related to connectivity?
      </div>
      <app-form-toggle
        class="raise-incident__toggle-field"
        [toggleOptions]="toggleOptions"
        [(selectedOption)]="ticket.related_to_connectivity"
        [type]="ToggleType.RADIO"
      ></app-form-toggle>
      <ng-container *ngIf="ticket.related_to_connectivity === 'yes'">
        <div class="raise-incident__ip-fields">
          <app-form-field
            [type]="FieldType.TEXT"
            [name]="'srcip'"
            class="raise-incident__form-field"
            [(inputValue)]="ticket.srcip"
            [errorMsg]="errors.srcip"
            (inputBlur)="handleBlur('srcip')"
            (inputFocus)="handleFocus('srcip')"
            [placeholder]="'Source IP'"
            [extraText]="{ text: 'OPTIONAL', pill: PillStatus.PRIMARY }"
            >Source IP</app-form-field
          >
          <app-form-field
            [type]="FieldType.TEXT"
            [name]="'dstip'"
            class="raise-incident__form-field"
            [(inputValue)]="ticket.dstip"
            [errorMsg]="errors.dstip"
            (inputBlur)="handleBlur('dstip')"
            (inputFocus)="handleFocus('dstip')"
            [placeholder]="'Destination IP'"
            [extraText]="{ text: 'OPTIONAL', pill: PillStatus.PRIMARY }"
            >Destination IP</app-form-field
          >
        </div>
      </ng-container>
      <app-file-picker
        (uploadSuccess)="onUploadSuccess($event)"
        (removeSuccess)="onRemoveSuccess($event)"
        class="raise-incident__form-field"
        [fileOptions]="fileOptions"
        [label]="
          'Please provide any screenshots or a screen recording of the issue (if applicable).'
        "
      ></app-file-picker>

      <div class="raise-incident__text">
        <p>
          Once received Cloud Gateway will assess the request in order to
          determine whether the requester is authorised to request the change
          and whether we have all the required information to implement the
          change. Where further information / clarification is required, we will
          contact the requester.
        </p>
        <p>
          Service Requests raised with Cloud Gateway may incur a charge, please
          refer to your <abbr title="Service Level Agreement">SLA</abbr> for
          applicable costs.
        </p>
      </div>
    </form>
  </div>

  <div
    *ngIf="isSubmitted === false"
    modal-footer
    class="raise-incident__buttons"
  >
    <app-button (buttonClick)="cancel()">Cancel</app-button>
    <app-button
      [type]="ButtonType.SUBMIT"
      [role]="ButtonRole.PRIMARY"
      (buttonClick)="raiseIncident()"
      data-testid="submit-incident-button"
      [status]="submitButtonStatus"
      *appPermitted="['SUPPORT__INCIDENTS__CREATE']"
    >
      Raise incident
    </app-button>
  </div>

  <div
    *ngIf="isSubmitted === true"
    modal-confirmation
    class="modal-confirmation"
  >
    <div class="modal-confirmation__icon-container">
      <app-icon
        title="Success"
        class="modal-confirmation__icon"
        [type]="IconType.OUTLINED"
        >check_circle</app-icon
      >
    </div>

    <p>Incident raised successfully</p>

    <app-button
      class="modal-confirmation--view-ticket-button"
      [role]="ButtonRole.PRIMARY"
      (buttonClick)="viewTicketDetails()"
    >
      VIEW TICKET
    </app-button>

    <div class="modal-confirmation--major-incident-info">
      <p>
        When raising a major incident we recommend calling our service desk on
      </p>
      <p>
        <a href="tel:00441616961000"
          ><abbr title="Call: +44 161 696 1000">+44 (0)161 696 1000</abbr></a
        >
      </p>
    </div>
  </div>
</app-modal>
