<div
  class="donut-chart-legend donut-chart-legend--{{ size }}"
  [ngClass]="{
    'has-legend--top': legendPosition === LegendPosition.TOP,
    'has-legend--right': legendPosition === LegendPosition.RIGHT,
    'has-legend--bottom': legendPosition === LegendPosition.BOTTOM,
    'has-legend--left': legendPosition === LegendPosition.LEFT,
    'donut-chart-legend--right': legendPosition === LegendPosition.RIGHT,
    'is-loading': status === Status.LOADING,
    'has-error': status === Status.ERROR,
    'no-data': status === Status.NO_DATA
  }"
>
  <app-legend
    class="donut-chart-legend__legend chart__legend"
    [items]="legendItems"
    [size]="legendSize"
    [type]="legendType"
    [headings]="legendHeadings"
    [status]="status"
  ></app-legend>
  <div class="donut-chart-legend__chart">
    <app-donut-chart
      class="donut-chart-legend__canvas"
      [chartData]="chartData"
      [chartLabels]="chartLabels"
      [chartOptions]="chartOptions"
      [donutType]="type"
      [ariaLabel]="canvasAriaLabel"
    ></app-donut-chart>
    <div class="donut-chart-legend__skeleton">
      <app-chart-loading *ngIf="status === Status.LOADING"></app-chart-loading>
      <app-chart-error *ngIf="status === Status.ERROR"></app-chart-error>
      <app-chart-error
        *ngIf="status === Status.NO_DATA"
        [errorText]="'There is no data to show'"
      ></app-chart-error>
    </div>
  </div>
</div>
