import { Component, Input } from '@angular/core';
import {
  ButtonStyle,
  ButtonType,
  ButtonRole,
} from '@shared/models/button.model';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
})
export class ContactCardComponent {
  @Input() name: string | undefined;
  @Input() position: string | undefined;
  @Input() image: string | undefined;
  @Input() contact: string | undefined;

  public ButtonType = ButtonType;
  public ButtonStyle = ButtonStyle;
  public ButtonRole = ButtonRole;

  constructor() {}
}
