import { Component, OnDestroy, OnInit } from '@angular/core';
import { Account } from '@shared/models/account';
import {
  ButtonRole,
  ButtonSize,
  ButtonStatus,
  ButtonType,
} from '@shared/models/button.model';
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import { Status } from '@shared/models/status.model';
import { AccountService } from '@shared/services/account.service';
import { ErrorService } from '@shared/services/error.service';
import { ModalService } from '@shared/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FieldType } from '@forms/models/form-field.model';
import { ConnectService } from '../../connect.service';
import { SSLVPN } from '../../models/ssl-vpn.model';
import { Store } from '@ngrx/store';
import * as StatusActions from '@store/status/status.actions';
import * as AccountsActions from '@store/accounts/accounts.actions';
import * as fromStatus from '@store/status/status.reducer';
import { AlertService } from '@shared/services/alert.service';

@Component({
  selector: 'app-delete-ssl-users',
  templateUrl: './delete-ssl-users.component.html',
  styleUrls: ['./delete-ssl-users.component.scss'],
})
export class DeleteSSLUsersComponent implements OnInit, OnDestroy {
  public ModalSize = ModalSize;
  public TableStatus = Status;
  public ButtonSize = ButtonSize;
  public ButtonRole = ButtonRole;
  public ButtonType = ButtonType;
  public ButtonStatus = ButtonStatus;
  public Status = Status;
  public FieldType = FieldType;
  public ModalStatus = ModalStatus;

  private accountSubscription!: Subscription;
  private accountData!: Account;

  public modalStatus: ModalStatus = ModalStatus.OPEN;
  public modalCloseMessage: string =
    'Closing this modal will discard all changes. Are you sure you want to close the modal?';
  public selectedAccounts: SSLVPN[] = [];
  public tableStatus: Status = Status.LOADING;
  public buttonStatus: ButtonStatus = ButtonStatus.LOADING;
  public searchText: string = '';
  public deleteField: string = '';
  public deleteError: string = '';
  private waitingSubscription?: Subscription;
  private waiting$ = this.store.select(fromStatus.selectWaiting);

  constructor(
    private accountService: AccountService,
    private connectService: ConnectService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private store: Store,
    public alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(take(1))
      .subscribe((account?: Account) => {
        if (account && account.id) {
          this.accountData = account;
          const sslVpnContext = this.modalService.getContext();
          if (!sslVpnContext) {
            this.modalStatus = ModalStatus.CLOSED;
          } else {
            this.selectedAccounts = sslVpnContext;
            this.tableStatus = Status.COMPLETE;
          }
        }
      });
    this.waitingSubscription = this.waiting$.subscribe((val) => {
      this.buttonStatus = !val ? ButtonStatus.ACTIVE : ButtonStatus.LOADING;
    });
  }

  ngOnDestroy() {
    this.accountSubscription?.unsubscribe();
    this.waitingSubscription?.unsubscribe();
  }

  public cancel(): void {
    this.alertService.throwConfirmation(
      this.modalCloseMessage,
      'Close',
      'Warning',
      () => (this.modalStatus = ModalStatus.CLOSED)
    );
  }

  public deleteUsers(): void {
    const errorMessage = 'Please enter "Delete" in the text field';
    this.deleteError = '';
    if (!this.deleteField) {
      this.deleteError = errorMessage;
      return;
    }
    const input = this.deleteField.toLowerCase();
    if (input !== 'delete') {
      this.deleteError = errorMessage;
      return;
    }
    // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(StatusActions.setWaiting({ waiting: true }));
    this.connectService
      .bulkUserDelete(this.selectedAccounts, this.accountData.id)
      .subscribe({
        next: () => {
          const userString =
            this.selectedAccounts.length === 1 ? 'user' : `users`;
          const verbString =
            this.selectedAccounts.length === 1 ? 'has' : `have`;
          this.modalStatus = ModalStatus.CLOSED;
          // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
          this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
          this.toastr.success(
            `${this.selectedAccounts.length} ${userString} ${verbString} been deleted.`,
            'Success',
            {
              progressBar: true,
            }
          );
          // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
          this.store.dispatch(
            AccountsActions.removeSSLUsers({
              sslUsers: this.selectedAccounts,
              accountId: this.accountData.id,
            })
          );
        },
        error: (err) => {
          // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
          this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
          this.errorService.handleSaveErrors(err);
        },
      });
  }
}
