import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Account } from '@shared/models/account';
import { FirewallAddRequest } from '@shared/models/firewall-change-request.model';
import { AccountService } from '@shared/services/account.service';
import { Subscription } from 'rxjs';
import { FieldType } from '@forms/models/form-field.model';
import { ValidationError } from '@shared/models/error';
import { PillStatus } from '@shared/models/pill.model';
import { SupportValidationService } from '@support/support-validation.service';

@Component({
  selector: 'app-firewall-rule-add',
  templateUrl: './firewall-rule-add.component.html',
  styleUrls: ['./firewall-rule-add.component.scss'],
})
export class FirewallRuleAddComponent implements OnInit, OnDestroy {
  @Input() formDataInput!: FirewallAddRequest;
  @Output() FormUpdated = new EventEmitter<FirewallAddRequest>();
  @Output() FormValid = new EventEmitter<boolean>();

  // Styling and data constants
  public FieldType = FieldType;
  public PillStatus = PillStatus;

  public formData!: FirewallAddRequest;

  public errors: ValidationError = {
    name: [],
    sourceAddress: [],
    destinationAddress: [],
    service: [],
    securityProfile: [],
    sourceNat: [],
    destinationNat: [],
    urls: [],
    comment: [],
    cc_emails: [],
  };

  public accountSubscription!: Subscription;

  constructor(
    private accountService: AccountService,
    private supportValidationService: SupportValidationService
  ) {}

  ngOnInit(): void {
    this.formData = this.formDataInput;

    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .subscribe((account?: Account) => {
        if (account?.id) {
          this.formData.accountId = account.id;
        }
      });
  }

  ngOnDestroy(): void {
    this.accountSubscription?.unsubscribe();
  }

  /**
   * Handle the focus event from the form field
   * @param prop Name of the property to clear
   */
  public handleFocus(prop: string): void {
    this.errors[prop] = [];
  }

  /**
   * Handle the blur event from the form field
   * @param prop Name of the property to validate
   */
  public handleBlur(prop: string) {
    const validation = this.supportValidationService.validateFirewallAddRequest(
      this.formData
    );
    if (validation === true) {
      this.errors[prop] = [];
    } else {
      this.errors[prop] = validation[prop];
    }
  }

  /**
   * Determines state of submit button
   */
  public handleFormUpdate(): void {
    if (this.isFormValid()) {
      this.FormValid.emit(true);
    } else {
      this.FormValid.emit(false);
    }
    this.FormUpdated.emit(this.formData);
  }

  /**
   * Checks validation errors and adds them to the page
   * @return boolean
   */
  public isFormValid(): boolean {
    const validationResponse =
      this.supportValidationService.validateFirewallAddRequest(this.formData);

    return validationResponse === true ? true : false;
  }
}
