import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-firewall-service',
  templateUrl: './firewall-service.component.html',
  styleUrls: ['./firewall-service.component.scss'],
})
export class FirewallServiceComponent {
  @Input() data: any;

  constructor() {}
}
