<app-panel class="set-first-password">
  <ng-container panel-title>Set New Password</ng-container>
  <ng-container panel-subtitle>
    <p>Setting new password for account:</p>
    <p class="set-first-password__username">{{ username }}</p>
  </ng-container>
  <form panel-content class="set-first-password__form">
    <!-- Hidden field for password managers to pick up -->
    <app-form-field
      [type]="FieldType.TEXT"
      name="username"
      class="set-first-password__form-fields__username"
      [inputValue]="username ?? null"
      >Username</app-form-field
    >
    <app-form-field
      [type]="FieldType.PASSWORD"
      name="password"
      [(inputValue)]="password"
      [errorMsg]="errorMsg"
      [showErrorMsg]="false"
      [hideLabel]="true"
      placeholder="Enter your new password"
      preIcon="lock"
      [pattern]="PasswordPattern"
      autocomplete="new-password"
      [required]="true"
      >New Password</app-form-field
    >
    <app-form-field
      [type]="FieldType.PASSWORD"
      name="confirm-password"
      [(inputValue)]="confirmPassword"
      [errorMsg]="errorMsg"
      [showErrorMsg]="false"
      [hideLabel]="true"
      placeholder="Re-enter your new password"
      preIcon="lock"
      [pattern]="PasswordPattern"
      autocomplete="new-password"
      [required]="true"
      >Confirm New Password</app-form-field
    >
    <app-password-strength
      [minChars]="14"
      [password]="password"
      [passwordConfirm]="confirmPassword"
      (validityChanged)="passwordValidityChanged($event)"
    ></app-password-strength>
    <app-button
      [display]="ButtonDisplay.BLOCK"
      [role]="ButtonRole.PRIMARY"
      [type]="ButtonType.SUBMIT"
      [status]="passwordValid ? buttonStatus : ButtonStatus.DISABLED"
      (buttonClick)="setNewPassword($event)"
      >Set New Password</app-button
    >
  </form>
</app-panel>
