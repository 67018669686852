import { ActionReducerMap } from '@ngrx/store';

import * as fromNews from './news/news.reducer';
import * as fromStatus from './status/status.reducer';
import * as fromPartners from './partners/partners.reducer';
import * as fromAccounts from './accounts/accounts.reducer';
import * as fromPermissions from './permissions/permissions.reducer';
import * as fromUsers from './users/users.reducer';

export interface State {
  news: fromNews.NewsState;
  status: fromStatus.StatusState;
  partners: fromPartners.PartnerState;
  accounts: fromAccounts.AccountsState;
  permissions: fromPermissions.PermissionsState;
  users: fromUsers.UserState;
}

export const reducers: ActionReducerMap<State> = {
  news: fromNews.reducer,
  status: fromStatus.reducer,
  partners: fromPartners.reducer,
  accounts: fromAccounts.reducer,
  permissions: fromPermissions.reducer,
  users: fromUsers.reducer,
};
