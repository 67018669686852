import {
  ActiveElement,
  BarProps,
  ChartType,
  Point,
  Tooltip,
  TooltipPosition,
  TooltipPositionerFunction,
} from 'chart.js';

/**
 * Custom positioner
 * @function Chart.Tooltip.positioners.centre
 * @param elements {Chart.Element[]} the tooltip elements
 * @param eventPosition {Point} the position of the event in canvas coordinates
 * @returns {Point} the tooltip position
 */
Tooltip.positioners.centre = (
  elements: readonly ActiveElement[],
  eventPosition: Point
): TooltipPosition | false => {
  if (elements.length === 0) {
    return false;
  }

  // Centre is half way between x and y bases
  const element0 = elements[0].element as unknown as BarProps;
  const element1 = elements[1].element as unknown as BarProps;
  const yPos = (element0.base + element1.base) / 2;

  return {
    x: eventPosition.x,
    y: yPos,
  };
};

// declare module 'chart.js' {
//   interface TooltipPositionerMap {
//     centre: TooltipPositionerFunction<ChartType>;
//   }
// }
