import { Component, Input, OnInit } from '@angular/core';
import { DatatableType } from '@shared/models/datatable.model';
import { Status } from '@shared/models/status.model';
import { LegendItem, LegendSize, LegendType } from '../../models/legend.model';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
})
export class LegendComponent implements OnInit {
  @Input() items: LegendItem[][] = [[]];
  @Input() size: LegendSize = LegendSize.SMALL;
  @Input() type: LegendType = LegendType.LIST;
  @Input() headings?: string[];
  @Input() status?: Status;

  public LegendSize = LegendSize;
  public LegendType = LegendType;
  public Status = Status;
  public DatatableType = DatatableType;

  public displayedColumns = ['colour', 'label', 'value'];
  public headingOne!: string;
  public headingTwo!: string;

  ngOnInit() {
    if (this.headings?.length) {
      this.headingOne = this.headings[1] ? this.headings[1] : 'Label';
      this.headingTwo = this.headings[2] ? this.headings[2] : 'Value';
    }
  }
}
