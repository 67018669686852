<app-modal [size]="ModalSize.XSMALL" [status]="modalStatus">
  <div modal-header>Delete users</div>
  <div modal-body>
    <form>
      <p>
        You are deleting
        <strong>{{
          selectedAccounts.length === 1
            ? "one user"
            : selectedAccounts.length + " users"
        }}</strong>
      </p>

      <app-datatable
        class="delete-ssl-users__table"
        [data]="selectedAccounts"
        [displayedColumns]="['email']"
        [limit]="10"
        [sort]="['email', 'desc']"
        [status]="tableStatus"
        [showFooter]="false"
      >
        <!-- email column -->
        <ng-container cdkColumnDef="email">
          <th cdk-header-cell *cdkHeaderCellDef sort-header>E-Mail</th>
          <td cdk-cell *cdkCellDef="let row">{{ row.email }}</td>
        </ng-container>
      </app-datatable>

      <p>Type the word "Delete" to continue:</p>
      <app-form-field
        class="delete-ssl-users__delete-field"
        [type]="FieldType.TEXT"
        [(inputValue)]="deleteField"
        name="delete-field"
        placeholder="Delete"
        [errorMsg]="deleteError"
      ></app-form-field>
      <app-button-group>
        <app-button
          [status]="ButtonStatus.ACTIVE"
          [size]="ButtonSize.LARGE"
          [role]="ButtonRole.DEFAULT"
          (buttonClick)="cancel()"
          >Cancel</app-button
        >
        <app-button
          [status]="buttonStatus"
          [role]="ButtonRole.PRIMARY"
          [size]="ButtonSize.LARGE"
          (buttonClick)="deleteUsers()"
          >Delete
          {{
            selectedAccounts.length === 1
              ? "one user"
              : selectedAccounts.length + " users"
          }}</app-button
        >
      </app-button-group>
    </form>
  </div>
</app-modal>
