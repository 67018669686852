import { Component, Input } from '@angular/core';
import { AddressType } from '@shared/models/firewall.datatypes';
import { IconSize, IconStatus } from '@shared/models/icon.model';

@Component({
  selector: 'app-address-type-icon',
  templateUrl: './address-type-icon.component.html',
  styleUrls: ['./address-type-icon.component.scss'],
})
export class AddressTypeIconComponent {
  @Input() addressType!: AddressType;
  @Input() iconStatus: IconStatus = IconStatus.DEFAULT;

  public AddressType = AddressType;
  public IconSize = IconSize;

  constructor() {}
}
