<div class="add-comment">
  <section class="add-comment__comment">
    <div class="add-comment__comment__header">New Comment</div>
    <app-form-field
      [type]="FieldType.TEXTAREA"
      [name]="'comment'"
      placeholder="Enter your new comment here."
      class="raise-ticket__form-field"
      [(inputValue)]="comment.comment"
      [showErrorMsg]="errors.comment !== null"
      [errorMsg]="errors.comment"
      (inputBlur)="handleBlur('comment')"
      (inputFocus)="handleFocus('comment')"
      [hideLabel]="true"
    >
    </app-form-field>
  </section>
  <section class="add-comment__file-upload">
    <fieldset class="form__group">
      <label class="form__label" for="file">
        <div class="form__text">Need to upload a file?</div>
        <app-file-picker
          (uploadSuccess)="onUploadSuccess($event)"
          (removeSuccess)="onRemoveSuccess($event)"
          [fileOptions]="fileOptions"
        ></app-file-picker>
      </label>
    </fieldset>
  </section>
  <section class="add-comment__actions">
    <app-button
      class="button button--cancel"
      [type]="ButtonType.BUTTON"
      [role]="ButtonRole.DELETE"
      (buttonClick)="discardComment()"
      data-testid="discard-comment-button"
    >
      Discard comment
    </app-button>
    <app-button
      class="button button--primary"
      [role]="ButtonRole.PRIMARY"
      [iconName]="'add_box'"
      [iconType]="IconType.OUTLINED"
      (buttonClick)="addComment()"
      data-testid="submit-comment-button"
      [status]="addCommentStatus"
    >
      Add comment
    </app-button>
  </section>
</div>
