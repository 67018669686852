import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  provideCharts,
  withDefaultRegisterables,
  BaseChartDirective,
} from 'ng2-charts';
import { SharedModule } from '@shared/shared.module';

/* Components */
import { ChartLoadingComponent } from './components/chart-loading/chart-loading.component';
import { ChartErrorComponent } from './components/chart-error/chart-error.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { LegendComponent } from './components/legend/legend.component';

/* Widgets */
import { BandwidthChartComponent } from './widgets/bandwidth-chart/bandwidth-chart.component';
import { DonutChartLegendComponent } from './widgets/donut-chart-legend/donut-chart-legend.component';
import { PercentageDonutChartComponent } from './widgets/percentage-donut-chart/percentage-donut-chart.component';
import { LineChartLegendComponent } from './widgets/line-chart-legend/line-chart-legend.component';

@NgModule({
  declarations: [
    ChartLoadingComponent,
    ChartErrorComponent,
    BarChartComponent,
    DonutChartComponent,
    LineChartComponent,
    LegendComponent,
    BandwidthChartComponent,
    DonutChartLegendComponent,
    PercentageDonutChartComponent,
    LineChartLegendComponent,
  ],
  exports: [
    BandwidthChartComponent,
    DonutChartLegendComponent,
    PercentageDonutChartComponent,
    LineChartLegendComponent,
    LegendComponent,
  ],
  providers: [provideCharts(withDefaultRegisterables())],
  imports: [CommonModule, SharedModule, BaseChartDirective],
})
export class ChartsModule {}
