import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Router } from '@angular/router';

import { CognitoResponse } from '../models/cognito-response';
import { CognitoService } from './cognito.service';
import { CognitoErrorResponse } from '../models/cognito-errors';
import { Auth } from 'aws-amplify';

@Injectable()
export class CognitoLogoutService {
  // Route to go to once logged out
  public logoutRoute: string = '/login';

  /**
   * Logout service.
   * @param { Router } router - provider for routing servies.
   * @param { CognitoService } cognitoService - core service provider for shared functionality.
   */
  constructor(private router: Router, private cognitoService: CognitoService) {}

  /**
   * Log the user out.
   * @return { Observable<CognitoResponse>} response.
   */
  public logout(errorMsg: string | null = null): Observable<CognitoResponse> {
    return new Observable((observer: Observer<CognitoResponse>) => {
      const opts: any = {
        global: true,
      };

      Auth.signOut(opts)
        .then(() => {
          this.router.navigateByUrl(this.logoutRoute, { state: { errorMsg } });
          this.cognitoService.success(observer);
        })
        .catch((reason: CognitoErrorResponse) => {
          this.cognitoService.handleAuthError(
            reason,
            observer,
            'generic_logout_failed'
          );
        });
    });
  }
}
