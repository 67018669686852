<div class="secondary-navigation">
  <ng-container *ngIf="children?.length">
    <ng-container *ngFor="let child of children">
      <a
        *ngIf="!child.hidden"
        class="secondary-navigation__tab"
        [routerLink]="['/' + currentRoute?.path + '/' + child.path]"
        routerLinkActive="secondary-navigation__tab--active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ child.data?.title }}
      </a>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!children?.length">
    <p
      class="secondary-navigation__textbox"
      *ngIf="currentRoute?.path === 'dashboard'"
    >
      You are currently viewing your {{ currentAccount?.companyName }} account.
      <span
        class="secondary-navigation__textbox--highlight"
        *ngIf="multiUserAccount"
      >
        <a [routerLink]="['/summary']">Change account.</a>
      </span>
    </p>
    <p
      class="secondary-navigation__textbox"
      *ngIf="currentRoute?.path === 'summary'"
    >
      You are currently viewing your
      <span class="secondary-navigation__textbox--highlight"
        >Account List.</span
      >
    </p>
  </ng-container>
</div>
