import {
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChartDataset, ChartType } from 'chart.js';
import { LineAnnotationOptions } from 'chartjs-plugin-annotation';
import { BaseChartDirective } from 'ng2-charts';
import {
  BarBaseChartDirective,
  BarChartOptions,
} from '../../models/bar-chart.model';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnChanges {
  @Input() chartData: ChartDataset[] = [];
  @Input() chartLabels: any[] = [];
  @Input() chartOptions: BarChartOptions = {};
  @Input() chartColors: any[] = [];
  @Input() chartLabelString: string | undefined;
  @ViewChild(BaseChartDirective) chartComponent!: BarBaseChartDirective;

  public chartType: ChartType = 'bar';

  @HostListener('window:resize')
  onWindowResize() {
    //debounce resize, wait for resize to finish before doing stuff
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      this.updateSize();
    }, 500);
  }
  private resizeTimeout!: ReturnType<typeof setTimeout>;

  ngOnChanges(changes: SimpleChanges) {
    // Update scale label
    if (
      changes?.chartLabelString?.currentValue !== '' &&
      this.chartOptions?.scales?.y?.title
    ) {
      this.chartOptions.scales.y.title.text = this.chartLabelString;
    }

    // Update the annotation values on the chart
    if (this.chartOptions?.plugins?.annotation?.annotations) {
      Object.values(this.chartOptions.plugins.annotation.annotations).forEach(
        (annotation: any): void => {
          const annotationId = annotation?.id;
          if (!annotationId) {
            return;
          }
          if (
            this.chartComponent?.chart?.annotation.elements[annotationId]
              .options
          ) {
            this.chartComponent.chart.annotation.elements[
              annotationId
            ].options.value = (annotation as LineAnnotationOptions).value;
          }
        }
      );
    }
  }

  updateSize() {
    const chartCanvas = this.chartComponent?.chart?.canvas;
    if (chartCanvas) {
      chartCanvas.style.width = '0';
      setTimeout(() => {
        chartCanvas.style.width = 'auto';
        this.chartComponent.chart.resize();
      }, 50);
    }
  }
}
