<div
  class="bandwidth-chart bandwidth-chart--{{ size }}"
  [ngClass]="{
    'has-legend--top': legendPosition === LegendPosition.TOP,
    'has-legend--right': legendPosition === LegendPosition.RIGHT,
    'has-legend--bottom': legendPosition === LegendPosition.BOTTOM,
    'has-legend--left': legendPosition === LegendPosition.LEFT,
    'is-loading': status === Status.LOADING,
    'has-error': status === Status.ERROR || status === Status.NOT_AVAILABLE
  }"
>
  <app-legend
    *ngIf="legendPosition"
    class="bandwidth-chart__legend chart__legend"
    [items]="legendItems"
    [size]="legendSize"
    [type]="legendType"
    [headings]="legendHeadings"
  ></app-legend>
  <div class="bandwidth-chart__chart">
    <app-bar-chart
      *ngIf="status === Status.COMPLETE"
      class="bandwidth-chart__canvas"
      [chartData]="bandwidthChartData"
      [chartLabels]="bandwidthChartLabels"
      [chartOptions]="bandwidthChartOptions"
      [chartColors]="bandwidthChartColors"
      [chartLabelString]="bandwidthBpsUnit"
    ></app-bar-chart>
    <div class="bandwidth-chart__skeleton">
      <app-chart-loading *ngIf="status === Status.LOADING"></app-chart-loading>
      <app-chart-error *ngIf="status === Status.ERROR"></app-chart-error>
      <app-chart-error
        *ngIf="status === Status.NOT_AVAILABLE"
        errorText="VPN only account - no bandwidth data to display"
      ></app-chart-error>
    </div>
  </div>
</div>
