import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { User } from '@shared/models/user';

import { ApiService } from '@shared/services/api.service';

import * as UserActions from './users.actions';
import * as StatusActions from '../status/status.actions';

@Injectable()
export class UserEffects {
  fetchAllUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.FETCH_ALL_USERS),
      mergeMap(() => {
        return this.apiService.get(`/users/all`).pipe(
          map((users: User[]) => UserActions.setUsers({ users })),
          catchError((err) => of(StatusActions.handleError(err)))
        );
      })
    );
  });

  constructor(private apiService: ApiService, private actions$: Actions) {}
}
