import { createAction, props } from '@ngrx/store';

import { NewsStory } from '@shared/models/news-story.model';
import { NewsState } from './news.reducer';

// export const GET_NEWS = '[News] Get News';
export const SET_NEWS = '[News] Set News';
export const FETCH_NEWS = '[News] Fetch News';

// export const getNews = createAction(GET_NEWS);
export const setNews = createAction(SET_NEWS, props<{ news: NewsStory[] }>());
export const fetchNews = createAction(FETCH_NEWS);
