<div title="Threat country infographic" class="top-country top-country--{{ status }}">
  <app-loading-error *ngIf="status === Status.ERROR"
    >There's been a problem</app-loading-error
  >
  <app-loading-error *ngIf="status === Status.NOT_AVAILABLE"
    >The data is unavailable</app-loading-error
  >
  <app-loading *ngIf="status === Status.LOADING"></app-loading>
  <ng-container *ngIf="status === Status.COMPLETE">
    <app-country-outline
      class="top-country__outline"
      [country]="country"
    ></app-country-outline>
    <div class="top-country__data">
      <app-country-flag
        class="top-country__flag"
        [country]="country"
      ></app-country-flag>
      <p class="top-country__name">{{ countryName }}</p>
      <p class="top-country__value">{{ value | number: "1.0-0":"en-GB" }}</p>
    </div>
  </ng-container>
</div>
