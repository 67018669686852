<app-modal
  [size]="ModalSize.XSMALL"
  [status]="modalStatus"
  class="raise-service-request"
  [warningOnClose]="!isSubmitted"
>
  <div modal-header>Raise a new service request</div>
  <div modal-body>
    <form *ngIf="isSubmitted === false" modal-body class="form">
      <app-form-field
        [type]="FieldType.TEXT"
        [name]="'subject'"
        placeholder="Subject"
        class="raise-service-request__form-field"
        [(inputValue)]="ticket.title"
        [errorMsg]="errors.title"
        (inputBlur)="handleBlur('title')"
        (inputFocus)="handleFocus('title')"
        >Subject</app-form-field
      >
      <app-form-field
        [type]="FieldType.TEXT"
        [name]="'cc_emails'"
        [extraText]="{ text: 'OPTIONAL', pill: PillStatus.PRIMARY }"
        placeholder="e.g colleague@mycompany.com"
        class="raise-service-request__form-field"
        [(inputValue)]="ccEmails"
        [errorMsg]="errors.cc_emails"
        (inputBlur)="handleBlur('cc_emails')"
        (inputFocus)="handleFocus('cc_emails')"
        >Copy people into this request (cc)</app-form-field
      >

      <app-info-box class="raise-service-request__form-field">
        You can copy in users in the field above using a comma separated list.
        In order to ensure we can action your service request quickly,
        <strong
          >if applicable please copy in an authorised approver when
          submitting</strong
        >
        your ticket.
      </app-info-box>

      <app-form-field
        [type]="FieldType.TEXTAREA"
        [name]="'description'"
        placeholder="Add text here"
        class="raise-service-request__form-field"
        [(inputValue)]="ticket.description"
        [errorMsg]="errors.description"
        (inputBlur)="handleBlur('description')"
        (inputFocus)="handleFocus('description')"
        >Description</app-form-field
      >

      <app-file-picker
        (uploadSuccess)="onUploadSuccess($event)"
        (removeSuccess)="onRemoveSuccess($event)"
        class="raise-service-request__form-field"
        [fileOptions]="fileOptions"
      ></app-file-picker>

      <div class="raise-service-request__text">
        <p>
          Once received Cloud Gateway will assess the request in order to
          determine whether the requester is authorised to request the change
          and whether we have all the required information to implement the
          change. Where further information / clarification is required, we will
          contact the requester.
        </p>
        <p>
          Service Requests raised with Cloud Gateway may incur a charge, please
          refer to your <abbr title="Service Level Agreement">SLA</abbr> for
          applicable costs.
        </p>
      </div>
    </form>
  </div>

  <div
    *ngIf="isSubmitted === false"
    modal-footer
    class="raise-service-request__buttons"
  >
    <app-button (buttonClick)="cancel()">Cancel</app-button>
    <app-button
      [type]="ButtonType.SUBMIT"
      [role]="ButtonRole.PRIMARY"
      (buttonClick)="raiseTicket()"
      data-testid="submit-sr-button"
      [status]="submitButtonStatus"
      *appPermitted="['SUPPORT__SERVICE_REQUESTS__CREATE']"
      >Raise service request</app-button
    >
  </div>

  <div
    *ngIf="isSubmitted === true"
    modal-confirmation
    class="modal-confirmation"
  >
    <div class="modal-confirmation__icon-container">
      <app-icon
        title="Success"
        class="modal-confirmation__icon"
        [type]="IconType.OUTLINED"
        >check_circle</app-icon
      >
    </div>

    <p>Your service request has been raised</p>

    <app-button
      class="modal-confirmation--view-ticket-button"
      [role]="ButtonRole.PRIMARY"
      (buttonClick)="viewTicketDetails()"
    >
      VIEW TICKET
    </app-button>
  </div>
</app-modal>
