<h3 class="firewall-service__title">Service</h3>
<div *ngIf="data">
  <p *ngIf="data.length === 1 && data[0].name === 'ALL'">
    Affects traffic on all services
  </p>
  <div *ngIf="data.length > 0 && data[0].name !== 'ALL'">
    <p>Affects traffic on the following services:</p>
    <ul class="firewall-service__list">
      <li *ngFor="let service of data">
        {{ service.name }}
        <span *ngIf="service['tcp-portrange']">{{
          service["tcp-portrange"] | protocol: "TCP"
        }}</span>
        <span *ngIf="service['udp-portrange']">{{
          service["udp-portrange"] | protocol: "UDP"
        }}</span>
        <span *ngIf="service.protocol"> ({{ service.protocol }}/any)</span>
      </li>
    </ul>
  </div>
</div>
