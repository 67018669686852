<div class="password-requirement">
  <app-icon
    [title]="param ? 'valid' : 'invalid'"
    [size]="IconSize.SMALL"
    [ngClass]="param ? 'check' : 'cross'"
    >{{ param ? "check" : "close" }}</app-icon
  >
  <p [ngClass]="param ? 'valid' : 'invalid'">
    <ng-content></ng-content>
  </p>
</div>
