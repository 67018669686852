import { Component, Input } from '@angular/core';
import { ButtonDisplay, ButtonFlexType } from '@shared/models/button.model';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
})
export class ButtonGroupComponent {
  @Input() display: ButtonDisplay = ButtonDisplay.INLINE;
  @Input() flexType: ButtonFlexType = ButtonFlexType.FLEXSTART;

  public ButtonFlexType = ButtonFlexType;
  public ButtonDisplay = ButtonDisplay;
}
