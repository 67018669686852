<div class="ticket-data ticket-data--{{ layout }} ticket-data--{{ status }}">
  <section class="ticket-data__info">
    <ng-template [ngIf]="status === Status.LOADING">
      <app-loading></app-loading>
    </ng-template>
    <ng-template [ngIf]="status === Status.NO_DATA">
      <p>Support account not configured.</p>
    </ng-template>
    <ng-template [ngIf]="status === Status.ERROR">
      <app-loading-error
        >The ticket data could not be retrieved</app-loading-error
      >
    </ng-template>
    <ng-template [ngIf]="status === Status.COMPLETE">
      <app-icon-stat
        class="ticket-data__icon-stat"
        *ngIf="open !== undefined"
        [size]="IconSize.MEDIUM"
      >
        <ng-container stat-icon>
          <app-icon-tile
            [size]="IconSize.MEDIUM"
            [status]="IconStatus.PRIMARY"
            [iconStatus]="IconStatus.SUCCESS"
            title="Open tickets"
            >confirmation_number</app-icon-tile
          >
        </ng-container>
        <ng-container stat-value> {{ open | number }}</ng-container>
        <ng-container stat-label>Open</ng-container>
      </app-icon-stat>
      <app-icon-stat
        class="ticket-data__icon-stat"
        *ngIf="pending !== undefined"
        [size]="IconSize.MEDIUM"
      >
        <ng-container stat-icon>
          <app-icon-tile
            [size]="IconSize.MEDIUM"
            [status]="IconStatus.PRIMARY"
            [iconStatus]="IconStatus.WARNING"
            title="Pending tickets"
            >confirmation_number</app-icon-tile
          >
        </ng-container>
        <ng-container stat-value> {{ pending | number }}</ng-container>
        <ng-container stat-label>Pending</ng-container>
      </app-icon-stat>
      <app-icon-stat
        class="ticket-data__icon-stat"
        *ngIf="resolved !== undefined"
        [size]="IconSize.MEDIUM"
      >
        <ng-container stat-icon>
          <app-icon-tile
            [size]="IconSize.MEDIUM"
            [status]="IconStatus.PRIMARY"
            [iconStatus]="IconStatus.SECONDARY"
            title="Resolved tickets"
            >confirmation_number</app-icon-tile
          >
        </ng-container>
        <ng-container stat-value>{{ resolved | number }}</ng-container>
        <ng-container stat-label>Resolved</ng-container>
      </app-icon-stat>
      <app-icon-stat
        class="ticket-data__icon-stat"
        *ngIf="closed !== undefined"
        [size]="IconSize.MEDIUM"
      >
        <ng-container stat-icon>
          <app-icon-tile
            [size]="IconSize.MEDIUM"
            [status]="IconStatus.PRIMARY"
            [iconStatus]="IconStatus.SECONDARY"
            title="Closed tickets"
            >confirmation_number</app-icon-tile
          >
        </ng-container>
        <ng-container stat-value>{{ closed | number }}</ng-container>
        <ng-container stat-label>Closed</ng-container>
      </app-icon-stat>
    </ng-template>
  </section>

  <section class="ticket-data__actions" *ngIf="status !== Status.NO_DATA">
    <ng-container *ngIf="type === TicketType.SR">
      <app-button
        [iconPosition]="ButtonIconPosition.START"
        iconName="build"
        [iconType]="IconType.OUTLINED"
        [display]="ButtonDisplay.BLOCK"
        [role]="ButtonRole.PRIMARY"
        [type]="ButtonType.BUTTON"
        *appPermitted="'SUPPORT__SERVICE_REQUESTS__CREATE'"
        (buttonClick)="raiseTicketClick()"
        >Raise service request</app-button
      >
    </ng-container>
    <ng-container *ngIf="!type || type === TicketType.INCIDENTS">
      <app-button
        [iconPosition]="ButtonIconPosition.START"
        iconName="add_box"
        [iconType]="IconType.OUTLINED"
        [display]="ButtonDisplay.BLOCK"
        [role]="ButtonRole.PRIMARY"
        [type]="ButtonType.BUTTON"
        *appPermitted="'SUPPORT__INCIDENTS__CREATE'"
        (buttonClick)="raiseTicketClick()"
        >Raise incident</app-button
      >
    </ng-container>
    <app-button
      [display]="ButtonDisplay.BLOCK"
      [type]="ButtonType.BUTTON"
      iconName="chevron_right"
      [iconPosition]="ButtonIconPosition.END"
      (buttonClick)="viewAllTicketsClick()"
    >
      {{
        !type || type === TicketType.SR
          ? "View incident tickets"
          : "View service requests"
      }}
    </app-button>
  </section>
</div>
