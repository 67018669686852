import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

/* Models */
import { mfaTypes } from '@shared/models/mfa-types';
import { FieldType, FieldStatus } from '@forms/models/form-field.model';
import { IconSize } from '@shared/models/icon.model';
import { Status } from '@shared/models/status.model';
import { CognitoResponse } from '../../models/cognito-response';

/* Services */
import { CognitoLoginService } from '../../services/cognito-login.service';
import { CognitoErrorService } from '../../services/cognito-error.service';
import { CognitoService } from '../../services/cognito.service';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss'],
})
export class TwoFactorAuthComponent implements OnInit {
  public FieldType = FieldType;
  public FieldStatus = FieldStatus;
  public IconSize = IconSize;

  public code: string = '';
  public Status = Status;
  public status: Status = Status.NOT_AVAILABLE;
  public timer?: number;
  public timeRemaining?: number;

  public errorMsg?: string;
  public mfaType?: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA';
  public mfaTypes = mfaTypes;
  public codeDestination: string = '***';

  constructor(
    private router: Router,
    private cognitoLoginService: CognitoLoginService,
    private cognitoService: CognitoService,
    private cognitoErrorService: CognitoErrorService
  ) {}

  ngOnInit() {
    // NOTE - need to restart the login flow if the user is missing
    // most likely, the browser has been refreshed since the login flow began
    this.cognitoService
      .getUser()
      .pipe(take(1))
      .subscribe((user) => {
        if (!user || !Object.keys(user).length) {
          this.router.navigate(['/']);
        } else {
          this.mfaType = user.challengeName;
          if (user.challengeName === mfaTypes.SMS) {
            this.codeDestination =
              user.challengeParam.CODE_DELIVERY_DESTINATION;
          }
          this.setupTimer();
        }
      });
  }

  /**
   * Verify the users authentication code.
   */
  public verify(code: string): void {
    this.status = Status.LOADING;
    this.cognitoLoginService.verify(code).subscribe((res: CognitoResponse) => {
      if (res.success) {
        this.status = Status.COMPLETE;
        window.clearInterval(this.timer);
        this.router.navigate(['/']);
      } else {
        this.status = Status.ERROR;
        this.errorMsg = res.reason;
        this.cognitoErrorService.throwError(this.errorMsg);
      }
    });
  }

  public setupTimer() {
    this.timeRemaining = 180;
    this.timer = window.setInterval(() => {
      this.timerTick();
    }, 1000);
  }

  private timerTick() {
    if (this.timeRemaining && this.timeRemaining > 0) {
      this.timeRemaining -= 1;
    } else {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.router.navigate(['/login']);
      this.errorMsg =
        "Your code has expired. \n\nIf you can't complete this step for any reason, then you will need to ask your Administrator to help you.";
      this.cognitoErrorService.throwError(this.errorMsg);
      this.router.navigate(['/']);
    }
  }
}
