import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ConnectionStatus,
  ConnectionStatusDetail,
} from '@shared/models/connection.model';
import { Account } from '@shared/models/account';
import { NetworkOverviewData } from '@shared/models/api-response/api-response-connection.model';
import {
  ButtonDisplay,
  ButtonIconPosition,
  ButtonType,
} from '@shared/models/button.model';
import { CustomIcons } from '@shared/models/icon.model';
import { Status } from '@shared/models/status.model';
import { AccountService } from '@shared/services/account.service';
import { Subscription } from 'rxjs';
import { ConnectService } from 'src/app/connect/connect.service';
import { PortType } from '@connect/models/network-overview.model';

@Component({
  selector: 'app-connect-summary',
  templateUrl: './connect-summary.component.html',
  styleUrls: ['./connect-summary.component.scss'],
})
export class ConnectSummaryComponent implements OnInit, OnDestroy {
  public CustomIcons = CustomIcons;
  public ButtonDisplay = ButtonDisplay;
  public ButtonType = ButtonType;
  public ButtonIconPosition = ButtonIconPosition;
  public ConnectionStatus = ConnectionStatus;

  private accountSubscription!: Subscription;
  private accountId!: string;

  public connectLoadingStatus: Status = Status.LOADING;
  public connectStatus: ConnectionStatus = ConnectionStatus.UP;
  public connectionDetails: ConnectionStatusDetail[] = [];

  public Status = Status;

  constructor(
    private accountService: AccountService,
    private connectService: ConnectService
  ) {}

  ngOnInit() {
    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .subscribe((account?: Account) => {
        if (account?.id && account?.id !== this.accountId) {
          this.accountId = account.id;
          this.getData();
        }
      });
  }

  ngOnDestroy() {
    this.accountSubscription?.unsubscribe();
  }

  private getData(): void {
    this.connectLoadingStatus = Status.LOADING;
    this.connectService
      .getNetworkOverview({ accountId: this.accountId, cacheBreak: true })
      .subscribe({
        next: (results: NetworkOverviewData) => {
          if (results) {
            this.connectionDetails = [];
            const cloudStatus = this.getCloudStatus(results);
            const internetStatus = this.getInternetStatus(results);
            const privateNetworkStatus = this.getPrivateNetworkStatus(results);
            const enterpriseStatus = this.getEnterpriseStatus(results);
            if (
              cloudStatus === ConnectionStatus.DOWN ||
              internetStatus === ConnectionStatus.DOWN ||
              privateNetworkStatus === ConnectionStatus.DOWN ||
              enterpriseStatus === ConnectionStatus.DOWN
            ) {
              // Something down
              this.connectStatus = ConnectionStatus.DOWN;
            } else if (
              cloudStatus === ConnectionStatus.WARNING ||
              internetStatus === ConnectionStatus.WARNING ||
              privateNetworkStatus === ConnectionStatus.WARNING ||
              enterpriseStatus === ConnectionStatus.WARNING
            ) {
              // Something warning
              this.connectStatus = ConnectionStatus.WARNING;
            } else {
              // Everything UP
              this.connectStatus = ConnectionStatus.UP;
            }
            this.connectLoadingStatus = Status.COMPLETE;
          }
        },
        error: () => {
          this.connectLoadingStatus = Status.ERROR;
        },
      });
  }

  private getCloudStatus(results: NetworkOverviewData): ConnectionStatus {
    let cloudConnectionsUp = 0;
    let cloudConnectionsTotal = 0;
    let cloudConnectionsDown = 0;
    let cloudStatus: ConnectionStatus = ConnectionStatus.UP;
    const cloudServices = results.services.filter(
      (service) => service.type === PortType.CLOUD
    );
    cloudServices.forEach((cloud) => {
      if (cloud.status === ConnectionStatus.UP) {
        cloudConnectionsUp++;
      }
      if (cloud.status === ConnectionStatus.DOWN) {
        cloudConnectionsDown++;
      }
      cloudConnectionsTotal++;
    });
    if (cloudConnectionsTotal > 0) {
      if (cloudConnectionsDown === cloudConnectionsTotal) {
        cloudStatus = ConnectionStatus.DOWN;
      } else if (cloudConnectionsUp < cloudConnectionsTotal) {
        cloudStatus = ConnectionStatus.WARNING;
      }
    }
    if (cloudConnectionsTotal > 0) {
      this.connectionDetails.push({
        status: cloudStatus,
        label: 'Cloud Connections',
        value: `${cloudConnectionsUp}/${cloudConnectionsTotal}`,
      });
    }
    return cloudStatus;
  }

  private getInternetStatus(results: NetworkOverviewData): ConnectionStatus {
    let internetStatus: ConnectionStatus = ConnectionStatus.UP;
    const internetService = results.services.find(
      (service) => service.type === PortType.INTERNET
    );
    if (internetService?.status) {
      if (internetService.status === ConnectionStatus.UP) {
        internetStatus = ConnectionStatus.UP;
      } else {
        internetStatus = ConnectionStatus.DOWN;
      }
      this.connectionDetails.push({
        status: internetStatus,
        label: 'Internet',
        value: ``,
      });
    }
    return internetStatus;
  }

  private getPrivateNetworkStatus(
    results: NetworkOverviewData
  ): ConnectionStatus {
    let privateNetworkUp = 0;
    let privateNetworkDown = 0;
    let privateNetworkTotal = 0;
    let privateNetworkStatus: ConnectionStatus = ConnectionStatus.UP;
    const privateNetworksServices = results.services.filter(
      (service) =>
        service.type === PortType.HSCN || service.type === PortType.PSN
    );
    privateNetworksServices.forEach((pnService) => {
      if (pnService.status === ConnectionStatus.UP) {
        privateNetworkUp++;
      }
      if (pnService.status === ConnectionStatus.DOWN) {
        privateNetworkDown++;
      }
      privateNetworkTotal++;
    });
    if (privateNetworkTotal > 0) {
      if (privateNetworkDown === privateNetworkTotal) {
        privateNetworkStatus = ConnectionStatus.DOWN;
      } else if (privateNetworkUp < privateNetworkTotal) {
        privateNetworkStatus = ConnectionStatus.WARNING;
      }
    }
    if (privateNetworkTotal > 0) {
      this.connectionDetails.push({
        status: privateNetworkStatus,
        label: 'Private Network Connections',
        value: `${privateNetworkUp}/${privateNetworkTotal}`,
      });
    }
    return privateNetworkStatus;
  }

  private getEnterpriseStatus(results: NetworkOverviewData): ConnectionStatus {
    let enterpriseConnectionsUp = 0;
    let enterpriseConnectionsTotal = 0;
    let enterpriseStatus: ConnectionStatus = ConnectionStatus.UP;
    results.sites.forEach((site) => {
      if (site.status === ConnectionStatus.UP) {
        enterpriseConnectionsUp++;
      }
      enterpriseConnectionsTotal++;
    });
    if (enterpriseConnectionsTotal > 0 && enterpriseConnectionsUp === 0) {
      enterpriseStatus = ConnectionStatus.DOWN;
    } else if (enterpriseConnectionsUp < enterpriseConnectionsTotal) {
      enterpriseStatus = ConnectionStatus.WARNING;
    }
    if (enterpriseConnectionsTotal > 0) {
      this.connectionDetails.push({
        status: enterpriseStatus,
        label: 'Enterprise Connections',
        value: `${enterpriseConnectionsUp}/${enterpriseConnectionsTotal}`,
      });
    }
    return enterpriseStatus;
  }
}
