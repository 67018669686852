import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* Resolvers */
import { AccountsResolverService } from '@shared/resolvers/accounts-resolver.service';
import { Feature } from '@shared/models/features';
import { ContainerComponent } from '@core/widgets/container/container.component';
import { AuthGuard } from '@auth/services/auth.guard';
import { UserGuard } from '@shared/user.guard';
import { PermissionsResolverService } from '@shared/resolvers/permissions-resolver.service';
import { PartnersResolverService } from '@shared/resolvers/partners-resolver.service';
import { SummaryComponent } from './summary.component';
import { AccountGuard } from '@shared/account.guard';

const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    canActivate: [AuthGuard, AccountGuard, UserGuard],
    data: { title: 'Account List', id: Feature.SUMMARY },
    resolve: [
      AccountsResolverService,
      PartnersResolverService,
      PermissionsResolverService,
    ],
    children: [
      {
        path: '',
        component: SummaryComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SummaryRoutingModule {}
