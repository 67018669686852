import { Component, Input } from '@angular/core';
import { CustomIcons } from '@shared/models/icon.model';

@Component({
  selector: 'app-icon-title',
  templateUrl: './icon-title.component.html',
  styleUrls: ['./icon-title.component.scss'],
})
export class IconTitleComponent {
  @Input() icon: string | undefined;
  @Input() customIcon: CustomIcons | undefined;
  @Input() subHeader?: string;

  public CustomIcons = CustomIcons;

  constructor() {}
}
