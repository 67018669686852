import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import { CognitoService } from './cognito.service';
import { CognitoLogoutService } from './cognito-logout.service';
import { TimeoutService } from '@shared/services/timeout.service';
import { AccountService } from '@shared/services/account.service';
import { Account } from '@shared/models/account';
import { CognitoAction } from '../models/cognito-action';
import { CognitoResponse } from '../models/cognito-response';
import { CognitoErrorResponse } from '../models/cognito-errors';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';

@Injectable()
export class CognitoLoginService {
  // cache user responses ( required for resend functionality )
  private username: string = '';
  private password: string = '';

  constructor(
    private cognitoService: CognitoService,
    private accountService: AccountService,
    private cognitoLogoutService: CognitoLogoutService,
    private timeoutService: TimeoutService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  /**
   * Start user's login flow.
   * Step 1 in login flow.
   * @param { string } username - user's username.
   * @param { string } password - user's password.
   * @returns { Observable<CognitoResponse>} response.
   */
  public login(
    username: string,
    password: string
  ): Observable<CognitoResponse> {
    return new Observable((observer: Observer<CognitoResponse>) => {
      const paramsValid: boolean = this.cognitoService.validateParams(
        observer,
        {
          generic_bad_param_username: username,
          generic_bad_param_password: password,
        }
      );

      if (paramsValid) {
        this.username = username;
        this.password = password;
        Auth.signIn(username, password)
          .then((user: CognitoUser) => {
            this.cognitoService.setSignInUser(user);

            // ask UI to respond to any challenges
            if (user.challengeName) {
              switch (user.challengeName) {
                case 'NEW_PASSWORD_REQUIRED':
                  this.cognitoService.success(
                    observer,
                    CognitoAction.SetNewPassword
                  );
                  break;
                case 'SMS_MFA':
                case 'SOFTWARE_TOKEN_MFA':
                  this.cognitoService.success(
                    observer,
                    CognitoAction.EnterCode
                  );
                  break;
              }
              this.cognitoService.success(observer);
            } else {
              this.checkAccountStatus(user, observer);
              // MFA users start timeout in this.verify()
              this.timeoutService.start();
            }
          })
          .catch((reason: CognitoErrorResponse) => {
            this.cognitoService.handleAuthError(
              reason,
              observer,
              'generic_login_failed'
            );
          });
      }
    });
  }

  /**
   * If the user has an account set - check if it's enabled before allowing login.
   * @param user
   * @param observer
   * @param action
   */
  private checkAccountStatus(
    user: any,
    observer: Observer<CognitoResponse>,
    action: CognitoAction | null = null
  ) {
    if (
      user.attributes['custom:account_ids'] &&
      user.attributes?.['custom:account_ids'] !== '[]'
    ) {
      this.accountService.checkAccounts().subscribe((accounts?: Account[]) => {
        if (accounts?.length === 0) {
          this.router.navigateByUrl('/logout');
          const errorMsg =
            'We cannot log you in because your company account is disabled.';
          this.toastr.warning(errorMsg, 'Login failed', {
            progressBar: true,
          });
          this.cognitoService.failed(observer, errorMsg);
          this.cognitoLogoutService.logout(errorMsg);
        } else if (accounts !== undefined) {
          this.cognitoService.success(observer, action);
        }
      });
    } else {
      this.cognitoService.success(observer, action);
    }
  }

  /**
   * Request two factor auth code be re-sent to the user.
   * Step 1a in login flow.
   */
  public resend(): void {
    Auth.signIn(this.username, this.password).then((user: CognitoUser) =>
      this.cognitoService.setSignInUser(user)
    );
  }

  /**
   * Update user's password.
   * Step 2 in login flow ( if first login ).
   * @param { string } newPassword - user's new password.
   * @returns { Observable<CognitoResponse>} response.
   */
  public changePassword(newPassword: string): Observable<CognitoResponse> {
    return new Observable((observer: Observer<CognitoResponse>) => {
      const paramsValid: boolean = this.cognitoService.validateParams(
        observer,
        {
          generic_bad_param_password: newPassword,
        }
      );

      if (paramsValid) {
        Auth.completeNewPassword(
          this.cognitoService.getUser().getValue(),
          newPassword,
          []
        )
          .then((user: any) => {
            this.cognitoService.setSignInUser(user);

            if (
              ['SMS_MFA', 'SOFTWARE_TOKEN_MFA'].includes(user.challengeName)
            ) {
              this.cognitoService.success(observer, CognitoAction.EnterCode);
            } else {
              this.checkAccountStatus(user, observer);
            }
          })
          .catch((reason: CognitoErrorResponse) => {
            this.cognitoService.handleAuthError(
              reason,
              observer,
              'generic_change_password_failed'
            );
          });
      }
    });
  }

  /**
   * Verify user login with two factor authentication code.
   * Step 3 in login flow.
   * @param { string } code - Two factor authentication code.
   * @returns { Observable<CognitoResponse>} response.
   */
  public verify(code: string): Observable<CognitoResponse> {
    return new Observable((observer: Observer<CognitoResponse>) => {
      const paramsValid: boolean = this.cognitoService.validateParams(
        observer,
        {
          generic_bad_param_code: code,
        }
      );

      const user = this.cognitoService.getUser().getValue();

      if (paramsValid) {
        Auth.confirmSignIn(user, code, user.challengeName)
          .then((user: CognitoUser) => {
            this.cognitoService.setSignInUser(user);
            this.checkAccountStatus(user, observer);
            this.timeoutService.start();
          })
          .catch((reason: CognitoErrorResponse) => {
            this.cognitoService.handleAuthError(
              reason,
              observer,
              'generic_verify_failed'
            );
          });
      }
    });
  }
}
