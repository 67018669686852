import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { CognitoErrors } from '../models/cognito-errors';

@Injectable()
export class CognitoErrorService {
  // error messages
  private errorMessages: CognitoErrors = {
    generic_login_failed: 'Login failed.',
    generic_change_password_failed: 'Change password failed.',
    generic_forgot_password_failed: 'Forgot password failed.',
    generic_logout_failed: 'Logout failed.',
    generic_verify_failed: 'Verify failed',
    generic_bad_param_username: 'Bad username',
    generic_bad_param_password: 'Bad password',
    generic_bad_param_code: 'Bad code',
    generic_profile_update_failed: 'Update profile failed.',
    generic_profile_refresh_failed: 'Refresh profile failed',
  };

  /**
   * Service for holding error messages.
   */
  constructor(private toastr: ToastrService) {}

  /**
   * Replace the in built error messages with something a little more app specific.
   * @param { CognitoErrors } errorMessages - replacement error messages.
   */
  public replaceErrorMessages(errorMessages: CognitoErrors): void {
    this.errorMessages = errorMessages;
  }

  /**
   * Return a specified error message string.
   * @param { keyof CognitoErrors } key - name of error message to return.
   */
  public getErrorMessage(key: keyof CognitoErrors): string {
    const message = this.errorMessages[key];
    if (message) {
      return message;
    }

    return '';
  }

  public throwError(errorMsg?: string, enableHtml = false) {
    if (errorMsg) {
      this.toastr.warning(errorMsg, 'Something went wrong', {
        progressBar: true,
        enableHtml,
      });
    } else {
      this.toastr.warning('Please try again later.', 'Something went wrong', {
        progressBar: true,
        enableHtml,
      });
    }
  }
}
