import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FieldStatus, FieldType } from '../../models/form-field.model';
import { CustomIcons, IconSize, IconType } from '@shared/models/icon.model';
import { PillSize, PillStatus } from '@shared/models/pill.model';

interface PreIcon {
  type: IconType;
  name: string;
}
@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements OnChanges {
  @Input() type: FieldType = FieldType.TEXT;
  @Input() name: string = '';
  @Input() status: FieldStatus = FieldStatus.ACTIVE;
  @Input() placeholder: string = '';
  @Input() extraText: { text: string; link?: string; pill?: PillStatus } = {
    text: '',
  };
  @Input() inputValue: string | null = '';
  @Input() errorMsg?: string | string[] | null;
  @Input() autocomplete?: string;
  @Input() hideLabel: boolean = false;
  @Input() preInput?: string;
  @Input() preIcon?: string | PreIcon;
  @Input() showErrorMsg: boolean = true;
  @Input() subtext?: string;
  @Input() pattern: string = '';
  @Input() required: boolean = false;

  @Output() inputValueChange = new EventEmitter();
  @Output() inputKeyUp = new EventEmitter<{
    event: KeyboardEvent;
    source: FormFieldComponent;
  }>();
  @Output() inputBlur = new EventEmitter();
  @Output() inputFocus = new EventEmitter();

  public FieldType = FieldType;
  public CustomIcons = CustomIcons;
  public IconType = IconType;
  public IconSize = IconSize;
  public PillSize = PillSize;

  public visiblePassword = false;
  public preIconObject: PreIcon = {
    type: IconType.OUTLINED,
    name: '',
  };

  @ViewChild('input') input!: ElementRef;

  public get isDisabled(): boolean {
    return this.status === FieldStatus.DISABLED;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.errorMsg = null;
    if (changes.errorMsg) {
      this.errorMsg = changes.errorMsg.currentValue;
    }
    this.preIconObject = {
      type: (<PreIcon>this.preIcon)?.type
        ? (<PreIcon>this.preIcon)?.type
        : IconType.OUTLINED,
      name: (<PreIcon>this.preIcon)?.name
        ? (<PreIcon>this.preIcon)?.name
        : <string>this.preIcon,
    };
  }

  public get classList(): string[] {
    const classArray = [];
    if (this.errorMsg && this.errorMsg.length !== 0) {
      classArray.push(`form__input--error`);
    }
    return classArray;
  }

  onFocus() {
    this.inputFocus.emit();
  }

  onKeyUp(event: KeyboardEvent) {
    this.inputKeyUp.emit({ event, source: this });
  }

  onKeyDown(event: KeyboardEvent) {
    const regex = /\d|\./;
    const key = event.key;
    if (!regex.test(key)) {
      event.preventDefault();
    }
  }

  onBlur() {
    this.inputBlur.emit();
  }

  select() {
    const nativeElement = this.input.nativeElement as HTMLFormElement;
    nativeElement.focus();
    nativeElement.select();
  }

  clear() {
    const nativeElement = this.input.nativeElement as HTMLFormElement;
    nativeElement.value = '';
  }

  togglePasswordVisibility() {
    this.visiblePassword = !this.visiblePassword;
  }
}
