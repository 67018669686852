import { Component, OnInit } from '@angular/core';
import { slideLeft } from '@shared/animations/animations';
import { CarouselCard } from '../../models/carousel-card';
import { environment } from '@environments/default/environment';
import { IconSize } from '@shared/models/icon.model';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [slideLeft()],
})
export class CarouselComponent implements OnInit {
  public IconSize = IconSize;

  public theme: string = environment.theme;
  public cards: CarouselCard[] = [
    {
      text: 'A new, fast, powerful and feature rich experience, providing you with 360 visibility of your infrastructure.',
      image: '/assets/img/carousel/CG_Slider_001.webp',
    },
    {
      text: `Get advanced data analytics and network visibility with PRISM, our flagship networking solution.`,
      image: '/assets/img/carousel/CG_Slider_002.webp',
    },
    {
      text: `Let us know what features hold the most value to you, as we make our way to total network visibility.`,
      image: '/assets/img/carousel/CG_Slider_003.webp',
    },
  ];
  public slideIndex = 0;
  public playing: boolean = true;

  private timer: number | null = null;

  ngOnInit(): void {
    if (this.cards.length > 1) this.resetTimer();
  }

  resetTimer(): void {
    if (this.timer) window.clearInterval(this.timer);
    this.timer = window.setInterval(() => {
      if (this.slideIndex < this.cards.length - 1) {
        this.slideIndex++;
      } else {
        this.slideIndex = 0;
      }
    }, 10000);
  }

  stopTimer(): void {
    if (this.timer) window.clearInterval(this.timer);
  }

  togglePlaying(): void {
    if (this.playing) {
      this.stopTimer();
    } else {
      this.resetTimer();
    }

    this.playing = !this.playing;
  }

  selectSlide(index: number): void {
    this.slideIndex = index;
    this.resetTimer();
  }

  public prev() {
    const index =
      this.slideIndex === 0 ? this.cards.length - 1 : this.slideIndex - 1;
    this.selectSlide(index);
  }

  public next() {
    const index =
      this.slideIndex === this.cards.length - 1 ? 0 : this.slideIndex + 1;
    this.selectSlide(index);
  }
}
