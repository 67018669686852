<a *ngIf="options.closeButton" (click)="remove()" class="toast-close-button"
  ><app-icon [size]="IconSize.SMALL">close</app-icon>
</a>
<app-icon [size]="IconSize.LARGE" class="toast-icon">{{
  icon[toastPackage.toastType]
}}</app-icon>
<h4 *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
  {{ title }}
</h4>
<div
  *ngIf="message && options.enableHtml"
  role="alert"
  aria-live="polite"
  [class]="options.messageClass"
  [innerHTML]="message"
></div>
<div
  *ngIf="message && !options.enableHtml"
  role="alert"
  aria-live="polite"
  [class]="options.messageClass"
  [attr.aria-label]="message"
>
  {{ message }}
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
