import { Component, Input } from '@angular/core';
import { fadeIn, slideUp } from '@shared/animations/animations';
import { IconSize, IconWeight } from '@shared/models/icon.model';
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import { ModalService } from '@shared/services/modal.service';
import { AlertService } from '@shared/services/alert.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [slideUp(), fadeIn()],
})
export class ModalComponent {
  @Input() size: ModalSize = ModalSize.XSMALL;
  @Input() status: ModalStatus = ModalStatus.OPEN;
  @Input() closeable: boolean = true;
  @Input() warningOnClose: boolean = false;
  @Input() warningOnCloseMessage: string =
    'Are you sure you want to close this modal?';

  public IconSize = IconSize;
  public ModalStatus = ModalStatus;
  public IconWeight = IconWeight;

  constructor(
    private modalService: ModalService,
    public alertService: AlertService
  ) {}

  /**
   * Close this modal
   */
  public close(event: Event): void {
    if (!this.closeable) {
      return;
    }
    // Only close if it's close button or space around modal__wrapper
    const target = event?.target as HTMLElement;
    const closestAnchor = target.closest('a');
    const isCloseButton =
      closestAnchor &&
      ['close--inner', 'close--header'].indexOf(closestAnchor.id) > -1;
    const isModalOuter = target.id === 'modal';
    if (isCloseButton || isModalOuter) {
      if (this.warningOnClose) {
        this.alertService.throwConfirmation(
          this.warningOnCloseMessage,
          'Close',
          'Warning',
          () => (this.status = ModalStatus.CLOSED)
        );
      } else {
        this.status = ModalStatus.CLOSED;
      }
    }
  }

  public async animationDone(): Promise<void> {
    if (this.status === ModalStatus.CLOSED) {
      await this.modalService.closeAll();
    }
  }
}
