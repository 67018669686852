<app-widget
  class="account-overview"
  *ngIf="accountContacts$ | async as accountContacts"
>
  <ng-container widget-title>
    Account contacts
    <app-help-icon>
      <p>From here you can access your account contacts.</p>
    </app-help-icon>
  </ng-container>
  <div widget-content>
    <div class="account-overview__content">
      <section class="account-overview__contacts">
        <ng-container *ngFor="let contact of accountContacts">
          <app-contact-card
            [name]="contact.name"
            [position]="contact.position"
            [image]="contact.image"
            contact="mailto:{{ contact.email }}"
            class="account-overview__contact-card"
          >
          </app-contact-card>
        </ng-container>
      </section>
    </div>
  </div>
</app-widget>
