import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

export enum TimeoutStatus {
  'STOPPED' = 0, // Service loaded but interval not running
  'RUNNING' = 1, // Interval running
  'WARNING' = 2, // Interval running and showing warning toast
}

/**
 * Sets a timer running to first show a warning and then log user out if inactive for too long
 * Timeout lengths are hardcoded here for the moment: they may move into an account-specific session length at a later date
 */
@Injectable({ providedIn: 'root' })
export class TimeoutService {
  private eventSubscription?: Subscription;
  private intervalLength: number = 10000; // How often to check if we should log out (in milliseconds)
  private intervalId: any;
  private overallTimeoutLength: number; // (timeout length in milliseconds from inactive to logged out)
  private logoutTimeoutLength: number; // (timeout length in milliseconds after warning is shown)
  public status: TimeoutStatus;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    // initialise in stopped state
    this.status = TimeoutStatus.STOPPED;
    // Timeout lengths might be pulled from account details in future
    this.overallTimeoutLength = 30 * 60 * 1000;
    this.logoutTimeoutLength = 2 * 60 * 1000;
  }

  start(): void {
    this.status = TimeoutStatus.RUNNING;
    this.toastr.clear();

    // Reset expiry time on nav events
    this.eventSubscription = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.reset();
      }
    });

    // Set the interval going to check against CognitoExpiry
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(() => {
      this.checkTime();
    }, this.intervalLength);

    this.reset();
    this.checkTime();
  }

  stop(): void {
    // Update status
    this.status = TimeoutStatus.STOPPED;

    // Reset interval
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    // Stop listening for user events
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }

    // Remove localStorage item
    this.localStorageService.removeLocalStorage('CognitoExpiry');
  }

  private checkTime(): void {
    const cognitoExpiry =
      this.localStorageService.getLocalStorage('CognitoExpiry');
    const now = new Date().getTime();
    if (cognitoExpiry) {
      const expiryNumber: number = Number(cognitoExpiry);
      if (expiryNumber < now) {
        return this.logout();
      }
      if (
        this.status !== TimeoutStatus.WARNING &&
        expiryNumber - now < this.logoutTimeoutLength
      ) {
        this.status = TimeoutStatus.WARNING;
        this.showWarning();
      } else if (expiryNumber < now) {
        this.logout();
      }
    } else {
      this.logout();
    }
  }

  private reset(): void {
    // Reset localStorage time
    const now = new Date().getTime();
    const timeout = this.overallTimeoutLength;
    const newExpiry = now + timeout + '';
    this.localStorageService.setLocalStorage('CognitoExpiry', newExpiry);
  }

  private logout(): void {
    this.toastr.clear();
    this.toastr.warning(
      'You have been logged out due to inactivity',
      `Signed out`,
      {
        disableTimeOut: true,
        closeButton: true,
      }
    );
    this.stop();
    this.router.navigate(['/logout']);
  }

  private showWarning(): void {
    // Show warning toast
    const warningToast = this.toastr.warning(
      `You will be logged out soon due to inactivity.
      <br><br>
      <a class='button button--toast confirm-stay-logged-in'>Stay logged in</a>`,
      'Logout warning',
      {
        closeButton: true,
        disableTimeOut: true,
        enableHtml: true,
      }
    );

    // Check to see if user clicks on warning toast
    // User can click anywhere on the toast - attaching a listener to the button didn't seem to work consistently
    warningToast.onTap.subscribe(() => {
      // Stop listening to events (otherwise there will be two listeners)
      if (this.eventSubscription) {
        this.eventSubscription.unsubscribe();
      }
      this.start();
      this.toastr.clear(warningToast.toastId);
    });
  }
}
