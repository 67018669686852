<div class="firewall-rule-modify">
  <!-- Rule selection -->
  <div>
    <h2 class="firewall-rule-modify__title">Select Rule</h2>
    <app-filter-text
      [(inputValue)]="searchText"
      (inputValueChange)="filter()"
      name="fireall-rules-filter"
      placeholder="Search by rule name or policy ID"
      class="firewall-rule-modify__filter"
    ></app-filter-text>

    <!-- Firewall Rule selection table -->
    <app-datatable
      [data]="filteredData"
      [displayedColumns]="tableColumns"
      [limit]="10"
      [status]="tableStatus"
      (rowClick)="this.onRowClick($event)"
    >
      <ng-container cdkColumnDef="selected">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          class="firewall-rule-modify__table-header"
        >
          Select
        </th>
        <td
          cdk-cell
          *cdkCellDef="let row"
          class="firewall-rule-modify__table-cell"
        >
          <div class="firewall-rule-modify__cell-entry">
            <app-checkbox
              [checked]="
                !!selectedRule && selectedRule.policyid === row.policyid
              "
            ></app-checkbox>
          </div>
        </td>
      </ng-container>

      <!-- Name column -->
      <ng-container cdkColumnDef="name">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          class="firewall-rule-modify__table-header"
        >
          Rule Name
        </th>
        <td
          cdk-cell
          *cdkCellDef="let row"
          class="cdk-cell--primary firewall-rule-modify__table-cell"
        >
          <div class="firewall-rule-modify__cell-entry">
            <ng-container *ngIf="row.status === FirewallStatus.disable">
              <app-icon
                title="Disabled"
                [size]="IconSize.MEDIUM"
                [type]="IconType.OUTLINED"
                >cancel</app-icon
              >
            </ng-container>
            <span>{{ row.name }}</span>
          </div>
        </td>
      </ng-container>

      <!-- Source column -->
      <ng-container cdkColumnDef="source">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          class="firewall-rule-modify__table-header"
        >
          IP Source
        </th>
        <td
          cdk-cell
          *cdkCellDef="let row"
          class="firewall-rule-modify__table-cell"
        >
          <div
            class="firewall-rule-modify__address-entry"
            title="{{ row.srcaddr | join: '&#13;' : 'name' }}"
          >
            {{ row.srcaddr[0].name }}
            <ng-container *ngIf="row.srcaddr.length > 1"
              >(+{{ row.srcaddr.length - 1 }} more)</ng-container
            >
          </div>
        </td>
      </ng-container>

      <!-- Destination column -->
      <ng-container cdkColumnDef="destination">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          class="firewall-rule-modify__table-header"
        >
          IP Destination
        </th>
        <td
          cdk-cell
          *cdkCellDef="let row"
          class="firewall-rule-modify__table-cell"
        >
          <div
            class="firewall-rule-modify__address-entry"
            title="{{ row.dstaddr | join: '&#13;' : 'name' }}"
          >
            {{ row.dstaddr[0].name }}
            <ng-container *ngIf="row.dstaddr.length > 1"
              >(+{{ row.dstaddr.length - 1 }} more)</ng-container
            >
          </div>
        </td>
      </ng-container>
    </app-datatable>
  </div>

  <!-- Contextual Panel -->
  <div *ngIf="selectedRule && selectedRule.policyid !== null">
    <app-firewall-rule-information
      [ruleData]="selectedRule"
    ></app-firewall-rule-information>
  </div>

  <!-- Form panel -->
  <div *ngIf="selectedRule && selectedRule.policyid !== null">
    <h2 class="firewall-rule-modify__title">Requested Changes</h2>
    <form class="request-form">
      <app-form-field
        [type]="FieldType.TEXTAREA"
        placeholder="Enter your request here, please keep requests specific to your selected rule only"
        name="comment"
        id="comment"
        [errorMsg]="
          errors.comment.length ? errors.comment.join('. ') : undefined
        "
        [(inputValue)]="formData.comment"
        (inputBlur)="handleBlur('comment')"
        (inputFocus)="handleFocus('comment')"
        (inputValueChange)="handleFormUpdate()"
      ></app-form-field>
    </form>
  </div>
</div>
