import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CognitoLogoutService } from '../../services/cognito-logout.service';

import * as fromStatus from '@store/status/status.actions';
import { TimeoutService } from '@shared/services/timeout.service';
import { LocalStorageService } from '@shared/services/local-storage.service';

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent implements OnInit {
  constructor(
    private store: Store,
    private cognitoLogoutService: CognitoLogoutService,
    private timeoutService: TimeoutService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    // Stop inactivity timeout countdowns
    this.timeoutService.stop();
    // Reset all local storage values
    this.localStorageService.removeAllItems();
    // Clears all data in the store (accounts, partners, status, user)
    this.store.dispatch(fromStatus.logout());
    //calls the logout
    this.cognitoLogoutService.logout().subscribe(() => {});
    setTimeout(() => {
      this.reloadPage();
    }, 500);
  }

  reloadPage() {
    location.reload();
  }
}
