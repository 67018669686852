import { Component } from '@angular/core';
import { IconSize } from '@shared/models/icon.model';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  public IconSize = IconSize;

  constructor() {}
}
