import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { ApiService } from '@shared/services/api.service';
import * as NewsActions from './news.actions';
import * as StatusActions from '../status/status.actions';

@Injectable()
export class NewsEffects {
  fetchNews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsActions.FETCH_NEWS),
      mergeMap(() => {
        return this.apiService.get(`/settings/news`).pipe(
          map((news) => NewsActions.setNews({ news })),
          catchError((err) => of(StatusActions.handleError(err)))
        );
      })
    );
  });

  constructor(private apiService: ApiService, private actions$: Actions) {}
}
