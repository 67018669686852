import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CognitoService } from '@auth/services/cognito.service';
import { environment } from '@environments/default/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private cognito: CognitoService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const APIKey = environment.postman_api_key;

    let headers: HttpHeaders;
    const credentials = this.cognito.getAmazonCredentials();
    if (credentials) {
      headers = req.headers.set(
        'Authorization',
        this.cognito.getAmazonCredentials(),
      );
    } else {
      return next.handle(req);
    }

    // Always set Postman API key in local, as some routes might be mocked
    if (environment.name === 'local' || environment.useMockAPI) {
      headers = headers.append('x-api-key', APIKey);
    }

    const modifiedRequest = req.clone({
      headers,
    });
    return next.handle(modifiedRequest);
  }
}
