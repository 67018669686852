<div class="connection-status connection-status--{{ loadingStatus }}">
  <app-loading-error *ngIf="loadingStatus === Status.ERROR"
    >There's been a problem</app-loading-error
  >
  <app-loading *ngIf="loadingStatus === Status.LOADING"></app-loading>
  <ng-container *ngIf="loadingStatus === Status.COMPLETE">
    <div class="connection-status__icon connection-status__icon--{{ status }}">
      <app-icon
        *ngIf="status === ConnectionStatus.UP"
        [type]="IconType.OUTLINED"
        [status]="IconStatus.SUCCESS"
        [title]="ConnectionsAltText.UP"
        >check_circle</app-icon
      >
      <app-icon
        *ngIf="status === ConnectionStatus.WARNING"
        [type]="IconType.OUTLINED"
        [status]="IconStatus.WARNING"
        [title]="ConnectionsAltText.WARNING"
        >warning</app-icon
      >
      <app-icon
        *ngIf="status === ConnectionStatus.DOWN"
        [type]="IconType.OUTLINED"
        [status]="IconStatus.ERROR"
        [title]="ConnectionsAltText.DOWN"
        >cancel</app-icon
      >
      <p class="connection-status__text">{{ status }}</p>
      <h3 class="connection-status__title">Status</h3>
    </div>
    <div *ngIf="details.length" class="connection-status__details">
      <p *ngFor="let detail of details" class="connection-status__detail">
        <app-icon
          *ngIf="detail.status === ConnectionStatus.UP"
          class="connection-status__detail-icon"
          [type]="IconType.OUTLINED"
          [size]="IconSize.MEDIUM"
          [status]="IconStatus.SUCCESS"
          [title]="ConnectionsAltText.UP"
          >check_circle</app-icon
        >
        <app-icon
          *ngIf="detail.status === ConnectionStatus.WARNING"
          class="connection-status__detail-icon"
          [type]="IconType.OUTLINED"
          [size]="IconSize.MEDIUM"
          [status]="IconStatus.WARNING"
          [title]="ConnectionsAltText.WARNING"
          >warning</app-icon
        >
        <app-icon
          *ngIf="detail.status === ConnectionStatus.DOWN"
          class="connection-status__detail-icon"
          [type]="IconType.OUTLINED"
          [size]="IconSize.MEDIUM"
          [status]="IconStatus.ERROR"
          [title]="ConnectionsAltText.DOWN"
          >cancel</app-icon
        >
        <span class="connection-status__detail-label">{{ detail.label }}</span>
        <span class="connection-status__detail-value">{{ detail.value }}</span>
      </p>
    </div>
  </ng-container>
</div>
