export interface Permission {
  id: number;
  name: string;
  description: string;
}

export enum PermissionsOperator {
  AND = 'AND',
  OR = 'OR',
}

export type PermissionList = {
  [key in PermissionSection]?: PermissionLevel;
};

export enum PermissionSection {
  AUTH = 'AUTH',
  ADMIN = 'ADMIN',
  CONNECT = 'CONNECT',
  PROTECT = 'PROTECT',
  INSPECT = 'INSPECT',
  SUPPORT = 'SUPPORT',
  PARTNER = 'PARTNER',
  PROFILE = 'PROFILE',
}

export interface PermissionLevel {
  [key: string]: PermissionGroup;
}

export interface PermissionGroup {
  description: string;
  CREATE: number | false;
  READ: number | false;
  UPDATE: number | false;
  DELETE: number | false;
  ALL_IS_CHECKED: boolean;
  ALL: number[];
}
