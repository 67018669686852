import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

/* Models */
import { FieldStatus, FieldType } from '@forms/models/form-field.model';
import { SelectOptions } from '@forms/models/form-select.model';
import {
  ButtonStatus,
  ButtonType,
  ButtonRole,
} from '@shared/models/button.model';
import { IconStatus, IconType } from '@shared/models/icon.model';
import { ModalStatus, ModalSize } from '@shared/models/modal.model';
import {
  ReportPeriod,
  ReportPeriodDict,
  ReportRecord,
  ReportSchedule,
  ReportScheduleDict,
  ReportType,
} from '@shared/models/report.model';
import { User } from '@shared/models/user';

/* Services*/
import { ReportsService } from 'src/app/reporting/reports.service';
import { UserService } from '@shared/services/user.service';
import { ErrorService } from '@shared/services/error.service';

/* Store */
import { Store } from '@ngrx/store';
import * as fromStatus from '@store/status/status.reducer';
import * as StatusActions from '@store/status/status.actions';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from '@shared/services/alert.service';

const initialReportOptions: SelectOptions[] = [
  {
    value: -1,
    label: '-- Please select --',
  },
];
@Component({
  selector: 'app-add-report',
  templateUrl: './add-report.component.html',
  styleUrls: ['./add-report.component.scss'],
})
export class AddReportComponent implements OnInit, OnDestroy {
  public errors: { [key: string]: string[] | null } = {
    layoutId: null,
    title: null,
    userId: null,
    accountId: null,
    type: null,
    schedule: null,
    emails: null,
    period: null,
  };

  public report: ReportRecord = {
    layoutId: -1,
    title: '',
    type: ReportType.IMMEDIATE,
    period: ReportPeriod.LAST_7_DAYS,
    schedule: ReportSchedule.EVERY_MONDAY_AT_8AM,
  };

  public typeSelectOptions: SelectOptions[] = [
    {
      value: ReportType.IMMEDIATE,
      label: 'immediately',
    },
    {
      value: ReportType.SCHEDULED,
      label: 'on a schedule',
    },
  ];

  public periodSelectOptions: SelectOptions[] = [
    {
      value: ReportPeriod.LAST_7_DAYS,
      label: ReportPeriodDict[ReportPeriod.LAST_7_DAYS],
    },
    {
      value: ReportPeriod.LAST_30_DAYS,
      label: ReportPeriodDict[ReportPeriod.LAST_30_DAYS],
    },
    {
      value: ReportPeriod.LAST_MONTH,
      label: ReportPeriodDict[ReportPeriod.LAST_MONTH],
    },
  ];

  public scheduleSelectOptions: SelectOptions[] = [
    {
      value: ReportSchedule.EVERY_MONDAY_AT_8AM,
      label: ReportScheduleDict[ReportSchedule.EVERY_MONDAY_AT_8AM],
    },
    {
      value: ReportSchedule.FIRST_OF_THE_MONTH_AT_8AM,
      label: ReportScheduleDict[ReportSchedule.FIRST_OF_THE_MONTH_AT_8AM],
    },
  ];

  public reportSelectOptions: SelectOptions[] = initialReportOptions;

  public emails = ''; // .split(',') and add to report.emails before submitting
  public formfieldStatus: FieldStatus = FieldStatus.DISABLED;
  public submitButtonStatus: ButtonStatus = ButtonStatus.DISABLED;
  public uploadError: string = '';
  public modalStatus: ModalStatus = ModalStatus.OPEN;
  public modalCloseMessage: string =
    'Closing this modal will discard all changes. Are you sure you want to close the modal?';

  private accountSubscription!: Subscription;
  private userSubscription!: Subscription;
  private waitingSubscription?: Subscription;

  public ReportType = ReportType;
  public IconStatus = IconStatus;
  public IconType = IconType;
  public ModalSize = ModalSize;
  public ModalStatus = ModalStatus;
  public FieldType = FieldType;
  public ButtonType = ButtonType;
  public ButtonRole = ButtonRole;

  private waiting$ = this.store.select(fromStatus.selectWaiting);

  constructor(
    private store: Store,
    private reportsService: ReportsService,
    private userService: UserService,
    private errorService: ErrorService,
    private toastr: ToastrService,
    public alertService: AlertService
  ) {}

  ngOnInit(): void {
    // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(StatusActions.setWaiting({ waiting: true }));
    this.reportsService.getReportList().subscribe({
      next: (reports) => {
        // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
        this.reportSelectOptions = initialReportOptions.concat(
          reports.map((report) => {
            return {
              value: report.id,
              label: report.title,
            };
          })
        );
      },
      error: (err) => {
        this.reportSelectOptions = [
          {
            value: -1,
            label:
              'There was an error fetching the list of reports. please reload the page',
          },
        ];
      },
    });

    this.userSubscription = this.userService.getCurrentUser().subscribe({
      next: (user: User | null) => {
        if (user?.email) {
          this.emails = user.email;
        }
      },
    });

    this.waitingSubscription = this.waiting$.subscribe((val) => {
      this.submitButtonStatus = !val
        ? ButtonStatus.ACTIVE
        : ButtonStatus.LOADING;
      this.formfieldStatus = !val ? FieldStatus.ACTIVE : FieldStatus.DISABLED;
    });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.accountSubscription?.unsubscribe();
    this.waitingSubscription?.unsubscribe();
  }

  public cancel() {
    this.alertService.throwConfirmation(
      this.modalCloseMessage,
      'Close',
      'Warning',
      () => (this.modalStatus = ModalStatus.CLOSED)
    );
  }

  public addReport() {
    // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(StatusActions.setWaiting({ waiting: true }));

    // Reset errors
    Object.keys(this.errors).forEach((prop) => {
      this.errors[prop] = null;
    });

    // Remove spaces from email list and split on commas
    this.report.emails = this.emails.replace(' ', '').split(',');
    // Get layout title from list of options (can't be empty in POST request)
    this.report.title =
      <string>(
        this.reportSelectOptions.find(
          (option) => option.value === this.report.layoutId
        )?.label
      ) || 'Unknown';

    this.reportsService.addReport(this.report).subscribe({
      next: () => {
        const successMessage =
          this.report.type === ReportType.IMMEDIATE
            ? 'Your report is being created and will be available for download shortly.'
            : 'Your report will be generated at the scheduled time.';
        this.toastr.success(successMessage, 'Success', {
          progressBar: true,
        });
        this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
        this.modalStatus = ModalStatus.CLOSED;
      },
      error: (err) => {
        // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
        this.errorService.handleSaveErrors(err);
        // Show validation errors
        this.errors = err;
      },
    });
  }
}
