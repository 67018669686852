import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FieldStatus, FieldType } from '@forms/models/form-field.model';
import {
  ButtonRole,
  ButtonType,
  ButtonDisplay,
  ButtonStatus,
} from '@shared/models/button.model';

import { CognitoResponse } from '../../models/cognito-response';
import { CognitoLoginService } from '../../services/cognito-login.service';
import { CognitoErrorService } from '../../services/cognito-error.service';
import { CognitoAction } from '../../models/cognito-action';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { EmailPattern, PasswordPattern } from '@auth/models/patterns';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public FieldStatus = FieldStatus;
  public FieldType = FieldType;
  public ButtonRole = ButtonRole;
  public ButtonType = ButtonType;
  public ButtonDisplay = ButtonDisplay;
  public ButtonStatus = ButtonStatus;
  public EmailPattern = EmailPattern;
  public PasswordPattern = PasswordPattern;

  // form values
  public username: string = '';
  public password: string = '';

  // error message
  public errorMsg?: string;

  public buttonStatus: ButtonStatus = ButtonStatus.ACTIVE;

  // destination URL
  @Input() destination: string = '/';

  constructor(
    private router: Router,
    private cognitoLoginService: CognitoLoginService,
    private cognitoErrorService: CognitoErrorService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    if (history?.state?.errorMsg) {
      this.errorMsg = history.state.errorMsg;
    }
  }

  /**
   * Login the user with the supplied credentials.
   */
  public login(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.localStorageService.removeAllItems();

    this.buttonStatus = ButtonStatus.LOADING;
    if (this.username) {
      this.username = this.username.toLowerCase();
    }

    this.cognitoLoginService
      .login(this.username ? this.username : '', this.password)
      .subscribe((res: CognitoResponse) => {
        if (res.success) {
          this.redirectUser(res);
        } else {
          this.buttonStatus = ButtonStatus.ACTIVE;
          this.errorMsg = res.reason;
          this.cognitoErrorService.throwError(this.errorMsg, true);
        }
      });
  }

  private redirectUser(res: CognitoResponse) {
    if (!res.action) {
      this.router.navigate([this.destination]);
    } else {
      switch (res.action) {
        case CognitoAction.SetNewPassword:
          this.router.navigate(['/set-first-password'], {
            state: { username: this.username },
          });
          break;
        case CognitoAction.EnterCode:
          this.router.navigate(['/verify']);
          break;
      }
    }
  }
}
