import { Injectable } from '@angular/core';

import { Observable, Observer } from 'rxjs';

import { CognitoAction } from '../models/cognito-action';
import { CognitoResponse } from '../models/cognito-response';
import { CognitoService } from './cognito.service';
import { CognitoErrorResponse } from '../models/cognito-errors';
import { Auth } from 'aws-amplify';
import { ApiService } from '@shared/services/api.service';

@Injectable()
export class CognitoResetPasswordService {
  // cache user responses ( required for resend functionality )
  private username: string = '';

  /**
   * Constructor.
   * @param { CognitoService } cognitoService - provider for cognito services.
   */
  constructor(
    private cognitoService: CognitoService,
    private apiService: ApiService
  ) {}

  /**
   * Reset User's password.
   * Step 1 in reset password flow.
   * @param { string } username - user's username.
   * @returns { Observable<CognitoResponse>} response.
   */
  public forgotPassword(username: string): Observable<CognitoResponse> {
    return new Observable((observer: Observer<CognitoResponse>) => {
      const paramsValid: boolean = this.cognitoService.validateParams(
        observer,
        {
          generic_bad_param_username: username,
        }
      );

      if (paramsValid) {
        this.username = username;
        Auth.forgotPassword(username)
          .then(() => {
            this.cognitoService.success(observer, CognitoAction.EnterCode);
          })
          .catch((reason: CognitoErrorResponse) => {
            if (
              reason.message ===
              'User password cannot be reset in the current state.'
            ) {
              // If we cannot resend it the regular way, we will send the email
              // through the API by calling Cognito's "Admin create user" function
              this.apiService
                .postOpen('/users/resetPassword', {
                  email: username,
                })
                .subscribe({
                  next: () => {
                    this.cognitoService.success(
                      observer,
                      CognitoAction.EnterCode
                    );
                  },
                  error: () => {
                    this.cognitoService.handleAuthError(
                      reason,
                      observer,
                      'generic_forgot_password_failed'
                    );
                  },
                });
            } else {
              this.cognitoService.handleAuthError(
                reason,
                observer,
                'generic_forgot_password_failed'
              );
            }
          });
      }
    });
  }

  /**
   * Send another code to reset the user's password.
   * Step 1a in reset password flow.
   * @return { Observable<CognitoResponse>} response.
   */
  public resendForgotPasswordCode(): Observable<CognitoResponse> {
    return this.forgotPassword(this.username);
  }

  /**
   * Set a new password for a forgotten password.
   * Step 2 in reset password flow.
   * @param { string } code - User's authentication code to reset their password.
   * @param { string } password - User's new password.
   * @return { Observable<CognitoResponse>} response.
   */
  public setNewPasswordForForgottenPassword(
    code: string,
    password: string,
    username: string
  ): Observable<CognitoResponse> {
    return new Observable((observer: Observer<CognitoResponse>) => {
      const paramsValid: boolean = this.cognitoService.validateParams(
        observer,
        {
          generic_bad_param_code: code,
          generic_bad_param_password: password,
          generic_bad_param_username: username,
        }
      );

      if (paramsValid) {
        Auth.forgotPasswordSubmit(username, code, password)
          .then(() => {
            this.cognitoService.success(observer);
          })
          .catch((reason: CognitoErrorResponse) => {
            this.cognitoService.handleAuthError(
              reason,
              observer,
              'generic_change_password_failed'
            );
          });
      }
    });
  }
}
