<app-panel class="login" id="login">
  <ng-container panel-title>Sign in to your account</ng-container>
  <form class="login__form" panel-content>
    <div class="login__form-fields">
      <app-form-field
        class="login__form__field"
        [type]="FieldType.TEXT"
        name="username"
        [(inputValue)]="username"
        [errorMsg]="errorMsg"
        [showErrorMsg]="false"
        [hideLabel]="true"
        placeholder="Enter your email"
        preIcon="email"
        [pattern]="EmailPattern"
        data-testid="email-address"
        >Email Address</app-form-field
      >
      <app-form-field
        class="login__form__field"
        [type]="FieldType.PASSWORD"
        name="password"
        [(inputValue)]="password"
        [errorMsg]="errorMsg"
        [showErrorMsg]="false"
        [hideLabel]="true"
        placeholder="Enter your password"
        preIcon="lock"
        [pattern]="PasswordPattern"
        autocomplete="current-password"
        data-testid="password"
        [required]="true"
        >Password</app-form-field
      >
    </div>
    <p class="login__form-details">
      <a routerLink="/forgot-password">Forgotten Password?</a>
    </p>
    <app-button
      [display]="ButtonDisplay.BLOCK"
      [role]="ButtonRole.PRIMARY"
      [type]="ButtonType.SUBMIT"
      [status]="buttonStatus"
      (buttonClick)="login($event)"
      data-testid="sign-in"
      >Sign In</app-button
    >
  </form>
</app-panel>
