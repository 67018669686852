import SuperColor from 'super-color';

export interface NetworkItem {
  bps_in: number;
  bps_out: number;
  bps_total: number;
  name?: string;
  status: string;
  icon?: string;
  key?: string;
  label?: string;
  type: PortType;
  updated_at?: number | boolean;
  location?: string;
  secondary?: NetworkItem;
}

export interface SiteItem extends NetworkItem {
  bits: {
    total:
      | {
          bandwidth: number;
          units: string;
        }
      | boolean;
    in:
      | {
          bandwidth: number;
          units: string;
        }
      | boolean;
    out:
      | {
          bandwidth: number;
          units: string;
        }
      | boolean;
  };
  pos: PositionXY;
  currentColour: SuperColor;
}

export enum PortType {
  INTERNET = 'INTERNET',
  HSCN = 'HSCN',
  PSN = 'PSN',
  CLOUD = 'CLOUD',
  SITE = 'SITE',
}

/**
 * XY Position.
 * @property {number} x - x position.
 * @property {number} y - y position.
 */
export interface PositionXY {
  x: number;
  y: number;
}

/**
 * RGB Colour.
 * @property {number} r - red.
 * @property {number} g - green.
 * @property {number} b - blue.
 */
export interface ColorRGB {
  r: number;
  g: number;
  b: number;
}

/**
 * RGBA Colour.
 * @property {number} r - red.
 * @property {number} g - green.
 * @property {number} b - blue.
 * @property {number} a - alpha.
 */
export interface ColorRGBA {
  r: number;
  g: number;
  b: number;
  a: number;
}

export const bitAbbreviations = {
  unit: 'bps',
  kilo: 'kbps',
  mega: 'Mbps',
  giga: 'Gbps',
  tera: 'Tbps',
};

export interface ServiceLabel {
  label: string;
  pos: PositionXY;
}

/**
 * Interpolated point.
 * @property {string} mode - mode.
 * @property {number} t - interpolation time, 0 to 1.
 * @property {number} tstep - t delta between renders.
 * @property {PositionXY} pos - current position.
 * @property {PositionXY} start - start position.
 * @property {PositionXY} end - end position.
 */
export interface CanvasRenderRuntime {
  mode: string;
  t: number;
  tstep: number;
  pos: PositionXY;
  start: PositionXY;
  end: PositionXY;
}
