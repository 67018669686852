/* eslint-disable ngrx/prefer-inline-action-props */
import { createAction, props } from '@ngrx/store';

import { Account } from '@shared/models/account';
import { Ticket } from '@support/models/ticket';
import { ApiResponse } from '@shared/models/api-response/api-response';
import { BandwidthRequest, BandwidthResponse, ResourceRequest, ResourceResponse, TrafficLogsExportsRequest, TrafficLogsExportsResponse, TrafficLogsRequest, TrafficLogsResponse } from '@shared/models/api-response/api-response-analytics.model';
import { NetworkOverviewRequest, NetworkOverviewResponse } from '@shared/models/api-response/api-response-connection.model';
import { FirewallAPIRequest, FirewallAPIResponse, URLFilterAPIRequest, URLFilterAPIResponse } from '@shared/models/api-response/api-response-firewall.model';
import { RouteRequest, RouteResponse, SSLVPNResponse } from '@shared/models/api-response/api-response-remote.model';
import { Report, ReportRecord, ReportStatus } from '@shared/models/report.model';
import { SSLVPN } from 'src/app/connect/models/ssl-vpn.model';
import { FirewallType } from '@shared/models/firewall.model';
import { TicketStatus } from '@support/models/ticket-status.constant';

/* Actions for the store */
export const SET_ACCOUNTS = '[Accounts] Set Accounts';
export const CLEAR_ACCOUNTS = '[Accounts] Clear Accounts';
export const SET_BANDWIDTH_DATA = '[Accounts - Analytics] Set Bandwidth Data';
export const SET_RESOURCE_DATA = '[Accounts - Analytics] Set Resource Data';
export const SET_TRAFFIC_LOGS_EXPORTS = '[Accounts - Analytics] Set Traffic Logs Exports';
export const SET_ROUTE_DATA = '[Accounts - Remote] Set Route Data';
export const SET_SSL_VPN_DATA = '[Accounts - Remote] Set SSL VPN Data';
export const ADD_SSL_USER = '[Accounts - Remote] Add SSL User'
export const REMOVE_SSL_USERS = '[Accounts - Remote] Remove SSL User'
export const SET_NETWORK_OVERVIEW = '[Accounts - Connections] Set Network Overview';
export const SET_FIREWALL_DATA = '[Accounts - Firewall] Set Firewall Data';
export const SET_FIREWALL_URLFILTERS = '[Accounts - Firewall] Set Firewall URL Filters';
export const SET_TICKETS = '[Support] Set Tickets'
export const SET_REPORT_LIST = '[Support] Set Report List'
export const SET_REPORTS = '[Support] Set Reports'
export const ADD_REPORT = '[Support] Add Report'
export const UPDATE_REPORT_STATUS = '[Support] Update Report Status'
export const UPDATE_TICKET_STATUS = '[Support] Update Ticket Status'
export const COMPLETE_FLUSH = '[Accounts] Complete Cache Flush';

/* Actions for effects */
export const FETCH_ACCOUNT = '[Accounts] Fetch Account';
export const FETCH_ACCOUNTS = '[Accounts] Fetch Accounts';
export const FETCH_MY_ACCOUNT = '[Accounts] Fetch My Account';
export const FETCH_PARTNER_ACCOUNTS = '[Accounts] Get Partner Account';
export const FETCH_BANDWIDTH_DATA = '[Accounts - Analytics] Fetch Bandwidth Data';
export const FETCH_RESOURCE_DATA = '[Accounts - Analytics] Fetch Resource Data';
export const FETCH_TRAFFIC_LOGS_EXPORTS = '[Accounts - Analytics] Fetch Traffic Logs Exports';
export const FETCH_ROUTE_DATA = '[Accounts - Remote] Fetch Route Data';
export const FETCH_SSL_VPN_DATA = '[Accounts - Remote] Fetch SSL VPN Data';
export const FETCH_NETWORK_OVERVIEW = '[Accounts - Connections] Fetch Network Overview';
export const FETCH_FIREWALL_DATA = '[Accounts - Firewall] Fetch Firewall Data';
export const FETCH_FIREWALL_URLFILTERS = '[Accounts - Firewall] Fetch Firewall URL Filters';
export const FETCH_TICKETS = '[Support] Fetch Tickets'
export const FETCH_REPORT_LIST = '[Support] Fetch Report List'
export const FETCH_REPORTS = '[Support] Fetch Reports'
export const FLUSH_CACHE = '[Accounts] Flush Cache';
export const ACCOUNTS_ERROR = '[Accounts] Accounts Error';

/* ACTIONS FOR STORE */
export const setAccounts = createAction(SET_ACCOUNTS, props<{ accounts: Account[] }>());
export const clearAccounts = createAction(CLEAR_ACCOUNTS);
export const setBandwidthData = createAction(SET_BANDWIDTH_DATA, props<{ bandwidthData: BandwidthResponse }>());
export const setResourceData = createAction(SET_RESOURCE_DATA, props<{ resourceData: ResourceResponse }>());
export const setTrafficLogsExports = createAction(SET_TRAFFIC_LOGS_EXPORTS, props<{ trafficLogsExports: TrafficLogsExportsResponse }>());
export const setRouteData = createAction(SET_ROUTE_DATA, props<{ routeData: RouteResponse }>());
export const setSSLVPNData = createAction(SET_SSL_VPN_DATA, props<{ sslVPNData: SSLVPNResponse }>());
export const addSSLUser = createAction(ADD_SSL_USER, props<{ sslUser: SSLVPN }>());
export const removeSSLUsers = createAction(REMOVE_SSL_USERS, props<{ sslUsers: SSLVPN[], accountId: string }>());
export const setNetworkOverview = createAction(SET_NETWORK_OVERVIEW, props<{ networkOverviewData: NetworkOverviewResponse }>());
export const setFirewallData = createAction(SET_FIREWALL_DATA, props<{ firewallData: FirewallAPIResponse, firewallType: FirewallType }>());
export const setFirewallURLFilters = createAction(SET_FIREWALL_URLFILTERS, props<{ filterData: URLFilterAPIResponse }>());
export const setTickets = createAction(SET_TICKETS, props<{ data: Ticket[], meta: any }>());
export const setReportList = createAction(SET_REPORT_LIST, props<{ data: Report[], meta: any }>());
export const setReports = createAction(SET_REPORTS, props<{ data: Report[], meta: any }>());
export const addReport = createAction(ADD_REPORT, props<{ report: ReportRecord }>());
export const updateReportStatus = createAction(UPDATE_REPORT_STATUS, props<{ accountId: string, reportId: string, reportStatus: ReportStatus }>());
export const updateTicketStatus = createAction(UPDATE_TICKET_STATUS, props<{ accountId: string, ticketId: string, ticketStatus: TicketStatus }>());
export const completeFlush = createAction(COMPLETE_FLUSH);

/* ACTIONS FOR EFFECTS */
export const fetchAccounts = createAction(FETCH_ACCOUNTS);
export const fetchAccount = createAction(FETCH_ACCOUNT, props<{ id: string }>());
export const fetchMyAccount = createAction(FETCH_MY_ACCOUNT);
export const fetchPartnerAccounts = createAction(FETCH_PARTNER_ACCOUNTS);
export const fetchBandwidthData = createAction(FETCH_BANDWIDTH_DATA, props<BandwidthRequest>());
export const fetchResourceData = createAction(FETCH_RESOURCE_DATA, props<ResourceRequest>());
export const fetchTrafficLogsExports = createAction(FETCH_TRAFFIC_LOGS_EXPORTS, props<TrafficLogsExportsRequest>());
export const fetchRouteData = createAction(FETCH_ROUTE_DATA, props<RouteRequest>());
export const fetchSSLVPNData = createAction(FETCH_SSL_VPN_DATA, props<RouteRequest>());
export const fetchNetworkOverview = createAction(FETCH_NETWORK_OVERVIEW, props<NetworkOverviewRequest>());
export const fetchFirewallData = createAction(FETCH_FIREWALL_DATA, props<FirewallAPIRequest>());
export const fetchFirewallURLFilters = createAction(FETCH_FIREWALL_URLFILTERS, props<URLFilterAPIRequest>());
export const fetchTickets = createAction(FETCH_TICKETS, props<{accountId: string, ticketType: string}>());
export const fetchReportList = createAction(FETCH_REPORT_LIST, props<{accountId: string}>());
export const fetchReports = createAction(FETCH_REPORTS, props<{accountId: string}>());
export const flushCache = createAction(FLUSH_CACHE, props<{ accountId: string }>());
export const accountsError = createAction(ACCOUNTS_ERROR, props<ApiResponse>());
