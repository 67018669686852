import { Injectable } from '@angular/core';
import {
  FirewallAddRequest,
  FirewallDeleteRequest,
  FirewallModifyRequest,
  FirewallRuleChangeType,
} from '@shared/models/firewall-change-request.model';

@Injectable({
  providedIn: 'root',
})
export class SupportHelperService {
  constructor() {}

  public getBlankChange(
    type: FirewallRuleChangeType,
    accountId: string
  ): FirewallAddRequest | FirewallModifyRequest | FirewallDeleteRequest {
    switch (type) {
      case FirewallRuleChangeType.ADD:
        return {
          type: FirewallRuleChangeType.ADD,
          name: '',
          comment: '',
          accountId: accountId,
          sourceAddress: '',
          destinationAddress: '',
          service: '',
          securityProfile: '',
          sourceNat: '',
          destinationNat: '',
          urls: '',
          cc_emails: '',
        } as FirewallAddRequest;
      case FirewallRuleChangeType.MODIFY:
        return {
          type: FirewallRuleChangeType.MODIFY,
          name: '',
          comment: '',
          accountId: accountId,
          policyid: '',
        } as FirewallModifyRequest;
      case FirewallRuleChangeType.DELETE:
      default:
        return {
          type: FirewallRuleChangeType.DELETE,
          name: '',
          comment: '',
          accountId: accountId,
          policyid: '',
          confirmed: false,
        } as FirewallDeleteRequest;
    }
  }

  public getFormattedAddRuleRequest(
    firewallChangeRequest: FirewallAddRequest
  ): string {
    return `
      <table class="request-information-table">
        <tbody> 
          <tr>
            <td class="request-information-table__name">Request Type</td>
            <td class="request-information-table__value">${firewallChangeRequest.type}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Rule Name</td>
            <td class="request-information-table__value">${firewallChangeRequest.name}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Source IP</td>
            <td class="request-information-table__value">${firewallChangeRequest.sourceAddress}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Destination IP</td>
            <td class="request-information-table__value">${firewallChangeRequest.destinationAddress}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Service</td>
            <td class="request-information-table__value">${firewallChangeRequest.service}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Source NAT</td>
            <td class="request-information-table__value">${firewallChangeRequest.sourceNat}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Destination NAT</td>
            <td class="request-information-table__value">${firewallChangeRequest.destinationNat}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Security Profile</td>
            <td class="request-information-table__value">${firewallChangeRequest.securityProfile}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">URL(s)</td>
            <td class="request-information-table__value">${firewallChangeRequest.urls}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Comments</td>
            <td class="request-information-table__value">${firewallChangeRequest.comment}</td>
          </tr>
        </tbody>
      </table>
    `;
  }

  public getFormattedModifyRuleRequest(
    firewallChangeRequest: FirewallModifyRequest
  ): string {
    return `
      <table class="request-information-table">
        <tbody> 
          <tr>
            <td class="request-information-table__name">Request Type</td>
            <td class="request-information-table__value">${firewallChangeRequest.type}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Rule Name</td>
            <td class="request-information-table__value">${firewallChangeRequest.name}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Policy ID</td>
            <td class="request-information-table__value">${firewallChangeRequest.policyid}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Comments</td>
            <td class="request-information-table__value">${firewallChangeRequest.comment}</td>
          </tr>
        </tbody>
      </table>
    `;
  }

  public getFormattedDeleteRuleRequest(
    firewallChangeRequest: FirewallDeleteRequest
  ): string {
    return `
      <table class="request-information-table">
        <tbody> 
          <tr>
            <td class="request-information-table__name">Request Type</td>
            <td class="request-information-table__value">${firewallChangeRequest.type}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Rule Name</td>
            <td class="request-information-table__value">${firewallChangeRequest.name}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Policy ID</td>
            <td class="request-information-table__value">${firewallChangeRequest.policyid}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Comments</td>
            <td class="request-information-table__value">${firewallChangeRequest.comment}</td>
          </tr>
          <tr>
            <td class="request-information-table__name">Confirmed?</td>
            <td class="request-information-table__value">${firewallChangeRequest.confirmed}</td>
          </tr>
        </tbody>
      </table>
    `;
  }
}
