export enum ModalSize {
  XSMALL = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  XLARGE = 'xl',
}

export enum ModalStatus {
  INIT = 'init',
  OPEN = 'open',
  CLOSED = 'closed',
}
