import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { FormsModule } from '@forms/forms.module';

/* Pages */
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { TwoFactorAuthComponent } from './pages/two-factor-auth/two-factor-auth.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SetFirstPasswordComponent } from './pages/set-first-password/set-first-password.component';
import { LogoutComponent } from './pages/logout/logout.component';

/* Widgets */
import { AuthContainerComponent } from './widgets/auth-container/auth-container.component';
import { PanelComponent } from './widgets/panel/panel.component';
import { MfaCodeComponent } from './widgets/mfa-code/mfa-code.component';

/* Components */
import { CarouselComponent } from './components/carousel/carousel.component';

/* Services */
import { AuthGuard } from './services/auth.guard';
import { CognitoErrorService } from './services/cognito-error.service';
import { CognitoLoginService } from './services/cognito-login.service';
import { CognitoLogoutService } from './services/cognito-logout.service';
import { CognitoResetPasswordService } from './services/cognito-reset-password.service';
import { CognitoService } from './services/cognito.service';
import { CognitoUpdateService } from './services/cognito-update.service';

/* Pipes */
import { secondsToTimePipe } from './pipes/secondsToTime.pipe';

@NgModule({
  declarations: [
    AuthContainerComponent,
    ForgotPasswordComponent,
    LoginComponent,
    CarouselComponent,
    TwoFactorAuthComponent,
    SetFirstPasswordComponent,
    PanelComponent,
    ResetPasswordComponent,
    secondsToTimePipe,
    LogoutComponent,
    MfaCodeComponent,
  ],
  exports: [MfaCodeComponent],
  imports: [SharedModule, CommonModule, AuthRoutingModule, FormsModule],
  providers: [
    AuthGuard,
    CognitoErrorService,
    CognitoLoginService,
    CognitoLogoutService,
    CognitoResetPasswordService,
    CognitoService,
    CognitoUpdateService,
  ],
})
export class AuthModule {}
