<div class="icon-container">
  <app-icon [size]="IconSize.MEDIUM" [status]="iconStatus">
    <ng-container *ngIf="addressType === AddressType.addrgrp"
      >book</ng-container
    >
    <ng-container *ngIf="addressType === AddressType.geography"
      >public</ng-container
    >
    <ng-container *ngIf="addressType === AddressType.iprange"
      >location_on</ng-container
    >
    <ng-container *ngIf="addressType === AddressType.fqdn"
      >spellcheck</ng-container
    >
    <ng-container
      *ngIf="
        !addressType ||
        addressType === AddressType.ipmask ||
        addressType === AddressType.vip ||
        addressType === AddressType.staticnat
      "
      >dns</ng-container
    >
  </app-icon>
</div>
