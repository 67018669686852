<app-panel class="reset-password">
  <ng-container panel-title>Reset Password</ng-container>
  <ng-container panel-subtitle>
    <p>Resetting password for account:</p>
    <p class="reset-password__username">{{ username }}</p>
  </ng-container>
  <form panel-content class="reset-password__form">
    <app-form-field
      [type]="FieldType.TEXT"
      name="code"
      [(inputValue)]="code"
      (inputValueChange)="codeChanged($event)"
      [errorMsg]="errorMsg"
      [showErrorMsg]="false"
      [hideLabel]="true"
      placeholder="Enter the six-digit code"
      [preIcon]="{ type: IconType.REGULAR, name: 'pin' }"
      [pattern]="CodePattern"
      [required]="true"
      >Code</app-form-field
    >
    <!-- Hidden field for password managers to pick up -->
    <app-form-field
      [type]="FieldType.TEXT"
      name="username"
      class="reset-password__form-fields__username"
      [inputValue]="username"
      >Username</app-form-field
    >
    <app-form-field
      [type]="FieldType.PASSWORD"
      name="password"
      [(inputValue)]="password"
      [errorMsg]="errorMsg"
      [showErrorMsg]="false"
      [hideLabel]="true"
      placeholder="Enter your new password"
      preIcon="lock"
      [pattern]="PasswordPattern"
      [autocomplete]="'new-password'"
      [required]="true"
      >New Password</app-form-field
    >
    <app-form-field
      [type]="FieldType.PASSWORD"
      name="confirm-password"
      [(inputValue)]="confirmPassword"
      [errorMsg]="errorMsg"
      [showErrorMsg]="false"
      [hideLabel]="true"
      placeholder="Re-enter your new password"
      preIcon="lock"
      [pattern]="PasswordPattern"
      [autocomplete]="'new-password'"
      [required]="true"
      >Confirm New Password</app-form-field
    >
    <div class="reset-password__password-strength">
      <app-password-strength
        [minChars]="14"
        [password]="password"
        [passwordConfirm]="confirmPassword"
        (validityChanged)="passwordValidityChanged($event)"
      ></app-password-strength>
    </div>
    <app-button
      [display]="ButtonDisplay.BLOCK"
      [role]="ButtonRole.PRIMARY"
      [type]="ButtonType.SUBMIT"
      [status]="formValid ? buttonStatus : ButtonStatus.DISABLED"
      (buttonClick)="resetPassword($event)"
      >Reset Password</app-button
    >
    <ng-container *ngIf="successMsg">
      <p class="reset-password__success">{{ successMsg }}</p>
    </ng-container>
  </form>
</app-panel>
