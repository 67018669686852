import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';

import { PartnerAccount } from '@shared/models/partner-account';
import { Account } from '@shared/models/account';

import * as PartnerActions from './partners.actions';
import * as StatusActions from '../status/status.actions';
import { selectAllAccounts } from '../accounts/accounts.selectors';

export interface PartnerState {
  loaded: boolean;
  data: PartnerAccount[];
}

/* Initial states */
export const initialState: PartnerState = {
  loaded: false,
  data: [],
};

/* Setup reducers */
export function reducer(state: PartnerState | undefined, action: Action) {
  return partnersReducer(state, action);
}

const setPartners = (
  state: PartnerState,
  action: { type: string; partners: PartnerAccount[] }
) => {
  const partners = [...action.partners];

  const sortedPartners = partners.sort((a: PartnerAccount, b: PartnerAccount) =>
    a.partnerName.toUpperCase() > b.partnerName.toUpperCase() ? 1 : -1
  );

  const newState = { ...state, loaded: true, data: sortedPartners };
  return newState;
};

const clearPartners = (state: PartnerState, action: { type: string }) => {
  const newState = { ...state, loaded: false, data: [] };
  return newState;
};

const partnersReducer = createReducer(
  initialState,
  on(PartnerActions.setPartners, setPartners),
  on(StatusActions.logout, clearPartners)
);

/* Functions fired by reducers */

/* Selectors */
const selectPartnerFeatureState =
  createFeatureSelector<PartnerState>('partners');

export const selectAllPartners = createSelector(
  selectPartnerFeatureState,
  (state: PartnerState) => state.data
);

/* Selectors combining multiple data */
export const selectPartner = (props: { id: string }) =>
  createSelector(
    selectAllPartners,
    selectAllAccounts,
    (partners: PartnerAccount[], accounts: Account[]) => {
      const partner = partners.find(
        (partner: PartnerAccount) => partner.id === props.id
      );

      if (partner && accounts) {
        return populateAccountDetails(partner, accounts);
      } else {
        throw Error('cannot find partners account');
      }
    }
  );

/**
 * We return the first item in the partners array. If there are multiple (when the user has
 * permission to read multiple partners) then we don't need to return anything.
 */
export const selectCurrentPartnerData = (props: {
  canReadAllPartners?: boolean;
}) =>
  createSelector(
    selectAllPartners,
    selectAllAccounts,
    (partners: PartnerAccount[], accounts: Account[]) => {
      if (partners && accounts && !props.canReadAllPartners && partners[0]) {
        const updatedPartners = partners.map((partner) =>
          populateAccountDetails(partner, accounts)
        );
        return updatedPartners[0];
      } else {
        return;
      }
    }
  );

export const selectAllPartnersWithAccountData = createSelector(
  selectAllPartners,
  selectAllAccounts,
  (partners: PartnerAccount[], accounts: Account[]) =>
    partners.map((partner: PartnerAccount) =>
      populateAccountDetails(partner, accounts)
    )
);

/* Functions used by selectors */

const populateAccountDetails = (
  partner: PartnerAccount,
  accounts: Account[]
) => {
  const partnerWithDetails = { ...partner };
  const accountsWithDetails: Account[] = [];

  if (partner && accounts) {
    (partner.accounts as string[]).forEach((accountId: string) => {
      const foundAccount = accounts.find(
        (account: Account) => account.id === accountId
      );
      if (foundAccount) {
        accountsWithDetails.push(foundAccount);
      }
    });
    partnerWithDetails.accounts = accountsWithDetails;
  }
  return partnerWithDetails;
};
