import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filename' })
export class FilenamePipe implements PipeTransform {
  transform(name: string, removeTimestamp: boolean = false): string {
    let r;
    // Remove query string
    r = name.replace(/[\#\?].*$/, '');
    // Remove everything up to final /
    r = r.split('/').pop();
    if (removeTimestamp) {
      // Take off an number and then underscore at beginning (e.g. 1606820155100_cloud_tick.png)
      r = r?.replace(/^[0-9]+_/, '');
    }
    if (r) r = decodeURI(r);
    return r ? r : name;
  }
}
