export enum UserGroups {
  STANDARD_USER = 'standard_user',
  NETWORK_USER = 'network_user',
  ACCOUNT_ADMIN = 'account_admin',
  SUPER_ADMIN = 'super_admin',
  NONE = 'none',
}

class UserGroup {
  displayName: string = '';
  admin: boolean = false; // can the user edit other users
  icon: string = '';
  permissions: number[] = [];
}

export interface IUserGroupData {
  [key: string]: UserGroup;
}

export const UserGroupData: IUserGroupData = {
  standard_user: {
    displayName: 'Standard User',
    admin: false,
    icon: 'user',
    permissions: [
      0, 1, 2, 1001, 1002, 1003, 1007, 1012, 1016, 2001, 2002, 2007, 3001, 3002,
      3003, 3004, 3005, 3006, 3007, 5001, 5002, 5003, 5005, 5006, 5007, 5009,
      5010, 5011, 6001, 6002, 9001, 9002,
    ],
  },
  network_user: {
    displayName: 'Network User',
    admin: false,
    icon: 'user',
    permissions: [
      0, 1, 2, 1001, 1002, 1003, 1007, 1012, 1016, 2001, 2002, 2004, 2007, 3001,
      3002, 3003, 3004, 3005, 3006, 3007, 5001, 5002, 5003, 5005, 5006, 5007,
      5009, 5010, 5011, 6001, 6002, 9001, 9002,
    ],
  },
  account_admin: {
    displayName: 'Account Administrator',
    admin: true,
    icon: 'users2',
    permissions: [
      0, 1, 2, 1001, 1002, 1003, 1007, 1012, 1016, 1019, 1020, 1021, 2001, 2002,
      2003, 2004, 2007, 3001, 3002, 3003, 3004, 3005, 3006, 3007, 5001, 5002,
      5003, 5005, 5006, 5007, 5009, 5010, 5011, 6001, 6002, 8001, 8002, 8003,
      8004, 9001, 9002,
    ],
  },
  super_admin: {
    displayName: 'Super Administrator',
    admin: true,
    icon: 'star',
    permissions: [
      0, 1, 2, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
      26, 27, 1001, 1002, 1003, 1007, 1011, 1012, 1013, 1014, 1015, 1016, 1017,
      1018, 1019, 1020, 1021, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008,
      3001, 3002, 3003, 3004, 3005, 3006, 3007, 3008, 4006, 5001, 5002, 5003,
      5004, 5005, 5006, 5007, 5008, 5009, 5010, 5011, 5012, 6001, 6002, 8001,
      8002, 8003, 8004, 9001, 9002,
    ],
  },
};

export const CustomerUsers = [
  UserGroups.STANDARD_USER,
  UserGroups.NETWORK_USER,
  UserGroups.ACCOUNT_ADMIN,
];
export const NetworkAdminUsers = [UserGroups.ACCOUNT_ADMIN];
export const AdminUsers = [UserGroups.SUPER_ADMIN];
