<div class="header-details">
  <app-page-title></app-page-title>
  <div class="header-details__user">
    <app-custom-icon
      class="header-details__notifications-bell"
      icon
      [type]="CustomIcons.NOTIFICATIONS_BELL"
      [size]="IconSize.LARGE"
      [appPopover]="content"
      [ariaLabel]="'Notifications'"
      *ngIf="
        usersCurrentPreferredMFA &&
        usersCurrentPreferredMFA !== mfaTypes.TOTP &&
        user &&
        user?.preferredMFA !== mfaTypes.TOTP
      "
    ></app-custom-icon>
    <app-popover [direction]="Directions.DOWN" #content>
      <div>
        You can set up two-factor authentication device in
        <a routerLink="/settings">your profile settings.</a>
        This will allow you to login quicker without having to rely on receiving
        a text message.
      </div>
    </app-popover>
    <app-user-details
      class="header-details__user-details"
      role="button"
      routerLink="/settings"
      [userName]="userName"
      aria-labelledby="user-details__name"
    ></app-user-details>
  </div>
</div>
