<h3 class="firewall-schedule__title">Schedule</h3>
<div *ngIf="data">
  <ng-container
    *ngIf="days.length === 7 && !data.start && !data.end; else scheduled"
  >
    <h4 class="firewall-schedule__subtitle">
      <app-icon
        [size]="IconSize.MEDIUM"
        [type]="IconType.OUTLINED"
        [status]="IconStatus.DEFAULT"
        title="Schedule"
        >schedule</app-icon
      >24/7/365
    </h4>
    <p class="firewall-schedule__text">This rules runs at all times.</p>
  </ng-container>
  <ng-template #scheduled>
    <h4 class="firewall-schedule__subtitle">
      <app-icon
        [size]="IconSize.MEDIUM"
        [type]="IconType.OUTLINED"
        [status]="IconStatus.DEFAULT"
        title="Schedule"
        >schedule</app-icon
      >Scheduled at specific times
    </h4>
    <p class="firewall-schedule__text">
      <ng-container *ngIf="days.length === 7"
        >This rule runs every day</ng-container
      >
      <ng-container *ngIf="days.length !== 7">
        This rule runs on {{ daysString }}
      </ng-container>
      <ng-container *ngIf="data.start && data.end">
        between {{ data.start }} and {{ data.end }}.
      </ng-container>
    </p></ng-template
  >
</div>
