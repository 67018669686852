<div class="firewall-webfilter-detail" data-testid="firewall-webfilter-detail">
  <h3 class="firewall-webfilter-detail__title">Web Filtering</h3>
  <p>
    <app-pill
      *ngIf="data?.name"
      class="firewall-webfilter-detail__name"
      [type]="PillStatus.NEUTRAL"
      [size]="PillSize.SMALL"
      >{{ data.name }}</app-pill
    >
  </p>
  <p *ngIf="data?.comment">{{ data.comment }}</p>
  <div *ngIf="hasFilterData" ngClass="urlFilterContainer">
    <p>
      The following <abbr title="Uniform Resource Locator">URLs</abbr> are
      affected by this rule:
    </p>
    <p>
      <app-filter-text
        placeholder="Search by URL"
        [(inputValue)]="searchText"
        (inputValueChange)="filterByText($event)"
      ></app-filter-text>
    </p>
    <div class="firewall-webfilter-detail__table-container">
      <app-datatable
        class="firewall-webfilter-detail__table"
        [data]="filteredEntries"
        [displayedColumns]="tableColumns"
        [limit]="10"
        [sort]="['url', 'desc']"
        [status]="tableStatus"
        [type]="DatatableType.COMPACT"
      >
        <!-- URL column -->
        <ng-container cdkColumnDef="url">
          <th cdk-header-cell *cdkHeaderCellDef sort-header>URL</th>
          <td cdk-cell *cdkCellDef="let row" class="cdk-cell--primary">
            {{ row.url }}
          </td>
        </ng-container>

        <!-- Action column -->
        <ng-container cdkColumnDef="action">
          <th cdk-header-cell *cdkHeaderCellDef>Action</th>
          <td cdk-cell *cdkCellDef="let row">
            <div
              class="firewall-webfilter-detail__action"
              *ngIf="row.action === URLFilterAction.ALLOW"
            >
              <app-icon
                [size]="IconSize.MEDIUM"
                [type]="IconType.OUTLINED"
                [status]="IconStatus.SUCCESS"
                title="Allow"
                >done</app-icon
              >Allow
            </div>
            <div
              class="firewall-webfilter-detail__action"
              *ngIf="row.action === URLFilterAction.BLOCK"
            >
              <app-icon
                [size]="IconSize.MEDIUM"
                [type]="IconType.OUTLINED"
                [status]="IconStatus.ERROR"
                title="Block"
                >close</app-icon
              >Block
            </div>
          </td>
        </ng-container>
      </app-datatable>
    </div>
  </div>
</div>
