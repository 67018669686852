import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConnectService } from '../../connect.service';
import { Observable, Observer, Subscription, take } from 'rxjs';
import { AccountService } from '@shared/services/account.service';
import { ErrorService } from '@shared/services/error.service';

/* Models */
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import { FieldStatus, FieldType } from '@forms/models/form-field.model';
import {
  ButtonDisplay,
  ButtonRole,
  ButtonStatus,
  ButtonType,
} from '@shared/models/button.model';
import { Account } from '@shared/models/account';
import { CreateSSLUserRequest } from '@shared/models/api-response/api-response-remote.model';

/* Store */
import * as StatusActions from '@store/status/status.actions';
import * as AccountsActions from '@store/accounts/accounts.actions';
import { Store } from '@ngrx/store';
import * as fromStatus from '@store/status/status.reducer';
import { SSLVPN } from '@connect/models/ssl-vpn.model';
import { AlertService } from '@shared/services/alert.service';

@Component({
  selector: 'app-create-ssluser',
  templateUrl: './create-ssl-user.component.html',
  styleUrls: ['./create-ssl-user.component.scss'],
})
export class CreateSSLUserComponent implements OnInit, OnDestroy {
  public errors: { [key: string]: string[] | null } = {
    email: null,
    firstname: null,
    lastname: null,
  };

  public modalStatus: ModalStatus = ModalStatus.OPEN;
  public ModalSize = ModalSize;
  public FieldType = FieldType;
  public ButtonType = ButtonType;
  public ButtonRole = ButtonRole;
  public ButtonDisplay = ButtonDisplay;

  public formfieldStatus: FieldStatus = FieldStatus.ACTIVE;
  public submitButtonStatus: ButtonStatus = ButtonStatus.DISABLED;
  public modalCloseMessage: string =
    'Closing this modal will discard all changes. Are you sure you want to close the modal?';

  private accountSubscription!: Subscription;
  private accountId!: string;
  public user: CreateSSLUserRequest = {
    email: '',
    firstname: '',
    lastname: '',
  };
  private sslVpnLicenceCount!: number;

  private waitingSubscription?: Subscription;
  private waiting$ = this.store.select(fromStatus.selectWaiting);

  public activeTab = 'create-user';
  public tabs = [
    {
      id: 'create-user',
      label: 'Create New User',
    },
    {
      id: 'create-multiple-users',
      label: 'Create Multiple Users',
    },
  ];

  constructor(
    private toastr: ToastrService,
    private connectService: ConnectService,
    private accountService: AccountService,
    private store: Store,
    private errorService: ErrorService,
    public alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(take(1))
      .subscribe((account?: Account) => {
        if (account?.id) {
          this.accountId = account.id;
          this.sslVpnLicenceCount = account.sslVpnLicenceCount || Infinity;
          this.checkButtonStatus().subscribe({
            next: (status) => {
              this.submitButtonStatus = status;
            },
          });
        }
      });
    this.waitingSubscription = this.waiting$.subscribe((val) => {
      this.checkButtonStatus(val).subscribe({
        next: (status) => {
          this.submitButtonStatus = status;
        },
      });
      this.formfieldStatus = !val ? FieldStatus.ACTIVE : FieldStatus.DISABLED;
    });
  }

  ngOnDestroy(): void {
    this.accountSubscription?.unsubscribe();
    this.waitingSubscription?.unsubscribe();
  }

  private checkButtonStatus(
    currentlyWaiting?: boolean,
  ): Observable<ButtonStatus> {
    return new Observable((observer: Observer<ButtonStatus>) => {
      if (currentlyWaiting) {
        observer.next(ButtonStatus.LOADING);

        return;
      }
      // Get number of existing VPNs
      this.connectService
        .getSSLVPNs({ accountId: this.accountId, cacheBreak: false })
        .subscribe({
          next: (vpns?: SSLVPN[]) => {
            // Enable 'add users' button if limit is not reached
            if (
              vpns &&
              this.sslVpnLicenceCount &&
              vpns.length < this.sslVpnLicenceCount
            ) {
              observer.next(ButtonStatus.ACTIVE);
            } else {
              observer.next(ButtonStatus.DISABLED);
            }
          },
        });
    });
  }

  public save() {
    this.store.dispatch(StatusActions.setWaiting({ waiting: true }));
    // Reset errors
    const thisErrors = this.errors;
    let key: keyof typeof thisErrors;
    for (key in this.errors) {
      this.errors[key] = [];
    }
    this.connectService.createSSLUser(this.user, this.accountId).subscribe({
      next: (createdUsername) => {
        this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
        this.store.dispatch(
          AccountsActions.addSSLUser({
            sslUser: {
              adUsername: createdUsername,
              vpnUsername: createdUsername,
              email: this.user.email,
              active: false,
              accountId: this.accountId,
            },
          }),
        );
        this.modalStatus = ModalStatus.CLOSED;
        this.toastr.success('The user has been created.', 'Success', {
          progressBar: true,
        });
      },
      error: (err) => {
        this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
        this.errorService.handleSaveErrors(err);
        this.errors = err;
      },
    });
  }

  public cancel() {
    this.alertService.throwConfirmation(
      this.modalCloseMessage,
      'Close',
      'Warning',
      () => (this.modalStatus = ModalStatus.CLOSED),
    );
  }

  /**
   * Handle the blur event from the form field
   * @param prop Name of the property to validate
   */
  public handleBlur(prop: string) {
    const validation = this.connectService.validate(this.user);
    if (validation === true) {
      this.errors[prop] = [];
    } else {
      this.errors[prop] = validation[prop];
    }
  }

  /**
   * Handle the focus event from the form field
   * @param prop Name of the property to clear
   */
  public handleFocus(prop: string): void {
    // Clear the error message
    this.errors[prop] = [];
  }

  public switchTab(tabName: string) {
    this.activeTab = tabName;
  }
}
