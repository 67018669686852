import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { openClose } from '@shared/animations/animations';
import { Directions } from '@shared/models/directions.model';
import {
  CustomIcons,
  IconSize,
  IconStatus,
  IconType,
} from '@shared/models/icon.model';
import { filter, map, startWith } from 'rxjs';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  animations: [openClose()],
})
export class NavItemComponent implements OnInit {
  @Input() icon: string | CustomIcons | undefined;
  @Input() title: string = 'Title';
  @Input() link: string = '/';
  @Input() isMobile: boolean = false;
  @Input() customIcon: boolean = false;
  @Input() clickable: boolean = true;
  @Input() linkExactMatch: boolean = false;

  public IconSize = IconSize;
  public IconType = IconType;
  public IconStatus = IconStatus;
  public Directions = Directions;

  public subListAlign: string = 'top';
  public currentRoute: string = '/';
  public isSelected: boolean = false;

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        startWith(this.router),
        filter(
          (event) => event instanceof NavigationEnd || event instanceof Router,
        ),
        map((event) => (event as NavigationEnd).url),
      )
      .subscribe((url) => {
        if (this.linkExactMatch) {
          this.isSelected = this.link === url;
        } else {
          this.currentRoute = '/' + url.split('/')[1];
          this.isSelected = this.link.includes(this.currentRoute);
        }
      });
  }

  onClick(): void {
    if (!this.clickable) {
      return;
    }
    // otherwise:
    // check if link ends with "/"
    // and remove if it it does
    let navLink = this.link;
    if (navLink.charAt(navLink.length - 1) === '/')
      navLink = navLink.slice(0, -1);
    this.router.navigateByUrl(navLink);
  }
}
