import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldType } from '@forms/models/form-field.model';
import {
  ButtonRole,
  ButtonSize,
  ButtonStatus,
} from '@shared/models/button.model';

@Component({
  selector: 'app-firewall-notes',
  templateUrl: './firewall-notes.component.html',
  styleUrls: ['./firewall-notes.component.scss'],
})
export class FirewallNotesComponent {
  @Input() data: string | null = null;
  @Input() errors: { notes: string } | null = null;
  @Input() buttonStatus: ButtonStatus = ButtonStatus.ACTIVE;
  @Input() noteLoaded: boolean = false;

  @Output() notesUpdate = new EventEmitter();

  public FieldType = FieldType;
  public ButtonRole = ButtonRole;
  public ButtonSize = ButtonSize;

  save() {
    this.notesUpdate.emit(this.data);
  }
}
