import { Pipe, PipeTransform } from '@angular/core';
import {
  isNumberFinite,
  isPositive,
  isInteger,
  toDecimal,
} from './number-utils';

export type ByteUnit = 'B' | 'kB' | 'KB' | 'MB' | 'GB' | 'TB';

export const ByteFormats: {
  [key: string]: { max: number; prev?: ByteUnit | false };
} = {
  B: { max: 1000 },
  kB: { max: Math.pow(1000, 2), prev: 'B' },
  KB: { max: Math.pow(1000, 2), prev: 'B' }, // Backward compatible
  MB: { max: Math.pow(1000, 3), prev: 'kB' },
  GB: { max: Math.pow(1000, 4), prev: 'MB' },
  TB: { max: Number.MAX_SAFE_INTEGER, prev: 'GB' },
};
@Pipe({
  name: 'bytes',
})
export class BytesPipe implements PipeTransform {
  static formatResult(
    result: number,
    unit: string = 'B',
    displayUnits: boolean
  ): string {
    if (displayUnits) {
      return `${result} ${unit}`;
    } else {
      return `${result}`;
    }
  }

  static calculateResult(
    format: { max: number; prev?: ByteUnit | false },
    bytes: number
  ) {
    const prev = format.prev ? ByteFormats[format.prev] : undefined;
    return prev ? bytes / prev.max : bytes;
  }

  transform(
    input: any,
    displayUnits: boolean = true,
    decimal: number = 0,
    from: ByteUnit = 'B',
    to?: ByteUnit
  ): any {
    if (typeof input === 'string') {
      input = parseInt(input, 10);
    }

    if (
      !(
        isNumberFinite(input) &&
        isNumberFinite(decimal) &&
        isInteger(decimal) &&
        isPositive(decimal)
      )
    ) {
      return input;
    }

    let bytes: number = input;
    let unit: ByteUnit = from;
    while (unit !== 'B') {
      bytes *= 1000;
      unit = ByteFormats[unit].prev || 'B';
    }

    if (!to) {
      let prevMax = 0;
      to =
        (Object.keys(ByteFormats).find((key: string) => {
          const format: any = ByteFormats[key];
          if (bytes < format.max && bytes > prevMax) {
            return true;
          }
          prevMax = format.max;
          return false;
        }) as ByteUnit) || 'B';
    }

    const format = ByteFormats[to];
    const result = toDecimal(BytesPipe.calculateResult(format, bytes), decimal);
    return BytesPipe.formatResult(result, to, displayUnits);
  }
}
