import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Account } from '@shared/models/account';
import { NavRoute, primaryNavData } from '@shared/models/nav-data';
import { PartnerAccount } from '@shared/models/partner-account';
import { User } from '@shared/models/user';
import { AccountService } from '@shared/services/account.service';
import { PermissionsService } from '@shared/services/permissions.service';
import { UserService } from '@shared/services/user.service';
import {
  combineLatest,
  distinctUntilChanged,
  Observable,
  skipWhile,
  Subscription,
  take,
} from 'rxjs';

@Component({
  selector: 'app-secondary-navigation',
  templateUrl: './secondary-navigation.component.html',
  styleUrls: ['./secondary-navigation.component.scss'],
})
export class SecondaryNavigationComponent implements OnInit, OnDestroy {
  public navData?: NavRoute[] = [];
  public primaryNavData?: NavRoute[] = [];
  public children?: NavRoute[] = [];
  public currentRoute?: NavRoute;

  private userAccountSubscription!: Subscription;

  public currentAccount?: Account;
  public currentPartner?: PartnerAccount;
  public currentUser!: User | null;

  public multiUserAccount: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private permissionsService: PermissionsService,
    private userService: UserService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.currentRoute = primaryNavData.find((navItem) => {
      return navItem?.data?.id === this.route.routeConfig?.data?.id;
    });

    this.children = this.currentRoute ? this.currentRoute.children : [];

    const user: Observable<User | null> = this.userService
      .getCurrentUser()
      .pipe(
        skipWhile((v) => !v), // ignore the null that comes at logout
        take(1),
        distinctUntilChanged()
      );
    const account: Observable<Account | undefined> = this.accountService
      .fetchCurrentAccount()
      .pipe(
        take(1),
        distinctUntilChanged() // only take a values that's new (stops redrawing on double null for admins)
      );

    this.userAccountSubscription = combineLatest([user, account]).subscribe(
      ([user, account]) => {
        if (account) {
          this.currentAccount = { ...account };
        }
        this.currentUser = user;
        if (user) {
          this.multiUserAccount =
            this.permissionsService.userHasAccessToMultipleAccounts(user);
        }
        this.children = this.updateNavItems();
      }
    );
  }

  ngOnDestroy() {
    this.userAccountSubscription?.unsubscribe();
  }

  private updateNavItems = () => {
    // Remove items from navData if needed
    return this.children?.map((navItem) => {
      if (!navItem.data?.id) {
        return navItem;
      }

      navItem.hidden = !this.permissionsService.isFeatureAllowed(
        navItem.data?.id,
        this.currentAccount,
        this.currentUser
      );

      return navItem;
    });
  };
}
