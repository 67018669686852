import { Pipe, PipeTransform } from '@angular/core';
import { isNumberFinite } from '@shared/pipes/number-utils';

@Pipe({
  name: 'secondsToTime',
})
export class secondsToTimePipe implements PipeTransform {
  transform(input: any) {
    if (typeof input === 'string') {
      input = parseInt(input, 10);
    }

    if (!isNumberFinite(input)) {
      return input;
    }

    const minutes: number = Math.floor(input / 60);
    const seconds: number = input - minutes * 60;

    return (
      this.str_pad_left(minutes, '0', 2) +
      ':' +
      this.str_pad_left(seconds, '0', 2)
    );
  }

  str_pad_left = (number: number, pad: string, length: number) => {
    return (new Array(length + 1).join(pad) + number).slice(-length);
  };
}
