<app-modal
  [size]="ModalSize.LARGE"
  class="view-ticket"
  [warningOnClose]="addComment"
>
  <div modal-header>
    Viewing ticket <strong>#{{ ticket.number }}</strong>
  </div>
  <div modal-body class="view-ticket__body">
    <app-ticket-details
      class="view-ticket__details"
      [ticket]="ticket"
      [type]="type"
      [showAddComment]="!addComment"
      (addCommentClick)="toggleAddComment()"
    >
    </app-ticket-details>
    <app-widget class="view-ticket__content" [type]="WidgetType.CARDLESS">
      <ng-container widget-title>
        Initial Request
        <app-help-icon>
          <p>
            This is the initial request which was made when this ticket was
            opened. We have included the original message here to make it easier
            for you to remember the context of this ticket.
          </p>
        </app-help-icon>
      </ng-container>
      <div widget-content>
        <div *ngIf="ticket">
          <div class="view-ticket__ticket" *ngIf="ticket.description">
            <app-comment
              *ngIf="commentsLoaded"
              class="view-ticket__comment"
              [comment]="initialRequest"
              [type]="CommentType.INITIAL"
            ></app-comment>
          </div>

          <app-add-comment
            *ngIf="addComment"
            ticketId="{{ ticket.id }}"
            (closeComment)="toggleAddComment()"
            (commentAdded)="changeTicketStatus($event)"
          ></app-add-comment>

          <div
            class="view-ticket__comments--loading"
            *ngIf="!commentsLoaded"
            style="min-height: 20rem"
          >
            <app-loading></app-loading>
          </div>
          <ng-container *ngIf="commentsLoaded">
            <app-widget [type]="WidgetType.CARDLESS">
              <ng-container widget-title>
                Comments
                <app-help-icon>
                  <p>
                    This is the comment thread which contains all comments that
                    have been made on this ticket. The most recent comment is
                    highlighted at the top and older comments are shown in order
                    as you scroll.
                  </p>
                  <p>
                    From here you can add new comments to this ticket and this
                    will update the comment thread to show your most recent
                    comment at the top.
                  </p>
                </app-help-icon>
              </ng-container>
              <div widget-content class="view-ticket__comments">
                <div *ngIf="!ticket.comments || ticket.comments.length === 0">
                  <p>There are no comments associated with this ticket.</p>
                </div>
                <div *ngIf="ticket.comments?.length">
                  <app-comment
                    class="view-ticket__comment"
                    [comment]="ticket.comments![0]"
                    [type]="CommentType.RECENT"
                  ></app-comment>
                </div>
                <ng-container *ngIf="olderCommentsLoaded">
                  <div *ngFor="let comment of additionalComments">
                    <app-comment
                      class="view-ticket__comment"
                      [comment]="comment"
                    ></app-comment>
                  </div>
                </ng-container>
              </div>
            </app-widget>
          </ng-container>
          <div class="view-ticket__buttons" *ngIf="ticket.comments">
            <app-button
              [status]="ButtonStatus.ACTIVE"
              *ngIf="!olderCommentsLoaded && ticket.comments!.length > 1"
              [role]="ButtonRole.REFRESH"
              [iconName]="'refresh'"
              (buttonClick)="loadAdditionalComments()"
              >Load Older Comments</app-button
            >
            <ng-container
              *appPermitted="[
                'SUPPORT__SERVICE_REQUESTS__UPDATE',
                'SUPPORT__INCIDENTS__UPDATE'
              ]"
            >
              <app-button
                (buttonClick)="toggleAddComment()"
                class="ticket-details__add-comment"
                *ngIf="!addComment"
                data-testid="add-comment-button"
                [role]="ButtonRole.PRIMARY"
                [iconName]="'add_box'"
                [iconType]="IconType.OUTLINED"
                >Add comment</app-button
              >
            </ng-container>
          </div>
        </div>
      </div>
    </app-widget>
  </div>
</app-modal>
