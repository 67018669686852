import { Component, Input } from '@angular/core';
import { IconSize } from '@shared/models/icon.model';

@Component({
  selector: 'app-chart-error',
  templateUrl: './chart-error.component.html',
  styleUrls: ['./chart-error.component.scss'],
})
export class ChartErrorComponent {
  @Input() errorText = 'There was an error fetching the data';

  public IconSize = IconSize;

  constructor() {}
}
