import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FieldType } from '@forms/models/form-field.model';
import {
  ButtonRole,
  ButtonType,
  ButtonDisplay,
  ButtonStatus,
} from '@shared/models/button.model';

import { CognitoResetPasswordService } from '../../services/cognito-reset-password.service';
import { CognitoResponse } from '../../models/cognito-response';
import { CognitoErrorService } from '../../services/cognito-error.service';
import { IconType } from '@shared/models/icon.model';
import { CodePattern, PasswordPattern } from '@auth/models/patterns';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private queryParamsSub?: Subscription;

  public FieldType = FieldType;
  public ButtonRole = ButtonRole;
  public ButtonType = ButtonType;
  public ButtonDisplay = ButtonDisplay;
  public ButtonStatus = ButtonStatus;
  public IconType = IconType;
  public CodePattern = CodePattern;
  public PasswordPattern = PasswordPattern;

  public errorMsg?: string;
  public successMsg?: string;
  public code: string = '';
  public password: string = '';
  public confirmPassword: string = '';
  public username: string = '';
  public buttonStatus = ButtonStatus.ACTIVE;

  public codeValid: boolean = false;
  public passwordValid: boolean = false;
  public passwordsMatch: boolean = false;
  public formValid: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cognitoResetPasswordService: CognitoResetPasswordService,
    private cognitoErrorService: CognitoErrorService
  ) {}

  ngOnInit(): void {
    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      const data = params.data;
      if (data) {
        const decodedData = atob(data);
        const dataObj = JSON.parse(decodedData);
        this.username = dataObj.username;
      }
    });
  }

  public ngOnDestroy() {
    if (this.queryParamsSub) {
      this.queryParamsSub.unsubscribe();
    }
  }

  resetPassword(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (
      this.code &&
      this.code.length > 0 &&
      this.password &&
      this.password.length > 0 &&
      this.confirmPassword &&
      this.confirmPassword.length > 0 &&
      this.password === this.confirmPassword
    ) {
      this.buttonStatus = ButtonStatus.LOADING;
      this.cognitoResetPasswordService
        .setNewPasswordForForgottenPassword(
          this.code,
          this.password,
          this.username
        )
        .subscribe((res: CognitoResponse) => {
          if (res.success) {
            this.successMsg = 'Password successfully reset';
            setTimeout(() => {
              this.buttonStatus = ButtonStatus.ACTIVE;
              this.router.navigate(['/login']);
            }, 2500);
          } else {
            this.buttonStatus = ButtonStatus.ACTIVE;
            this.errorMsg = res.reason;
            this.cognitoErrorService.throwError(this.errorMsg);
          }
        });
    } else {
      // handle feedback for bad form
      if (!this.code || this.code.length < 1) {
        this.errorMsg = 'Please enter your code';
      } else if (!this.password || this.password.length < 1) {
        this.errorMsg = 'Please enter a password';
      } else if (
        !this.confirmPassword ||
        this.confirmPassword.length < 1 ||
        this.password !== this.confirmPassword
      ) {
        this.errorMsg = 'Please check your passwords match';
      }
      this.buttonStatus = ButtonStatus.ACTIVE;
      this.cognitoErrorService.throwError(this.errorMsg);
    }
  }

  public codeChanged(code: string): void {
    this.codeValid = code.length === 6;
    this.updateFormValidity();
  }

  public passwordValidityChanged(valid: boolean): void {
    this.passwordValid = valid;
    this.updateFormValidity();
  }

  private updateFormValidity(): void {
    this.formValid = this.codeValid && this.passwordValid;
  }
}
