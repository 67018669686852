import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { filter, mergeMap, Observable, of, Subscription, zip } from 'rxjs';
import { Store } from '@ngrx/store';

/* Services */
import { ModalService } from '@shared/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@shared/services/user.service';
import { ApiService } from '@shared/services/api.service';
import { ErrorService } from '@shared/services/error.service';
import { AccountService } from '@shared/services/account.service';
import { PermissionsService } from '@shared/services/permissions.service';

/* Models */
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import {
  ApiErrorResponse,
  UserValidationError,
  ValidationError,
} from '@shared/models/error';
import {
  AdminUsers,
  UserGroupData,
  UserGroups,
} from '@shared/models/user-group';
import {
  User,
  UserPreferredMFA,
  UserStatus,
  RequesterLevel,
} from '@shared/models/user';
import { SelectOptions, SelectType } from '@forms/models/form-select.model';
import { AccountStoreItem } from '@store/account-store-item.model';
import { FieldStatus, FieldType } from '@forms/models/form-field.model';
import {
  ButtonDisplay,
  ButtonFlexType,
  ButtonRole,
  ButtonSize,
  ButtonStatus,
  ButtonType,
} from '@shared/models/button.model';
import { ToggleOptions, ToggleType } from '@forms/models/form-toggle.model';
import { FontStyle } from '@shared/models/font';

/* Store */
import * as StatusActions from '@store/status/status.actions';
import * as fromStatus from '@store/status/status.reducer';
import * as fromAccounts from '@store/accounts/accounts.selectors';
import { mfaTypes } from '@shared/models/mfa-types';
import {
  Permission,
  PermissionsOperator,
} from '@shared/models/permission.model';
import { Account } from '@shared/models/account';
import { PartnerAccount } from '@shared/models/partner-account';
import { DateTime } from 'luxon';
import { AlertService } from '@shared/services/alert.service';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss'],
})
export class AddEditUserComponent implements OnInit, OnDestroy {
  @ViewChildren('permissionCheckbox') private checkboxes!: QueryList<
    ElementRef<HTMLInputElement>
  >;

  public ModalSize = ModalSize;
  public FieldType = FieldType;
  public ButtonType = ButtonType;
  public ButtonRole = ButtonRole;
  public ButtonDisplay = ButtonDisplay;
  public ButtonFlexType = ButtonFlexType;
  public ButtonSize = ButtonSize;
  public FieldStatus = FieldStatus;
  public ModalStatus = ModalStatus;
  public SelectType = SelectType;
  public MfaTypes = mfaTypes;
  public PermissionsOperator = PermissionsOperator;
  public UserGroups = UserGroups;
  public ToggleType = ToggleType;
  public FontStyle = FontStyle;

  public modalCloseMessage: string =
    'Closing this modal will discard all changes. Are you sure you want to close the modal?';
  public errors: ValidationError = JSON.parse(
    JSON.stringify(UserValidationError),
  );
  public user: User = {
    email: '',
    groups: UserGroups.STANDARD_USER,
    sub: '',
    accountId: [],
    givenName: '',
    familyName: '',
    jobTitle: '',
    createdDate: '',
    updatedDate: '',
    countryCode: '+44',
    phoneNumber: '',
    preferredMFA: UserPreferredMFA.SMS_MFA,
    supportId: '',
    username: '',
    permissions: [],
    securityContact: false,
  };
  public phoneNumber: string = '';
  public availableUserGroups: SelectOptions[] = [];
  public availableRequesterLevels: SelectOptions[] = [];
  public availableAccountOptions: SelectOptions[] = [];
  public availableCountryOptions: SelectOptions[] = [
    // UK
    {
      value: '+44',
      label: '+44',
    },
    // Canada
    {
      value: '+1',
      label: '+1',
    },
    // Faroe Islands
    {
      value: '+298',
      label: '+298',
    },
    // Greece
    {
      value: '+30',
      label: '+30',
    },
    // Netherlands
    {
      value: '+31',
      label: '+31',
    },
    // Belgium
    {
      value: '+32',
      label: '+32',
    },
    // France
    {
      value: '+33',
      label: '+33',
    },
    // Gibraltar
    {
      value: '+350',
      label: '+350',
    },
    // Portugal
    {
      value: '+351',
      label: '+351',
    },
    // Luxembourg
    {
      value: '+352',
      label: '+352',
    },
    // Ireland
    {
      value: '+353',
      label: '+353',
    },
    // Iceland
    {
      value: '+354',
      label: '+354',
    },
    // Albania
    {
      value: '+355',
      label: '+355',
    },
    // Malta
    {
      value: '+356',
      label: '+356',
    },
    // Cyprus
    {
      value: '+357',
      label: '+357',
    },
    // Finland
    {
      value: '+358',
      label: '+358',
    },
    // Bulgaria
    {
      value: '+359',
      label: '+359',
    },
    // Hungary
    {
      value: '+36',
      label: '+36',
    },
    // Lithuania
    {
      value: '+370',
      label: '+370',
    },
    // Latvia
    {
      value: '+371',
      label: '+371',
    },
    // Estonia
    {
      value: '+372',
      label: '+372',
    },
    // Moldova
    {
      value: '+373',
      label: '+373',
    },
    // Belarus
    {
      value: '+375',
      label: '+375',
    },
    // Andorra
    {
      value: '+376',
      label: '+376',
    },
    // Monaco
    {
      value: '+377',
      label: '+377',
    },
    // San Marino
    {
      value: '+378',
      label: '+378',
    },
    // Vatican City
    {
      value: '+379',
      label: '+379',
    },
    // Ukraine
    {
      value: '+380',
      label: '+380',
    },
    // Serbia
    {
      value: '+381',
      label: '+381',
    },
    // Montenegro
    {
      value: '+382',
      label: '+382',
    },
    // Kosovo
    {
      value: '+383',
      label: '+383',
    },
    // Croatia
    {
      value: '+385',
      label: '+385',
    },
    // Slovenia
    {
      value: '+386',
      label: '+386',
    },
    // Bosnia and Herzegovina
    {
      value: '+387',
      label: '+387',
    },
    // North Macedonia
    {
      value: '+389',
      label: '+389',
    },
    // Italy
    {
      value: '+39',
      label: '+39',
    },
    // Romania
    {
      value: '+40',
      label: '+40',
    },
    // Switzerland
    {
      value: '+41',
      label: '+41',
    },
    // Czechia
    {
      value: '+420',
      label: '+420',
    },
    // Slovakia
    {
      value: '+421',
      label: '+421',
    },
    // Liechtenstein
    {
      value: '+423',
      label: '+423',
    },
    // Austria
    {
      value: '+43',
      label: '+43',
    },
    // Denmark
    {
      value: '+45',
      label: '+45',
    },
    // Sweden
    {
      value: '+46',
      label: '+46',
    },
    // Norway
    {
      value: '+47',
      label: '+47',
    },
    // Poland
    {
      value: '+48',
      label: '+48',
    },
    // Germany
    {
      value: '+49',
      label: '+49',
    },
    // Chile
    {
      value: '+56',
      label: '+56',
    },
    // Australia
    {
      value: '+61',
      label: '+61',
    },
    // India
    {
      value: '+91',
      label: '+91',
    },
  ];
  public editMode: boolean = false;
  public loggedInUser: User = this.user;

  public modalStatus: ModalStatus = ModalStatus.OPEN;
  public isSaveLoading: boolean = false;
  public isRemoveLoading: boolean = false;
  public isResetLoading: boolean = false;
  private accounts: AccountStoreItem[] = [];

  public waiting$: Observable<boolean>;
  public currentAccountID$: Observable<string | null>;
  public companyName$!: Observable<string | undefined>;

  public accountIsEditable: boolean = false;
  public accountIsDisabled: boolean = false;
  public isRemovable: boolean = false;
  public previousUserGroup: string = '';

  public permissions: Permission[] = [];
  public selectedAccounts!: any[];
  public allAccountsIds!: any[];

  public allAccounts: Account[] = [];
  public partnerAccounts: PartnerAccount[] = [];

  private multiSubscription!: Subscription;
  private waitingSubscription!: Subscription;

  public showEditPartnerAccount: boolean = true;
  public disableEditPartner: boolean = true;

  public needsResetting: boolean = false;

  public acceptedTermsVersion?: string;
  public acceptedTermsDate?: string;

  public selectedUserGroup: UserGroups = UserGroups.STANDARD_USER;
  public selectedRequesterLevel: string = RequesterLevel.NONE;
  public selectedSecurityContact: string = 'false';

  public showEditAccount: boolean = true;
  public disableEditAccount: boolean = true;
  public showEditUserGroup: boolean = true;
  public disableEditUserGroup: boolean = true;

  public isProfile: boolean = true;
  public buttonStatus: ButtonStatus = ButtonStatus.ACTIVE;
  public lockAccountType: boolean = false;

  public toggleOptions: ToggleOptions[] = [
    {
      value: 'true',
      label: 'Yes',
    },
    {
      value: 'false',
      label: 'No',
    },
  ];

  constructor(
    private accountService: AccountService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private userService: UserService,
    private apiService: ApiService,
    private store: Store,
    private errorService: ErrorService,
    private permissionsService: PermissionsService,
    public alertService: AlertService,
  ) {
    this.waiting$ = this.store.select(fromStatus.selectWaiting);
    this.currentAccountID$ = this.store.select(
      fromStatus.selectCurrentAccountID,
    );
    this.companyName$ = this.store.select(fromAccounts.selectCompanyName);
    this.waitingSubscription = this.waiting$.subscribe((val) => {
      this.buttonStatus = !val ? ButtonStatus.ACTIVE : ButtonStatus.LOADING;
    });
  }

  /**
   * Init
   */
  ngOnInit(): void {
    let userSub: Observable<User | null>;
    const userContext = this.modalService.getContext();
    if (userContext) {
      // We still have to call the API to get the preferredMFA field
      this.isProfile = userContext.isProfile;
      userSub = this.userService
        .getUser(userContext.sub)
        .pipe(filter((user) => user !== null));
    } else {
      // Get the username from route params
      userSub = this.route.params.pipe(
        mergeMap((params: Params): Observable<User | null> => {
          return params.username
            ? this.userService.getUser(params.username)
            : of(null);
        }),
      );
    }
    this.runSubscriptions(userSub, !!userContext);
  }

  /**
   * Destroy
   */
  ngOnDestroy(): void {
    this.multiSubscription?.unsubscribe();
    this.waitingSubscription?.unsubscribe();
  }

  private runSubscriptions(
    userSub: Observable<User | null>,
    hasContext: boolean,
  ): void {
    // Get all these subs together
    // And then sort out visibility
    const loggedInUserSub = this.userService
      .getCurrentUser()
      .pipe(filter((user) => user !== null));
    const accountsSub = this.accountService.checkAccounts();
    const permissionsSub = this.permissionsService.permissions.pipe(
      filter((permissions) => permissions !== null),
    );
    this.multiSubscription = zip(
      userSub,
      loggedInUserSub,
      accountsSub,
      permissionsSub,
    ).subscribe({
      next: ([user, loggedInUser, accounts, permissions]): void => {
        // Checks whether is profile when no context passed (happens with refresh)
        if (!hasContext && loggedInUser?.username !== user?.username) {
          this.isProfile = false;
        }
        if (user) {
          // Add UK country code for existing users which don't have it set
          if (!user.countryCode) {
            user.countryCode = '+44';
          }
          this.editMode = true;
          this.user = user;
          if (this.user.securityContact) {
            this.selectedSecurityContact = 'true';
          }
          if (this.user.requesterLevel) {
            this.selectedRequesterLevel = this.user.requesterLevel;
            if (this.user.requesterLevel === RequesterLevel.COMPANY_HEAD) {
              this.lockAccountType = true;
            }
          }
          if (this.user.groups) {
            this.selectedUserGroup = this.user.groups;
            if (this.selectedUserGroup === UserGroups.SUPER_ADMIN) {
              this.lockAccountType = true;
            }
          }
          this.needsResetting = this.userNeedsResetting();
        }
        if (loggedInUser) {
          this.loggedInUser = loggedInUser;
          this.availableUserGroups =
            this.userService.getEditableGroups(loggedInUser);
          this.availableRequesterLevels =
            this.userService.getRequesterLevels(loggedInUser);
        }

        if (accounts) this.allAccounts = accounts;

        if (permissions) {
          this.permissions = permissions.filter((permission) =>
            loggedInUser?.permissions?.includes(permission.id),
          );
        }
        if (!this.user.permissions?.length) {
          // Fill permissions based on role
          this.onUserGroupChange();
        }

        // Check on visibility and fix data
        this.fixAndUpdate();
        this.checkVisibility();
      },
      error: (_err) => {
        this.toastr.warning(
          'Data could not be loaded',
          'Something went wrong',
          {
            progressBar: true,
          },
        );
      },
    });
  }

  private fixAndUpdate() {
    this.updateAccounts();
    this.setPhoneNumber();
    this.setTermsFields();

    if (Array.isArray(this.user.accountId)) {
      this.selectedAccounts = this.user.accountId;
    } else {
      this.selectedAccounts = [this.user.accountId];
    }

    this.allAccountsIds = this.allAccounts.map((account) => {
      return account.id;
    });

    // filter out user-associated accounts that do not exist on actual list of accounts
    this.selectedAccounts = this.selectedAccounts.filter((accountId) => {
      return this.allAccountsIds.includes(accountId);
    });

    if (this.user.groups) {
      this.previousUserGroup = UserGroupData[this.user.groups]?.displayName;
    }
  }

  private updateAccounts(): void {
    this.accounts = this.allAccounts;
  }

  /**
   * Convert user's phone number to form-friendly version
   */
  private setPhoneNumber(): void {
    this.phoneNumber = this.user.phoneNumber
      ? this.user.phoneNumber.replace(this.user.countryCode, '')
      : '';
  }

  private setTermsFields() {
    const termsVersionString = this.user.termsVersion?.split(';');
    if (termsVersionString) {
      this.acceptedTermsVersion = termsVersionString[0];
      this.acceptedTermsDate = termsVersionString[1];
    }
  }

  public turnOffTOTP(): void {
    const params = {
      SoftwareTokenMfaSettings: {
        Enabled: false,
        PreferredMfa: false,
      },
      SMSMfaSettings: {
        Enabled: true,
        PreferredMfa: true,
      },
    };
    this.apiService
      .post(`/users/${this.user.username}/setUserMFAPreference`, params)
      .subscribe({
        next: () => {
          if (!this.user.username) {
            return;
          }
          // reload the mfa status?
          this.userService.getUser(this.user.username).subscribe({
            next: (user: User) => {
              this.user = user;
              this.setPhoneNumber();
              this.editMode = true;
            },
            error: (err: ApiErrorResponse) => {
              this.errorService.handleSaveErrors(err);
            },
          });
        },
        error: (err: ApiErrorResponse) => {
          this.errorService.handleSaveErrors(err);
        },
      });
  }

  /**
   * Run checks to see if accounts/partners/usergroups should be visible, and assigns them to static props
   * This saves these checks being run every change detection cycle
   */
  private checkVisibility(): void {
    this.checkRemovable();
  }

  /**
   * Whether or not this user can be removed
   * @returns {boolean} return if user removable.
   */
  private checkRemovable(): void {
    this.isRemovable =
      this.loggedInUser && this.user.username !== this.loggedInUser.username;
  }

  /**
   * userNeedsResetting
   * Calculate how long it was since the user was updated
   * Check user status is "FORCE_CHANGE_PASSWORD"
   */
  public userNeedsResetting() {
    if (!this.user.updatedDate) {
      return false;
    }
    const now = DateTime.now();
    const updated = DateTime.fromISO(this.user.updatedDate);
    const dateDifference = updated.diff(now, 'days');
    return (
      this.user.status === UserStatus.FORCE_CHANGE_PASSWORD &&
      dateDifference.days > 7
    );
  }

  public onUserGroupChange(): void {
    this.user.groups = this.selectedUserGroup;

    if (AdminUsers.includes(this.selectedUserGroup)) {
      // super admin has no accountId or partnerId
      this.user.partnerId = '';
      this.user.accountId = [''];
    }
    // partners might have one or more accountIds
    this.updateAccounts();
    this.checkVisibility();

    // Set permissions for that user group
    this.user.permissions = UserGroupData[this.selectedUserGroup].permissions;
  }

  public onRequesterLevelChange(): void {
    this.user.requesterLevel = this.selectedRequesterLevel;

    // partners might have one or more accountIds
    this.updateAccounts();
    this.checkVisibility();
  }

  public handleChangeTypeToggle(value: string): void {
    this.selectedSecurityContact = value;
    this.user.securityContact = value === 'false' ? false : true;

    // partners might have one or more accountIds
    this.updateAccounts();
    this.checkVisibility();
  }

  /**
   * Apply modified properties to user object
   */
  private prepUserObject(): void {
    this.user.phoneNumber = this.phoneNumber;
    // If all-accounts is selected, change to all accounts
    if (this.user.accountId?.includes('all-accounts')) {
      this.user.accountId = this.accounts.map((account) => account.id);
    }
  }

  /**
   * Handle the blur event from the form field
   * @param prop Name of the property to validate
   */
  public handleBlur(prop: string): void {
    this.prepUserObject();
    const validation = this.userService.validate(this.user);
    if (validation === true) {
      this.errors[prop] = [];
    } else {
      this.errors[prop] = validation[prop];
    }
  }

  /**
   * Handle the focus event from the form field
   * @param prop Name of the property to clear
   */
  public handleFocus(prop: string): void {
    // Clear the error message
    this.errors[prop] = [];
  }

  /**
   * Resets and closes form
   */
  public cancelRequest(): void {
    this.alertService.throwConfirmation(
      this.modalCloseMessage,
      'Close',
      'Warning',
      () => (this.modalStatus = ModalStatus.CLOSED),
    );
  }

  /**
   * Whether or not this user can be removed
   * @returns {boolean} return if user removable.
   */
  public removable(): boolean {
    if (!this.loggedInUser) {
      return false;
    }
    return this.user.username !== this.loggedInUser.username;
  }

  /**
   * Remove this user
   */
  public remove(): void {
    // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(StatusActions.setWaiting({ waiting: true }));
    this.isRemoveLoading = true;
    this.userService.remove(this.user).subscribe({
      next: () => {
        // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
        this.isRemoveLoading = false;
        this.modalStatus = ModalStatus.CLOSED;
        this.toastr.success('User removed', 'Success', {
          progressBar: true,
        });
      },
      error: (err: ApiErrorResponse) => {
        this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
        this.isRemoveLoading = false;
        if (err.status !== 419) {
          this.errorService.handleSaveErrors(err);
        }
      },
    });
  }

  /**
   * Save account details - either create new or update existing
   */
  public save() {
    this.isSaveLoading = true;
    this.store.dispatch(StatusActions.setWaiting({ waiting: true }));
    this.errors = JSON.parse(JSON.stringify(UserValidationError));
    this.prepUserObject();
    if (this.editMode) {
      if (
        this.permissionsService.isUserPermitted(this.user, [
          'ADMIN__PARTNER__CREATE',
        ])
      ) {
        // Super admin mustn't have an account ID
        this.user.accountId = [''];
      }

      // We shouldn't be passing in the terms version here
      delete this.user.termsVersion;

      this.userService.update(this.user).subscribe({
        next: (_user: User) => {
          this.handleSuccessResponse('User updated');
        },
        error: (err: ValidationError | ApiErrorResponse) => {
          this.handleErrorResponse(err);
        },
      });
    } else {
      this.userService
        .create(this.user, this.loggedInUser.accountId)
        .subscribe({
          next: () => {
            this.handleSuccessResponse('User created');
          },
          error: (err: ValidationError | ApiErrorResponse) => {
            this.handleErrorResponse(err);
          },
        });
    }
  }

  private handleSuccessResponse(successMessage: string): void {
    this.isSaveLoading = false;
    this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
    this.modalStatus = ModalStatus.CLOSED;
    this.toastr.success(successMessage, 'Success', {
      progressBar: true,
    });
  }

  private handleErrorResponse(err: ValidationError | ApiErrorResponse): void {
    this.isSaveLoading = false;
    this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
    if (err.error) {
      this.errorService.handleSaveErrors(err as ApiErrorResponse);
    } else {
      const errorMsg = Object.values(err).find((value) => value.length);
      this.errors = err as ValidationError;
      this.errorService.handleSaveErrors(errorMsg[0]);
    }
  }
}
