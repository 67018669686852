import { Component } from '@angular/core';
import { ButtonRole, ButtonType } from '@shared/models/button.model';
import { CustomIcons, IconSize } from '@shared/models/icon.model';
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import { ModalService } from '@shared/services/modal.service';

@Component({
  selector: 'app-service-request-choice',
  templateUrl: './service-request-choice.component.html',
  styleUrls: ['./service-request-choice.component.scss'],
})
export class ServiceRequestChoiceComponent {
  public ModalStatus = ModalStatus;
  public ModalSize = ModalSize;
  public ButtonType = ButtonType;
  public ButtonRole = ButtonRole;
  public CustomIcons = CustomIcons;
  public IconSize = IconSize;
  public modalStatus: ModalStatus = ModalStatus.OPEN;

  constructor(private modalService: ModalService) {}

  raiseFirewallRule() {
    this.modalService.show('request-firewall-rule');
  }

  raiseServiceRequest() {
    this.modalService.raiseServiceRequest();
  }
}
