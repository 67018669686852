import { Component, Input } from '@angular/core';
import { IconSize } from '@shared/models/icon.model';

@Component({
  selector: 'app-password-strength-item',
  templateUrl: './password-strength-item.component.html',
  styleUrls: ['./password-strength-item.component.scss'],
})
export class PasswordStrengthItemComponent {
  @Input() param: boolean = false;

  public IconSize = IconSize;

  constructor() {}
}
