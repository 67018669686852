import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSize, IconStatus } from '@shared/models/icon.model';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() currentPage: number = 1;
  @Input() lastPage?: number;
  @Output() navigate = new EventEmitter<number>();

  public IconStatus = IconStatus;
  public IconSize = IconSize;

  goToPage(page: number) {
    this.navigate.emit(page);
  }
}
