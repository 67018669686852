import { Data, Route } from '@angular/router';
import { CustomIcons } from '@shared/models/icon.model';
import { Feature } from '@shared/models/features';

// this nav data has the same fields as Route,
// plus these additional fields
export interface NavRoute extends Route {
  hidden?: boolean;
  disabled?: boolean;
  data?: NavData;
  children?: NavRoute[];
  clickable?: boolean;
  newSection?: boolean;
  link?: string;
}

// Also used in routing modules, this shapes what can be in the
// data: {} object

export interface NavData extends Data {
  id?: Feature;
  title: string;
  icon?: string;
}

// This is the data used to draw the nav menus,
// It does *not* configure the routing
export const primaryNavData: NavRoute[] = [
  {
    path: 'dashboard',
    data: {
      title: 'Dashboard',
      icon: 'speed',
      id: Feature.DASHBOARD,
      sectionIndex: 0,
    },
  },
  {
    path: 'summary',
    data: {
      title: 'Account List',
      icon: 'list_alt',
      id: Feature.SUMMARY,
      sectionIndex: 0,
    },
  },
  {
    path: 'connect',
    data: {
      title: 'Connect',
      icon: CustomIcons.CONNECT,
      customIcon: true,
      id: Feature.CONNECT,
      sectionIndex: 1,
    },
    children: [
      {
        path: '',
        data: {
          title: 'Home',
          id: Feature.CONNECT_DASHBOARD,
        },
      },
      {
        path: 'remote-access',
        data: {
          title: 'Remote Access',
          id: Feature.SSLVPN,
        },
      },
      {
        path: 'routing-tables',
        data: {
          title: 'Routing Tables',
          id: Feature.ROUTING_TABLES,
        },
      },
      {
        path: 'ipsec-vpn',
        data: {
          title: 'VPN Site Connectivity',
          id: Feature.IPSECVPN,
        },
      },
    ],
  },
  {
    path: 'protect',
    data: {
      icon: CustomIcons.PROTECT,
      title: 'Protect',
      customIcon: true,
      id: Feature.PROTECT,
      sectionIndex: 1,
    },
    children: [
      {
        path: '',
        data: {
          title: 'Home',
          id: Feature.PROTECT_DASHBOARD,
        },
      },
      {
        path: 'firewall',
        data: {
          title: 'Firewall Rules',
          id: Feature.FIREWALL,
        },
      },
      {
        path: 'firewall-proxy',
        data: {
          title: 'Proxy Rules',
          id: Feature.FIREWALL_PROXY,
        },
      },
    ],
  },
  {
    path: 'inspect',
    data: {
      title: 'Inspect',
      icon: CustomIcons.INSPECT,
      customIcon: true,
      id: Feature.INSPECT,
      sectionIndex: 1,
    },
    children: [
      {
        path: '',
        data: {
          title: 'Home',
          id: Feature.INSPECT_DASHBOARD,
        },
      },
      {
        path: 'analytics',
        data: {
          title: 'Analytics',
          id: Feature.ANALYTICS,
        },
      },
      {
        path: 'traffic-logs',
        data: {
          title: 'Traffic Logs',
          id: Feature.TRAFFIC_LOGS,
        },
      },
    ],
  },
  {
    path: 'support',
    data: {
      title: 'Support',
      icon: 'support',
      id: Feature.SUPPORT,
      sectionIndex: 2,
    },
    children: [
      {
        path: 'incidents',
        data: {
          title: 'Incidents',
          icon: 'add_box',
          id: Feature.INCIDENTS,
          sectionIndex: 2,
        },
      },
      {
        path: 'service-requests',
        data: {
          title: 'Service Requests',
          icon: 'build',
          id: Feature.SERVICE_REQUESTS,
          sectionIndex: 2,
        },
      },
    ],
  },
  {
    path: 'reporting',
    data: {
      title: 'Reporting',
      icon: 'book',
      id: Feature.REPORTS,
      sectionIndex: 2,
    },
    children: [
      {
        path: '',
        data: {
          title: 'Home',
          id: Feature.REPORTS,
        },
      },
    ],
  },
  {
    path: 'settings',
    data: {
      title: 'Settings',
      icon: 'settings',
      id: Feature.SETTINGS,
      sectionIndex: 3,
    },
    children: [
      {
        path: '',
        data: {
          title: 'Home',
          id: Feature.SETTINGS,
        },
      },
      {
        path: 'user-management',
        data: {
          title: 'Manage Users',
          id: Feature.USER_MANAGEMENT,
        },
      },
    ],
  },
  {
    path: 'logout',
    data: {
      title: 'Logout',
      icon: 'power_settings_new',
      sectionIndex: 3,
    },
  },
];
