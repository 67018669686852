import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take, switchMap, skipWhile, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

/* Services */
import { ApiService } from './api.service';
import { UserService } from './user.service';

/* Models */
import { User } from '../models/user';
import { PartnerAccount } from '../models/partner-account';

import * as PartnersActions from '../../store/partners/partners.actions';
import * as fromPartners from '../../store/partners/partners.reducer';
import { PermissionsService } from './permissions.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  private user: User | null = null;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private store: Store,
    private actions$: Actions,
    private permissionsService: PermissionsService
  ) {
    this.userService.getCurrentUser().subscribe((user: User | null) => {
      if (user) {
        this.user = user;
      }
    });
  }

  /**
   * Get list of FreshService partners
   * @returns {Observable<any>} observer to subscribe to for result.
   */
  public getSupportPartners(): Observable<any> {
    return this.apiService.get('/support/partners');
  }

  /**
   * Get all partners
   * @returns { Observable<PartnerAccount> } observer to subscribe to for result.
   */
  public fetchPartners(): Observable<PartnerAccount[]> {
    return this.store
      .select(fromPartners.selectAllPartnersWithAccountData)
      .pipe(skipWhile((val) => val == null));
  }

  /**
   * Check that partners are loaded
   * if not populate the partners store
   * (usually called from the partner resolver to make sure partner data exists pre-routing)
   */
  public checkPartners(): Observable<PartnerAccount[]> {
    return this.store.select(fromPartners.selectAllPartners).pipe(
      take(1),
      switchMap((partners) => {
        if (!partners || partners.length === 0) {
          if (
            this.permissionsService.isUserPermitted(this.user, [
              'ADMIN__ACCOUNT__READ',
            ])
          ) {
            this.store.dispatch(PartnersActions.fetchAllPartners());
          } else if (this.user?.partnerId) {
            this.store.dispatch(PartnersActions.fetchMyPartner());
          } else {
            return of([]);
          }
          return this.actions$.pipe(
            ofType(PartnersActions.SET_PARTNERS),
            map((result: { type: string; partners: PartnerAccount[] }) => {
              return result.partners;
            })
          );
        } else {
          return of(partners);
        }
      })
    );
  }

  /**
   * Get a partner from its ID
   * @param { string } id  The partner ID
   * @returns { Observable<PartnerAccount> } observer to subscribe to for result.
   */
  public getPartner(id: string): Observable<PartnerAccount> {
    return this.store.select(fromPartners.selectPartner({ id }));
  }

  /**
   * Get current partner
   * @returns { Observable<Account> } observer to subscribe to for result.
   */
  public fetchCurrentPartner(): Observable<PartnerAccount | undefined> {
    const canReadAllPartners = this.permissionsService.isUserPermitted(
      this.user,
      ['ADMIN__PARTNER__READ']
    );
    return this.store.select(
      fromPartners.selectCurrentPartnerData({
        canReadAllPartners,
      })
    );
  }
}
