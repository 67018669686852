<app-modal
  [size]="ModalSize.LARGE"
  [status]="modalStatus"
  class="vpn-detail"
  *ngIf="ipsec"
>
  <div modal-header><span>Remote Gateway: </span>{{ ipsec.remoteGateway }}</div>
  <div modal-body>
    <section class="vpn-detail__section">
      <h2 class="vpn-detail__section-header">Remote Gateway</h2>
      <app-datatable
        [data]="[ipsec]"
        [displayedColumns]="[
          'siteId',
          'remoteGateway',
          'phaseOne',
          'phaseTwo',
          'incomingData',
          'outgoingData'
        ]"
        [limit]="1"
        [status]="tableStatus"
        [showFooter]="false"
      >
        <!-- siteId column -->
        <ng-container cdkColumnDef="siteId">
          <th cdk-header-cell *cdkHeaderCellDef sort-header>Site Identifier</th>
          <td cdk-cell *cdkCellDef="let row">{{ row.phaseOne }}</td>
        </ng-container>

        <!-- remoteGateway column -->
        <ng-container cdkColumnDef="remoteGateway">
          <th cdk-header-cell *cdkHeaderCellDef sort-header>Remote Gateway</th>
          <td cdk-cell *cdkCellDef="let row">{{ row.remoteGateway }}</td>
        </ng-container>

        <!-- phaseOne column -->
        <ng-container cdkColumnDef="phaseOne">
          <th cdk-header-cell *cdkHeaderCellDef sort-header>Phase One</th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.phaseOne">
              <span
                *ngIf="row.phaseOneStatus === ConnectionStatus.UP"
                class="vpn-detail__connection-status-text vpn-detail__connection-status-text--active"
                >Active</span
              >
              <span
                *ngIf="row.phaseOneStatus === ConnectionStatus.DOWN"
                class="vpn-detail__connection-status-text vpn-detail__connection-status-text--inactive"
                >Inactive</span
              >
            </ng-container>
            <ng-container *ngIf="!row.phaseOne">n/a</ng-container>
          </td>
        </ng-container>

        <!-- phaseTwo column -->
        <ng-container cdkColumnDef="phaseTwo">
          <th cdk-header-cell *cdkHeaderCellDef sort-header>Phase Two</th>
          <td cdk-cell *cdkCellDef="let row">
            <ng-container *ngIf="row.phaseTwo">
              <ng-container
                *ngIf="
                  countActiveConnections(row.phaseTwo) as activeConnections
                "
              >
                <app-icon-tile
                  class="vpn-detail__phase-two-icon"
                  [size]="IconSize.XXSMALL"
                  [status]="IconStatus.SUCCESS"
                  >arrow_upward</app-icon-tile
                >
                {{ activeConnections }} up
                {{ activeConnections === 1 ? "connection" : "connections" }}
              </ng-container>
              <ng-container
                *ngIf="
                  countInactiveConnections(row.phaseTwo) as inactiveConnections
                "
              >
                <app-icon-tile
                  class="vpn-detail__phase-two-icon"
                  [size]="IconSize.XXSMALL"
                  [status]="IconStatus.ERROR"
                  >arrow_downward</app-icon-tile
                >
                {{ inactiveConnections }} down
                {{ inactiveConnections === 1 ? "connection" : "connections" }}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!row.phaseTwo">n/a</ng-container>
          </td>
        </ng-container>

        <!-- incomingData column -->
        <ng-container cdkColumnDef="incomingData">
          <th cdk-header-cell *cdkHeaderCellDef sort-header>Incoming Data</th>
          <td cdk-cell *cdkCellDef="let row">
            {{ row.incomingData | bytes : true : 2 }}
          </td>
        </ng-container>

        <!-- outgoingData column -->
        <ng-container cdkColumnDef="outgoingData">
          <th cdk-header-cell *cdkHeaderCellDef sort-header>Outgoing Data</th>
          <td cdk-cell *cdkCellDef="let row">
            {{ row.outgoingData | bytes : true : 2 }}
          </td>
        </ng-container>
      </app-datatable>
    </section>
    <section class="vpn-detail__section">
      <h2 class="vpn-detail__section-header">Phase Two Connections</h2>
      <div *ngIf="ipsec">
        <app-datatable
          [data]="ipsec.phaseTwo"
          [displayedColumns]="[
            'status',
            'source',
            'destination',
            'incomingData',
            'outgoingData'
          ]"
          [limit]="10"
          [sort]="['source', 'desc']"
          [status]="tableStatus"
          [showFooter]="ipsec.phaseTwo && ipsec.phaseTwo.length > 1"
        >
          <!-- status column -->
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell *cdkHeaderCellDef sort-header>status</th>
            <td cdk-cell *cdkCellDef="let row">
              <app-icon-tile
                *ngIf="row.status === 'up'"
                [size]="IconSize.XXSMALL"
                [status]="IconStatus.SUCCESS"
                >arrow_upward</app-icon-tile
              >
              <app-icon-tile
                *ngIf="row.status !== 'up'"
                [size]="IconSize.XXSMALL"
                [status]="IconStatus.ERROR"
                >arrow_downward</app-icon-tile
              >
            </td>
          </ng-container>

          <!-- source column -->
          <ng-container cdkColumnDef="source">
            <th cdk-header-cell *cdkHeaderCellDef sort-header>Proxy source</th>
            <td cdk-cell *cdkCellDef="let row">{{ row.source }}</td>
          </ng-container>

          <!-- destination column -->
          <ng-container cdkColumnDef="destination">
            <th cdk-header-cell *cdkHeaderCellDef sort-header>
              Proxy destination
            </th>
            <td cdk-cell *cdkCellDef="let row">{{ row.destination }}</td>
          </ng-container>

          <!-- incomingData column -->
          <ng-container cdkColumnDef="incomingData">
            <th cdk-header-cell *cdkHeaderCellDef sort-header>Incoming data</th>
            <td cdk-cell *cdkCellDef="let row">
              {{ row.incomingData | bytes : true : 2 }}
            </td>
          </ng-container>

          <!-- outgoingData column -->
          <ng-container cdkColumnDef="outgoingData">
            <th cdk-header-cell *cdkHeaderCellDef sort-header>Outgoing data</th>
            <td cdk-cell *cdkCellDef="let row">
              {{ row.outgoingData | bytes : true : 2 }}
            </td>
          </ng-container>
        </app-datatable>
      </div>
    </section>
    <section
      class="vpn-detail__section"
      *appPermitted="['CONNECT__IPSEC_VPN__UPDATE']"
    >
      <h2 class="vpn-detail__section-header">
        VPN Tunnel Reset
        <app-help-icon>
          <p>
            If you are experiencing connectivity issues with this VPN
            connection, you can reset the VPN Tunnel.
          </p>
        </app-help-icon>
      </h2>
      <div class="vpn-detail__reset-status">
        <app-button
          [status]="
            buttonStatus === Status.LOADING
              ? ButtonStatus.LOADING
              : ButtonStatus.ACTIVE
          "
          [role]="ButtonRole.DEFAULT"
          [type]="ButtonType.BUTTON"
          [iconPosition]="ButtonIconPosition.START"
          iconName="refresh"
          (buttonClick)="resetTunnel()"
          >Reset VPN Tunnel</app-button
        >
        <ng-container *ngIf="resetStatus">
          <div class="vpn-detail__reset-pill" [ngSwitch]="resetStatus">
            <app-pill [type]="PillStatus.WARNING" *ngSwitchCase="'starting'">
              {{ resetStatus | titlecase }}...
            </app-pill>
            <app-pill [type]="PillStatus.WARNING" *ngSwitchCase="'started'">
              {{ resetStatus | titlecase }}
            </app-pill>
            <app-pill [type]="PillStatus.ERROR" *ngSwitchCase="'failed'">
              {{ resetStatus | titlecase }}
            </app-pill>
            <app-pill [type]="PillStatus.SUCCESS" *ngSwitchCase="'succeeded'">
              {{ resetStatus | titlecase }}
            </app-pill>
          </div>
        </ng-container>
      </div>
    </section>
  </div>
</app-modal>
