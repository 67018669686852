import { Component, Input } from '@angular/core';
import { AddressDataSource } from '@shared/models/firewall.datatypes';
import { Address, AddressLocation } from '@shared/models/firewall.model';
import { FontWeight } from '@shared/models/font';

@Component({
  selector: 'app-firewall-address',
  templateUrl: './firewall-address.component.html',
  styleUrls: ['./firewall-address.component.scss'],
})
export class FirewallAddressComponent {
  @Input() addressLocation!: AddressLocation;
  @Input() addressData!: Address[];
  @Input() fontWeight: FontWeight = FontWeight.SEMIBOLD;

  AddressLocation = AddressLocation;
  AddressDataSource = AddressDataSource;

  constructor() {}
}
