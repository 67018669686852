<ul class="pagination" *ngIf="lastPage === undefined || lastPage > 1">
  <li
    *ngIf="currentPage > 2"
    class="pagination__item pagination__item--first"
    (click)="goToPage(1)"
    (keyup.enter)="goToPage(1)"
    tabindex="0"
  >
    <app-icon title="First page" [size]="IconSize.SMALL">first_page</app-icon>
  </li>
  <li
    *ngIf="currentPage > 1"
    class="pagination__item pagination__item--back"
    (click)="goToPage(currentPage - 1)"
    (keyup.enter)="goToPage(currentPage - 1)"
    tabindex="0"
  >
    <app-icon title="Previous" [size]="IconSize.SMALL">chevron_left</app-icon>
  </li>
  <li
    *ngIf="currentPage > 5"
    class="pagination__item"
    (click)="goToPage(1)"
    (keyup.enter)="goToPage(1)"
    tabindex="0"
  >
    1
  </li>
  <li
    *ngIf="currentPage > 5"
    class="pagination__item pagination__item--ellipsis"
  >
    &hellip;
  </li>
  <li
    *ngIf="currentPage > 2"
    class="pagination__item"
    (click)="goToPage(currentPage - 2)"
    (keyup.enter)="goToPage(currentPage - 2)"
    tabindex="0"
  >
    {{ currentPage - 2 | number }}
  </li>
  <li
    *ngIf="currentPage > 1"
    class="pagination__item"
    (click)="goToPage(currentPage - 1)"
    (keyup.enter)="goToPage(currentPage - 1)"
    tabindex="0"
  >
    {{ currentPage - 1 | number }}
  </li>
  <li class="pagination__item pagination__item--current">
    {{ currentPage | number }}
  </li>
  <li
    *ngIf="!lastPage || currentPage + 1 <= lastPage"
    class="pagination__item"
    (click)="goToPage(currentPage + 1)"
    (keyup.enter)="goToPage(currentPage + 1)"
    tabindex="0"
  >
    {{ currentPage + 1 | number }}
  </li>
  <li
    *ngIf="!lastPage || currentPage + 2 <= lastPage"
    class="pagination__item"
    (click)="goToPage(currentPage + 2)"
    (keyup.enter)="goToPage(currentPage + 2)"
    tabindex="0"
  >
    {{ currentPage + 2 | number }}
  </li>
  <li
    *ngIf="lastPage && currentPage < lastPage - 4"
    class="pagination__item pagination__item--ellipsis"
  >
    &hellip;
  </li>
  <li
    *ngIf="lastPage && currentPage < lastPage - 4"
    class="pagination__item"
    (click)="goToPage(lastPage)"
    (keyup.enter)="goToPage(lastPage)"
    tabindex="0"
  >
    {{ lastPage | number }}
  </li>
  <li
    *ngIf="!lastPage || currentPage < lastPage"
    class="pagination__item pagination__item--next"
    (click)="goToPage(currentPage + 1)"
    (keyup.enter)="goToPage(currentPage + 1)"
    tabindex="0"
  >
    <app-icon title="Next" [size]="IconSize.SMALL">chevron_right</app-icon>
  </li>
  <li
    *ngIf="lastPage && currentPage < lastPage - 1"
    class="pagination__item pagination__item--last"
    (click)="goToPage(lastPage)"
    (keyup.enter)="goToPage(lastPage)"
    tabindex="0"
  >
    <app-icon title="Last page" [size]="IconSize.SMALL">last_page</app-icon>
  </li>
</ul>
