import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Account } from '@shared/models/account';
import {
  FirewallDeleteRequest,
  FirewallRuleChangeType,
} from '@shared/models/firewall-change-request.model';
import { AccountService } from '@shared/services/account.service';
import { Subscription, catchError, take } from 'rxjs';
import { FieldType } from '@forms/models/form-field.model';
import { IconSize, IconType } from '@shared/models/icon.model';

import { PillSize, PillStatus } from '@shared/models/pill.model';
import { Status } from '@shared/models/status.model';
import {
  ExtendedFirewallRule,
  FirewallRule,
  FirewallType,
} from '@shared/models/firewall.model';
import { FirewallStatus } from '@shared/models/firewall.datatypes';
import { ProtectService } from '@protect/protect.service';
import { SubnetToCIDRPipe } from '@protect/pipes/subnet-to-cidr.pipe';
import { SupportValidationService } from '@support/support-validation.service';
import { ValidationError } from '@shared/models/error';
import { SupportHelperService } from '@support/support-helper.service';

@Component({
  selector: 'app-firewall-rule-delete',
  templateUrl: './firewall-rule-delete.component.html',
  styleUrls: ['./firewall-rule-delete.component.scss'],
  providers: [SubnetToCIDRPipe],
})
export class FirewallRuleDeleteComponent implements OnInit, OnDestroy {
  @Input() type = FirewallType.REGULAR;
  @Input() formDataInput!: FirewallDeleteRequest;
  @Output() FormUpdated = new EventEmitter<FirewallDeleteRequest>();
  @Output() FormValid = new EventEmitter<boolean>();

  public accountSubscription?: Subscription;
  public accountId!: string;

  public tableData: FirewallRule[] = [];
  public tableColumns: string[] = ['selected', 'name', 'source', 'destination'];
  public tableStatus: Status = Status.LOADING;
  public filteredData: FirewallRule[] = [];
  public proxyIps?: string;
  public proxyPort?: string;
  public searchText = '';

  // Styling and data constants
  public FirewallStatus = FirewallStatus;
  public IconSize = IconSize;
  public IconType = IconType;
  public FirewallType = FirewallType;
  public PillStatus = PillStatus;
  public PillSize = PillSize;
  public FieldType = FieldType;

  public formData: FirewallDeleteRequest = {
    type: 'Delete an existing rule',
    name: '',
    comment: '',
    accountId: '',
    policyid: '',
    confirmed: false,
  };
  public errors: ValidationError = {
    confirmed: [],
  };
  public selectedRule: FirewallRule | null = null;

  constructor(
    private accountService: AccountService,
    private protectService: ProtectService,
    private supportValidationService: SupportValidationService,
    private supportHelperService: SupportHelperService,
    private subnetToCIDRPipe: SubnetToCIDRPipe
  ) {}

  ngOnInit(): void {
    this.formData = this.formDataInput;

    this.tableColumns =
      this.type === FirewallType.REGULAR
        ? ['selected', 'name', 'source', 'destination']
        : ['selected', 'name', 'proxy', 'to', 'source', 'destination'];

    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(take(1))
      .subscribe((account?: Account) => {
        if (account?.id) {
          this.accountId = account.id;
          this.formData.accountId = account.id;
          this.getData();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
  }

  /**
   * Retrieves data from protect service and applies to component data
   */
  public getData(): void {
    this.protectService
      .getFirewallRules({ accountId: this.accountId, firewallType: this.type })
      .pipe(
        catchError((error) => {
          this.tableStatus = Status.ERROR;
          throw error;
        })
      )
      .subscribe((rules: FirewallRule[] | undefined) => {
        if (rules) {
          this.tableData = rules;
          this.filteredData = rules;
          this.tableStatus = Status.COMPLETE;
          this.proxyIps = this.getProxyIps();
          this.proxyPort = rules[0].proxyPort;
          if (this.formData?.policyid) {
            this.setSelectedRule();
          }
        }
      });
  }

  /**
   * Formats proxyIps into a single string
   */
  public getProxyIps(): string | undefined {
    return this.filteredData?.[0].proxyIps
      ?.map((ip) => this.subnetToCIDRPipe.transform(ip, ip))
      .join(', ');
  }

  /**
   * Filter the table data
   */
  public filter(): void {
    this.filteredData = this.tableData;

    if (this.searchText) {
      this.filteredData = this.filterByText();
    }
  }

  /**
   * Text search filter - case-insensitive
   * @returns { FirewallRule[] } list of rules matching the text filter
   */
  public filterByText(): FirewallRule[] {
    const filteredFirewallRules = this.tableData.filter(
      (
        firewallRule: FirewallRule | ExtendedFirewallRule
      ): boolean | undefined => {
        return (
          firewallRule.name
            ?.toLowerCase()
            .indexOf(this.searchText.toLowerCase()) > -1 ||
          firewallRule.policyid?.toString().startsWith(this.searchText)
        );
      }
    );
    return filteredFirewallRules;
  }

  /**
   * Preselects row in table if provided
   */
  public setSelectedRule(): void {
    this.searchText = this.formData.name;
    this.filter();

    const ruleFound = this.tableData.find(
      (rule) => rule.policyid === parseInt(this.formData.policyid)
    );

    if (ruleFound) {
      this.selectedRule = ruleFound;
    }
  }

  /**
   * Handle rule selection
   * @param data Rule selected from table
   */
  public onRowClick(data: unknown) {
    const rule = data as FirewallRule | ExtendedFirewallRule;

    if (rule.policyid === this.selectedRule?.policyid) {
      this.selectedRule = null;
      this.resetFormFields();
    } else {
      // Clear formData before repopulating with new rule data
      this.resetFormFields();
      this.selectedRule = rule;
      this.formData.policyid = rule.policyid.toString();
      this.formData.name = rule.name;
    }

    this.handleFormUpdate();
  }

  /**
   * Handles checkbox updates from template
   * @param state Checkbox state
   */
  public handleConfirmation(state: boolean): void {
    this.formData.confirmed = state;

    const validation =
      this.supportValidationService.validateFirewallDeleteRequest(
        this.formData
      );

    if (validation === true) {
      this.errors['confirmed'] = [];
    } else {
      this.errors['confirmed'] = validation['confirmed'];
    }

    this.handleFormUpdate();
  }

  /**
   * Emits relevant events for parent component
   */
  public handleFormUpdate(): void {
    if (this.isFormValid()) {
      this.FormValid.emit(true);
    } else {
      this.FormValid.emit(false);
    }
    this.FormUpdated.emit(this.formData);
  }

  /**
   * Checks validation errors and adds them to the page
   * @return boolean
   */
  public isFormValid(): boolean {
    const validationResponse =
      this.supportValidationService.validateFirewallDeleteRequest(
        this.formData
      );

    return validationResponse === true ? true : false;
  }

  /**
   * Resets for fields back to default
   */
  public resetFormFields(): void {
    this.formData = this.supportHelperService.getBlankChange(
      FirewallRuleChangeType.DELETE,
      this.accountId
    ) as FirewallDeleteRequest;
  }
}
