import { createAction, props } from '@ngrx/store';
import { CookieConsentType } from '@shared/models/cookie-consent-type';
import { ErrorData } from '@shared/models/error';
import { User } from '@shared/models/user';

export const SET_CURRENT_ACCOUNT_ID = '[Status] Set Current Account ID';
export const SET_CURRENT_USER = '[Status] Set Current User';
export const HANDLE_ERROR = '[Status] Handle Error';
export const SET_WAITING = '[Status] Set Waiting';
export const SET_COOKIE_CONSENT = '[Status] Set Cookie';
export const ADD_RESET_TUNNEL_STATUS = '[Status] Add Reset Tunnel Status';
export const UPDATE_RESET_TUNNEL_STATUS = '[Status] Update Reset Tunnel Status';
export const LOGOUT = '[Status] Logout'

export const handleError = createAction(HANDLE_ERROR, props<{ error: ErrorData }>());
export const setWaiting = createAction(SET_WAITING, props<{ waiting: boolean }>());
export const setCookieConsent = createAction(SET_COOKIE_CONSENT, props<{ cookieConsent: CookieConsentType }>());
export const setCurrentAccountID = createAction(SET_CURRENT_ACCOUNT_ID, props<{ id: string }>());
export const setCurrentUser = createAction(SET_CURRENT_USER, props<{ user: User }>());
export const addResetTunnelStatus = createAction(ADD_RESET_TUNNEL_STATUS, props<{ message: string, site: string }>());
export const updateResetTunnelStatus = createAction(UPDATE_RESET_TUNNEL_STATUS, props<{ message: string, site: string, running: boolean }>());
export const logout = createAction(LOGOUT);
