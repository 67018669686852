import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg-2';

import { CdkTableModule } from '@angular/cdk/table';
import { NgxFileDropModule } from 'ngx-file-drop';

/* Components */
import { ButtonComponent } from './components/button/button.component';
import { ButtonGroupComponent } from './components/button-group/button-group.component';
import { IconComponent } from './components/icon/icon.component';
import { CustomIconComponent } from './components/custom-icon/custom-icon.component';
import { WidgetComponent } from './components/widget/widget.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { IconStatComponent } from './components/icon-stat/icon-stat.component';
import { IconTileComponent } from './components/icon-tile/icon-tile.component';
import { CountryFlagComponent } from './components/country-flag/country-flag.component';
import { LogoComponent } from './components/logo/logo.component';
import { IconTitleComponent } from './components/icon-title/icon-title.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { PillComponent } from './components/pill/pill.component';
import { DatatableComponent } from './components/datatable/datatable.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PopoverComponent } from './components/popover/popover.component';
import { PageTitleGroupComponent } from './components/page-title-group/page-title-group.component';
import { BandwidthTooltipComponent } from './components/bandwidth-tooltip/bandwidth-tooltip.component';
import { FilePickerComponent } from './components/file-picker/file-picker.component';
import { CsvExportComponent } from './components/csv-export/csv-export.component';
import { TicketDataComponent } from './components/ticket-data/ticket-data.component';
import { LoadingErrorComponent } from './components/loading-error/loading-error.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ModalComponent } from './components/modal/modal.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { StackedBarDiagramComponent } from './components/stacked-bar-diagram/stacked-bar-diagram.component';
import { OptionsButtonComponent } from './components/options-button/options-button.component';
import { ModalTabsComponent } from './components/modal-tabs/modal-tabs.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { PasswordStrengthItemComponent } from './components/password-strength/password-strength-item/password-strength-item.component';

/* Widgets */
import { HelpIconComponent } from './widgets/help-icon/help-icon.component';
import { NetworkOverviewComponent } from './widgets/network-overview/network-overview.component';
import { NetworkPanelComponent } from './widgets/network-panel/network-panel.component';
import { SupportTicketsComponent } from './widgets/support-tickets/support-tickets.component';

/* Pipes */
import { BpsPipe } from './pipes/bps.pipe';
import { BytesPipe } from './pipes/bytes.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { FilenamePipe } from './pipes/filename.pipe';
import { TicketPriorityPipe } from './pipes/ticket-priority.pipe';

/* Services */
import { StylesService } from './services/styles.service';

/* Other */
import { PopoverDirective } from './directives/popover.directive';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { PermittedDirective } from './directives/permitted.directive';
import { RouterLinkDirective } from './directives/router-link.directive';
import { RouterModule } from '@angular/router';
import { JoinPipe } from './pipes/join.pipe';

@NgModule({
  declarations: [
    ButtonComponent,
    ButtonGroupComponent,
    IconComponent,
    CustomIconComponent,
    WidgetComponent,
    PageTitleComponent,
    IconStatComponent,
    IconTileComponent,
    CountryFlagComponent,
    LogoComponent,
    IconTitleComponent,
    BpsPipe,
    BytesPipe,
    PopoverComponent,
    ContactCardComponent,
    PillComponent,
    DatatableComponent,
    PaginationComponent,
    PopoverDirective,
    HelpIconComponent,
    ModalComponent,
    SupportTicketsComponent,
    TicketDataComponent,
    LoadingErrorComponent,
    LoadingComponent,
    FilenamePipe,
    TicketPriorityPipe,
    FilePickerComponent,
    CsvExportComponent,
    TimeAgoPipe,
    PageTitleGroupComponent,
    BandwidthTooltipComponent,
    NetworkOverviewComponent,
    NetworkPanelComponent,
    DebounceClickDirective,
    PermittedDirective,
    StackedBarDiagramComponent,
    OptionsButtonComponent,
    InfoBoxComponent,
    ModalTabsComponent,
    JoinPipe,
    PasswordStrengthComponent,
    PasswordStrengthItemComponent,
    LoadingSpinnerComponent,
    RouterLinkDirective,
  ],
  exports: [
    ButtonComponent,
    ButtonGroupComponent,
    IconComponent,
    CustomIconComponent,
    WidgetComponent,
    PageTitleComponent,
    IconStatComponent,
    IconTileComponent,
    CountryFlagComponent,
    LogoComponent,
    IconTitleComponent,
    BpsPipe,
    BytesPipe,
    PopoverComponent,
    ContactCardComponent,
    PillComponent,
    DatatableComponent,
    CdkTableModule,
    PopoverDirective,
    HelpIconComponent,
    ModalComponent,
    SupportTicketsComponent,
    TicketDataComponent,
    LoadingErrorComponent,
    LoadingComponent,
    FilenamePipe,
    TicketPriorityPipe,
    FilePickerComponent,
    CsvExportComponent,
    TimeAgoPipe,
    PageTitleGroupComponent,
    BandwidthTooltipComponent,
    NetworkOverviewComponent,
    NetworkPanelComponent,
    DebounceClickDirective,
    PermittedDirective,
    StackedBarDiagramComponent,
    OptionsButtonComponent,
    InfoBoxComponent,
    ModalTabsComponent,
    JoinPipe,
    PasswordStrengthComponent,
    PasswordStrengthItemComponent,
    LoadingSpinnerComponent,
    RouterLinkDirective,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    CdkTableModule,
    NgxFileDropModule,
    RouterModule,
  ],
  providers: [
    TicketPriorityPipe,
    BpsPipe,
    BytesPipe,
    FilenamePipe,
    StylesService,
    JoinPipe,
  ],
})
export class SharedModule {}
