import { Injectable } from '@angular/core';
import { Account } from '@shared/models/account';
import { NavRoute, primaryNavData } from '@shared/models/nav-data';
import { User } from '@shared/models/user';
import { AccountService } from '@shared/services/account.service';
import { PermissionsService } from '@shared/services/permissions.service';
import { UserService } from '@shared/services/user.service';
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  skipWhile,
  take,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public currentAccount?: Account;
  public currentUser!: User | null;

  constructor(
    private accountService: AccountService,
    private permissionsService: PermissionsService,
    private userService: UserService
  ) {
    const userSub: Observable<User | null> = this.userService
      .getCurrentUser()
      .pipe(
        skipWhile((v) => !v), // ignore the null that comes at logout
        take(1)
      );
    const accountSub: Observable<Account | undefined> = this.accountService
      .fetchCurrentAccount()
      .pipe(
        distinctUntilChanged() // only take a values that's new (stops redrawing on double null for admins)
      );

    combineLatest([userSub, accountSub]).subscribe((result: any) => {
      const [user, account] = result;
      if (user) {
        this.currentUser = user;
      }
      if (account) {
        this.currentAccount = account;
      }
    });
  }

  /* Direct user to the first available child page */
  public getMenuLink(navRoute: NavRoute): string {
    let link = '';
    const firstEnabledChild = navRoute.children?.find((childItem) => {
      if (childItem.data?.id) {
        return this.permissionsService.isFeatureAllowed(
          childItem.data.id,
          this.currentAccount,
          this.currentUser
        );
      }

      return false;
    });

    if (firstEnabledChild) {
      link = '/' + navRoute.path + '/' + firstEnabledChild?.path;
    } else {
      link = '/' + navRoute.path;
    }

    return link;
  }

  public getMenuLinkByPath(navRoutePath: string): string {
    const navRoute = primaryNavData.find(
      (navItem) => navItem.path === navRoutePath
    );
    if (!navRoute) {
      return navRoutePath;
    }

    return this.getMenuLink(navRoute);
  }
}
