import { Component, Input } from '@angular/core';
import { IconSize } from '../../models/icon.model';

@Component({
  selector: 'app-icon-stat',
  templateUrl: './icon-stat.component.html',
  styleUrls: ['./icon-stat.component.scss'],
})
export class IconStatComponent {
  @Input() size!: IconSize;
}
