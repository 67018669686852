import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';

import { TicketDataLayout } from '@shared/components/ticket-data/ticket-data-layout.datatype';
import { StackLocation } from '@shared/models/account';
import { ButtonDisplay, ButtonIconPosition } from '@shared/models/button.model';
import { Feature } from '@shared/models/features';
import { Status } from '@shared/models/status.model';
import { AccountService } from '@shared/services/account.service';
import { BandwidthRequest } from '@shared/models/api-response/api-response-analytics.model';
import { DataService } from '@shared/services/data.service';

import { Ticket, TicketType } from '@support/models/ticket';
import { BandwidthDatapoint } from '@charts/models/bandwidth.model';
import { SupportService } from '@support/support.service';

import { AccountStoreItem } from '@store/account-store-item.model';
import { ChartSize } from '@charts/models/chart.model';
import { filter, take } from 'rxjs/operators';
import { ModalService } from '@shared/services/modal.service';
import { TicketStatus } from '@support/models/ticket-status.constant';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account-summary',
  templateUrl: './account-summary.component.html',
  styleUrls: ['./account-summary.component.scss'],
})
export class AccountSummaryComponent implements OnChanges, OnDestroy {
  @Input() account!: AccountStoreItem;
  @Input() load: boolean = false;
  @Output() dataLoaded = new EventEmitter<any>();

  public bandwidthData: BandwidthDatapoint[] = [];
  public bandwidthStatus: Status = Status.LOADING;

  public ButtonDisplay = ButtonDisplay;
  public ButtonIconPosition = ButtonIconPosition;
  public TicketDataLayout = TicketDataLayout;
  public ChartSize = ChartSize;

  public Status = Status;
  public supportStatus: Status = Status.LOADING;

  public openTickets?: number;
  public closedTickets?: number;

  private ticketSubscription?: Subscription;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private supportService: SupportService,
    private dataService: DataService,
    private modalService: ModalService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // Only load data if `load` attribute has changed from false to true
    if (changes.load?.currentValue && !changes.load?.previousValue) {
      this.getData();
    }
  }

  public raiseIncident(): void {
    this.modalService.raiseIncident(this.account.id);
  }

  public viewTickets(): void {
    this.accountService.updateLoadedAccountSubject(this.account.id);
    this.router.navigateByUrl('/support/incidents');
  }

  private getData(): void {
    this.loadSupportTicketData();
    this.loadBandwidthData();
  }

  private loadSupportTicketData(): void {
    this.supportStatus = Status.LOADING;
    if (!this.account.supportId || this.account.supportId === '0') {
      this.supportStatus = Status.NO_DATA;
      return;
    }

    // "incidents" hardcoded because this component only displays incidents ticket type
    this.ticketSubscription = this.supportService
      .getTickets(TicketType.INCIDENTS, this.account.id)
      .subscribe({
        next: (incidents: Ticket[]) => {
          const openIncidents = incidents.filter(
            (ticket: Ticket) => ticket.status === TicketStatus.OPEN
          );

          const closedIncidents = incidents.filter(
            (ticket: Ticket) => ticket.status === TicketStatus.CLOSED
          );

          this.openTickets = openIncidents.length;
          this.closedTickets = closedIncidents.length;
          this.supportStatus = Status.COMPLETE;
        },
        error: () => {
          this.supportStatus = Status.ERROR;
        },
      });
  }

  private loadBandwidthData(): void {
    if (this.account.stackLocation === StackLocation.VPN_ONLY) {
      this.bandwidthStatus = Status.NOT_AVAILABLE;
      this.dataLoaded.emit();
    } else {
      this.bandwidthStatus = Status.LOADING;
      const request: BandwidthRequest = {
        accountId: this.account.id,
        interval: '30days',
        groupBy: '2h',
      };
      this.dataService
        .getBandwidthData(request)
        .pipe(
          filter((val) => val !== null),
          take(1)
        )
        .subscribe(
          (data) => {
            if (data) {
              this.bandwidthData = data;
              this.dataLoaded.emit();
              this.bandwidthStatus = Status.COMPLETE;
            }
          },
          () => {
            this.dataLoaded.emit();
            this.bandwidthStatus = Status.ERROR;
          }
        );
    }
  }

  public viewDetails() {
    this.accountService.updateLoadedAccountSubject(this.account.id);
    if (this.account.stackLocation === StackLocation.VPN_ONLY) {
      this.router.navigateByUrl('/connect/remote-access');
    } else {
      this.router.navigateByUrl(Feature.DASHBOARD);
    }
  }

  ngOnDestroy(): void {
    this.ticketSubscription?.unsubscribe();
  }
}
