import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';

import { NewsStory } from '@shared/models/news-story.model';
import * as NewsActions from './news.actions';
import * as StatusActions from '../status/status.actions';

export const initialState: NewsState = {
  loaded: false,
  data: [],
};

export interface NewsState {
  loaded: boolean;
  data: NewsStory[] | null;
}

export function reducer(state: NewsState | undefined, action: Action) {
  return newsReducer(state, action);
}

const setNews = (
  state: NewsState,
  action: { type: string; news: NewsStory[] }
) => {
  const newState = { loaded: true, data: action.news };
  return newState;
};

const newsReducer = createReducer(
  initialState,
  on(NewsActions.setNews, setNews)
);

const getNewsFeatureState = createFeatureSelector<NewsState>('news');

export const selectNews = createSelector(
  getNewsFeatureState,
  (state: NewsState) => state.data
);

export const isLoaded = createSelector(
  getNewsFeatureState,
  (state: NewsState) => state.loaded
);
