<header class="header">
  <div class="header__wrapper" [ngClass]="theme">
    <app-logo [size]="LogoSize.COMPACT" class="header__logo"></app-logo>
    <div class="header__menu">
      <app-header-details class="header__details"></app-header-details>
      <nav
        class="header__mobile-nav"
        (click)="toggleMenu()"
        data-testid="mobile-nav"
        aria-label="Mobile menu"
      >
        <app-icon title="Menu" [size]="IconSize.MEDIUM">menu</app-icon>
      </nav>
    </div>
  </div>
  <app-secondary-navigation></app-secondary-navigation>
  <app-primary-navigation
    [@openClose]="navOpen ? 'open' : 'closed'"
    class="header__mobile-menu"
    [isMobile]="true"
    [open]="navOpen"
    data-testid="primary-navigation"
  ></app-primary-navigation>
</header>
