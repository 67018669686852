export enum Feature {
  SUMMARY = 'summary',
  DASHBOARD = 'dashboard',
  CONNECT = 'connect',
  PROTECT = 'protect',
  INSPECT = 'inspect',
  CONNECT_DASHBOARD = 'connect__dashboard',
  PROTECT_DASHBOARD = 'protect__dashboard',
  INSPECT_DASHBOARD = 'inspect__dashboard',
  DEV = 'dev',
  ANALYTICS = 'analytics',
  FIREWALL = 'firewall',
  FIREWALL_PROXY = 'firewall__proxy',
  SUPPORT = 'support',
  INCIDENTS = 'support__incidents',
  SERVICE_REQUESTS = 'support__servicerequests',
  REPORTS = 'support__reports',
  SSLVPN = 'ssl-vpn',
  IPSECVPN = 'ipsec-vpn',
  ROUTING_TABLES = 'routing-tables',
  PARTNERS = 'partner',
  ERROR_404 = '404',
  USER_MANAGEMENT = 'user_management',
  SETTINGS = 'settings',
  TRAFFIC_LOGS = 'traffic-logs',
  WAF_LOGS = 'waf-logs',
}

// Permissions needed to show features in navigation
// Arrays are checked using an 'OR' operator
export const FeatureUserPermissionMap: { [key in string]: number[] } = {
  [Feature.ANALYTICS]: [3002, 3003, 3004, 3005, 3006],
  [Feature.INSPECT_DASHBOARD]: [3001],
  [Feature.SUPPORT]: [5002, 5006],
  [Feature.INCIDENTS]: [5002],
  [Feature.SERVICE_REQUESTS]: [5006],
  [Feature.REPORTS]: [5010],
  [Feature.CONNECT_DASHBOARD]: [1001],
  [Feature.PROTECT_DASHBOARD]: [2001],
  [Feature.FIREWALL]: [2004],
  [Feature.SSLVPN]: [1012],
  [Feature.IPSECVPN]: [1016],
  [Feature.ROUTING_TABLES]: [1007],
  [Feature.DASHBOARD]: [1002, 2002, 3002, 3007],
  [Feature.CONNECT]: [
    1001, 1002, 1003, 1007, 1011, 1012, 1013, 1014, 1015, 1016, 1017, 1018,
  ],
  [Feature.PROTECT]: [2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008],
  [Feature.INSPECT]: [3001, 3002, 3003, 3004, 3005, 3006, 3007, 3008],
  [Feature.DEV]: [26],
  [Feature.USER_MANAGEMENT]: [8002],
  [Feature.TRAFFIC_LOGS]: [3008],
  [Feature.FIREWALL_PROXY]: [2008],
};

export const FeatureAccountPermissionMap: { [key in string]: string[] } = {
  [Feature.ANALYTICS]: [
    'ACC__TOTAL_SESSIONS',
    'ACC__TOP_COUNTRIES',
    'ACC__TOP_TEN_SOURCES',
    'ACC__TOP_TEN_DESTINATIONS',
    'ACC__TOP_TEN_APPLICATIONS',
  ],
  [Feature.INSPECT_DASHBOARD]: [
    'ACC__TOP_SOURCES',
    'ACC__TOTAL_SESSIONS',
    'ACC__TOP_APPLICATIONS',
    'ACC__BANDWIDTH_OVERVIEW',
  ],
  [Feature.INCIDENTS]: [
    'ACC__SUPPORT_INCIDENTS_SUMMARY',
    'ACC__CONTACT_SUPPORT',
    'ACC__SUPPORT_INCIDENTS_TABLE',
  ],
  [Feature.SERVICE_REQUESTS]: [
    'ACC__SUPPORT_SR_SUMMARY',
    'ACC__CONTACT_SUPPORT',
    'ACC__SUPPORT_SR_TABLE',
  ],
  [Feature.CONNECT_DASHBOARD]: [
    'ACC__NETWORK_OVERVIEW',
    'ACC__ROUTING_TABLES',
    'ACC__TOTAL_ROUTES',
    'ACC__INTERFACES',
  ],
  [Feature.PROTECT_DASHBOARD]: ['ACC__THREAT_MAP', 'ACC__THREAT_SUMMARY'],
  [Feature.FIREWALL]: ['ACC__FIREWALL_RULES'],
  [Feature.SSLVPN]: ['ACC__REMOTE_ACCESS', 'ACC__VPN_USER_SUMMARY'],
  [Feature.IPSECVPN]: ['ACC__VPN_SITE_CONNECTIVITY'],
  [Feature.ROUTING_TABLES]: ['ACC__ROUTING_TABLES'],
  [Feature.DASHBOARD]: [
    'ACC__CONNECT_SUMMARY',
    'ACC__PROTECT_SUMMARY',
    'ACC__INSPECT_SUMMARY',
    'ACC__SUPPORT_INCIDENTS_SUMMARY',
    'ACC__ACCOUNT_OVERVIEW',
  ],
  [Feature.SUMMARY]: ['ACC__SUMMARY'],
  [Feature.CONNECT]: [
    'ACC__NETWORK_OVERVIEW',
    'ACC__ROUTING_TABLES',
    'ACC__TOTAL_ROUTES',
    'ACC__INTERFACES',
    'ACC__VPN_SITE_CONNECTIVITY',
    'ACC__REMOTE_ACCESS',
    'ACC__VPN_USER_SUMMARY',
  ],
  [Feature.PROTECT]: [
    'ACC__THREAT_MAP',
    'ACC__THREAT_SUMMARY',
    'ACC__FIREWALL_RULES',
    'ACC__PROXY_RULES',
  ],
  [Feature.INSPECT]: [
    'ACC__TOP_SOURCES',
    'ACC__TOTAL_SESSIONS',
    'ACC__TOP_APPLICATIONS',
    'ACC__BANDWIDTH_OVERVIEW',
    'ACC__TOTAL_SESSIONS',
    'ACC__TOP_COUNTRIES',
    'ACC__TOP_TEN_SOURCES',
    'ACC__TOP_TEN_DESTINATIONS',
    'ACC__TOP_TEN_APPLICATIONS',
    'ACC__TRAFFIC_LOGS_TABLE',
    'ACC__TRAFFIC_LOGS_CSV',
    'ACC__TRAFFIC_LOGS_EXPORTS',
  ],
  [Feature.USER_MANAGEMENT]: ['ACC__USER_MANAGEMENT'],
  [Feature.TRAFFIC_LOGS]: [
    'ACC__TRAFFIC_LOGS_TABLE',
    'ACC__TRAFFIC_LOGS_CSV',
    'ACC__TRAFFIC_LOGS_EXPORTS',
  ],
  [Feature.WAF_LOGS]: ['ACC__WAF_LOGS_TABLE'],
  [Feature.FIREWALL_PROXY]: ['ACC__PROXY_RULES'],
  [Feature.REPORTS]: ['ACC__REPORTING'],
};
