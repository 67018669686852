import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, repeat, switchMap, take } from 'rxjs/operators';
import { ApiService } from '@shared/services/api.service';
import * as PermissionActions from './permissions.actions';

@Injectable()
export class PermissionEffects {
  fetchPermissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PermissionActions.FETCH_PERMISSIONS),
      switchMap(() => this.apiService.get(`/settings/permissions`)),
      map((response) =>
        PermissionActions.setPermissions({ permissions: response.data })
      ),
      take(1),
      repeat()
    );
  });

  constructor(private apiService: ApiService, private actions$: Actions) {}
}
