import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AlertService {
  constructor(private toastr: ToastrService) {}

  /**
   * Return the current application version
   *
   * @returns { string } version string.
   */
  public throwConfirmation(
    confirmationQuestion: string,
    confirmationAnswer: string,
    confirmationTitle: string,
    callback: Function
  ) {
    const toastr = this.toastr.warning(
      `${confirmationQuestion}
    <br><br>
    <a href='#' class='toast-message__button confirm-action'>${confirmationAnswer}</a>`,
      confirmationTitle,
      {
        closeButton: true,
        disableTimeOut: true,
        enableHtml: true,
      }
    );
    toastr.onShown.subscribe(() => {
      document
        .getElementsByClassName('confirm-action')[0]
        .addEventListener('click', (e) => {
          e.preventDefault();
          callback();
        });
    });
  }
}
