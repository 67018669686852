import { Component, Input } from '@angular/core';
import { IconSize } from '@shared/models/icon.model';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  @Input() size: IconSize = IconSize.MEDIUM;

  constructor() {}
}
