import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import { CognitoResponse } from '../models/cognito-response';
import { CognitoErrorResponse } from '../models/cognito-errors';
import { CognitoService } from './cognito.service';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

@Injectable()
export class CognitoUpdateService {
  /**
   * Update Service
   *
   * @param { CognitoService } cognitoService
   */
  constructor(private cognitoService: CognitoService) {}

  /**
   * Refresh the user's profile (after updating attributes on server-side.
   * @return { Observable<CognitoResponse>} response.
   */
  public refresh(): Observable<CognitoResponse> {
    return new Observable((observer: Observer<CognitoResponse>) => {
      Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
        .then((user: CognitoUser) => {
          this.cognitoService.setSignInUser(user);
          this.cognitoService.success(observer);
        })
        .catch((reason: CognitoErrorResponse) => {
          this.cognitoService.handleAuthError(
            reason,
            observer,
            'generic_profile_refresh_failed'
          );
        });
    });
  }
}
