<div class="files__list" *ngIf="files && files.length > 0">
  <ng-container *ngFor="let file of files">
    <a href="{{ file }}" class="attachment" target="_blank" rel="noopener">
      <app-icon title="Attachment" class="attachment__icon">{{
        getFileIcon(file)
      }}</app-icon>
      {{ file | filename: true }}
    </a>
  </ng-container>
</div>
