import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

/* Services */
import { ModalService } from '@shared/services/modal.service';
import { SupportService } from '../../../support/support.service';

/* Models */
import { Ticket, TicketType } from '@support/models/ticket';
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import { TicketStatus } from '@support/models/ticket-status.constant';
import { UserService } from '@shared/services/user.service';
import { WidgetType } from '@shared/models/widget.model';
import { CommentType, TicketComment } from '@support/models/comment';
import { ButtonRole, ButtonStatus } from '@shared/models/button.model';
import { IconType } from '@shared/models/icon.model';
import { environment } from '@environments/default/environment';

@Component({
  selector: 'app-view-ticket',
  templateUrl: './view-ticket.component.html',
  styleUrls: ['./view-ticket.component.scss'],
})
export class ViewTicketComponent implements OnInit, OnDestroy {
  public ticket: Ticket = {
    opened_at: undefined,
    resolved_at: undefined,
    description: '',
    id: undefined,
    priority: undefined,
    number: undefined,
    title: '',
    comments: undefined,
    file: [],
  };
  public htmlDescription: string = '';
  private routeSubscription!: Subscription;
  public commentsLoaded: boolean = false;
  public addComment: boolean = false;
  public modalStatus: ModalStatus = ModalStatus.OPEN;
  public initialRequest!: TicketComment;
  public additionalComments: TicketComment[] = [];
  public olderCommentsLoaded: boolean = false;

  public ModalSize = ModalSize;
  public ModalStatus = ModalStatus;
  public WidgetType = WidgetType;
  public CommentType = CommentType;
  public ButtonStatus = ButtonStatus;
  public ButtonRole = ButtonRole;
  public IconType = IconType;

  private userSubscription!: Subscription;
  private email?: string;
  public type?: TicketType;

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private toastr: ToastrService,
    private supportService: SupportService,
    private userService: UserService
  ) {}

  /**
   * Init
   */
  ngOnInit() {
    // Get ticket details from context if possible
    const ticketContext = this.modalService.getContext();

    if (ticketContext) {
      this.formatTicketDescription(ticketContext);
    }

    // Go and get further ticket details if comments = null
    // (comments aren't returned from getTickets in FreshService)
    // or if there's no modal context
    if (!ticketContext || this.ticket.comments === null) {
      // Get route details to find ticket id
      this.routeSubscription = this.route.params.subscribe((params) => {
        if (!params.ticket || params.ticket === false) {
          this.modalStatus = ModalStatus.CLOSED;
          return;
        }
        this.supportService.getTicket(params.ticket).subscribe({
          next: (ticket: Ticket) => {
            this.ticket = ticket;
            this.commentsLoaded = true;
            this.initialRequest = {
              comment: ticket.description,
              author: {
                email: ticket.requester?.email ?? null,
                name: ticket.requester?.name ?? null,
                supportId: ticket.accountId ?? null,
              },
              date: ticket.opened_at ?? null,
              file: ticket.file,
            };
            this.type = params.type;
          },
          error: (err) => {
            this.toastr.warning(err.message, 'Error: ' + err, {
              progressBar: true,
            });
          },
        });
      });
    } else {
      // Comments already loaded
      this.commentsLoaded = true;
    }
    this.userSubscription = this.userService
      .getCurrentUser()
      .subscribe((user) => {
        if (user) {
          this.email = user.email;
        }
      });
  }

  /**
   * Destroy
   */
  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    this.userSubscription?.unsubscribe();
  }

  /**
   * Turn on/off the add comment box
   */
  toggleAddComment(): void {
    this.addComment = !this.addComment;
  }

  formatTicketDescription(ticketContext: Ticket): void {
    this.ticket = ticketContext;

    if (
      ticketContext.description &&
      // formatting not needed for tickets originating from FreshService
      // that already have HTML-formatted descriptions and won't return JS linebreaks
      !ticketContext.description.startsWith('<div')
    ) {
      // format JS line-breaks to HTML br tags
      const jsDescription = JSON.stringify(ticketContext.description, null, 1);

      const lineBreakRegex = /\\n/g;
      this.htmlDescription = jsDescription
        .replace(lineBreakRegex, '<br>')
        .trim();

      // check start/end quotation marks
      if (
        this.htmlDescription[0] === '"' &&
        this.htmlDescription[this.htmlDescription.length - 1] === '"'
      ) {
        // remove quotation marks
        this.htmlDescription = this.htmlDescription
          .split('')
          .slice(1, this.htmlDescription.length - 1)
          .join('');
      }
      this.ticket.description = this.htmlDescription;
    }
  }

  changeTicketStatus(newComment: { data: TicketComment }) {
    if (
      !this.email?.includes('cloudgateway.co.uk') ||
      !environment.production
    ) {
      this.ticket = { ...this.ticket, ...{ status: TicketStatus.OPEN } };
    }
    this.ticket.comments?.unshift(newComment.data);
    this.additionalComments = this.ticket.comments!.slice(1);
  }

  loadAdditionalComments() {
    this.additionalComments = this.ticket.comments!.slice(1);
    this.olderCommentsLoaded = true;
  }
}
