import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

/* Services */
import { SupportService } from '../../support.service';
import { ErrorService } from '@shared/services/error.service';
import { ModalService } from '@shared/services/modal.service';
import { UserService } from '@shared/services/user.service';

/* Models */
import { Account } from '@shared/models/account';
import { NewTicket, Ticket, TicketType } from '../../models/ticket';
import { TicketPriorities } from '../../models/ticket-priorities';
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import { FieldType } from '@forms/models/form-field.model';
import { FileOptions, FilePreviewModel } from '@shared/models/file-options';
import {
  ButtonRole,
  ButtonStatus,
  ButtonType,
} from '@shared/models/button.model';
import { SelectOptions } from '@forms/models/form-select.model';
import { IconStatus, IconType } from '@shared/models/icon.model';
/* Store */
import { Store } from '@ngrx/store';
import * as fromStatus from '@store/status/status.reducer';
import * as StatusActions from '@store/status/status.actions';
import { PillStatus } from '@shared/models/pill.model';
import { SupportValidationService } from '@support/support-validation.service';
import { AlertService } from '@shared/services/alert.service';

@Component({
  selector: 'app-raise-service-request',
  templateUrl: './raise-service-request.component.html',
  styleUrls: ['./raise-service-request.component.scss'],
})
export class RaiseServiceRequestComponent implements OnInit, OnDestroy {
  public errors: { [key: string]: string[] | null } = {
    description: null,
    title: null,
    cc_emails: null,
    priority: null,
    accountId: null,
  };
  public ticket: NewTicket = {
    description: '',
    title: '',
    cc_emails: [],
    accountId: '',
    file: [],
  };

  public fileOptions: FileOptions = {
    directory: 'support',
    fileTypes: [],
    fileSize: 300,
    fileCount: 20,
  };

  public prioritySelectOptions: SelectOptions[] = [
    {
      value: null,
      label: '-- Please select --',
    },
    {
      value: 1,
      label: TicketPriorities[1],
    },
    {
      value: 2,
      label: TicketPriorities[2],
    },
    {
      value: 3,
      label: TicketPriorities[3],
    },
    {
      value: 4,
      label: TicketPriorities[4],
    },
  ];
  public accountSelectOptions: SelectOptions[] = [
    {
      value: null,
      label: '-- Please select --',
    },
  ];
  public submitButtonStatus: ButtonStatus = ButtonStatus.ACTIVE;
  public accounts: Account[] = [];
  public priorities = TicketPriorities;
  public uploadError: string = '';
  public modalStatus: ModalStatus = ModalStatus.OPEN;
  public modalCloseMessage: string =
    'Closing this modal will discard all changes. Are you sure you want to close the modal?';

  public accountSubscription!: Subscription;
  public userSubscription?: Subscription;
  public waitingSubscription?: Subscription;

  public IconStatus = IconStatus;
  public IconType = IconType;
  public ModalSize = ModalSize;
  public ModalStatus = ModalStatus;
  public FieldType = FieldType;
  public ButtonType = ButtonType;
  public ButtonRole = ButtonRole;
  public PillStatus = PillStatus;

  public isSubmitted = false;
  public ticketId: string | undefined = '';
  public ccEmails: string | null = null;
  public userName: string = '';
  public ticketResponse?: Ticket;
  public formattedTicket?: Ticket;
  private waiting$ = this.store.select(fromStatus.selectWaiting);

  constructor(
    private supportService: SupportService,
    private errorService: ErrorService,
    private store: Store,
    private userService: UserService,
    private modalService: ModalService,
    private supportValidationService: SupportValidationService,
    public alertService: AlertService
  ) {}

  ngOnInit() {
    this.userName = 'xyz098';
    this.submitButtonStatus = ButtonStatus.ACTIVE;
    this.ticket.accountId = 'abc123';

    this.accountSubscription = this.store
      .select(fromStatus.selectCurrentAccountID)
      // eslint-disable-next-line ngrx/no-store-subscription
      .subscribe((accountId) => {
        if (accountId) {
          this.ticket.accountId = accountId;
        }
      });

    this.userSubscription = this.userService
      .getCurrentUser()
      .subscribe((user) => {
        this.userName = this.userService.getUserName(user);
      });

    this.waitingSubscription = this.waiting$.subscribe((val) => {
      this.submitButtonStatus = !val
        ? ButtonStatus.ACTIVE
        : ButtonStatus.LOADING;
    });
  }

  ngOnDestroy(): void {
    this.accountSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
    this.waitingSubscription?.unsubscribe();
  }

  public showAccountDropdown(): boolean {
    return this.accounts.length > 1;
  }

  public onUploadSuccess(file: FilePreviewModel) {
    // Get filename from API response
    if (file.uploadResponse.body?.data) {
      const uploadedFileName = `${file.uploadResponse.body.data.directory}/${file.uploadResponse.body.data.fileName}`;
      this.ticket.file.push(uploadedFileName);
    }
  }

  public onRemoveSuccess(file: FilePreviewModel) {
    const removedFileName = `${file.uploadResponse.body.data.directory}/${file.uploadResponse.body.data.fileName}`;
    const removedFileIndex = this.ticket.file.indexOf(removedFileName);
    this.ticket.file.splice(removedFileIndex, 1);
  }

  public cancel() {
    this.alertService.throwConfirmation(
      this.modalCloseMessage,
      'Close',
      'Warning',
      () => (this.modalStatus = ModalStatus.CLOSED)
    );
  }

  // Raise a new ticket
  public raiseTicket() {
    // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(StatusActions.setWaiting({ waiting: true }));
    // Reset errors
    Object.keys(this.errors).forEach((prop) => {
      this.errors[prop] = null;
    });
    this.ticket.cc_emails =
      this.ccEmails?.replace(/\s/g, '').split(',') ?? undefined;
    this.supportService.raiseServiceRequest(this.ticket as Ticket).subscribe({
      next: (response) => {
        // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(StatusActions.setWaiting({ waiting: false }));

        this.isSubmitted = true;
        this.ticketResponse = response;
        this.ticketId = response.id;
      },
      error: (err) => {
        // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
        this.errorService.handleSaveErrors(err);
        // Show validation errors
        this.errors = err;
      },
    });
  }

  public viewTicketDetails() {
    if (this.ticketResponse) {
      this.formattedTicket = this.supportService.formatServiceRequestTicket(
        this.ticketResponse,
        this.userName
      );
    }

    this.modalService.viewTicket(this.ticketResponse as Ticket, TicketType.SR);
  }

  /**
   * Handle the blur event from the form field
   * @param prop Name of the property to validate
   */
  public handleBlur(prop: string) {
    this.ticket.cc_emails =
      this.ccEmails?.replace(/\s/g, '').split(',') ?? undefined;
    const validation = this.supportValidationService.validateSR(
      this.ticket as Ticket
    );
    if (validation === true) {
      this.errors[prop] = [];
    } else {
      this.errors[prop] = validation[prop];
    }
  }

  /**
   * Handle the focus event from the form field
   * @param prop Name of the property to clear
   */
  public handleFocus(prop: string): void {
    // Clear the error message
    this.errors[prop] = [];
  }
}
