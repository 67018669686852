import { Injectable } from '@angular/core';
import countries from 'world-countries';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor() {}

  public getCountryCode(
    countryName: string,
    type: string = 'alpha2'
  ): string | undefined {
    const country = this.getCountry(countryName);
    let code;
    switch (type) {
      case 'alpha2':
        code = country?.cca2;
        break;
      case 'alpha3':
        code = country?.cca3;
        break;
    }

    if (code) {
      return code.toLowerCase();
    }

    return;
  }

  private getCountry(countryName: string) {
    return countries.find(
      (country) =>
        country.name.official === countryName ||
        country.name.common === countryName ||
        country.altSpellings.includes(countryName)
    );
  }

  public getCountryNameFromCode(countryCode: string): string {
    const country = countries.find(
      (country) => country.cca2 === countryCode.toUpperCase()
    );
    if (country) {
      return country.name.common;
    }

    return '';
  }
}
