<div class="form-input--text-wrapper">
  <app-form-field
    [type]="FieldType.TEXT"
    [name]="name"
    [placeholder]="placeholder"
    [hideLabel]="true"
    [(inputValue)]="inputValue"
    (inputValueChange)="inputValueChange.emit(inputValue)"
  ></app-form-field>
  <app-icon title="Search" [size]="IconSize.MEDIUM" class="form-input__icon"
    >search</app-icon
  >
</div>
