import { Component, Input } from '@angular/core';
import { CustomIcons, IconSize, IconStatus } from '../../models/icon.model';

@Component({
  selector: 'app-icon-tile',
  templateUrl: './icon-tile.component.html',
  styleUrls: ['./icon-tile.component.scss'],
})
export class IconTileComponent {
  @Input() status: IconStatus = IconStatus.DEFAULT;
  @Input() size: IconSize = IconSize.MEDIUM;
  @Input() iconStatus!: IconStatus;
  @Input() customIcon: CustomIcons | undefined;
  @Input() countryFlag: string | undefined;
  @Input() title!: string;

  // Get an array of classes for the tile
  public get classList(): string[] {
    const classArray: string[] = [];
    if (this.status) {
      classArray.push(`icon-tile--${this.status}`);
    }
    if (this.size) {
      classArray.push(`icon-tile--${this.size}`);
    }
    return classArray;
  }

  // Get an array of classnames for the icon itself
  public get iconClassList(): string[] {
    const classArray: string[] = [];
    if (this.iconStatus) {
      // Icon status different to tile
      classArray.push(`icon--${this.iconStatus}`);
    } else if (this.status) {
      // Icon status same as tile
      classArray.push(`icon--${this.status}`);
    }
    if (this.size) {
      classArray.push(`icon--${this.size}`);
    }
    return classArray;
  }
}
