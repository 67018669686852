import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { IconSize } from '@shared/models/icon.model';
import { flyInOut } from '@shared/animations/animations';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-custom-toast]',
  templateUrl: './custom-toast.component.html',
  animations: [flyInOut()],
})
export class CustomToastComponent extends Toast {
  public icon: { [key: string]: string } = {
    'toast-success': 'check',
    'toast-info': 'notifications',
    'toast-error': 'close',
    'toast-warning': 'report_problem',
  };
  public IconSize = IconSize;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }
}
