import { UserGroups } from './user-group';

/**
 * User.
 * @property { string } sub - Cognito sub.
 * @property { string } email - email.
 * @property { string } accountId - custom:account_ids.
 * @property { string } partnerId - custom:partnerId
 * @property { string } givenName - given name.
 * @property { string } familyName - family name.
 * @property { string } groups - user group.
 * @property { string } jobTitle - job title.
 * @property { string } phoneNumber - phone number.
 * @property { string } createdDate - date user created.
 * @property { string } updatedDate - date user updated.
 * @property { string } supportId  ID of account in FreshService.
 * @property { UserStatus } status  status of user
 * @property { string } termsVersion  version of Ts and Cs the user has accepted
 * @property { number[] } permissions  array of permission numbers
 */
export interface User {
  username?: string; // optional, as when a new user is created, the username is generated and passed back
  sub?: string;
  email: string;
  accountId?: string[];
  partnerId?: string;
  givenName: string;
  familyName: string;
  groups?: UserGroups;
  jobTitle: string;
  countryCode: string;
  phoneNumber: string;
  createdDate?: string;
  updatedDate?: string;
  preferredMFA?: UserPreferredMFA;
  userMFASettingList?: [];
  supportId?: string;
  status?: UserStatus;
  termsVersion?: string;
  permissions?: number[];
  requesterLevel?: string;
  securityContact?: boolean;
}

export enum UserStatus {
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
  CONFIRMED = 'CONFIRMED',
  DISABLED = 'DISABLED',
  RESET_REQUIRED = 'RESET_REQUIRED',
}

export enum UserPreferredMFA {
  SMS_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
}

export enum RequesterLevel {
  NONE = 'none',
  STANDARD = 'standard',
  GENERAL = 'general',
  ENHANCED = 'enhanced',
  COMPANY_HEAD = 'company_head',
}
