import { environment } from '@environments/default/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CognitoService } from '@auth/services/cognito.service';

@Injectable({ providedIn: 'root' })
export class ApiService {
  /**
   * The base URL of the API
   *
   * @type { string }
   */
  private apiBaseUrl = environment.useMockAPI
    ? environment.mockApiUrl
    : environment.apiBaseUrl;

  /**
   * ApiService constructor
   *
   * @param { HttpClient } http - provider for http services.
   * @param { CognitoService } cognito - provider for cognito servies.
   */
  constructor(private http: HttpClient, private cognito: CognitoService) {}

  /**
   * Get headers.
   * @return { HttpHeaders } headers.
   */
  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  /**
   * Perform a DELETE request
   *
   * @param { string } path - path to send delete request to.
   * @returns { Observable<any>} observer to subscribe to for result.
   */
  public destroy(path: string): Observable<any> {
    const options = {
      headers: this.getHeaders(),
    };
    return this.http.delete(`${this.apiBaseUrl}${path}`, options);
  }

  /**
   * Perform a GET request
   *
   * @param { string } path - path to send get request to.
   * @param {{}} params - params to send.
   * @returns { Observable<any>} observer to subscribe to for result.
   */
  public get(
    path: string,
    params: HttpParams = new HttpParams(),
    extraOptions: {} = {}
  ): Observable<any> {
    let options = {
      params,
      headers: this.getHeaders(),
    };

    if (extraOptions) {
      options = {
        ...options,
        ...extraOptions,
      };
    }

    return this.http.get(`${this.apiBaseUrl}${path}`, options);
  }

  /**
   * Perform a POST request
   *
   * @param { string } path - path to send request to.
   * @param {{}} params - params to send.
   * @returns { Observable<any>} observer to subscribe to for result.
   */
  public post(path: string, params: {} | null = null): Observable<any> {
    const options = {
      headers: this.getHeaders(),
    };
    return this.http.post(`${this.apiBaseUrl}${path}`, params, options);
  }

  /**
   * Perform a multipart POST request
   *
   * @param { string } path - path to send request to.
   * @param {{}} params - params to send.
   * @returns { Observable<any>} observer to subscribe to for result.
   */
  public postFile(path: string, params: {} | null = null): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.cognito.getAmazonCredentials(),
      }),
      reportProgress: true,
      observe: 'events' as const,
    };
    return this.http.post(`${this.apiBaseUrl}${path}`, params, options);
  }

  /**
   * Perform a PUT request
   *
   * @param { string } path - path to send request to.
   * @param {{}} params - params to send.
   * @returns { Observable<any>} observer to subscribe to for result.
   */
  public put(path: string, params: {} | null = null): Observable<any> {
    const options = {
      headers: this.getHeaders(),
    };
    return this.http.put(`${this.apiBaseUrl}${path}`, params, options);
  }

  /**
   * Perform a DELETE request with a request body
   *
   * @param { string } path - path to send delete request to.
   * @param { string } body - body to send.
   * @returns { Observable<any>} observer to subscribe to for result.
   */
  public deleteWithBody(path: string, body: string): Observable<any> {
    const options = {
      headers: this.getHeaders(),
      body,
    };
    return this.http.delete(`${this.apiBaseUrl}${path}`, options);
  }

  /**
   * Perform a file DELETE request
   *
   * @param { string } path - path to send delete request to.
   * @returns { Observable<any>} observer to subscribe to for result.
   */
  public deleteFile(path: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.cognito.getAmazonCredentials(),
      }),
      reportProgress: true,
    };
    return this.http.delete(`${this.apiBaseUrl}${path}`, options);
  }

  /**
   * Perform an unprotected POST request with an API key
   *
   * @param { string } path - path to send request to.
   * @param {{}} params - params to send.
   * @returns { Observable<any>} observer to subscribe to for result.
   */
  public postOpen(path: string, params: {} | null = null): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'x-api-key': environment.open_api_key,
      }),
    };
    return this.http.post(`${this.apiBaseUrl}${path}`, params, options);
  }
}
