<div class="mfa-code">
  <div class="mfa-code__code-entry">
    <ng-container *ngFor="let num of [0, 1, 2, 3, 4, 5]; index as i">
      <app-form-field
        [type]="FieldType.CODE"
        (inputKeyUp)="onDigitInput($event, i)"
        (inputFocus)="onFocus(i)"
        [status]="fieldStatus"
        [hideLabel]="true"
        [inputValue]="enteredCode[i]"
        [name]="'mfa input' + i"
        #codeInput
        class="mfa-code__input-field"
      ></app-form-field>
    </ng-container>
  </div>
  <div *ngIf="status === Status.COMPLETE" class="mfa-code__loading">
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>
