import { Component, Input, OnInit } from '@angular/core';
import { IconSize, IconType, IconStatus } from '@shared/models/icon.model';

@Component({
  selector: 'app-firewall-schedule',
  templateUrl: './firewall-schedule.component.html',
  styleUrls: ['./firewall-schedule.component.scss'],
})
export class FirewallScheduleComponent implements OnInit {
  @Input() data: any = {};

  public days: string[] = [];
  public daysString: string = '';

  public IconSize = IconSize;
  public IconType = IconType;
  public IconStatus = IconStatus;

  constructor() {}

  ngOnInit() {
    this.days = this.data.day?.split(' ') || [];
    this.daysString = this.days
      .flatMap((day, i) => {
        // Join last two together with 'and'
        if (i === this.days.length - 2) {
          const dayOne = day.charAt(0).toUpperCase() + day.slice(1);
          const dayTwo =
            this.days[i + 1].charAt(0).toUpperCase() +
            this.days[i + 1].slice(1);
          return [`${dayOne} and ${dayTwo}`];
        }
        // Skip last one
        if (i === this.days.length - 1) {
          return [];
        }
        return [day.charAt(0).toUpperCase() + day.slice(1)];
      })
      .join(', ');
  }
}
