import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-country-outline',
  templateUrl: './country-outline.component.html',
  styleUrls: ['./country-outline.component.scss'],
})
export class CountryOutlineComponent {
  @Input() country: string | undefined;

  constructor() {}
}
