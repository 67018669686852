import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-firewall-name',
  templateUrl: './firewall-name.component.html',
  styleUrls: ['./firewall-name.component.scss'],
})
export class FirewallNameComponent {
  @Input() name?: string;
}
