<app-modal [size]="ModalSize.XSMALL" [status]="modalStatus" class="add-report">
  <div modal-header>Add a new report</div>

  <form modal-body class="form">
    <app-form-select
      [selectOptions]="reportSelectOptions"
      [(selectedOption)]="report.layoutId"
      class="add-report__form-field"
      [errorMsg]="errors.layoutId"
      [status]="formfieldStatus"
      data-testid="report-category"
      >Report Category</app-form-select
    >

    <app-form-select
      [selectOptions]="periodSelectOptions"
      [(selectedOption)]="report.period"
      class="add-report__form-field"
      [errorMsg]="errors.period"
      [status]="formfieldStatus"
      >Show data from</app-form-select
    >

    <app-form-select
      [selectOptions]="typeSelectOptions"
      [(selectedOption)]="report.type"
      class="add-report__form-field"
      [errorMsg]="errors.type"
      [status]="formfieldStatus"
      >Send the report</app-form-select
    >

    <ng-container *ngIf="report.type === ReportType.IMMEDIATE">
      <app-form-field
        [type]="FieldType.TEXTAREA"
        [name]="'emails'"
        placeholder="e.g. jane.smith@example.com"
        class="add-report__form-field"
        [(inputValue)]="emails"
        [errorMsg]="errors.emails"
        [status]="formfieldStatus"
        subtext="Please enter a comma-separated list of email addresses"
        >Emails</app-form-field
      >
    </ng-container>

    <ng-container *ngIf="report.type === ReportType.SCHEDULED">
      <app-form-select
        [selectOptions]="scheduleSelectOptions"
        [(selectedOption)]="report.schedule"
        class="add-report__form-field"
        [errorMsg]="errors.schedule"
        [status]="formfieldStatus"
        >Scheduled Time</app-form-select
      >
    </ng-container>
  </form>

  <div modal-footer class="add-report__buttons">
    <app-button
      (buttonClick)="cancel()"
      data-testid="cancel-report-button"
      [status]="submitButtonStatus"
      >Cancel</app-button
    >
    <app-button
      [type]="ButtonType.SUBMIT"
      [role]="ButtonRole.PRIMARY"
      (buttonClick)="addReport()"
      data-testid="add-report-button"
      [status]="submitButtonStatus"
      >Create report</app-button
    >
  </div>
</app-modal>
