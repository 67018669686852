import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { PopoverComponent } from '@shared/components/popover/popover.component';

@Directive({
  selector: '[appPopover]',
})
export class PopoverDirective {
  @Input() appPopover!: PopoverComponent;

  constructor(private el: ElementRef) {}

  // Handler for mouse events on the host component (icon etc.)
  @HostListener('mouseover') onMouseEnter() {
    const rect = this.el.nativeElement.getBoundingClientRect();
    this.appPopover.show(rect);
  }

  // Handler for mouse events on the host component (icon etc.)
  @HostListener('mouseleave') onMouseLeave() {
    setTimeout(() => {
      if (!this.appPopover.mouseOnPopover) {
        this.appPopover.hide();
      }
    }, 300);
  }

  @HostListener('keyup.enter')
  onEnterPress() {
    this.onMouseEnter();
  }

  @HostListener('blur')
  onTabPress() {
    this.appPopover.hide();
  }
}
