import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import * as Sentry from '@sentry/angular-ivy';

import '@angular/common/locales/global/en-GB';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { AuthModule } from '@auth/auth.module';
import { ChartsModule } from '@charts/charts.module';
import { WildcardRoutingModule } from './wildcard-routing.module';

import { reducers } from '@store/app.reducer';
import { AppEffects } from '@store/app.effects';
import { PartnerEffects } from '@store/partners/partners.effects';
import { NewsEffects } from '@store/news/news.effects';
import { AccountEffects } from '@store/accounts/accounts.effects';
import { UserEffects } from '@store/users/users.effects';

import { environment } from '@environments/default/environment';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@shared/interceptors/auth-interceptor';
import { CustomToastComponent } from '@shared/components/custom-toast/custom-toast.component';
import { HttpCancelInterceptor } from '@shared/interceptors/http-cancel-interceptor';
import { PermissionEffects } from '@store/permissions/permissions.effects';
import { DashboardRoutingModule } from './core/pages/dashboard/dashboard-routing.module';
import { SummaryRoutingModule } from '@core/pages/summary/summary-routing.module';

const providers: any[] = [
  { provide: LOCALE_ID, useValue: 'en-GB' },
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  {
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: HttpCancelInterceptor,
  },
];

@NgModule({
  declarations: [AppComponent, CustomToastComponent],
  imports: [
    BrowserModule,
    StoreModule.forRoot(reducers, {
      metaReducers: [],
    }),
    EffectsModule.forRoot([
      AppEffects,
      NewsEffects,
      PartnerEffects,
      AccountEffects,
      PermissionEffects,
      UserEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    GoogleTagManagerModule.forRoot({
      id: environment.gtm_id,
    }),
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    ChartsModule,
    CoreModule,
    SharedModule,
    WildcardRoutingModule,
    DashboardRoutingModule,
    SummaryRoutingModule,
  ],
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
