import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import {
  ButtonDisplay,
  ButtonIconPosition,
  ButtonRole,
  ButtonSize,
  ButtonStatus,
  ButtonStyle,
  ButtonType,
} from '../../models/button.model';
import { IconSize, IconType } from '../../models/icon.model';

/**
 * This is a button!!
 */
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() role: ButtonRole = ButtonRole.DEFAULT;
  @Input() type: ButtonType = ButtonType.BUTTON;
  @Input() size: ButtonSize = ButtonSize.MEDIUM;
  @Input() status: ButtonStatus = ButtonStatus.ACTIVE;
  @Input() style: ButtonStyle = ButtonStyle.NORMAL;
  @Input() iconName: string | undefined;
  @Input() iconPosition: ButtonIconPosition | false = false;
  @Input() iconType: IconType = IconType.REGULAR;
  @Input() display: ButtonDisplay = ButtonDisplay.INLINE;
  @Input() link: string | undefined;
  @Input() className!: string;

  @Output() buttonClick = new EventEmitter<MouseEvent>();
  @Output() iconClick = new EventEmitter<MouseEvent>();

  @HostBinding() tabindex = '-1'; // We want <button> to be tabbable, but not <app-button>

  public ButtonType = ButtonType;
  public IconSize = IconSize;

  public get iconClasses(): string[] {
    const classArray = [];
    classArray.push(`icon--button`);

    if (this.iconPosition === ButtonIconPosition.START) {
      classArray.push(`icon--button-start`);
    }

    if (this.iconPosition === ButtonIconPosition.END) {
      classArray.push(`icon--button-end`);
    }

    return classArray;
  }

  public get isDisabled(): boolean {
    return this.status === ButtonStatus.DISABLED;
  }

  public get buttonClasses(): string[] {
    const classArray = [];
    // Button role
    classArray.push(`button--${this.role}`);
    // Button size
    classArray.push(`button--${this.size}`);
    // Button status
    classArray.push(`button--${this.status}`);
    // Button display
    if (this.display === 'block') {
      classArray.push(`button--${this.display}`);
    }
    // Additional classes
    if (this.style === 'emphasised') {
      classArray.push(`button--emphasised`);
    }
    if (this.iconName) {
      classArray.push(`button--icon`);
      if (this.iconPosition === 'start') {
        classArray.push(`button--icon-start`);
      } else if (this.iconPosition === 'end') {
        classArray.push(`button--icon-end`);
      }
    }
    return classArray;
  }

  public onButtonClick(event: MouseEvent) {
    this.buttonClick.emit(event);
  }

  public onIconClick(event: MouseEvent) {
    this.iconClick.emit(event);
  }
}
