import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-tabs',
  templateUrl: './modal-tabs.component.html',
  styleUrls: ['./modal-tabs.component.scss'],
})
export class ModalTabsComponent {
  @Input() tabs!: { id: string; label: string }[];
  @Output() tabSwitched = new EventEmitter();

  public activeTabId?: string;

  switchTab(id: string) {
    this.activeTabId = id;
    this.tabSwitched.emit(id);
  }
}
