import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, zip } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { FormsModule } from '@forms/forms.module';
import { CoreModule } from '@core/core.module';

import { AccountStoreItem } from '@store/account-store-item.model';
import { PartnerAccount } from '@shared/models/partner-account';
import { FieldType } from '@forms/models/form-field.model';

import * as fromAccounts from '@store/accounts/accounts.selectors';
import * as fromPartners from '@store/partners/partners.reducer';

import { SelectOptions } from '@forms/models/form-select.model';
import { Account, AccountStatus } from '@shared/models/account';
import {
  ButtonDisplay,
  ButtonIconPosition,
  ButtonRole,
  ButtonType,
} from '@shared/models/button.model';
import { IconStatus, IconType } from '@shared/models/icon.model';
import { AccountService } from '@shared/services/account.service';
import { PermissionsOperator } from '@shared/models/permission.model';

@Component({
  standalone: true,
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  imports: [CommonModule, SharedModule, FormsModule, CoreModule],
})
export class SummaryComponent implements OnInit, OnDestroy {
  private detailsSubscription!: Subscription;
  private userSubscription!: Subscription;

  public accounts: AccountStoreItem[] = [];
  public partners: PartnerAccount[] = [];

  public selectedPartner: PartnerAccount | null = null;
  public partnerOptions: SelectOptions[] = [];

  public activeAccountIndex: number = 0;
  public searchText: string = '';
  public isSuperAdmin: boolean = false;
  public isPartner: boolean = false;
  public noResults: boolean = false;

  public FieldType = FieldType;
  public ButtonRole = ButtonRole;
  public ButtonType = ButtonType;
  public ButtonIconPosition = ButtonIconPosition;
  public ButtonDisplay = ButtonDisplay;
  public IconStatus = IconStatus;
  public IconType = IconType;
  public PermissionsOperator = PermissionsOperator;

  constructor(
    private accountService: AccountService,
    private store: Store,
  ) {}

  ngOnInit() {
    const accounts$ = this.store
      .select(fromAccounts.selectAllAccounts)
      .pipe(first());
    const partners$ = this.store
      .select(fromPartners.selectAllPartnersWithAccountData)
      .pipe(first());

    this.detailsSubscription = zip(accounts$, partners$)
      .pipe(map(([accounts, partners]) => ({ accounts, partners })))
      .subscribe((result) => {
        const { accounts, partners } = result;
        this.partners = partners;
        this.partnerOptions = this.populatePartnerList(partners);
        this.assignAccountDetails(accounts);
      });
  }

  ngOnDestroy() {
    this.detailsSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }

  /**
   * Set the details property of the account summaries
   * @param { Account[] } accounts The array of accounts to assign
   */
  private assignAccountDetails(accounts: AccountStoreItem[]) {
    let numberOfEnabledAccounts = 0;
    const results: AccountStoreItem[] = [];
    accounts.forEach((account: AccountStoreItem) => {
      if (account.id) {
        let isHidden: boolean = false;
        if (account.accountStatus === AccountStatus.DISABLED) {
          isHidden = true;
        } else {
          numberOfEnabledAccounts += 1;
        }
        const updatedAccount: AccountStoreItem = {
          ...account,
          isHidden,
          partnerName: this.findPartnerDetails(account.id),
        };
        results.push(updatedAccount);
      }
    });
    this.accounts = results;
    this.noResults = numberOfEnabledAccounts === 0;
  }

  /**
   * Find if a given account is stored against a partner and
   * return the partner name if found
   */

  private findPartnerDetails(accountId: string): string | null {
    let foundPartnerName = null;
    this.partners.map((partner) => {
      if (partner.accounts && partner.accounts.length !== 0) {
        const accountFound = (partner.accounts as Account[]).find(
          (account: Account) => account.id === accountId,
        );
        if (accountFound) {
          foundPartnerName = partner.partnerName;
        }
      }
    });
    return foundPartnerName;
  }

  private populatePartnerList(partners: PartnerAccount[]): SelectOptions[] {
    const options: SelectOptions[] = [
      { value: null, label: '-- Filter by Partner --' },
    ];
    partners.forEach((partner: PartnerAccount) => {
      options.push({ value: partner, label: partner.partnerName });
    });

    options.push({ value: null, label: 'Show all accounts' });

    return options;
  }

  /**
   * Set isHidden param based selected partner drop-down
   */
  public filterByPartner() {
    let selectedCount = 0;
    if (this.selectedPartner !== null) {
      this.accounts.forEach((account: AccountStoreItem) => {
        // this.selectedCount += isHidden ? 0 : 1;
        const isHidden =
          account.partnerName !== this.selectedPartner?.partnerName;
        account.isHidden = isHidden;
        if (!isHidden) {
          selectedCount = selectedCount + 1;
        }
      });
    } else {
      this.accounts.forEach((account: AccountStoreItem) => {
        account.isHidden = false;
        selectedCount = selectedCount + 1;
      });
    }
    this.noResults = selectedCount === 0;
  }

  /**
   * Set isHidden param based on whether account name matches search text
   */
  public filterByText() {
    let selectedCount = 0;
    this.accounts.forEach((account: AccountStoreItem) => {
      const searchAccountNameMatches =
        account.companyName
          .toLowerCase()
          .indexOf(this.searchText.toLowerCase()) === -1;
      const searchAccountNVAMatches =
        account.nvaId?.toLowerCase().indexOf(this.searchText.toLowerCase()) ===
        -1;
      const isHidden = searchAccountNameMatches && searchAccountNVAMatches;

      account.isHidden = isHidden;
      this.noResults = selectedCount === 0;
    });
  }

  /**
   * Fired when each account finishes loading its data
   */
  public dataLoaded() {
    if (this.activeAccountIndex < this.accounts.length) {
      this.activeAccountIndex += 1;
    }
  }
}
