import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { FormsModule } from '@forms/forms.module';
import { ChartsModule } from '@charts/charts.module';
import { CoreModule } from '@core/core.module';
import {
  ButtonDisplay,
  ButtonIconPosition,
  ButtonType,
} from '@shared/models/button.model';
import { IconType } from '@shared/models/icon.model';
import { TicketType } from '@support/models/ticket';

@Component({
  standalone: true,
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  imports: [CommonModule, SharedModule, FormsModule, ChartsModule, CoreModule],
})
export class DashboardComponent {
  public ButtonType = ButtonType;
  public ButtonDisplay = ButtonDisplay;
  public ButtonIconPosition = ButtonIconPosition;
  public IconType = IconType;
  public TicketType = TicketType;
}
