import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { HttpClient, HttpHandler, HttpXhrBackend } from '@angular/common/http';
import { XhrFactory } from '@angular/common';

import { AppModule } from './app/app.module';
import { environment } from '@environments/default/environment';

import * as Sentry from '@sentry/angular-ivy';

import { Amplify, Logger } from 'aws-amplify';

import { CloudGatewayXhr } from './cloud-gateway-xhr';

// create the dependancies we need to run our service request, app isn't bootstrapped yet, so the DI system isn't available :/
const injector = Injector.create({
  providers: [
    { provide: XhrFactory, useClass: CloudGatewayXhr, deps: [] },
    { provide: HttpHandler, useClass: HttpXhrBackend, deps: [XhrFactory] },
    { provide: HttpClient, deps: [HttpHandler] },
  ],
});

if (environment.production) {
  Sentry.init({
    dsn: 'https://7a67a9d9c07f135fba9779454437a7ee@o586738.ingest.us.sentry.io/4507264696582144',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  enableProdMode();
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

if (environment.production) {
  enableProdMode();
  // Removing console logs
  window.console.log = function () {};
}
// Currently testing sentry outside of dev
// TODO - change this to include prod once tested and happy
if (!environment.production) {
  Sentry.init({
    dsn: 'https://7591984f67798ae5ccda0c51f5f55e49@o586738.ingest.us.sentry.io/4507180632834048',
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

// configure AWS Amplify
Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: environment.region,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: environment.user_pool_id,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: environment.client_id,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH',
  },
});

// enable verbose logging ( when not in production mode )
if (!environment.production) {
  Logger.LOG_LEVEL = 'DEBUG';
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
