<app-modal
  [size]="ModalSize.MEDIUM"
  [status]="modalStatus"
  [warningOnClose]="true"
>
  <div modal-header>Change passwords</div>
  <div modal-body>
    <form>
      <ng-container>
        <p>
          If a user has reported issues accessing the VPN you can change their
          password below.
        </p>

        <p>
          Please note, this process will provide you with the option to change
          user passwords inline and in plain text, therefore once changed
          <strong
            >you will need to manually provide the user with their new
            password.</strong
          >
        </p>
      </ng-container>

      <p>
        <app-datatable
          class="change-vpn-passwords__table"
          [data]="selectedAccounts"
          [displayedColumns]="['vpnUsername', 'password', 'actions']"
          [limit]="10"
          [sort]="['vpnUsername', 'desc']"
          [status]="tableStatus"
          [sortable]="false"
          [showFooter]="false"
        >
          <ng-container cdkColumnDef="vpnUsername">
            <th cdk-header-cell *cdkHeaderCellDef>Username</th>
            <td cdk-cell *cdkCellDef="let row">
              {{ row.vpnUsername }}
              <app-pill
                class="change-vpn-passwords__table__pill"
                *ngIf="row.passwordChanged"
                [size]="PillSize.SMALL"
                [type]="PillStatus.SUCCESS"
              >
                Changed
              </app-pill>
            </td>
          </ng-container>

          <ng-container cdkColumnDef="password">
            <th cdk-header-cell *cdkHeaderCellDef>Password</th>
            <td cdk-cell *cdkCellDef="let row">
              <ng-container *ngIf="!row.inputOpened && row.passwordChanged">
                {{ row.newPassword }}
              </ng-container>
              <ng-container *ngIf="!row.inputOpened && !row.passwordChanged">
                **************
              </ng-container>
              <ng-container *ngIf="row.inputOpened">
                <app-form-field
                  [type]="FieldType.PASSWORD"
                  name="password"
                  [(inputValue)]="activePassword"
                  [showErrorMsg]="false"
                  [hideLabel]="true"
                  placeholder="Enter new password"
                  [required]="true"
                  #passwordInput
                  >Password</app-form-field
                >
              </ng-container>
            </td>
          </ng-container>

          <ng-container cdkColumnDef="actions">
            <th cdk-header-cell *cdkHeaderCellDef>Actions</th>
            <td cdk-cell *cdkCellDef="let row">
              <app-icon
                *ngIf="!row.inputOpened"
                (click)="openPasswordField(row)"
                [size]="IconSize.SMALL"
                [ngClass]="{
                  'change-vpn-passwords__table__icon--save': !activeUsername,
                  'change-vpn-passwords__table__icon--inactive': activeUsername
                }"
                title="Edit"
              >
                edit
              </app-icon>
              <app-icon
                *ngIf="row.inputOpened && !awaitingResponse"
                (click)="changePassword(row)"
                [size]="IconSize.SMALL"
                [ngClass]="{
                  'change-vpn-passwords__table__icon--save': passwordValid,
                  'change-vpn-passwords__table__icon--inactive': !passwordValid
                }"
                title="Save"
              >
                save
              </app-icon>
              <app-loading-spinner
                *ngIf="row.inputOpened && awaitingResponse"
                [size]="IconSize.SMALL"
              ></app-loading-spinner>
              <app-icon
                *ngIf="row.inputOpened && !awaitingResponse"
                (click)="closePasswordField(row)"
                [size]="IconSize.SMALL"
                class="change-vpn-passwords__table__icon--cross"
                title="Close"
              >
                close
              </app-icon>
            </td>
          </ng-container>
        </app-datatable>

        <ng-container *ngIf="activeUsername">
          <p>
            Make sure the new password for user
            <strong>{{ activeUsername }}</strong> meets the following criteria:
          </p>
          <app-password-strength
            [minChars]="14"
            [password]="activePassword"
            [requireMatch]="false"
            (validityChanged)="passwordValidityChanged($event)"
            [requireNoSpecial]="true"
            [requireSpecial]="false"
            [requireNoSimilarity]="true"
            [stringToCheckAgainst]="activeUsername"
          ></app-password-strength>
        </ng-container>

        <app-button-group>
          <app-button
            [status]="ButtonStatus.ACTIVE"
            [size]="ButtonSize.LARGE"
            [role]="ButtonRole.DEFAULT"
            (buttonClick)="cancel()"
            >Cancel</app-button
          >
        </app-button-group>
      </p>
    </form>
  </div>
</app-modal>
