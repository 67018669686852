import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

/* Services */
import { ModalService } from '@shared/services/modal.service';
import { SupportService } from '../../support.service';
import { ErrorService } from '@shared/services/error.service';

/* Models */
import { Account } from '@shared/models/account';
import { NewIncident, Ticket, TicketType } from '../../models/ticket';
import { TicketPriorities } from '../../models/ticket-priorities';
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import { FieldType } from '@forms/models/form-field.model';
import { FileOptions, FilePreviewModel } from '@shared/models/file-options';
import {
  ButtonRole,
  ButtonStatus,
  ButtonType,
} from '@shared/models/button.model';
import { SelectOptions } from '@forms/models/form-select.model';
import { IconStatus, IconType } from '@shared/models/icon.model';

/* Store */
import { Store } from '@ngrx/store';
import * as fromAccounts from '@store/accounts/accounts.selectors';
import * as fromStatus from '@store/status/status.reducer';
import * as StatusActions from '@store/status/status.actions';
import { AccountService } from '@shared/services/account.service';
import { PillStatus } from '@shared/models/pill.model';
import { ToggleOptions, ToggleType } from '@forms/models/form-toggle.model';
import { SupportValidationService } from '@support/support-validation.service';
import { AlertService } from '@shared/services/alert.service';

@Component({
  selector: 'app-raise-incident',
  templateUrl: './raise-incident.component.html',
  styleUrls: ['./raise-incident.component.scss'],
})
export class RaiseIncidentComponent implements OnInit, OnDestroy {
  public errors: { [key: string]: string[] | null } = {
    description: null,
    title: null,
    cc_emails: null,
    priority: null,
    accountId: null,
  };
  public ticket: NewIncident = {
    description: '',
    title: '',
    cc_emails: [],
    priority: null,
    accountId: '',
    file: [],
    impact: '',
    affected_users: null,
    available_to_join: true,
    srcip: null,
    dstip: null,
    related_to_connectivity: 'yes',
  };

  public fileOptions: FileOptions = {
    directory: 'support',
    fileTypes: [],
    fileSize: 300,
    fileCount: 20,
  };

  public prioritySelectOptions: SelectOptions[] = [
    {
      value: null,
      label: '-- Please select --',
    },
    {
      value: 1,
      label: TicketPriorities[1],
    },
    {
      value: 2,
      label: TicketPriorities[2],
    },
    {
      value: 3,
      label: TicketPriorities[3],
    },
    {
      value: 4,
      label: TicketPriorities[4],
    },
  ];
  public toggleOptions: ToggleOptions[] = [
    {
      value: 'yes',
      label: 'YES',
    },
    {
      value: 'no',
      label: 'NO',
    },
  ];

  public IconStatus = IconStatus;
  public IconType = IconType;

  public accountSelectOptions: SelectOptions[] = [
    {
      value: null,
      label: '-- Please select --',
    },
  ];

  public submitButtonStatus: ButtonStatus = ButtonStatus.ACTIVE;
  public accounts: Account[] = [];
  public priorities = TicketPriorities;
  public modalStatus: ModalStatus = ModalStatus.OPEN;
  public modalCloseMessage: string =
    'Closing this modal will discard all changes. Are you sure you want to close the modal?';

  private accountSubscription!: Subscription;
  private accountsSubscription!: Subscription;
  private routeSubscription!: Subscription;
  private waitingSubscription?: Subscription;

  public ModalSize = ModalSize;
  public ModalStatus = ModalStatus;
  public FieldType = FieldType;
  public ButtonType = ButtonType;
  public ButtonRole = ButtonRole;
  public PillStatus = PillStatus;
  public ToggleType = ToggleType;

  // Check if incident is submitted to conditionally display modal content
  public isSubmitted = false;

  // Initialise ticket ID variable to be updated after ticket submitted
  public ticketId: string | undefined = '';
  public ccEmails: string | null = null;
  public ticketResponse?: Ticket;

  private waiting$ = this.store.select(fromStatus.selectWaiting);

  constructor(
    private route: ActivatedRoute,
    private supportService: SupportService,
    private errorService: ErrorService,
    private store: Store,
    private modalService: ModalService,
    private accountService: AccountService,
    private supportValidationService: SupportValidationService,
    public alertService: AlertService
  ) {}

  ngOnInit() {
    this.accountSubscription = this.store
      .select(fromStatus.selectCurrentAccountID)
      // eslint-disable-next-line ngrx/no-store-subscription
      .subscribe((accountID) => {
        if (accountID) {
          this.ticket.accountId = accountID;
        }
      });

    this.accountsSubscription = this.store
      .select(fromAccounts.selectAllAccounts)
      // eslint-disable-next-line ngrx/no-store-subscription
      .subscribe((accounts: Account[]) => {
        if (accounts?.length) {
          this.accounts = accounts;
          this.accounts.forEach((account) => {
            this.accountSelectOptions.push({
              value: account.id,
              label: account.companyName,
            });
          });
        }
      });

    this.routeSubscription = this.route.params.subscribe((params) => {
      if (params.accountId && params.accountId !== false) {
        this.ticket.accountId = params.accountId;
      }
    });

    this.waitingSubscription = this.waiting$.subscribe((val) => {
      this.submitButtonStatus = !val
        ? ButtonStatus.ACTIVE
        : ButtonStatus.LOADING;
    });
  }

  /**
   * Destroy
   */
  ngOnDestroy(): void {
    this.accountSubscription?.unsubscribe();
    this.accountsSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
    this.waitingSubscription?.unsubscribe();
  }

  public onUploadSuccess(file: FilePreviewModel) {
    // Get filename from API response
    if (file.uploadResponse.body?.data) {
      const uploadedFileName = `${file.uploadResponse.body.data.directory}/${file.uploadResponse.body.data.fileName}`;
      this.ticket.file.push(uploadedFileName);
    }
  }

  public onRemoveSuccess(file: FilePreviewModel) {
    const removedFileName = `${file.uploadResponse.body.data.directory}/${file.uploadResponse.body.data.fileName}`;
    const removedFileIndex = this.ticket.file.indexOf(removedFileName);
    this.ticket.file.splice(removedFileIndex, 1);
  }

  public cancel() {
    this.alertService.throwConfirmation(
      this.modalCloseMessage,
      'Close',
      'Warning',
      () => (this.modalStatus = ModalStatus.CLOSED)
    );
  }

  public viewTicketDetails() {
    if (this.ticket.accountId) {
      this.accountService.updateLoadedAccountSubject(this.ticket.accountId);
    }
    this.modalService.viewTicket(
      this.ticketResponse as Ticket,
      TicketType.INCIDENTS
    );
  }

  // Raise a new ticket
  public raiseIncident() {
    // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(StatusActions.setWaiting({ waiting: true }));
    // Reset errors
    Object.keys(this.errors).forEach((prop) => {
      this.errors[prop] = null;
    });
    this.ticket.cc_emails =
      this.ccEmails?.replace(/\s/g, '').split(',') ?? undefined;
    this.supportService.raiseIncident(this.ticket).subscribe({
      next: (response) => {
        // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(StatusActions.setWaiting({ waiting: false }));

        this.isSubmitted = true;
        this.ticketResponse = response;
        this.ticketId = response.id;
      },
      error: (err) => {
        // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(StatusActions.setWaiting({ waiting: false }));
        this.errorService.handleSaveErrors(err);
        // Show validation errors
        this.errors = err;
      },
    });
  }

  /**
   * Handle the blur event from the form field
   * @param prop Name of the property to validate
   */
  public handleBlur(prop: string) {
    this.ticket.cc_emails =
      this.ccEmails?.replace(/\s/g, '').split(',') ?? undefined;
    const validation = this.supportValidationService.validateIncident(
      this.ticket
    );
    if (validation === true) {
      this.errors[prop] = [];
    } else {
      this.errors[prop] = validation[prop];
    }
  }

  /**
   * Handle the focus event from the form field
   * @param prop Name of the property to clear
   */
  public handleFocus(prop: string): void {
    // Clear the error message
    this.errors[prop] = [];
  }
}
