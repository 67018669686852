import { Pipe, PipeTransform } from '@angular/core';
import {
  isNumberFinite,
  isPositive,
  isInteger,
  toDecimal,
} from './number-utils';

export type BpsUnit = 'bps' | 'kbps' | 'Mbps' | 'Gbps' | 'Tbps';

export const BpsFormats: {
  [key: string]: { max: number; prev?: BpsUnit | false };
} = {
  bps: { max: 1000 },
  kbps: { max: Math.pow(1000, 2), prev: 'bps' },
  Mbps: { max: Math.pow(1000, 3), prev: 'kbps' },
  Gbps: { max: Math.pow(1000, 4), prev: 'Mbps' },
  Tbps: { max: Number.MAX_SAFE_INTEGER, prev: 'Gbps' },
};

@Pipe({
  name: 'bps',
})
export class BpsPipe implements PipeTransform {
  static formatResult(
    result: number,
    unit: string,
    displayUnits: boolean
  ): string {
    if (displayUnits) {
      return `${result} ${unit}`;
    } else {
      return `${result}`;
    }
  }

  static calculateResult(
    format: { max: number; prev?: BpsUnit | false },
    bps: number
  ) {
    const prev = format.prev ? BpsFormats[format.prev] : undefined;
    return prev ? bps / prev.max : bps;
  }

  transform(
    input: any,
    displayUnits: boolean = true,
    decimal: number = 0,
    from: BpsUnit = 'Mbps',
    to?: BpsUnit
  ): number | string {
    if (typeof input === 'string') {
      input = parseInt(input, 10);
    }

    // Check validity of input and decimal
    if (
      !(
        isNumberFinite(input) &&
        isNumberFinite(decimal) &&
        isInteger(decimal) &&
        isPositive(decimal)
      )
    ) {
      return input;
    }

    let bps: number = input;
    let unit: BpsUnit = from;
    while (unit !== 'bps') {
      bps *= 1000;
      unit = BpsFormats[unit].prev || 'bps';
    }

    if (!to) {
      let prevMax = 0;
      to =
        (Object.keys(BpsFormats).find((key: string) => {
          const format: any = BpsFormats[key];
          if (bps < format.max && bps > prevMax) {
            return true;
          }
          prevMax = format.max;
          return false;
        }) as BpsUnit) || 'B';
    }

    // let returnValue: string | number = input;
    // let i = 0;
    // const formatKeys = Object.keys(BpsFormats);

    // for (; i < formatKeys.length; i++) {
    //   const key = formatKeys[i];
    //   const format: any = BpsFormats[key] || 0;
    //   if (bps < format.max) {
    //     const result = toDecimal(BpsPipe.calculateResult(format, bps), decimal);
    //     returnValue = BpsPipe.formatResult(result, key, displayUnits);
    //     break;
    //   }
    // }
    // return returnValue;

    const format = BpsFormats[to];
    const result = toDecimal(BpsPipe.calculateResult(format, bps), decimal);
    return BpsPipe.formatResult(result, to, displayUnits);
  }
}
