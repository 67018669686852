import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DateTime } from 'luxon';

/* Services */
import { SupportService } from '../../support.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@shared/services/user.service';
import { ErrorService } from '@shared/services/error.service';

/* Models */
import { TicketComment } from '../../models/comment';
import { FileOptions, FilePreviewModel } from '@shared/models/file-options';
import {
  ButtonRole,
  ButtonStatus,
  ButtonType,
} from '@shared/models/button.model';
import { FieldType } from '@forms/models/form-field.model';
import { SupportValidationService } from '@support/support-validation.service';
import { IconType } from '@shared/models/icon.model';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss'],
})
export class AddCommentComponent implements OnInit, OnDestroy {
  @Input() ticketId!: string;
  @Output() closeComment = new EventEmitter<boolean>();
  @Output() commentAdded = new EventEmitter<{ data: TicketComment }>();

  public errors: { [key: string]: string[] | null } = {
    comment: null,
    file: null,
  };
  public comment: TicketComment = {
    comment: null,
    file: [],
    author: {
      email: null,
      name: null,
      supportId: null,
    },
    date: null,
    ticketId: null,
  };
  public uploadError: string = '';
  public fileOptions: FileOptions = {
    directory: 'support',
    fileTypes: [],
    fileSize: 300,
    fileCount: 20,
  };

  private userSubscription!: Subscription;
  public addCommentStatus = ButtonStatus.ACTIVE;

  public ButtonType = ButtonType;
  public ButtonRole = ButtonRole;
  public FieldType = FieldType;
  public IconType = IconType;

  constructor(
    private supportService: SupportService,
    private toastr: ToastrService,
    private userService: UserService,
    private errorService: ErrorService,
    private supportValidationService: SupportValidationService
  ) {}

  ngOnInit(): void {
    this.comment.ticketId = this.ticketId;
    this.userSubscription = this.userService
      .getCurrentUser()
      .subscribe((user) => {
        if (user) {
          this.comment.author.email = user.email;
          this.comment.author.name = `${user.givenName} ${user.familyName}`;
          if (user.supportId) this.comment.author.supportId = user.supportId;
        }
      });
  }

  /**
   * Destroy
   */
  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  public addComment(): void {
    this.comment.date = DateTime.now().toFormat('YYYY-MM-DD HH:mm:ss');
    this.addCommentStatus = ButtonStatus.LOADING;
    this.supportService.addComment(this.comment).subscribe({
      next: (data) => {
        this.addCommentStatus = ButtonStatus.ACTIVE;
        this.toastr.success('Comment added', 'Success', {
          progressBar: true,
        });
        // Close this comment
        this.discardComment();
        this.commentAdded.emit(data as { data: TicketComment });
      },
      error: (err) => {
        this.addCommentStatus = ButtonStatus.ACTIVE;
        this.errorService.handleSaveErrors(err);
      },
    });
  }

  public discardComment(): void {
    this.comment = {
      comment: null,
      file: [],
      author: { email: null, name: null, supportId: null },
      date: null,
      ticketId: null,
    };
    this.closeComment.emit(true);
  }

  public onUploadSuccess(file: FilePreviewModel) {
    // Get filename from API response
    if (file.uploadResponse.body?.data) {
      const uploadedFileName = `${file.uploadResponse.body.data.directory}/${file.uploadResponse.body.data.fileName}`;
      this.comment.file.push(uploadedFileName);
    }
  }

  public onRemoveSuccess(file: FilePreviewModel) {
    const removedFileName = `${file.uploadResponse.body.data.directory}/${file.uploadResponse.body.data.fileName}`;
    const removedFileIndex = this.comment.file.indexOf(removedFileName);
    this.comment.file.splice(removedFileIndex, 1);
  }

  /**
   * Handle the blur event from the form field
   * @param prop Name of the property to validate
   */
  public handleBlur(prop: string) {
    const validation = this.supportValidationService.validateComment(
      this.comment
    );
    if (validation === true) {
      this.errors[prop] = [];
    } else {
      this.errors[prop] = validation[prop];
    }
  }

  /**
   * Handle the focus event from the form field
   * @param prop Name of the property to clear
   */
  public handleFocus(prop: string): void {
    // Clear the error message
    this.errors[prop] = [];
  }
}
