<app-icon
  [appPopover]="content"
  [size]="IconSize.SMALL"
  [type]="IconType.OUTLINED"
  class="help-icon__icon"
  tabindex="0"
  title="Information"
  >info</app-icon
>
<app-popover #content>
  <div class="help-icon__content">
    <ng-content></ng-content>
  </div>
</app-popover>
