<h3 class="has-body-font">
  Notes<app-help-icon>
    <p>
      You can add notes to your Firewall Rules to make them easier to identify
      and manage. This is a static text field without version history provided
      for convenience, please make sure you are happy with your changes before
      saving them. Information entered here will not affect your Firewall Rule
      on a technical level.
    </p>
  </app-help-icon>
</h3>
<ng-container *ngIf="!noteLoaded">
  <app-loading></app-loading>
</ng-container>
<ng-container *ngIf="noteLoaded">
  <app-form-field
    id="notes"
    [type]="FieldType.TEXTAREA"
    name="notes"
    placeholder="i.e. 'this rule blocks users from accessing gambling sites'..."
    [(inputValue)]="data"
    [errorMsg]="errors?.notes"
    [hideLabel]="true"
  >
  </app-form-field>
  <app-button
    (buttonClick)="save()"
    [role]="ButtonRole.PRIMARY"
    [size]="ButtonSize.LARGE"
    [status]="buttonStatus"
    data-testid="save-button"
    >Save Notes</app-button
  >
</ng-container>
