import { XhrFactory } from '@angular/common';

/**
 * Factory for building browser XMLHttpRequest instances.
 */
export class CloudGatewayXhr implements XhrFactory {
  /**
   * Constructor
   */
  constructor() {}

  /**
   * Crete a XMLHttpRequest instance,
   * @return {XMLHttpRequest} XMLHttpRequest instance.
   */
  build(): XMLHttpRequest {
    return new XMLHttpRequest();
  }
}
