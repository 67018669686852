import { Component, Input, OnInit } from '@angular/core';
import {
  IconSize,
  IconStatus,
  IconType,
  IconWeight,
} from '../../models/icon.model';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() status!: IconStatus;
  @Input() type!: IconType;
  @Input() size!: IconSize;
  @Input() weight!: IconWeight;
  @Input() className: string[] | string = [];
  @Input() title!: string;

  constructor() {}

  public get iconClasses(): string[] {
    const classArray = [];
    // Classnames passed from parent
    if (Array.isArray(this.className)) {
      // Passed as an array of strings
      classArray.push(...this.className);
    } else if (this.className) {
      // Passed as space separated strings
      classArray.push(...this.className.split(' '));
    }
    // Icon type
    if (this.type) {
      classArray.push(`icon--${this.type || IconType.REGULAR}`);
    }
    // Icon size
    if (this.size) {
      classArray.push(`icon--${this.size || IconSize.MEDIUM}`);
    }
    // Icon status
    if (this.status) {
      classArray.push(`icon--${this.status}`);
    }
    // Icon weight
    if (this.weight) {
      classArray.push(`icon--${this.weight}`);
    }

    return classArray;
  }
}
