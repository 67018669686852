import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoAction } from '../../models/cognito-action';
import { CognitoLoginService } from '../../services/cognito-login.service';
import { CognitoResponse } from '../../models/cognito-response';
import { FieldType } from '@forms/models/form-field.model';
import {
  ButtonDisplay,
  ButtonRole,
  ButtonStatus,
  ButtonType,
} from '@shared/models/button.model';
import { PasswordPattern } from '@auth/models/patterns';

@Component({
  selector: 'app-set-first-password',
  templateUrl: './set-first-password.component.html',
  styleUrls: ['./set-first-password.component.scss'],
})
export class SetFirstPasswordComponent {
  public password: string = '';
  public confirmPassword: string = '';
  public passwordValid: boolean = false;
  public errorMsg?: string;
  public username?: string;
  public buttonStatus = ButtonStatus.ACTIVE;

  public FieldType = FieldType;
  public ButtonRole = ButtonRole;
  public ButtonType = ButtonType;
  public ButtonDisplay = ButtonDisplay;
  public ButtonStatus = ButtonStatus;
  public PasswordPattern = PasswordPattern;

  constructor(
    private router: Router,
    private cognitoLoginService: CognitoLoginService
  ) {
    this.username =
      this?.router?.getCurrentNavigation()?.extras?.state?.username;
  }

  public passwordValidityChanged(valid: boolean): void {
    this.passwordValid = valid;
  }

  /**
   * Set a new password for the user.
   */
  public setNewPassword(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (
      this.password &&
      this.password.length > 0 &&
      this.confirmPassword &&
      this.confirmPassword.length > 0 &&
      this.password === this.confirmPassword
    ) {
      this.buttonStatus = ButtonStatus.LOADING;
      this.cognitoLoginService
        .changePassword(this.password)
        .subscribe((res: CognitoResponse) => {
          this.buttonStatus = ButtonStatus.ACTIVE;
          if (res.success) {
            if (res.action === CognitoAction.EnterCode) {
              this.router.navigate(['/verify']);
            } else {
              this.router.navigate(['/']);
            }
          } else {
            this.errorMsg = res.reason;
          }
        });
    } else {
      // handle feedback for bad form
      if (!this.password || this.password.length < 1) {
        this.errorMsg = 'Please enter a password';
      } else if (
        !this.confirmPassword ||
        this.confirmPassword.length < 1 ||
        this.password !== this.confirmPassword
      ) {
        this.errorMsg = 'Please check your passwords match';
      }
    }
  }
}
