/**
 * Ticket Priorities enum
 */

type TicketPrioritiesMap = Record<number, string>;

export const TicketPriorities: TicketPrioritiesMap = {
  1: 'Critical',
  2: 'High',
  3: 'Moderate',
  4: 'Low',
};
