<div
  role="dialog"
  aria-live="polite"
  aria-label="cookieconsent"
  aria-describedby="cookieconsent:desc"
  class="cookie-consent"
  *ngIf="!hasResponded"
  data-testid="cookieconsent"
>
  <span id="cookieconsent:desc" class="cookie-consent__message">
    We use cookies to improve customer experience and evolve our portal on an
    ongoing basis. Read more about our cookies and how we use them
    <a
      aria-label="learn more about cookies"
      role="button"
      tabindex="0"
      class="cookie-consent__link"
      href="https://{{ domain }}/cookie-policy"
      rel="noopener noreferrer nofollow"
      target="_blank"
      >here</a
    >.
  </span>
  <app-button-group class="cookie-consent__actions">
    <app-button
      [role]="ButtonRole.SECONDARY"
      (buttonClick)="onRespond(CookieConsentType.REQUIRED)"
      aria-label="deny cookies"
      aria-role="button"
      class="button--deny button button--primary"
      data-testid="cookie-accept-required"
    >
      Use Strictly Necessary Cookies
    </app-button>
    <app-button
      [role]="ButtonRole.PRIMARY"
      (buttonClick)="onRespond(CookieConsentType.ALL)"
      aria-label="allow cookies"
      aria-role="button"
      data-testid="cookie-accept-all"
      class="button button--primary"
      >Use All Cookies</app-button
    >
  </app-button-group>
</div>
