import { Component, OnDestroy, OnInit } from '@angular/core';
import { Account, VPNStack } from '@shared/models/account';
import {
  ButtonRole,
  ButtonSize,
  ButtonStatus,
  ButtonType,
} from '@shared/models/button.model';
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import { Status } from '@shared/models/status.model';
import { AccountService } from '@shared/services/account.service';
import { ErrorService } from '@shared/services/error.service';
import { ModalService } from '@shared/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FieldType } from '@forms/models/form-field.model';
import { ConnectService } from '../../connect.service';
import { PasswordResetRequest, SSLVPN } from '../../models/ssl-vpn.model';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '@shared/services/alert.service';

@Component({
  selector: 'app-reset-vpn-auth',
  templateUrl: './reset-vpn-auth.component.html',
  styleUrls: ['./reset-vpn-auth.component.scss'],
})
export class ResetVPNAuthComponent implements OnInit, OnDestroy {
  public ModalSize = ModalSize;
  public TableStatus = Status;
  public ButtonSize = ButtonSize;
  public ButtonRole = ButtonRole;
  public ButtonType = ButtonType;
  public ButtonStatus = ButtonStatus;
  public Status = Status;
  public FieldType = FieldType;
  public ModalStatus = ModalStatus;
  public modalCloseMessage: string =
    'Closing this modal will discard all changes. Are you sure you want to close the modal?';

  private accountSubscription!: Subscription;
  private accountData!: Account;
  private routeSubscription!: Subscription;

  public modalStatus: ModalStatus = ModalStatus.OPEN;
  public isAwaitingResponse: boolean = false;
  public selectedAccounts: SSLVPN[] = [];
  public step: number = 1;
  public tableStatus: Status = Status.LOADING;
  public searchText: string = '';
  public resetField: string = '';
  public resetError: string = '';
  public type: string = 'passwords';
  public label: string = 'passwords';

  constructor(
    private accountService: AccountService,
    private connectService: ConnectService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    public alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.accountSubscription = this.accountService
      .fetchCurrentAccount()
      .pipe(take(1))
      .subscribe((account?: Account) => {
        if (account && account.id) {
          this.accountData = account;
          const sslVpnContext = this.modalService.getContext();
          if (!sslVpnContext) {
            this.modalStatus = ModalStatus.CLOSED;
          } else {
            this.selectedAccounts = sslVpnContext;
            this.tableStatus = Status.COMPLETE;
          }
        }
      });
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.type = params.type;
      if (this.type === 'mfa-tokens') {
        this.label = 'MFA tokens';
      } else {
        this.label = 'passwords';
      }
    });
  }

  ngOnDestroy() {
    this.accountSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
  }

  public cancel(): void {
    this.alertService.throwConfirmation(
      this.modalCloseMessage,
      'Close',
      'Warning',
      () => (this.modalStatus = ModalStatus.CLOSED)
    );
  }

  public nextStep(): void {
    if (this.selectedAccounts.length === 0) {
      return;
    }
    this.step++;
  }

  public prevStep(): void {
    this.step--;
  }

  public reset(): void {
    const errorMessage = 'Please enter "Reset" in the text field';
    this.resetError = '';
    if (!this.resetField) {
      this.resetError = errorMessage;
      return;
    }
    const input = this.resetField.toLowerCase();
    if (input !== 'reset') {
      this.resetError = errorMessage;
      return;
    }
    this.isAwaitingResponse = true;

    if (this.type === 'mfa-tokens') {
      this.resetMFATokens();
    } else {
      this.resetPasswords();
    }
  }

  private resetPasswords() {
    const emailList = this.selectedAccounts.map(
      (sslAccount) => sslAccount.email as string
    );
    // account is healthConnect if vpnStack is not null, and not set to own stack
    const isHealthConnect =
      this.accountData.vpnStack !== null &&
      this.accountData.vpnStack !== VPNStack.OWN;
    const request: PasswordResetRequest = {
      company: this.accountData.companyShortName,
      emailList,
      isHealthConnect,
    };
    this.connectService.createToken(request).subscribe({
      next: () => {
        this.modalStatus = ModalStatus.CLOSED;
        this.toastr.success(
          'A reset email has been sent. This link will expire within 1 hour.',
          'Success',
          {
            progressBar: true,
          }
        );
        this.isAwaitingResponse = false;
      },
      error: (err) => {
        this.isAwaitingResponse = false;
        this.errorService.handleSaveErrors(err);
      },
    });
  }

  private resetMFATokens() {
    this.connectService
      .bulkUserResetTokens(this.selectedAccounts, this.accountData.id)
      .subscribe({
        next: () => {
          this.modalStatus = ModalStatus.CLOSED;
          this.toastr.success(
            'A reset email has been sent. This link will expire within 1 hour.',
            'Success',
            {
              progressBar: true,
            }
          );
          this.isAwaitingResponse = false;
        },
        error: (err) => {
          this.isAwaitingResponse = false;
          this.errorService.handleSaveErrors(err);
        },
      });
  }

  public getButtonStatus(): ButtonStatus {
    if (!this.selectedAccounts.length) {
      return ButtonStatus.DISABLED;
    } else if (this.isAwaitingResponse) {
      return ButtonStatus.LOADING;
    }
    return ButtonStatus.ACTIVE;
  }
}
