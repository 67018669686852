<nav class="primary-navigation" aria-label="Main navigation">
  <app-logo
    [size]="LogoSize.COMPACT"
    class="primary-navigation__logo"
  ></app-logo>
  <app-form-select
    *ngIf="currentAccount && accountList.length > 1"
    [selectOptions]="accountList"
    [(selectedOption)]="currentAccount.id"
    (selectedOptionChange)="accountChange(currentAccount.id)"
    data-testid="account-select-dropdown"
    [name]="'account-selector'"
  ></app-form-select>
  <h3 class="primary-navigation__account-name" *ngIf="accountList.length === 1">
    {{ currentAccount?.companyName }}
  </h3>
  <ul class="primary-navigation__list" [ngClass]="theme">
    <ng-container *ngFor="let route of primaryNavData">
      <li>
        <app-nav-item
          *ngIf="route.data && route.data.nav !== false && !route.hidden"
          class="primary-navigation__item"
          [icon]="route.data.icon"
          [customIcon]="route.data.customIcon || false"
          [title]="route.data.title"
          [link]="route.link ?? '/' + route.path"
          [isMobile]="isMobile"
          [clickable]="route.data.clickable === false ? false : true"
          [linkExactMatch]="route.path?.startsWith('support') ? true : false"
          [ngClass]="{
            'primary-navigation__item--new-section': route.newSection
          }"
        ></app-nav-item>
      </li>
    </ng-container>
  </ul>
</nav>
