<div class="file-picker">
  <div class="file-picker__label" *ngIf="label">
    {{ label }}
  </div>
  <ngx-file-drop
    (onFileDrop)="dropped($event)"
    [multiple]="true"
    class="file-picker__uploader"
    dropZoneClassName="file-picker__dropzone"
    contentClassName=""
    [accept]="fileTypes"
  >
    <ng-template
      ngx-file-drop-content-tmp
      let-openFileSelector="openFileSelector"
    >
      <p class="file-picker__dropzone-text">Drag and drop your files here</p>
      <p class="file-picker__dropzone-text">or</p>
      <app-button
        class="file-picker__dropzone-button button button--primary column"
        (buttonClick)="openFileSelector()"
        [role]="ButtonRole.PRIMARY"
      >
        Click to select your files
      </app-button>
    </ng-template>
  </ngx-file-drop>
  <ng-container *ngFor="let fileItem of uploadedFiles; let i = index">
    <div class="file-picker__file">
      <!-- Progress bar during upload -->
      <ng-container *ngIf="fileItem.uploadResponse?.progress">
        <div
          class="file-picker__file-progress"
          [ngStyle]="{ width: fileItem.uploadResponse.progress + '%' }"
        ></div>
      </ng-container>

      <app-icon
        title="File"
        class="file-picker__file-icon"
        [size]="IconSize.LARGE"
      >
        {{ getIcon(fileItem.file) }}
      </app-icon>

      <div class="file-picker__file-details">
        <p class="file-picker__file-name">
          <strong>{{ fileItem.file.name }}</strong>
        </p>
        <p class="file-picker__file-size">
          {{ fileItem.file.size | bytes: true : 2 }}
        </p>
      </div>
      <!-- Upload in progress -->
      <ng-container *ngIf="fileItem.uploadResponse?.progress < 100">
        <div class="file-picker__file-progress-text">
          {{ fileItem.uploadResponse?.progress }}%
        </div>
        <div class="file-picker__file-status">
          <app-icon [size]="IconSize.MEDIUM" className="icon--loading"
            >refresh</app-icon
          >
        </div>
      </ng-container>

      <!-- Upload completed, waiting for response-->
      <ng-container *ngIf="fileItem.uploadResponse?.progress === 100">
        <div class="file-picker__file-progress-text">Uploading file...</div>
        <div class="file-picker__file-status">
          <app-icon
            title="Uploading"
            [size]="IconSize.MEDIUM"
            className="icon--loading"
            >refresh</app-icon
          >
        </div>
      </ng-container>

      <!-- Upload and scan complete -->
      <ng-container
        *ngIf="fileItem.uploadResponse?.status === UploadStatus.UPLOADED"
      >
        <div class="file-picker__file-progress-text">
          <app-icon
            class="file-picker__status-icon file-picker__status-icon--remove"
            (click)="removeFile(fileItem, i)"
            title="Click here to remove this file"
            [size]="IconSize.MEDIUM"
            title="Delete"
          >
            delete
          </app-icon>
        </div>
        <div class="file-picker__file-status">
          <app-icon
            class="file-picker__status-icon file-picker__status-icon--success"
            [size]="IconSize.MEDIUM"
            title="Success"
          >
            check_circle
          </app-icon>
        </div>
      </ng-container>

      <!-- Error in scan -->
      <ng-container
        *ngIf="
          fileItem.uploadResponse &&
          fileItem.uploadResponse.code &&
          fileItem.uploadResponse.code === 500
        "
      >
        <div class="file-picker__file-progress-text">
          {{ fileItem.uploadResponse.message }}
        </div>
        <div class="file-picker__file-status">
          <app-icon
            title="Error"
            class="file-picker__status-icon file-picker__status-icon--error"
            [size]="IconSize.MEDIUM"
          >
            error
          </app-icon>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <p class="file-picker__error-message" *ngIf="uploadError">
    {{ uploadError }}
  </p>
</div>
