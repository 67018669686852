import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ButtonRole, ButtonStatus } from '@shared/models/button.model';
import { IconSize } from '@shared/models/icon.model';
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import { PillStatus } from '@shared/models/pill.model';
import {
  File,
  ReportPeriodDict,
  ReportRecord,
  ReportScheduleDict,
  ReportStatus,
} from '@shared/models/report.model';
import { Status } from '@shared/models/status.model';
import { ModalService } from '@shared/services/modal.service';
import { ReportsService } from 'src/app/reporting/reports.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.scss'],
})
export class ViewReportComponent implements OnInit {
  public ModalSize = ModalSize;
  public ModalStatus = ModalStatus;
  public ReportStatus = ReportStatus;
  public ButtonRole = ButtonRole;
  public PillStatus = PillStatus;
  public ReportScheduleDict = ReportScheduleDict;
  public ReportPeriodDict = ReportPeriodDict;
  public Status = Status;
  public IconSize = IconSize;

  public modalStatus: ModalStatus = ModalStatus.OPEN;
  public scheduleButtonStatus: ButtonStatus = ButtonStatus.ACTIVE;
  public report!: ReportRecord;

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private reportsService: ReportsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    // Get report details from context if possible
    const reportContext = this.modalService.getContext();

    if (reportContext) {
      this.report = reportContext;
    } else {
      // Go and get report details from reportId in URL
      this.route.params
        .pipe(
          switchMap((params: Params): Observable<ReportRecord | undefined> => {
            if (!params.report || params.report === false) {
              this.modalStatus = ModalStatus.CLOSED;
              return of(undefined);
            }
            return this.reportsService.getReport(params.report);
          })
        )
        .subscribe((report) => {
          if (report) {
            this.report = report;
          }
        });
    }
  }

  public scheduleReport(reportId: string | undefined): void {
    if (!reportId) {
      return;
    }
    const changeStatusTo =
      this.report.reportStatus === ReportStatus.DISABLED
        ? ReportStatus.ENABLED
        : ReportStatus.DISABLED;
    this.scheduleButtonStatus = ButtonStatus.LOADING;
    this.reportsService.scheduleReport(reportId, changeStatusTo).subscribe({
      next: (isUpdated: boolean) => {
        // Update the report's status based on response (should be `true`)
        if (isUpdated) {
          this.report = { ...this.report, reportStatus: changeStatusTo };
          this.scheduleButtonStatus = ButtonStatus.ACTIVE;
          this.toastr.success(
            `The report was successfully ${
              changeStatusTo === ReportStatus.ENABLED ? 'enabled' : 'disabled'
            }`,
            'Success',
            {
              progressBar: true,
            }
          );
        }
      },
      error: (_err: Error) => {
        this.toastr.warning(
          `It was not possible to ${
            changeStatusTo === ReportStatus.ENABLED ? 'enable' : 'disable'
          } the report. Please try again.`,
          'Error',
          {
            progressBar: true,
          }
        );
      },
    });
  }

  public onRowClick(e: unknown) {
    const file: File = <File>e;
    if (!file) {
      this.toastr.warning(
        'There is no PDF file associated with that report. Please try again.',
        'File not found',
        {
          progressBar: true,
        }
      );
      return;
    }
    this.reportsService.getFile(file).subscribe({
      next: (success) => {
        // Show toast with success message
        if (success === true) {
          this.toastr.success('The report was successfully downloaded', '', {
            progressBar: true,
          });
        } else {
          throw new Error('Unable to get file');
        }
      },
      error: (err) => {
        if (err.data.message.indexOf('Cannot find a report uuid') > -1) {
          this.toastr.warning(
            'The report is still being created. Please wait and try again later.',
            'File not ready',
            {
              progressBar: true,
            }
          );
        } else {
          this.toastr.warning(err.message, 'Error', {
            progressBar: true,
          });
        }
      },
    });
  }
}
