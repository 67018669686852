import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnInit, OnChanges {
  @Input() password: string = '';
  @Input() passwordConfirm: string = '';
  @Input() minChars: number = 14;
  @Input() requireUppercase: boolean = true;
  @Input() requireLowercase: boolean = true;
  @Input() requireNumber: boolean = true;
  @Input() requireSpecial: boolean = true;
  @Input() requireNoSpecial: boolean = false;
  @Input() requireMatch: boolean = true;
  @Input() requireNoSimilarity: boolean = true;
  @Input() stringToCheckAgainst: string = '';
  @Output() validityChanged: EventEmitter<boolean> = new EventEmitter();
  public containAtLeastMinChars: boolean = false;
  public containAtLeastOneLowerCaseLetter: boolean = false;
  public containAtLeastOneUpperCaseLetter: boolean = false;
  public containAtLeastOneDigit: boolean = false;
  public containAtLeastOneSpecialChar: boolean = false;
  public containNoSpecialChar: boolean = true;
  public containNoSimilarity: boolean = true;
  public passwordsMatch: boolean = false;
  public validPassword: boolean = false;
  public allValid: boolean = false;
  constructor() {}
  ngOnInit(): void {
    if (this.password) {
      this.checkValidPassword();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.password && this.password.length > 0
      ? this.checkValidPassword()
      : this.reset();
  }
  private checkValidPassword() {
    const upperCaseValid = this.requireUppercase
      ? this.checkContainAtLeastOneUpperCaseLetter()
      : true;
    const lowerCaseValid = this.requireLowercase
      ? this.checkContainAtLeastOneLowerCaseLetter()
      : true;
    const numberValid = this.requireNumber
      ? this.checkContainAtLeastOneDigit()
      : true;
    const specialValid = this.requireSpecial
      ? this.checkContainAtLeastOneSpecialChar()
      : true;
    const noSpecialValid = this.requireNoSpecial
      ? !this.checkContainAtLeastOneSpecialChar()
      : true;
    const noSimilarityValid = this.requireNoSimilarity
      ? this.checkContainNoSimilarity()
      : true;
    //
    const newValidPassword =
      this.checkContainAtLeastMinChars() &&
      upperCaseValid &&
      lowerCaseValid &&
      numberValid &&
      specialValid &&
      noSpecialValid &&
      noSimilarityValid;
    const matchValid = this.requireMatch ? this.checkMatch() : true;
    const allValid = newValidPassword && matchValid;
    this.validPassword = newValidPassword;
    if (allValid !== this.allValid) {
      this.allValid = allValid;
      this.validityChanged.emit(allValid);
    }
  }
  private checkContainAtLeastMinChars(): boolean {
    this.containAtLeastMinChars = this.password.length >= this.minChars;
    return this.containAtLeastMinChars;
  }
  private checkContainAtLeastOneLowerCaseLetter(): boolean {
    const regex = RegExp(/^(?=.*?[a-z])/);
    this.containAtLeastOneLowerCaseLetter = regex.test(this.password);
    return this.containAtLeastOneLowerCaseLetter;
  }
  private checkContainAtLeastOneUpperCaseLetter(): boolean {
    const regex = RegExp(/^(?=.*?[A-Z])/);
    this.containAtLeastOneUpperCaseLetter = regex.test(this.password);
    return this.containAtLeastOneUpperCaseLetter;
  }
  private checkContainAtLeastOneDigit(): boolean {
    const regex = RegExp(/^(?=.*?[0-9])/);
    this.containAtLeastOneDigit = regex.test(this.password);
    return this.containAtLeastOneDigit;
  }
  private checkContainAtLeastOneSpecialChar(): boolean {
    const regex = RegExp(/^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/);
    this.containAtLeastOneSpecialChar = regex.test(this.password);
    this.containNoSpecialChar = !this.containAtLeastOneSpecialChar;
    return this.containAtLeastOneSpecialChar;
  }
  private checkContainNoSimilarity(): boolean {
    const regex = RegExp(/(?=(\S{3}).*,.*\1)/);
    const stringToCheck = `${this.stringToCheckAgainst}, ${this.password}`;
    this.containNoSimilarity = !regex.test(stringToCheck);
    return this.containNoSimilarity;
  }
  private checkMatch(): boolean {
    const match = this.password === this.passwordConfirm;
    this.passwordsMatch = match;
    return match;
  }
  reset() {
    this.validPassword = false;
    this.containAtLeastMinChars = false;
    this.containAtLeastOneLowerCaseLetter = false;
    this.containAtLeastOneUpperCaseLetter = false;
    this.containAtLeastOneDigit = false;
    this.containAtLeastOneSpecialChar = false;
    this.containNoSpecialChar = true;
    this.containNoSimilarity = true;
  }
}
