import { Component, Input } from '@angular/core';
import { ExtendedFirewallRule } from '@shared/models/firewall.model';
import { PillSize, PillStatus } from '@shared/models/pill.model';

@Component({
  selector: 'app-firewall-security-filters',
  templateUrl: './firewall-security-filters.component.html',
  styleUrls: ['./firewall-security-filters.component.scss'],
})
export class FirewallSecurityFiltersComponent {
  @Input() data!: ExtendedFirewallRule;

  public PillStatus = PillStatus;
  public PillSize = PillSize;
}
