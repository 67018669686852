import {
  AddressType,
  FirewallAction,
  FirewallStatus,
  URLFilterEntryType,
} from './firewall.datatypes';

export class FirewallRule {
  action: FirewallAction;
  'application-list'?: ApplicationList;
  dstaddr: Address[];
  monitor?: MonitorData;
  name: string;
  policyid: number = 0;
  q_origin_key: number = 0;
  schedule: ScheduleData;
  service: ServiceData[];
  srcaddr: Address[];
  'ssl-ssh-profile'?: SSLSSHProfileData;
  nat?: string;
  status?: FirewallStatus;
  uuid: string;
  'webfilter-profile'?: WebFilter;
  comments: string | null;
  priority?: number;
  sourcenatip?: string = '';
  sourcenatipb?: string;
  proxyIps?: string[];
  proxyPort?: string;
  proxy?: string;
  securityprofiles?: any[];
  notes: string | null;

  // We check for these security profiles, but they aren't usually included
  'av-profile'?: any;
  'dnsfilter-profile'?: any;
  'ips-sensor'?: any;

  constructor(
    name: string,
    action: FirewallAction = FirewallAction.deny,
    dstaddr: Address[] = [
      {
        name: 'all',
        type: AddressType.ipmask,
        subnet: '0.0.0.0 0.0.0.0',
        q_origin_key: 'all',
        ipcidr: '0.0.0.0/32',
      },
    ],
    srcaddr: Address[] = [
      {
        name: 'all',
        type: AddressType.ipmask,
        subnet: '0.0.0.0 0.0.0.0',
        q_origin_key: 'all',
        ipcidr: '0.0.0.0/32',
      },
    ],
    service: ServiceData[] = [
      {
        name: 'ALL',
        datasource: 'firewall.service.custom',
        protocol: 'IP',
        q_origin_key: 'ALL',
      },
    ],
    schedule: ScheduleData = {
      name: 'always',
      day: 'sunday monday tuesday wednesday thursday friday saturday',
    },
    status: FirewallStatus = FirewallStatus.enable,
    uuid: string = '00000',
    comments: string = '',
    notes: string = ''
  ) {
    this.name = name;
    this.action = action;
    this.dstaddr = dstaddr;
    this.srcaddr = srcaddr;
    this.service = service;
    this.schedule = schedule;
    this.status = status;
    this.uuid = uuid;
    this.comments = comments;
    this.notes = notes;
    this.securityprofiles = [];
  }
}

// Used in the firewall details modal
export class ExtendedFirewallRule extends FirewallRule {
  'av-profile': any;
  'dnsfilter-profile': any;
  'ips-sensor': any;
}
export interface AddressItem {
  datasource?: string;
  'css-class'?: string;
  name: string;
  type: AddressType;
  comment?: string;
  q_origin_key: string;
}

export interface VirtualIP extends AddressItem {
  extip: string;
  extipb: string;
  mappedip: [{ range: string; q_origin_key: string }];
  extport: string;
  mappedport: string;
}

export interface StaticNAT extends AddressItem {
  extip: string;
  extipb: string;
  mappedip: [{ range: string; q_origin_key: string }];
  extport: string;
  mappedport: string;
}

export interface IPMask extends AddressItem {
  subnet: string;
  ipcidr: string;
}

export interface IPRange extends AddressItem {
  'start-ip'?: string;
  'end-ip'?: string;
}

export interface AddressGroup extends AddressItem {
  member: Address[];
}

export type Address = VirtualIP | IPMask | IPRange | AddressGroup | StaticNAT;

interface MonitorData {
  policyid: number;
  uuid: string;
  active_sessions: number;
  bytes: number;
  packets: number;
  last_used?: number;
  first_used?: number;
  hit_count?: number;
  session_last_used?: number;
  session_first_used?: number;
  session_count?: number;
}

interface ScheduleData {
  day: string;
  name: string;
}

interface ServiceData {
  datasource: 'firewall.service.custom' | 'firewall.service.group';
  icmptype?: string;
  name: string;
  protocol: string;
  q_origin_key: string;
  'sctp-portrange'?: string;
  'tcp-portrange'?: string;
  'udp-portrange'?: string;
}

interface SSLSSHProfileData {
  name: string;
  comment?: string;
  q_origin_key?: string;
  datasource?: string;
  'css-class'?: string;
}

export class WebFilter {
  comment: string = '';
  name: string = '';
  web?: { 'urlfilter-table': URLFilterTable };
}

interface WebDetails {
  datasource: string;
  id: number;
  q_origin_key: number;
}

interface URLFilterTable {
  datasource: string;
  id: number;
  q_origin_key: number;
}

export class URLFilter {
  public comment: string;
  public entries: URLFilterEntry[];
  public id: number;
  public q_origin_key: number;

  constructor(
    id: number,
    q_origin_key: number,
    comment: string,
    entries: URLFilterEntry[]
  ) {
    this.id = id;
    this.q_origin_key = q_origin_key;
    this.comment = comment;
    this.entries = entries;
  }
}

export interface URLFilterEntry {
  id: number;
  q_origin_key: number;
  url: string;
  type: URLFilterEntryType;
  action: URLFilterAction;
}

export enum URLFilterAction {
  ALLOW = 'allow',
  BLOCK = 'block',
}

export interface ApplicationList {
  name: string;
  comment: string;
  entries: ApplicationEntriesItem[];
}

export interface ApplicationEntriesItem {
  action: 'pass' | 'fail';
  id: number;
  q_origin_key: number;
}

export enum AddressLocation {
  'source',
  'destination',
}

export enum FirewallType {
  PROXY = 'proxy',
  REGULAR = 'regular',
}
