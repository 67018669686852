<div class="firewall-rule-delete">
  <!-- Rule selection -->
  <div>
    <h2 class="firewall-rule-delete__title">Select Rule</h2>
    <app-filter-text
      [(inputValue)]="searchText"
      (inputValueChange)="filter()"
      name="firewall-rules-filter"
      placeholder="Search by rule name or policy ID"
      class="firewall-rule-delete__filter"
    ></app-filter-text>

    <!-- Firewall Rule selection table -->
    <app-datatable
      [data]="filteredData"
      [displayedColumns]="tableColumns"
      [limit]="10"
      [status]="tableStatus"
      (rowClick)="this.onRowClick($event)"
    >
      <ng-container cdkColumnDef="selected">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          class="firewall-rule-delete__table-header"
        >
          Select
        </th>
        <td
          cdk-cell
          *cdkCellDef="let row"
          class="firewall-rule-delete__table-cell"
        >
          <div class="firewall-rule-delete__cell-entry">
            <app-checkbox
              [checked]="
                !!selectedRule && selectedRule.policyid === row.policyid
              "
            ></app-checkbox>
          </div>
        </td>
      </ng-container>

      <!-- Name column -->
      <ng-container cdkColumnDef="name">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          class="firewall-rule-delete__table-header"
        >
          Rule Name
        </th>
        <td
          cdk-cell
          *cdkCellDef="let row"
          class="cdk-cell--primary firewall-rule-delete__table-cell"
        >
          <div class="firewall-rule-delete__cell-entry">
            <ng-container *ngIf="row.status === FirewallStatus.disable">
              <app-icon
                title="Disabled"
                [size]="IconSize.MEDIUM"
                [type]="IconType.OUTLINED"
                >cancel</app-icon
              >
            </ng-container>
            <span>{{ row.name }}</span>
          </div>
        </td>
      </ng-container>

      <!-- Source column -->
      <ng-container cdkColumnDef="source">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          class="firewall-rule-delete__table-header"
        >
          IP Source
        </th>
        <td
          cdk-cell
          *cdkCellDef="let row"
          class="firewall-rule-delete__table-cell"
        >
          <div
            class="firewall-rule-delete__address-entry"
            title="{{ row.srcaddr | join: '&#13;' : 'name' }}"
          >
            {{ row.srcaddr[0].name }}
            <ng-container *ngIf="row.srcaddr.length > 1"
              >(+{{ row.srcaddr.length - 1 }} more)</ng-container
            >
          </div>
        </td>
      </ng-container>

      <!-- Destination column -->
      <ng-container cdkColumnDef="destination">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
          class="firewall-rule-delete__table-header"
        >
          IP Destination
        </th>
        <td
          cdk-cell
          *cdkCellDef="let row"
          class="firewall-rule-delete__table-cell"
        >
          <div
            class="firewall-rule-delete__address-entry"
            title="{{ row.dstaddr | join: '&#13;' : 'name' }}"
          >
            {{ row.dstaddr[0].name }}
            <ng-container *ngIf="row.dstaddr.length > 1"
              >(+{{ row.dstaddr.length - 1 }} more)</ng-container
            >
          </div>
        </td>
      </ng-container>
    </app-datatable>
  </div>

  <!-- Contextual Panel -->
  <div *ngIf="selectedRule && selectedRule.policyid !== null">
    <app-firewall-rule-information
      [ruleData]="selectedRule"
    ></app-firewall-rule-information>
  </div>

  <!-- Form panel -->
  <div *ngIf="selectedRule && selectedRule.policyid !== null">
    <h2 class="firewall-rule-delete__title">Additional Comments</h2>
    <form class="delete-form">
      <app-form-field
        [type]="FieldType.TEXTAREA"
        placeholder="Please specify a date and time when you would like this rule to be deleted, leaving this blank defaults to ASAP"
        name="comment"
        id="comment"
        (inputValueChange)="handleFormUpdate()"
        [(inputValue)]="formData.comment"
      ></app-form-field>
    </form>

    <h2 class="firewall-rule-delete__title">Confirmation</h2>
    <div class="firewall-rule-delete__confirmation">
      <!-- Custom validated form field -->
      <app-checkbox
        class="confirmation-checkbox"
        [checked]="formData.confirmed"
        (changed)="handleConfirmation($event)"
      ></app-checkbox>
      <span class="confirmation-text">
        I confirm that
        <span class="confirmation-text__bold"
          >I would like to delete this firewall rule</span
        >
        and
        <span class="confirmation-text__bold"
          >I am authorised to request this deletion</span
        >.
      </span>
      <p
        class="confirmation-text__error-message"
        *ngIf="errors.confirmed?.length"
      >
        {{ errors.confirmed[0] }}
      </p>
    </div>
  </div>
</div>
