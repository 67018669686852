import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameValueCompare',
})
export class NameValueComparePipe implements PipeTransform {
  transform(name: any, value: any): any {
    if (name === value || `${name}/32` === value) {
      return;
    } else {
      return `${value}`;
    }
  }
}
