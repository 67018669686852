import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/default/environment';
import {
  ButtonRole,
  ButtonStyle,
  ButtonType,
} from '@shared/models/button.model';
import { ModalSize, ModalStatus } from '@shared/models/modal.model';
import { User } from '@shared/models/user';
import { UserService } from '@shared/services/user.service';
import { skipWhile, Subscription } from 'rxjs';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
})
export class TermsConditionsComponent implements OnInit, OnDestroy {
  public ModalSize = ModalSize;
  public ModalStatus = ModalStatus;
  public ButtonType = ButtonType;
  public ButtonRole = ButtonRole;
  public ButtonStyle = ButtonStyle;

  public showModal: boolean = false;
  private userSubscription!: Subscription;
  public termsModalStatus = ModalStatus.OPEN;
  public user!: User;

  constructor(private router: Router, private userService: UserService) {}

  /* Only show the modal if the user has not accepted the current version terms and conditions */
  ngOnInit() {
    this.userSubscription = this.userService
      .getCurrentUser()
      .pipe(skipWhile((val) => !val))
      .subscribe((user) => {
        if (user) {
          this.setModalVisibility(user);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  public setModalVisibility(user: User) {
    this.user = user;
    const termsVersionString = user.termsVersion?.split(';');
    if (termsVersionString) {
      const termsVersion = parseInt(termsVersionString[0]);
      if (termsVersion < environment.terms_version || !termsVersion) {
        this.showModal = true;
      } else {
        this.showModal = false;
      }
    } else {
      this.showModal = true;
    }
  }

  acceptTerms() {
    this.showModal = false;
    this.userService
      .update({
        ...this.user,
        ...{ termsVersion: environment.terms_version.toString() },
        ...{
          phoneNumber: this.user.phoneNumber.replace(this.user.countryCode, ''),
        }, // Removing the "+44/+41/+whatever" from the phone number
      })
      .subscribe();
  }

  declineTerms() {
    this.showModal = false;
    this.router.navigate(['/logout']);
  }
}
