import { createFeatureSelector, createSelector } from '@ngrx/store';

import { User } from '@shared/models/user';

export interface UserState {
  loaded: boolean;
  data: User[];
}

/* Selectors */
const selectUserFeatureState = createFeatureSelector<UserState>('users');

export const selectAllUsers = createSelector(
  selectUserFeatureState,
  (state: UserState) => {
    return state.data;
  }
);
