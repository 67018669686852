import { Component } from '@angular/core';
import { IconSize, IconType } from '@shared/models/icon.model';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent {
  public IconSize = IconSize;
  public IconType = IconType;

  constructor() {}
}
