<div class="firewall-rule-add__container">
  <h2 class="firewall-rule-add__title">Rule Details</h2>
  <form class="firewall-rule-add__form">
    <app-form-field
      [type]="FieldType.TEXT"
      placeholder="Enter your new rule name (i.e UAT ACME RULE)"
      name="name"
      id="name"
      [errorMsg]="errors.name.length ? errors.name.join('. ') : undefined"
      [(inputValue)]="formData.name"
      (inputBlur)="handleBlur('name')"
      (inputFocus)="handleFocus('name')"
      (inputValueChange)="handleFormUpdate()"
      >Rule Name</app-form-field
    >
    <app-form-field
      [type]="FieldType.TEXT"
      placeholder="Enter the source IP address"
      name="sourceAddress"
      id="sourceAddress"
      [errorMsg]="
        errors.sourceAddress.length
          ? errors.sourceAddress.join('. ')
          : undefined
      "
      [(inputValue)]="formData.sourceAddress"
      (inputBlur)="handleBlur('sourceAddress')"
      (inputFocus)="handleFocus('sourceAddress')"
      (inputValueChange)="handleFormUpdate()"
      >Source <abbr title="Internet Protocols">IP</abbr></app-form-field
    >
    <app-form-field
      [type]="FieldType.TEXT"
      placeholder="Enter the destination IP address"
      name="destinationAddress"
      id="destinationAddress"
      [errorMsg]="
        errors.destinationAddress.length
          ? errors.destinationAddress.join('. ')
          : undefined
      "
      [(inputValue)]="formData.destinationAddress"
      (inputBlur)="handleBlur('destinationAddress')"
      (inputFocus)="handleFocus('destinationAddress')"
      (inputValueChange)="handleFormUpdate()"
      >Destination <abbr title="Internet Protocols">IP</abbr></app-form-field
    >
    <app-form-field
      [type]="FieldType.TEXT"
      placeholder="Service name and port (e.g. HTTPS/443)"
      name="service"
      id="service"
      [errorMsg]="errors.service.length ? errors.service.join('. ') : undefined"
      [(inputValue)]="formData.service"
      (inputBlur)="handleBlur('service')"
      (inputFocus)="handleFocus('service')"
      (inputValueChange)="handleFormUpdate()"
      >Service</app-form-field
    >
    <app-form-field
      [type]="FieldType.TEXT"
      [extraText]="{ text: 'OPTIONAL', pill: PillStatus.PRIMARY }"
      placeholder="Enter the name of the security profile (e.g. ACME_whitelist)"
      name="securityProfile"
      id="securityProfile"
      [(inputValue)]="formData.securityProfile"
      (inputBlur)="handleBlur('securityProfile')"
      (inputFocus)="handleFocus('securityProfile')"
      >Security Profile</app-form-field
    >
    <app-form-field
      [type]="FieldType.TEXT"
      [extraText]="{ text: 'OPTIONAL', pill: PillStatus.PRIMARY }"
      placeholder="Enter Source NAT (if applicable)"
      name="sourceNat"
      id="sourceNat"
      [(inputValue)]="formData.sourceNat"
      (inputBlur)="handleBlur('sourceNat')"
      (inputFocus)="handleFocus('sourceNat')"
      >Source NAT</app-form-field
    >
    <app-form-field
      [type]="FieldType.TEXT"
      [extraText]="{ text: 'OPTIONAL', pill: PillStatus.PRIMARY }"
      placeholder="Enter Destination NAT (if applicable)"
      name="destinationNat"
      id="destinationNat"
      [(inputValue)]="formData.destinationNat"
      (inputBlur)="handleBlur('destinationNat')"
      (inputFocus)="handleFocus('destinationNat')"
      >Destination NAT</app-form-field
    >
    <app-form-field
      [type]="FieldType.TEXTAREA"
      [extraText]="{ text: 'OPTIONAL', pill: PillStatus.PRIMARY }"
      placeholder="Please enter 1 per line, maximum of 10 (e.g. ACME.co.uk)"
      name="urls"
      id="urls"
      [(inputValue)]="formData.urls"
      (inputBlur)="handleBlur('urls')"
      (inputFocus)="handleFocus('urls')"
      >URL(s)</app-form-field
    >
    <app-form-field
      [type]="FieldType.TEXTAREA"
      [extraText]="{ text: 'OPTIONAL', pill: PillStatus.PRIMARY }"
      placeholder="Date/time for implementation (if specific date/time required) and any other information required."
      name="comment"
      id="comment"
      [(inputValue)]="formData.comment"
      (inputBlur)="handleBlur('comment')"
      (inputFocus)="handleFocus('comment')"
      >Comments</app-form-field
    >
    <app-form-field
      [type]="FieldType.TEXT"
      [name]="'cc_emails'"
      [extraText]="{ text: 'OPTIONAL', pill: PillStatus.PRIMARY }"
      placeholder="e.g colleague@mycompany.com"
      id="cc"
      [(inputValue)]="formData.cc_emails"
      [errorMsg]="errors.cc_emails"
      (inputBlur)="handleBlur('cc_emails')"
      (inputFocus)="handleFocus('cc_emails')"
      >Copy people into this request (cc)</app-form-field
    >

    <app-info-box class="firewall-rule-add__infobox" id="infobox">
      You can copy in users in the field above using a comma separated list. In
      order to ensure we can action your firewall request quickly,
      <strong>
        if applicable please copy in an authorised approver when submitting your
        ticket.
      </strong>
    </app-info-box>
  </form>
  <div class="firewall-rule-add__outro">
    <h2 class="firewall-rule-add__title">Additional Information</h2>
    <p class="firewall-rule-add__outro-text">
      Once received Cloud Gateway will assess the request in order to determine
      whether the requester is authorised to request the change and whether we
      have all the required information to implement the change. Where further
      information / clarification is required, we will contact the
      requester.<span class="firewall-rule-add__outro-text-bold">
        Service Requests raised with Cloud Gateway may incur a charge</span
      >, please refer to your
      <abbr title="Service Level Agreement">SLA</abbr> for applicable costs.
    </p>
  </div>
</div>
