<div class="icon-stat icon-stat--{{ size }}">
  <div class="icon-stat__icon">
    <ng-content select="[stat-icon]"></ng-content>
  </div>
  <h3 class="icon-stat__value">
    <ng-content select="[stat-value]"></ng-content>
  </h3>
  <small class="icon-stat__label"
    ><ng-content select="[stat-label]"></ng-content
  ></small>
  <div class="icon-stat__subvalue">
    <ng-content select="[stat-subvalue]"></ng-content>
  </div>
  <div class="icon-stat__detail">
    <ng-content select="[stat-detail]"></ng-content>
  </div>
</div>
