<app-terms-conditions></app-terms-conditions>
<div class="container">
  <aside class="side-bar" aria-label="Primary Navigation">
    <app-primary-navigation
      class="side-bar__desktop-menu"
    ></app-primary-navigation>
  </aside>
  <div class="main-content">
    <app-header class="main-content__header"></app-header>
    <main class="main-content__main">
      <router-outlet></router-outlet>
      <app-version-number></app-version-number>
    </main>
  </div>
</div>
