<div class="summary">
  <app-page-title-group>
    <ng-container title>
      My Accounts
      <app-help-icon>
        <p>
          From here you can view a list of all of the portal accounts that you
          have access to.
        </p>
      </app-help-icon>
    </ng-container>
    <div actions class="summary-header__search">
      <app-form-select
        *appPermitted="['ADMIN__PARTNER__READ']"
        [selectOptions]="partnerOptions"
        [(selectedOption)]="selectedPartner"
        (selectedOptionChange)="filterByPartner()"
      ></app-form-select>
      <app-form-field
        [type]="FieldType.TEXT"
        [name]="'example-input'"
        placeholder="Search Account"
        [hideLabel]="true"
        [(inputValue)]="searchText"
        (inputValueChange)="filterByText()"
        >Search Account
      </app-form-field>
    </div>
  </app-page-title-group>
  <div
    *appPermitted="'ACC__SUMMARY'"
    class="page page--summary"
    data-testid="account-list"
  >
    <p *ngIf="noResults">There are no accounts to show</p>
    <ng-container *ngIf="accounts.length <= 10">
      <ng-container *ngFor="let account of accounts; index as idx">
        <app-account-summary
          [account]="account"
          class="summary__account"
          [ngClass]="{ 'is-hidden': account.isHidden }"
          [load]="idx <= activeAccountIndex"
          (dataLoaded)="dataLoaded()"
        ></app-account-summary>
      </ng-container>
    </ng-container>
    <div class="summary--compact" *ngIf="accounts.length > 10">
      <ng-container *ngFor="let account of accounts; index as idx">
        <app-account-summary-compact
          [account]="account"
          class="summary__account"
          [ngClass]="{ 'is-hidden': account.isHidden }"
        ></app-account-summary-compact>
      </ng-container>
    </div>
  </div>
</div>
