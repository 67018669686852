import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  ButtonDisplay,
  ButtonIconPosition,
  ButtonRole,
  ButtonType,
} from '@shared/models/button.model';
import { IconSize, IconStatus, IconType } from '@shared/models/icon.model';
import { Status } from '@shared/models/status.model';
import { TicketType } from '@support/models/ticket';
import { TicketDataLayout } from './ticket-data-layout.datatype';

@Component({
  selector: 'app-ticket-data',
  templateUrl: './ticket-data.component.html',
  styleUrls: ['./ticket-data.component.scss'],
})
export class TicketDataComponent {
  @Input() layout: TicketDataLayout = TicketDataLayout.HORIZONTAL;
  @Input() open?: number;
  @Input() pending?: number;
  @Input() resolved?: number;
  @Input() closed?: number;
  @Input() status: Status = Status.LOADING;
  @Input() type?: string;

  @Output() raiseTicket = new EventEmitter<void>();
  @Output() viewTickets = new EventEmitter<void>();

  public IconSize = IconSize;
  public IconStatus = IconStatus;

  public ButtonIconPosition = ButtonIconPosition;
  public ButtonType = ButtonType;
  public ButtonRole = ButtonRole;
  public ButtonDisplay = ButtonDisplay;
  public TicketType = TicketType;
  public IconType = IconType;

  public Status = Status;

  constructor() {}

  raiseTicketClick() {
    this.raiseTicket.emit();
  }

  viewAllTicketsClick() {
    this.viewTickets.emit();
  }
}
