import { createAction, props } from '@ngrx/store';
import { Permission } from '@shared/models/permission.model';

export const SET_PERMISSIONS = '[Permissions] Set Permissions';
export const FETCH_PERMISSIONS = '[Permissions] Fetch All Permissions';

/* ACTIONS FOR STORE */
export const setPermissions = createAction(SET_PERMISSIONS, props<{ permissions: Permission[] }>());

/* ACTIONS FOR EFFECTS */
export const fetchPermissions = createAction(FETCH_PERMISSIONS);
