<app-modal [size]="ModalSize.MEDIUM" [status]="modalStatus">
  <div modal-header>Raise Service Request</div>
  <div modal-body>
    <div class="service-request-choice__content">
      <p>
        Raising a service request allows you to request key changes to your
        platform infrastructure.
      </p>
      <p>So we know where to direct you, first please tell us.</p>
      <p>Is this a....</p>
      <div class="service-request-choice__content__choice-container">
        <div class="service-request-choice__content__choice-container__choice">
          <div
            class="service-request-choice__content__choice-container__choice__icon"
          >
            <app-custom-icon
              [type]="CustomIcons.PROTECT"
              [ariaLabel]="'Firewall Rule request'"
            ></app-custom-icon>
          </div>
          Firewall rule change
          <app-button
            class="service-request-choice__content__choice-container__choice__button"
            [type]="ButtonType.SUBMIT"
            [role]="ButtonRole.PRIMARY"
            (buttonClick)="raiseFirewallRule()"
            >Firewall Rule Request</app-button
          >
        </div>
        <div
          class="service-request-choice__content__choice-container__choice service-request-choice__content__choice-container__choice--right"
        >
          <div
            class="service-request-choice__content__choice-container__choice__icon"
          >
            <app-icon title="General request">miscellaneous_services</app-icon>
          </div>
          Something else
          <app-button
            class="service-request-choice__content__choice-container__choice__button"
            [type]="ButtonType.SUBMIT"
            [role]="ButtonRole.PRIMARY"
            (buttonClick)="raiseServiceRequest()"
            >Raise a ticket</app-button
          >
        </div>
      </div>
    </div>
  </div>
</app-modal>
