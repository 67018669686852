import { Component } from '@angular/core';
import { IconSize, IconType } from '@shared/models/icon.model';

@Component({
  selector: 'app-help-icon',
  templateUrl: './help-icon.component.html',
  styleUrls: ['./help-icon.component.scss'],
})
export class HelpIconComponent {
  public IconSize = IconSize;
  public IconType = IconType;
}
