<ul class="font-{{ fontWeight }}">
  <li *ngFor="let address of addressList">
    <div class="address">
      <app-address-type-icon
        [addressType]="address.type"
      ></app-address-type-icon>
      <div data-testid="address-data">
        {{ address.name }}
        <div
          *ngIf="
            address.type === AddressType.ipmask &&
            (address.name | subnetToCIDR : address.subnet)
          "
        >
          ({{ address.name | subnetToCIDR : address.subnet }})
        </div>
        <div *ngIf="address.type === AddressType.iprange">
          ({{ address["start-ip"] }} &#8594; {{ address["end-ip"] }})
        </div>
        <div *ngIf="address.type === AddressType.geography">
          ({{ address.country }})
        </div>
        <div
          *ngIf="
            address.type === AddressType.fqdn &&
            (address.name | nameValueCompare : address.fqdn)
          "
        >
          ({{ address.name | nameValueCompare : address.fqdn }})
        </div>
        <div
          *ngIf="
            address.type === AddressType.vip ||
            address.type === AddressType.staticnat
          "
        >
          <ng-container
            *ngIf="address.extipb && address.extip !== address.extipb"
            >Primary:<br />
          </ng-container>
          {{ address.extip }} -> {{ address.mappedip[0].range }}
          <ng-container
            *ngIf="address.extipb && address.extip !== address.extipb"
          >
            <br />
            Secondary:<br />
            {{ address.extipb }} -> {{ address.mappedip[0].range }}
          </ng-container>
        </div>
        <div *ngIf="address.type === AddressType.fqdn && address.addrs.length">
          ({{ address.addrs | join }})
        </div>
      </div>
    </div>
    <div *ngIf="address.type === AddressType.addrgrp">
      <app-firewall-address-list
        ngClass="addressList--inner"
        [addressList]="address.member"
      ></app-firewall-address-list>
    </div>
  </li>
</ul>
