import { Component, Input } from '@angular/core';
import { CommentType, TicketComment } from '../../models/comment';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent {
  @Input() comment!: TicketComment;
  @Input() type: CommentType = CommentType.DEFAULT;

  public CommentType = CommentType;

  constructor() {}
}
