<span
  class="icon icon--custom"
  [ngClass]="iconClasses"
  [inlineSVG]="'/assets/img/icons/' + type + '.svg'"
  [fallbackSVG]="'/assets/img/icons/' + type + '.svg'"
  title="{{title || ariaLabel}}"
  [setSVGAttributes]="{
    class: 'icon__svg',
    'aria-label': ariaLabel,
    role: 'img',
    alt: ariaLabel
  }"
></span>
